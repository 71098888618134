import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle} from 'react-icons/fa';

import Dropzone from '../../Common/Dropzone.js'
import * as Style from '../../Common/StyledComponents';
import APICall from '../../../Common/APICall.js';
import Store from '../../../Common/Store.js';
import AlertBox from '../../Common/AlertBox.js';
import CSLLoader from '../../Common/CSLLoader.js';
import DatePicker from 'react-date-picker';

import '../../Common/DateSelect.css';


const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 995;
    opacity: 0.8;
    `;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #a0a0a0;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;

const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-weight: bold;
    font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 13px;
    float: right;
    cursor: pointer;
    font-family: Montserrat,sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 13px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
    font-family: Montserrat,sans-serif;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1002;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 95%;   
    margin-bottom: 10px;
    top: 5%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #DEDEDE;*/
    color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;
const ActionSelect = styled.select`    
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 92%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
    font-weight: bold;
`;

const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1005;
  opacity: 0.8;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    background-color: #EDF1F1;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const CSLDateCover = styled.div`
    margin-top: 0px;
    width: 250px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    font-weight: bold;
    `;
function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}

class AddActions extends React.Component
{
    state = { 
        showModal: false,
        disabled_button : false,
        binFiles: [],
        curBinFiles: [],
        action_title: "",
        actionDescription: "",
        action_status: "",
        assignedTo: 0,
        IsActionTitleProvided : false,
        IsActionAssignedToProvided : false,
        is_form_modified : false,
        open_data_lost_warning_modal : false,
        due_date : new Date(),
        first_request : true,
        view_mode : false,
        task_id : 0,
        parent_task_id : 0,
        alert_param : null,
        temp_id : null,
        cur_files_json: [],
        ready:false,
        followup_task: null,
        contactmap: [],
        unique_testing_act_id: null,
        risk_owners: [],
        is_loader:false,
        plan_ref:null
        
    }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prev_props) {
        if(prev_props === this.props) return
        this.setComponentState()

    }
    setComponentState = () => {
        console.log("RA:: Props::",this.props)
        let followup_task = this.props.followup_task
        let task_id = ('task_id' in this.props) ? this.props.task_id : this.state.task_id;

        let parent_task_id = this.props.parent_task_id

        let showModal = true

        let view_mode = (this.props.followup_task.cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE") ? true : false;
        console.log("view_mode::",view_mode)
        if(view_mode === true){
                    let postData = {"command": "get_task_file", "task_id": task_id};
                    let api = new APICall();
                    //let that = this;
                    api.command(postData, this.setCompTask)
        }else{
            if(parseInt(task_id) > 0 || typeof this.props.followup_task.temp_id !== 'undefined'){
                // console.log("in all life",this.props.followup_task.temp_id);
                if(parseInt(task_id) > 0){
                    ///for physical tasks
                    let postData = {"command": "get_task", "task_id": task_id};
                    let api = new APICall();
                    //let that = this;
                    api.command(postData, this.setTask)
                }else{
                    this.setState({ showModal, plan_ref:this.props.followup_task.plan_ref, due_date : this.props.followup_task.due_date,unique_testing_act_id:this.props.followup_task.testing_act_id, action_title: this.props.followup_task.name , actionDescription : this.props.followup_task.description , assignedTo : this.props.followup_task.cur_assigned_to, binFiles : this.props.followup_task.followupBinFiles , task_id : task_id , parent_task_id : parent_task_id, temp_id : this.props.followup_task.temp_id}, ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});                         

                }

            }else{
                // console.log("not in all life",this.props.followup_task.temp_id);
                this.setState({ showModal,due_date : new Date(), plan_ref:this.props.followup_task.plan_ref, action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0, curBinFiles : [], task_id : 0 , parent_task_id : this.props.parent_task_id}, ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});
            }
        }
        let gc_companies = Store.getStoreData('gc_companies')
        let user_info = Store.getStoreData('user_info')
        let role = Store.getStoreData('role')
        let contacts = Store.getStoreData('contacts')
        let user_roles = Store.getStoreData('user_roles')
        let plan = Store.getStoreData('selected_plan')

        let gc_id = role === "team" ? Store.getStoreData('team_gcid') : plan.gc_id

        let company_users_list = {}
        for(let gc of gc_companies) {
            if(gc.is_gc) {
                company_users_list[gc.id.toString()] = gc.users
            } else {
                company_users_list['0'] = gc.users
            }
        }
        let company_users = company_users_list[gc_id.toString()] 
        let risk_owners = []
        let found  = false
        for(let u_id in contacts) {
            if(user_roles[u_id] === 'admin_manager'){
                risk_owners.push({id: u_id, name: contacts[u_id]})
                //if(followup_task.testing_owner.toString() === u_id)found = true
            } else if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner' || user_roles[u_id] === 'subcat_owner' || user_roles[u_id] === 'team' || user_roles[u_id] === 'view_only_manager') {
                if(u_id in company_users){
                    risk_owners.push({id: u_id, name: contacts[u_id]})
                    //if(followup_task.testing_owner.toString() === u_id)found = true
                }
            }
        }
        let risk_owners_sorted =   risk_owners.sort((a, b) => a.name.localeCompare(b.name))

        this.setState({followup_task,risk_owners:risk_owners_sorted}) 

        
    }
    setCompTask = (result) =>{
        let task_id = ('task_id' in this.props) ? this.props.task_id : this.state.task_id;

        let parent_task_id = this.props.parent_task_id
        let showModal = true
        this.setState({ showModal, due_date : new Date(this.props.followup_task.due_date), action_title: this.props.followup_task.name, 
            actionDescription : this.props.followup_task.description , assignedTo : this.props.followup_task.cur_assigned_to , 
            binFiles : result.bin_files , action_note_undertaken : this.props.followup_task.action_note_undertaken , IsActionTitleProvided : false , IsActionAssignedToProvided : false , is_form_modified : false,showModal: true, first_request : false , view_mode : true , task_id : task_id , parent_task_id : parent_task_id}, ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});

    }
    setTask = (result) =>{
        console.log(result)
        let task_data = result.result.task.parenttask;
        let mainTask = JSON.parse(result.result.task.parenttask.task_json)
        let showModal = true
        let plan_ref = 'plan_ref' in mainTask.object_data ? mainTask.object_data.plan_ref : Store.getStoreData('selected_plan').unique_plan_id
        this.setState({ showModal, plan_ref:plan_ref, due_date : task_data.due_date,unique_testing_act_id:mainTask.object_data.headerinfo.unique_testing_act_id, 
            action_title: mainTask.object_data.headerinfo.risk_json.actions.action_title , actionDescription : mainTask.object_data.headerinfo.risk_json.actions.action_description , assignedTo : mainTask.object_data.headerinfo.risk_json.actions.action_assignto,
            binFiles : mainTask.object_data.bin_files, task_id : this.props.task_id , parent_task_id : this.props.parent_task_id, temp_id : this.props.followup_task.temp_id}, ()=>{this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});});                         
        
    }

    filesLoadedType2 = (jsonObj) => {
        // let me = Store.getStoreData('cmp-loggedin_user_info');
        let cur_files_json = this.state.curBinFiles;
        //console.log('files in Modal size 1==>', curBinFiles.length);
        let file_exists = {};
        let curBinFiles_1 = [];

            for (let i = 0; i < jsonObj.length; i++) {
                // console.log("curBinFiles_1",jsonObj[i])
                Object.assign(jsonObj[i], {user_id: Store.getStoreData('user_info').ID});
                Object.assign(jsonObj[i], {upload_date:  Date.now()});
                // Object.assign(jsonObj[i], {type: "complete_resource"});

                if (typeof file_exists[jsonObj[i].name] === "undefined") {
                    cur_files_json.push(jsonObj[i]);
                    file_exists[jsonObj[i].name] = true;
                }
                curBinFiles_1 = cur_files_json.filter((cur_files_json, index, self) => index === self.findIndex((t) => (t.name === cur_files_json.name && t.uid === cur_files_json.uid)));
                
            }
        
             this.setState({ curBinFiles: curBinFiles_1, is_form_modified: true });
    }

    filesRemovedType2 = (jsonObj) => {
        console.log('filesRemovedType2::',jsonObj)
        let curBinFiles = this.state.curBinFiles.filter(item => item.uid !== jsonObj);


        if (jsonObj.files_to_show_size === 0) {
            this.setState({ is_form_modified: false,curBinFiles });
        }else{
            this.setState({ curBinFiles });
        }
    }
    
    processcontactrole = () => {
        //alert(123)
        let roles = Store.getStoreData('user_roles');
        //console.log("RA:: roles",roles)
        let contacts = Store.getStoreData('contacts');
        let contactmap = [];

        for (const role in roles) {
          //console.log(`${role}: ${roles[role]}`);
          let uid = role;
          let urole = roles[role]
          
          if ( typeof contacts[uid] !== 'undefined' && (urole === 'admin_manager' || urole === 'risk_owner' || urole === 'team' || urole === 'plan_admin' || urole === 'subcat_owner')) {
            contactmap.push({ID:uid,name:contacts[uid]}) 
          }
        }
        //console.log("RA::contactmap",contactmap)
        return contactmap;

    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, is_form_modified: true});            
    }
    onScheduleDateChange = (date) => {
        // console.log("onScheduleDateChange state",this.state.due_date)

        // console.log("onScheduleDateChange",date)
        // let due_date = new Date(date)
        // let days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

        // console.log("onScheduleDateChange iso",days[due_date.getUTCDay()])
        this.setState({due_date: date});
    }
    closeAddActionModal = (event) => {
        event.preventDefault();
        this.setState({ showModal: false , task_id : 0 , parent_task_id : 0, plan_ref:null, action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0});
        this.props.closeModal()
    }

    submitAddActionValue = (event) => {
        event.preventDefault();
        // console.log("Submit State",this.state)
        if(this.state.action_title.trim() == '' || this.state.assignedTo === 0){
                if(this.state.action_title.trim() == ''){
                    this.setState({IsActionTitleProvided: true , disabled_button : false}); 
                }               

                if(this.state.assignedTo === 0 ){
                    this.setState({IsActionAssignedToProvided: true ,  disabled_button : false})
                }
                
        }else{
                event.preventDefault();
                // console.log("Addaction task_id::",this.state.task_id)
                this.setState({is_loader: true});
                if(parseInt(this.state.task_id) > 0){
                    //alert("task > 0")
                    let risk_json = {};
                        var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
                        // console.log("Submit State",this.bin_files)      
                        let actionsObject = {};
                        actionsObject.action_title = this.state.action_title;
                        actionsObject.action_description = this.state.actionDescription;
                        actionsObject.action_assignto = this.state.assignedTo;
                        //actionsObject.actionBinFiles = bin_files;
                        //actionsObject.due_date = this.state.due_date;    

                        console.log("actionsObject.due_date::",this.state.due_date)
                        let fs_due_date_obj = new Date(this.state.due_date);
                        let fs_day = fs_due_date_obj.getDate();
                        fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
                        let fs_month = fs_due_date_obj.getMonth() + 1;
                        fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
                        let fs_year = fs_due_date_obj.getFullYear();
                        let due_date = `${fs_year}-${fs_month}-${fs_day}T00:00:00`     
                        actionsObject.due_date = due_date    
                        console.log("actionsObject.due_date::",actionsObject.due_date)

                        risk_json.actions = actionsObject;                
                        let headerinfo = {}
                        headerinfo.risk_json = risk_json;
                        headerinfo.unique_testing_act_id = this.state.unique_testing_act_id ;
                        //headerinfo.bin_files = action_binfiles;           
                        let task = {
                            "task_data": { "module_id": "cmp", "last_action": "COMP_CMP_FOLLOWUP_CREATE", "ref_id" : this.state.unique_testing_act_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assignedTo, 'task_type' : 'COMP_CMP_FOLLOWUP', 'parent_task_id' : this.state.parent_task_id, 'cur_lane': 'COMP_CMP_FOLLOWUP_SAVED', due_date: due_date},
                            "object_data": {headerinfo},
                            "action_data": {action: 'COMP_CMP_FOLLOWUP_SAVED',actiondate :Date.now(), assigned_to: this.state.assignedTo, cur_lane : 'COMP_CMP_FOLLOWUP_SAVED'}
                        };
                        task.object_data.bin_files = bin_files;
                        task.object_data.plan_ref = this.state.plan_ref;
                        
                        let postData = {"command": "saveFollowupActionTask", "task_id" : this.state.task_id, "task_json" :task , "assigned_to" :this.state.assignedTo , "parent_task_id" : this.state.parent_task_id};
                          //api.command(postData, this.processSaveCompleteAction);
                        let api = new APICall();
                        let that = this;
                       api.command(postData, function callback(result){let alert_param = {title: 'Success', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: that.processSaveAction, }; that.setState({alert_param: alert_param,is_loader:false});});                   
                 
                         console.log("RA:: postData",postData)
                }else{
                    //alert("task < 0")
                    console.log("actionsObject.due_date::",this.state.due_date)
                        let fs_due_date_obj = new Date(this.state.due_date);
                        let fs_day = fs_due_date_obj.getDate();
                        fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
                        let fs_month = fs_due_date_obj.getMonth() + 1;
                        fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
                        let fs_year = fs_due_date_obj.getFullYear();
                        let due_date = `${fs_year}-${fs_month}-${fs_day}T00:00:00`               
                        console.log("actionsObject.due_date::",due_date)

                    var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
                    // this.props.refreshAddActionValue(this.state.action_title,this.state.actionDescription,this.state.assignedTo,bin_files,this.state.action_status , this.state.due_date,this.state.temp_id);
                    this.props.refreshAddActionValue(this.state.action_title,this.state.actionDescription,this.state.assignedTo,bin_files,this.state.action_status , due_date,this.state.temp_id);
                    this.setState({ showModal: false , task_id : 0 , is_loader:false,parent_task_id : 0, plan_ref:null,action_title: "", actionDescription: "", assignedTo: 0,binFiles: [],showModal: false , IsActionTitleProvided: false , IsActionAssignedToProvided : false , task_id : 0,disabled_button : false});

                }
        }
    }
    processSaveAction = (result , stack) => {
        

            //if(result === false) return;
            //console.log("processSaveAction stack",stack); 
            //this.props.processSaveAction();
            //this.props.actionModalOpen({'status' : false});
            let task_id = this.state.task_id
            let action_title =  this.state.action_title
            let actionDescription =  this.state.actionDescription
            let assignedTo =  this.state.assignedTo
            //let task_id = this.this.state.task_id

            this.setState({showModal:false,plan_ref:null,alert_param: null , first_request : true ,view_mode : false , task_id : 0 , parent_task_id : 0,disabled_button : false, action_title: "" , actionDescription : "" , action_status : "" , assignedTo : 0});
            var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
            this.props.refreshAddActionValueTask(task_id,action_title,actionDescription,assignedTo,this.state.due_date);
                    //this.props.closeModal();
    }   
    render() {
        console.log("RA:: State::",this.state)
        if(!this.state.showModal)return <div />
        //console.log("RA:: State::",this.state)
        return(
        
            <div > 
            {
                (() => {
                    if(this.state.is_loader){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <AlertBox alertParam = {this.state.alert_param}/>
                <InactiveOverlay style={{ height: "105%" }} />

                <MRModalContainer>
                    <MRModalHeader>
                    <MRModalHeaderText>Create / Update Action</MRModalHeaderText>   
                        <MRModalHeaderCloseBtn onClick={this.closeAddActionModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                    <RegModalContainertop>
                        <RegModalNameLabelDiv>Action Title</RegModalNameLabelDiv>
                        
                        {
                                        (() => {
                                            if(this.state.view_mode){
                                                return(
                                                    <RegModalNameLabelDiv>{this.state.action_title}</RegModalNameLabelDiv>
                                                    )
                                            }else{
                                                return(
                                                    <MRModalInput style={{width: "90%",marginLeft: "25px" , fontWeight: "bold" , color: "#000000"}} name="action_title" onChange={this.handleChange} value={this.state.action_title}/>
                                                )
                                            }
                                        })()
                                    }
                        {
                            (() => {
                                if(this.state.IsActionTitleProvided){
                                    return(
                                        <div style={{width: "200px" ,marginLeft: "150px", marginTop: "5px" }}>
                                            <div style={{clear: "both"}}></div>
                                            <label style={{color:"#FF0000"}}>Please provide action title.</label>
                                        </div>
                                        )
                                }  
                            })()
                        }                                   
                    </RegModalContainertop>
                    <RegModalContainertop style={{marginTop: "20px"}}>
                        <RegModalNameLabelDiv>Description</RegModalNameLabelDiv>
                        
{
                                        (() => {
                                            if(this.state.view_mode){
                                                return(
                                                    <RegModalNameLabelDiv>{this.state.actionDescription}</RegModalNameLabelDiv>
                                                    )
                                            }else{
                                                return(
                                                    <FCKTextarea rows="6" style={{width: "90%",marginLeft: "25px" , resize: "none"}} name="actionDescription" onChange={this.handleChange} value={this.state.actionDescription}/>
                                                    )
                                            }
                                        })()
                                    }
                        
                    </RegModalContainertop>
                    <RegModalContainertop style={{marginTop: "20px"}}>
                        <RegModalNameLabelDiv>Assigned To</RegModalNameLabelDiv>
                        {
                                        (() => {
                                            if(this.state.view_mode){
                                                return(
                                                    <RegModalNameLabelDiv>{Store.getStoreData('contacts')[this.state.assignedTo]}</RegModalNameLabelDiv>
                                                    )
                                            }else{
                                                return(
                                                <ActionSelect style={{marginLeft: "25px"}} name="assignedTo" onChange={this.handleChange} value={this.state.assignedTo}>
                                                <option key={0} value={0}>Select a user</option>
                                                    {/*
                                                        (() => {
                                                            let contactsdropdown = [];
                                                            let contacts = this.processcontactrole();
                                                            contacts.sort(GetSortOrder("name"));
                                                            contactsdropdown.push(<option key={0} value={0}>Select User</option>)
                                                            contacts.forEach((item) => {
                                                                contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
                                                            })
                                                            return contactsdropdown;                                                            
                                                        })()
                                                    */}
                                                    {
                                                        this.state.risk_owners.map((r, i) => {
                                                            return <option key={i} value={r.id}>{r.name}</option>
                                                        })
                                                    }
                        </ActionSelect>
                                                    )
                                            }
                                        })()
                                    }

                         {
                            (() => {
                                if(this.state.IsActionAssignedToProvided){
                                    return(
                                        <div style={{width: "200px" ,marginLeft: "150px", marginTop: "5px" }}>
                                            <div style={{clear: "both"}}></div>
                                            <label style={{color:"#FF0000"}}>Please select a user.</label>
                                        </div>  
                                        )
                                }  
                            })()
                        }                                                   
                    </RegModalContainertop>
                    <RegModalContainertop style={{marginTop: "20px"}}>
                        <RegModalNameLabelDiv>Due Date</RegModalNameLabelDiv>
                        <CSLDateCover style={{display: "inline-block", marginLeft: "27px"}}>
                                            <DatePicker
                                                    onChange={this.onScheduleDateChange}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    locale={"en-GB"}
                                                    value={new Date(this.state.due_date)}
                                                    style={{border:"none" ,  fontWeight: "bold"}}
                                                    className="csldatecontainer"
                                                    />
                        </CSLDateCover>

                    </RegModalContainertop>

                    <div style={{display: 'flex',marginTop: "25px"}}>
                                    <Style.ModalNameLabelDiv style={{width:"20%", marginRight: "50px",paddingTop: "3px" , color: "#000000" , fontWeight: "bold"}}>Attachments</Style.ModalNameLabelDiv>
                                    <Style.ModalNameInputDiv style={{ float: "right" ,marginRight: "50px"}}>
                                    {
                                        (() => {
                                            if(this.state.view_mode){
                                                return(
                                                    <Dropzone showFileButton={false} onFilesAdded={this.filesLoadedType2} initFiles={this.state.binFiles} onRemoveFile={this.filesRemovedType2} />
                                                    
                                                    )
                                            }else{
                                                return(
                                                    <Dropzone onFilesAdded={this.filesLoadedType2} initFiles={this.state.binFiles} onRemoveFile={this.filesRemovedType2} />
                                                )
                                            }
                                        })()
                                    }   
                                    </Style.ModalNameInputDiv>
                    </div>
                    <div style={{clear: "both"}} ></div>
                        <RegModalContainertop style={{marginTop: "20px"}}>
                            {
                                (() => {
                                    if(this.state.view_mode){
                                        return(
                                            <RegModalNameLabelDiv>Action Note Undertaken</RegModalNameLabelDiv>
                                            )
                                    }
                                })()
                            }                                   
                            {
                                (() => {
                                    if(this.state.view_mode){
                                        return(
                                            <RegModalNameLabelDiv>{this.state.followup_task.result[0].action_note_undertaken}</RegModalNameLabelDiv>
                                            )
                                    }
                                })()
                            }

                        </RegModalContainertop>
                    <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                    </MRModalBody>
                    <MRModalFooter>
                    <div style={{height: '50px', paddingRight: "31px", marginTop: "50px"}}>   
                    {
                                        (() => {
                                            if(this.state.view_mode){
                                                return (<div>&nbsp;</div>)
                                            }else{
                                                return (<MRModalNextBtn  style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7" }} onClick={this.submitAddActionValue}>Submit</MRModalNextBtn>) ;
                                            }
                                        })()
                                    }   
                                                            
                        
                        <MRModalCancelBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black"}} onClick={this.closeAddActionModal}>Cancel</MRModalCancelBtn>
                    </div>
                    </MRModalFooter>
                </MRModalContainer>
            </div>
            )
    }
}
export default AddActions;