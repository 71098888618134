import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaHome, FaCog, FaBook, FaElementor, FaWrench, FaRegListAlt , FaThLarge } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faGear, faSquareList } from "@fortawesome/pro-thin-svg-icons";
import * as Style from './StyledComponents'

const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;

class CSLHeader extends React.Component
{

    render()
    {
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		  let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		  use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		let role = Store.getStoreData('role');
		if(role === 'admin_manager'){
			return (
				<Style.HeadContainer>
					<Style.HomeLogo><FaHome style={{color: "#282828"}} /></Style.HomeLogo>
					<Style.HomeText><span style={{fontWeight: "600",color: "#282828"}}>{this.props.homeText}</span></Style.HomeText>
					<Categories title='Module Config' >
						<a href={'/moduleconfig'}>
							<FontAwesomeIcon icon={faGear} style={{ color: '#848484' }} />
						</a>
					</Categories>
					<Categories title='Audit Logs' >
						<a href={process.env.REACT_APP_AUDIT_URL}>
							<FontAwesomeIcon icon={faSquareList} style={{ color: '#848484' }} />
						</a>
					</Categories>
					<div style={{clear: "both"}}></div>
				</Style.HeadContainer>
			);
		}else{
			return (
				<Style.HeadContainer>
					<Style.HomeLogo><FaHome style={{color: "#282828"}}/></Style.HomeLogo>
                    <Style.HomeText><span style={{fontWeight: "600",color: "#282828"}}>{this.props.homeText}</span></Style.HomeText>
					<div style={{clear: "both"}}></div>
				</Style.HeadContainer>
			);

		}

    }
}
export default CSLHeader;
