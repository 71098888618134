import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FaTimes, FaPlusCircle, FaStarOfLife, FaCalendarAlt , FaTrash } from 'react-icons/fa';
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';
import CSLTable from '../../../Common/CSLTable.js';
import CSLLoader from '../../Common/CSLLoader.js';
import CustomFields from '../../Common/CustomFields.js';

import Dropzone from '../../Common/Dropzone.js'
import TAModal from './TAModal.js'
import SCModal from './SCModal.js'
import EventBus from '../../../Common/EventBus.js';
import AlertBox from '../../Common/AlertBox.js';
import CompleteModal from './CompleteModal.js';
import CMPUtils from '../../Common/CMPUtils.js';

const colorwiseTextStatus = { '#04ADA8' : 'Completed' , 'blue' : 'Scheduled' , '#F39C12' : 'Due' , 'red' : 'Overdue' };
const m_list = {'Jan': '0', 'Feb' : '1', 'Mar' : '2', 'Apr' : '3', 'May' : '4', 'Jun' : '5', 
                    'Jul' : '6', 'Aug' : '7', 'Sep' : '8', 'Oct' : '9', 'Nov' : '10', 'Dec' : '11'}

function includeTasks(visible_year, tas, months,risk_area) {

    // console.log("TAS::",tas)
    // console.log("TAS::months",months)
    // console.log("TAS::risk_area",risk_area)


    let ret = []
    //let risk_area = risk_areas
    // console.log("TSTACT Risk",risk_area) 
    let gc_companies = Store.getStoreData('gc_companies');
    let parent_comp = gc_companies.filter(function (comp) { return comp.is_gc === false })[0].id;
    //console.log('parent_comp::',parent_comp)
    let month_days = [31,28,31,30,31,30,31,31,30,31,30,31]
    if((0 == visible_year % 4) && (0 != visible_year % 100) || (0 == visible_year % 400)) month_days[1] = 29
    let all_physical_tasks = Store.getStoreData('plan_tasks')
    for(let t of tas) {

        let exclusions = 'exclusions' in t ? t.exclusions:{};

        if(t.unique_risk_id !== risk_area.unique_risk_id) continue;
        if(t.unique_subcat_id.toString() !== "0")continue;

        let stop_at = 100000000

        if('delete_reason' in t && t.delete_reason.reason === 'acitivity_by_series'){
        stop_at = ('delete_reason' in t && t.delete_reason.starting_from !== null) ? parseInt(t.delete_reason.starting_from) : stop_at

        }else{
        stop_at = ('delete_reason' in t && t.delete_reason.date_change_series !== null) ? parseInt(t.delete_reason.date_change_series.starting_from) : stop_at
        stop_at = ('delete_reason' in t && 
                    t.delete_reason.frequency_change !== null && 
                    parseInt(t.delete_reason.frequency_change.starting_from) < stop_at) ? parseInt(t.delete_reason.frequency_change.starting_from) : stop_at
        }

        let replaces = {}
        if('delete_reason' in t && typeof t.delete_reason.date_scheduled_change_single !== 'undefined' && t.delete_reason.date_scheduled_change_single !== null) {
            for(let ma of t.delete_reason.date_scheduled_change_single.missing_at) {
                let actual = ma.actual.toString()
                console.log("TAS:: actual::",ma.actual)
                console.log("TAS:: replaces::",ma.tobeShown)
                let tobeShown = ma.tobeShown.split('T')[0]
                let y_str = actual.substring(0, 4)
                // let m_str = actual.substring(4, 2)
                // let d_str = actual.substring(6, 2)
                let m_str = actual.substring(4, 6)
                let d_str = actual.substring(6, 8)
                
                let actual_id = `${t.unique_testing_act_id}_${y_str}-${m_str}-${d_str}`
                let replace_id = `${t.unique_testing_act_id}_${tobeShown}`
                let ts = tobeShown.split('-')
                let m = parseInt(ts[1]) - 1
                let y = parseInt(ts[0])
                let d = parseInt(ts[2])
                replaces[actual_id] = {y:y, m:m, d:d, id: replace_id}
            }
        }
    // console.log("TAS:: replaces::",replaces)
      //console.log('TSTACT t ',t.name,'=========================================================')
        //console.log('TSTACT t',  moment(new Date(t.first_schedule)).format("DD/MM/YYYY"))
        // let first_date_parts = moment(new Date(t.first_schedule)).format("DD/MM/YYYY")
        // let first_date_parts_split = first_date_parts.split('/')

        // let first_day = parseInt(first_date_parts_split[0])
        // let first_month = parseInt(first_date_parts_split[1])
        // let first_year = parseInt(first_date_parts_split[2])
        // let start_at = first_year * 10000 + first_month * 100 + first_day


        let first_date_parts = ''
        let first_day = ''
        let first_month = ''
        let first_year = ''
        if('first_schedule_dt_str' in t){
            first_date_parts = t.first_schedule_dt_str.split('/')

            first_day = parseInt(first_date_parts[0])
            first_month = parseInt(first_date_parts[1])
            first_year = parseInt(first_date_parts[2])
        }else{
            first_date_parts = t.first_schedule.split('T')[0].split('-')

            first_day = parseInt(first_date_parts[2])
            first_month = parseInt(first_date_parts[1])
            first_year = parseInt(first_date_parts[0])
        }
        let start_at = first_year * 10000 + first_month * 100 + first_day
        


        let by_days = false 
        let freq = 0
        switch(t.testing_freq) {
            case 'Annually': freq = 12; break;
            case 'Semi-Annually': freq = 6; break;
            case 'Quarterly': freq = 3; break;
            case 'Every-Two-Months': freq = 2; break;
            case 'Monthly': freq = 1; break;
            case 'Bi-weekly': by_days = true; freq = 14; break;
            case 'Weekly': by_days = true; freq = 7; break;
            case 'Daily': by_days = true; freq = 1; break;
        }
        //console.log('TSTACT t',t.freq,freq)
        //let risakarea_key = risk_area.filter(function (risk) { return risk.unique_risk_id === t.unique_risk_id });
        
        let risakarea_name = risk_area.risk_area_name;
        // console.log("TSTACT risakarea_key",risakarea_key) 
        // console.log("TSTACT risakarea_name",risakarea_name) 
        // console.log("TSTACT unique_subcat_id",t.unique_subcat_id) 

        //let subcat_name = t.unique_subcat_id.toString() === '0' ? '' : risk_area.subcategories.filter(function (sub) { return sub.unique_subcat_id === t.unique_subcat_id })[0].risk_subcategory_name;
        //console.log("TSTACT subcat_name",subcat_name) 
        let plan_gc_id = Store.getStoreData('plan_gc_id')
        let company_id = plan_gc_id.toString() === '0' ? parent_comp : plan_gc_id
        //console.log("first_schedule_dt_str::",t.first_schedule_dt_str)
        let first_schedule_dt_str = moment(t.first_schedule_dt_str).unix()
        //console.log("first_schedule_dt_str unix::",first_schedule_dt_str)
        let series_id = t.unique_risk_id+'-'+t.unique_subcat_id.toString()+'-'+t.unique_testing_act_id+'-'+t.plan_ref_id+'-'+t.gc_id;
        let base_task = {
                        // due_date: first_year*10000 + first_month*100 + first_day,
                        name: t.testing_activity,
                        subcat_id: t.unique_subcat_id,
                        testing_act_id: t.unique_testing_act_id,
                        task_id: null,
                        freq: t.testing_freq,
                        cur_lane: 'COMP_CMP_ASSIGNMENT',
                        completed_date: null,
                        testing_owner: t.testing_owner,
                        is_task: false,
                        risakarea_name: risakarea_name,
                        //subcat_name:subcat_name,
                        company_id:company_id,
                        cur_assigned_to:t.testing_owner,
                        risk_id: t.unique_risk_id,
                        first_schedule_dt_str: t.first_schedule_dt_str,
                        series_id: series_id
                   }

        if(by_days) {
            let fs_date =  moment(t.first_schedule)

            let fv_date = fs_date
            console.log("TSTACT fv_date::",fv_date)
            if(visible_year >= first_year) {
                let obj = CMPUtils.findNextDueDate(t.testing_freq, moment([visible_year]), t.first_schedule)
                let yyyy = obj.yy;
                let mm = obj.mm; // Months start at 0!
                let dd = obj.dd;

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                fv_date =moment( yyyy +'-'+ mm +'-'+ dd +'T00:00:00');                
                //let fy_date = moment([visible_year])
                // console.log("TSTACT fy_date::",fy_date)
                //fv_date = fy_date
                // if(freq > 1) {
                //     let diff = fy_date.diff(fs_date, 'days')
                //     console.log("TSTACT diff::",diff)
                //     let fd = freq - (diff % freq)
                //     console.log("TSTACT fd::",fd)
                //     fv_date = moment([visible_year, 0, fd])
                // }
                let d = fv_date
                console.log("TSTACT d::",d)
                while(d.year() === visible_year) {
                    let m = d.month()
                    let task_id = `${t.unique_testing_act_id}_${d.format('YYYY-MM-DD')}`
                    let task = JSON.parse(JSON.stringify(base_task))


                    if(task_id in replaces) {
                        let rep = replaces[task_id]
                        let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                        
                        task.due_date = rep.y +'/'+ mm_str +'/' + rep.d
                        let due_date = rep.y * 10000 + (rep.m + 1) * 100 + rep.d

                        
                        let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                        let exclude_dt = rep.y.toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                        }
                        //if(t.unique_risk_id === "Sc0tVrlZ")console.log('includeTasks task, start_at, stop_at', task, start_at, stop_at)
                        
                    } else {
                        task.due_date = d.year() + '/' + (d.month() + 1) + '/' + d.date()
                        let due_date = d.year() * 10000 + (d.month() + 1) * 100 + d.date()

                        let mm_str = (d.month() + 1) < 10 ? '0' + (d.month() + 1) : (d.month() + 1).toString()
                        let this_day_str = d.date() < 10 ? '0' + d.date() : d.date().toString()
                        let exclude_dt = d.year().toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[m].tasks[task_id] = task                          
                        }
                        //if(t.unique_risk_id === "Sc0tVrlZ")console.log('includeTasks task, start_at, stop_at', task, start_at, stop_at)
                    }

                    // task.due_date = d.year() +'/' + (d.month() + 1) +'/' + d.date()

                    // months[m].tasks[task_id] = task

                    d.add(freq, 'days')
                }
            }
        } else {
          
            if(freq === 0) {
                if(first_year === visible_year) {
                    let task = JSON.parse(JSON.stringify(base_task))
                    task.due_date = first_year +'/' + first_month +'/'+ first_day
                    let due_date = first_year*10000 + first_month*100 + first_day

                    let task_id = `${t.unique_testing_act_id}_${first_date_parts[2]}-${first_date_parts[1]}-${first_date_parts[0]}`

                    let physical_task_for_this_hint = Object.keys(all_physical_tasks).find(x => t.unique_testing_act_id === all_physical_tasks[x].testing_act_id && first_month === all_physical_tasks[x].month && visible_year === all_physical_tasks[x].year);
                    // let physical_task_for_this_hint1 = all_physical_tasks.filter(function (dt) { t.unique_testing_act_id === dt.testing_act_id && mm === dt.month});
                    if( t.unique_testing_act_id === 'sVKS1Y8MhVSK')console.log('includeTasks task::: physical_task_for_this_hint', physical_task_for_this_hint)
                    
                    if(typeof physical_task_for_this_hint !== 'undefined'){
                        task_id = physical_task_for_this_hint
                        // this_day = parseInt(physical_task_for_this_hint.split('-')[2])
                    }
                    if(task_id in replaces) {
                        let rep = replaces[task_id]
                        task.due_date = rep.y +'/' + (rep.m + 1) +'/' + rep.d
                        let due_date = rep.y * 10000 + (rep.m + 1) * 100 + rep.d

                        let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                        let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                        let exclude_dt = rep.y.toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                        }

                        
                    } else {
                        let mm_str = (first_month) < 10 ? '0' + (first_month) : (first_month).toString()
                        let this_day_str = first_day < 10 ? '0' + first_day : first_day.toString()
                        let exclude_dt = first_year.toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[first_month - 1].tasks[task_id] = task
                        }
                        
                    }
                    //months[first_month - 1].tasks[task_id] = task
                }
            } else {
                for(let m of months) {
                    //console.log("TAS:: m",m)
                    let mm = m.m + 1
                    let month_diff = (m.y - first_year - 1) * 12 + 12 - first_month + mm
                    if(month_diff >= 0 && month_diff % freq === 0) {
                        let task = JSON.parse(JSON.stringify(base_task))
                        let this_day = first_day > month_days[m.m] ? month_days[m.m] : first_day
                        let this_day_str = this_day < 10 ? '0' + this_day : this_day.toString()
                        let this_month_str = mm < 10 ? '0' + mm : mm.toString()
                        let task_id = `${t.unique_testing_act_id}_${m.y}-${this_month_str}-${this_day_str}`
                        //console.log("TAS:: task_id::",task_id)
                        console.log("exclusions A::",exclusions)
                        let physical_task_for_this_hint = Object.keys(all_physical_tasks).find(x => t.unique_testing_act_id === all_physical_tasks[x].testing_act_id && mm === all_physical_tasks[x].month && visible_year === all_physical_tasks[x].year);
                        // let physical_task_for_this_hint1 = all_physical_tasks.filter(function (dt) { t.unique_testing_act_id === dt.testing_act_id && mm === dt.month});
                        // if( t.unique_testing_act_id === 'sVKS1Y8MhVSK')console.log('includeTasks task::: physical_task_for_this_hint', physical_task_for_this_hint)
                        
                        if(typeof physical_task_for_this_hint !== 'undefined'){
                            task_id = physical_task_for_this_hint
                            this_day = parseInt(physical_task_for_this_hint.split('-')[2])
                        }
                        if(task_id in replaces) {

                            let rep = replaces[task_id]
                            task.due_date = rep.y +'/'+ (rep.m + 1) +'/' + rep.d
                            let due_date = rep.y * 10000 +  (rep.m + 1) * 100 +  rep.d

                            let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                            let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                            let exclude_dt = rep.y.toString() + mm_str + this_day_str

                            if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                              //exclude
                            }else{
                              if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                            }
                            
                        } else {
                            task.due_date = m.y +'/'+ mm +'/'+ this_day
                            let due_date = m.y*10000 + mm*100 + this_day
                            
                            let mm_str = mm < 10 ? '0' + mm : mm.toString()
                            let this_day_str = this_day < 10 ? '0' + this_day : this_day.toString()

                            let exclude_dt = m.y.toString()+ mm_str + this_day_str
                            
                            if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                              //exclude
                            }else{
                                if(due_date >= start_at && due_date < stop_at)m.tasks[task_id] = task
                            }
                        }
                        //task.due_date = m.y+'/' + mm+'/' + this_day

                        //m.tasks[task_id] = task
                    }
                }
            }
             //console.log("at false TSTACT months::",months)
        }
      
    }

    //console.log("TSTACT months::",months)
    // console.log("TALLY months::",months)

    let plan_tasks = Store.getStoreData('plan_tasks')
    if(plan_tasks){
        for(let pid in plan_tasks) {
           //console.log("INCLUDE tas plan",plan_tasks[pid].risk_id,risk_id,plan_tasks[pid].subcat_id,subcat_id)
            if(plan_tasks[pid].risk_id.toString() === risk_area.unique_risk_id.toString()){
                    
                    let t_dt = pid.split('_')[1]
                    let t_dts = t_dt.split('-')
                    let m_num = parseInt(t_dts[1]) - 1
                    let y_num = parseInt(t_dts[0]) 
                    // if(risk_id === 'n1T7p0Ei')console.log('INCLUDE tas pid, m_num, months', pid, m_num,  months[m_num].tasks)
                    if(pid in months[m_num].tasks) {
                        months[m_num].tasks[pid].completed_date = plan_tasks[pid].completed_date
                        months[m_num].tasks[pid].cur_lane = plan_tasks[pid].cur_lane
                        months[m_num].tasks[pid].task_id = plan_tasks[pid].task_id
                        months[m_num].tasks[pid].is_task = true 
                        months[m_num].tasks[pid].approver_id = "approver_id" in plan_tasks[pid] ? plan_tasks[pid].approver_id : 0

                    } else if(y_num === visible_year){
                        // console.log('includeTasks pid, plan_tasks[pid]', pid, plan_tasks[pid])
                        months[m_num].tasks[pid] = plan_tasks[pid]
                        months[m_num].tasks[pid].is_task = true
                    }
            }
        }        
    }

    return months;
}

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;

const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 100px);
    width: calc(100% - 650px);
    position: fixed;
    top: 76px;
    left: 300px;
    z-index: 1005;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;

const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-weight: bold;
    margin-left: 24px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    color: #758597;      
    padding: 10px;
    font-weight: 600;
`;
const RiskSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
    font-weight: bold;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1800px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 11px;
  top: -5px;
  z-index: 1002;
  opacity: 0.8;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: calc(96% - 5px);
    margin: 0 auto;
    margin-bottom: 30px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: calc(96% - 5px);
    margin: 0 auto;
    margin-top: 30px;    
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const TABoxContainer = styled.div`
    box-sizing: border-box;
    /*border-right: 1px solid #dadbdc;*/
    padding: 5px;
`;
const TABox = styled.div`
    background-color: #04ADA8;
    width: 83% ;
    height: 25px;
    border-radius: 4px;
    /*cursor: pointer;*/
`;


/*  */
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
    height:calc(100vh - 260px);
    overflow-y:auto
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const SelectedRadio = styled.div`
    background-color: #37ADA7;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;
const RadioBlock = styled.div`
    margin-top: 10px;
`;



class RAModal extends React.Component {
    state = {ready: false,is_loader:false,dev_modal: null, edit_object: null, risk_tasks:[], binFiles: [], risk_area: null, plan: null, risk_id: '0', risk_owners: [], 
    risk_impacts: [], testing_activities: [],  subcategories: [], IsActionTitleProvided:false,IsActionImpactToProvided:false,IsActionAssignedToProvided:false,
    IsActionstatusProvided:false,show_messasge:false,risk_area_name:'',risk_area_desc:'',initial_status:'Active',create_new_risk:false,alert_param:null,
    additional_fields : [],comp_risk_area:[], comp_task_id:0, curBinFiles: []}

    genKey(length){
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    componentDidMount = () => {
        this.componentRef = React.createRef();
        this.setComponentState_call()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
        this.componentRef = React.createRef();
        this.setComponentState_call()
    }

    setComponentState_call = () => {

        console.log("RAMODAL Props::",this.props)

        if(!this.props.create_new){
            let api = new APICall();
            let postData = { command: "list_ta_tasks"};
            postData['role'] = Store.getStoreData('role')
            postData['gcs_tasks'] = Store.getStoreData('gcs_tasks')
            postData['show_completed_tasks'] = true
            postData['plan_ref'] = this.props.plan.unique_plan_id
            let plan_gc_id = Store.getStoreData('plan_gc_id')
            postData['plan_gc_id'] = plan_gc_id
            postData['unique_risk_id'] = this.props.risk_area.unique_risk_id



            //console.log("setComponentState::",postData)
            api.command(postData, this.setComponentState);
        }else{
            let result = []
            this.setComponentState(result)

        }


    }
    setComponentState = (result) => {
        console.log("RAMODAL setComponentState::",result)
        
        let user_info = Store.getStoreData('user_info')
        let user_id = user_info.ID.toString()
        let create_new_risk = this.props.create_new
        let plan = this.props.plan
        let subcategories_parent_id = this.genKey(20)
        let risk_area = {
            created_by: user_id.toString(),
            description: "",
            attachments: [],
            gc_id: this.props.gc_id,
            plan_ref_id: plan.unique_plan_id,
            risk_area_name: "",
            risk_impact: '0',
            risk_owner_id: "0",
            selected: false,
            status: "Active",
            subcategories: {__type: 'list', value: subcategories_parent_id},
            subcategories_parent_id: subcategories_parent_id,
            unique_risk_id: this.genKey(20),
            additional_fields:[],
            __parent_id: plan.risk_areas_parent_id,
            __record_id: this.genKey(20),
        }
        risk_area = this.props.risk_area === null  ? risk_area : JSON.parse(JSON.stringify(this.props.risk_area))
        risk_area.attachments = 'attachments' in risk_area && risk_area.attachments!==null ? risk_area.attachments : []
        let curBinFiles = risk_area.attachments;
        risk_area.subcategories = {__type: 'list', value: risk_area.subcategories_parent_id}
        if(!('risk_impact' in risk_area))risk_area.risk_impact='0'
        let mc = Store.getStoreData('module_config')
        let found = false

        let additional_fields = []
        let active_additional_fields = {};
        if(!this.props.create_new && 'additional_fields' in risk_area){
            additional_fields = risk_area.additional_fields;
        }else{
            if(Object.keys(mc).length > 0) {
                if (mc.custom_fields.additional_fields.length !== 0 && mc.custom_fields.enabled) {
                    mc.custom_fields.additional_fields.forEach((item) => {
                        let copy_item = JSON.parse(JSON.stringify(item));
                        if('displayin' in item && (item.displayin === 'All Records' || item.displayin === 'Risk Area only') && item.isActive.toString() === '1' && item.isDelete.toString() === '0'){
                            active_additional_fields[item.name] = {'required' : item.required};
                            copy_item.value = item.type === "Calendar" ? new Date() : "";
                            let default_dropdown_value = (copy_item.value.trim() === '') ? '' : copy_item.value; //for dropdown only
                            copy_item.value = item.type === "Dropdown" ? default_dropdown_value : copy_item.value;
                            additional_fields.push(copy_item);
                        }
                    })
                }
            }
        }

        // console.log("RAMCAF additional_fields::",additional_fields)
        let risk_tasks = []
        if(!this.props.create_new){
            let testing_activities = Store.getStoreData('testing_activities')
            let visible_year = moment().year();
            let month_tasks = []
            for(let i=0; i < 12; i++) month_tasks.push({m: i, y: visible_year, tasks: {}})

            let start_year = new Date(
            Math.min(
                ...plan.testing_activities.map(element => {
                  return new Date(element.first_schedule);
                }),
              ),
            ).getFullYear();

            let end_year = moment().year();

            let all_hints = this.sort_hints(start_year,end_year,testing_activities,risk_area)

            //includeTasks(visible_year, testing_activities, month_tasks,risk_area)

            // console.log("TAS:: all_hints::",all_hints)

            let plan_tasks = Store.getStoreData('plan_tasks')
            for(let mo of all_hints) {
              for(let id in mo.tasks) {
               
                if(!plan_tasks.hasOwnProperty(id)){
                     result.push(mo.tasks[id])
                }
              }
            }

            risk_tasks = result;
            //this.setState({tasks:result});

        }
        
        risk_tasks.sort(function(a, b){return new Date(a.due_date) - new Date(b.due_date);})
        // let risk_area_name = risk_area.risk_area_name
        // let risk_area_desc = risk_area.description
        // let binFiles   = risk_area.attachments

        let initial_status = risk_area.status

        for(let im of mc.risk_impact) {
            if(risk_area.risk_impact === im.id)found = true
        }
        if(!found)risk_area.risk_impact = '0'
        
        let risk_id = this.props.risk_area === null ? '0' : risk_area.unique_risk_id

        // let mc = Store.getStoreData('module_config')
        let risk_impacts = []
        // console.log('plan===>', risk_area)
        for(let im of mc.risk_impact) {
            if(im.status === 'Active' || risk_area.risk_impact === im.id)risk_impacts.push(im)  
        }

        let gc_companies = Store.getStoreData('gc_companies')
        let role = Store.getStoreData('role')
        let contacts = Store.getStoreData('contacts')
        let user_roles = Store.getStoreData('user_roles')
        // let gc_companies = Store.getStoreData('gc_companies')
        let company_users_list = {}
        for(let gc of gc_companies) {
            if(gc.is_gc) {
                company_users_list[gc.id.toString()] = gc.users
            } else {
                company_users_list['0'] = gc.users
            }
        }
        let company_users = company_users_list[plan.gc_id.toString()] 
        let risk_owners = []
        found  = false
        for(let u_id in contacts) {
            if(user_roles[u_id] === 'admin_manager'){
                risk_owners.push({id: u_id, name: contacts[u_id]})
                if(risk_area.risk_owner_id.toString() === u_id)found = true
            } else if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner') {
                if(u_id in company_users){
                    risk_owners.push({id: u_id, name: contacts[u_id]})
                    if(risk_area.risk_owner_id.toString() === u_id)found = true
                }
            }
        }
        if(!found)risk_area.risk_owner_id = '0'
        let all_testing_activities = plan.testing_activities;
        let testing_activities = all_testing_activities.filter((t, i) => {
            return (t.unique_risk_id === risk_id && t.unique_subcat_id === "0")
        })
        let risk_areas = plan.risk_areas;
        let risk = risk_areas.filter((t, i) => {
            return (t.unique_risk_id === risk_id)
        })
        let subcategories = risk.length === 0 ? [] : risk[0].subcategories
        // console.log("testing_activities", risk)

        let dev_modal = null;
        let edit_object = null;
        if(this.props.from_sub !== null && this.props.create_new === false){
            dev_modal = 'sc';
            edit_object = this.props.from_sub
        }

        let ready = true
        let risk_owners_sorted =   risk_owners.sort((a, b) => a.name.localeCompare(b.name))

        this.setState({ready, dev_modal,edit_object,create_new_risk,curBinFiles, risk_tasks, additional_fields, plan, risk_area, risk_impacts,initial_status, risk_owners:risk_owners_sorted,risk_id, testing_activities,subcategories}, () => {this.componentRef.current.scrollTop = 0;})

    }
    sort_hints = (filter_start_year,filter_end_year,testing_activities,risk_areas) => {
        // console.log("JAIMA::sort_hints pram",filter_start_year,filter_end_year,testing_activities,risk_areas)
        let visible_year = [];
        let month_tasks = []
        visible_year.push(parseInt(filter_start_year))
        for(let i=0; i < 12; i++) month_tasks.push({m: i, y: parseInt(filter_start_year), tasks: {}})

        let inc_filter_start_year = filter_start_year
        if(filter_start_year !== filter_end_year){

            while(inc_filter_start_year < filter_end_year) {
                inc_filter_start_year++
                visible_year.push(parseInt(inc_filter_start_year))
                for(let i=0; i < 12; i++) month_tasks.push({m: i, y: parseInt(inc_filter_start_year), tasks: {}})
            }
        }

        // console.log('JAIMA::: testing_activities',testing_activities)
        // console.log("sort_hints::: visible_year",visible_year)       
        // console.log('sort_hints::: month_tasks::',month_tasks)
        let all_hints = []

        for(let x=0; x < visible_year.length; x++){
            let curr_month_tasks = []
            for(let i=0; i < 12; i++) curr_month_tasks.push({m: i, y: parseInt(visible_year[x]), tasks: {}})
            let curr_year_hints = includeTasks(parseInt(visible_year[x]), testing_activities, curr_month_tasks,risk_areas)
            // console.log('sort_hints::: curr_year_hints::',curr_year_hints)
            // console.log('sort_hints::: all_hints1::',all_hints)
            all_hints = [...all_hints,...curr_year_hints]
            // consoimport CMPUtils from '../Common/CMPUtils.js';import CMPUtils from '../Common/CMPUtils.js';import CMPUtils from '../Common/CMPUtils.js';import CMPUtils from '../Common/CMPUtils.js';le.log('sort_hints::: all_hints2::',all_hints)
        }
        //let all_hints = includeTasks(visible_year, testing_activities, month_tasks,risk_areas)
        return all_hints;
    }  
    openDeleteInterfaceSubcat = (subcat_id) => (event)=>{
        // console.log("openDeleteInterfaceSubcat",subcat_id)

        let testing_act_exists_flag = 0;

        let risk = this.state.risk_area;
        let plan = this.state.plan;

        for(let p of plan.testing_activities){
            if(p.unique_risk_id.toString() === risk.unique_risk_id && p.unique_subcat_id.toString() === subcat_id.toString() && p.is_deleted === false){
                testing_act_exists_flag = 1;
                break;
            }
        }


        // console.log("RAMODAL testing_act_exists_flag::",testing_act_exists_flag)


        if(testing_act_exists_flag === 1){
            let alert_param = {title: 'Alert', message: 'Testing Activities are assigned to this Subcategory, these must be removed first.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {subcat_id : subcat_id} };
            this.setState({alert_param: alert_param});          
        }else{
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to delete this subcategory?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.processSubcatDeleteRequest, stack: {unique_risk_id : risk.unique_risk_id , subcat_id : subcat_id ,gc_id : this.props.gc_id , plan_id : plan.unique_plan_id } , continue_to_next_screen_if_yes: false };
            this.setState({alert_param: alert_param});              
        }
    }
    AlertHandler = (result, stack) => {
        this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
    }
    processSubcatDeleteRequest = (result , stack ) => {
       console.log(result , stack)
       this.setState({alert_param: null} , ()=>{

            if(result === false) return;

            console.log("stack==>",stack);

            //let risk_data = this.state.risk_area;

            //let subcat_data = {};
            let subcategories = this.state.subcategories;
            let record  = {}
            console.log("RAMODAL subcategories A",subcategories)

            for(let i=0;i<subcategories.length;i++){
                if(subcategories[i].unique_subcat_id.toString() === stack.subcat_id.toString()){
                    // subcategories[i].status = 'Deleted';
                    subcategories[i].subcat_status = 'Deleted';
                    record = subcategories[i];
                }
            }
            //risk_data.subcategories = subcategories;

            console.log("RAMODAL subcategories",subcategories)

            let api = new APICall()
            console.log("RAMODAL record",record)
            let plan = this.state.plan
            let sub_command = 'update_record'
            record.ref_ids = ['sub_categories', record.unique_subcat_id, record.risk_subcategory_name]
            // record.attachments = attachments;
            let postData = {command: 'plan', sub_command: sub_command, record: record, object_id: plan.unique_plan_id}
            console.log("RAMODAL postData",postData)

            api.command(postData, this.processSubcatPostDelete)


        });
    }
    processSubcatPostDelete = () =>{
                    //Store.updateStore('from_riskarea_to_activity', true);
            let plan = this.state.plan
            
            const callback_values = {
                'plan_ref_id':plan.unique_plan_id,
                'dev_modal':'ra',
                'unique_risk_id':this.state.risk_area.unique_risk_id
            }
            EventBus.raiseEvent('data_risk_sub_reload', callback_values)
            let alert_param = {title: 'Success', message: 'Subcategory deleted successfully', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} , continue_to_next_screen_if_yes: false };
            this.setState({alert_param: alert_param});
    }
    // closeSubcat = () =>{
    //                 //Store.updateStore('from_riskarea_to_activity', true);
    //         let plan = this.state.plan
            
    //         const callback_values = {
    //             'plan_ref_id':plan.unique_plan_id,
    //             'dev_modal':'ra',
    //             'unique_risk_id':this.state.risk_area.unique_risk_id
    //         }
    //         EventBus.raiseEvent('data_risk_sub_reload', callback_values)
    //         this.setState({alert_param: alert_param});
    // }
    processSubcategoryData = () => {
        let ret = {data: [], columns: []};
        let i = 0;
        let initial_status = this.state.initial_status;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        {Header: 'Subcategory', accessor: 'risk_subcategory_name', minWidth: 90, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'subcat_description', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {Header: 'Assign to', accessor: 'subcat_assignto', minWidth: 70, headerStyle: {textAlign: 'left'}},
        {Header: 'Status', accessor: 'subcat_status', minWidth: 70, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
        <div>           
            {
                (() => {
                    //need to restrict by role
                    if(this.state.initial_status === 'Inactive'){
                        return (<MRButton style={{marginLeft:'30px', backgroundColor: "grey"}} onClick={() => this.openModal('sc', row.original.sc)}>View</MRButton>);
                    }else{
                        return (<MRButton style={{marginLeft:'30px', cursor: 'pointer' }} onClick={() => this.openModal('sc', row.original.sc)}>View</MRButton>);
                    }
                })()
            }
            {
                (() => {
                    //need to restrict by role
                    return (<FaTrash title="Delete this subcategory" onClick={this.openDeleteInterfaceSubcat(row.original.unique_subcat_id)} style={{marginLeft:'20px',cursor: 'pointer' ,  marginTop: "5px"}} />);
                })()
            }
            
            {/*<FaTrash title="Delete this subcategory"  style={{cursor: 'pointer' , marginTop: "5px"}} />*/}
        </div>
        ), width: 270, headerStyle: {textAlign: 'center'}},
        ];
        //let risk_area = this.this.state.risk_area
        let k = 1;
        let subcategories = this.state.subcategories;
        for(var j = 0 ; j< subcategories.length ; j++) {
            console.log("SCMODAL List",subcategories[j])
            let subcat_status = 'subcat_status' in subcategories[j] ? subcategories[j].subcat_status : subcategories[j].status
            if(subcat_status !== 'Deleted'){
            let elem = {'index':k,
                        'risk_subcategory_name': subcategories[j].risk_subcategory_name,
                        'subcat_description': subcategories[j].subcat_description,
                        'subcat_assignto': Store.getStoreData('contacts')[subcategories[j].subcat_assignto],
                        'subcat_status': subcat_status,
                        'unique_subcat_id': subcategories[j].unique_subcat_id,
                        'sc': subcategories[j]
                    }
            ret.data.push(elem);
            k++;
            }
        }
        
        return ret;
    }
    processTestingActivitiesData = () => {
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        {Header: 'Activity', accessor: 'activity', minWidth: 90, headerStyle: {textAlign: 'left'}},
        {Header: 'Assign to', accessor: 'assigned_to', minWidth: 70, headerStyle: {textAlign: 'left'}},
        {Header: 'Frequency', accessor: 'frequency', minWidth: 70, headerStyle: {textAlign: 'left'}},
        {Header: 'Due Date', accessor: 'due_date_timestamp', Cell: c => <div style={{borderRadius: "4px" , padding: "4px", height : "25px" ,width : "100%" , textAlign: 'center' , color : "#111111"}}>{c.original.due_date}</div>, minWidth: 120, headerStyle: {textAlign: 'left'},
        sortType: 'datetime'},
        // {Header: 'Due Date', accessor: 'due_date' , minWidth: 60, headerStyle: {textAlign: 'left'}},
        {Header: 'Status', Cell: row => (
        <div>
            <TABoxContainer style={{padding : "0px"}}>
                                <TABox style={{backgroundColor : row.original.color , width: "80%"}}>
                        
                                    <div style={{clear: "both"}}></div>
                                </TABox>
            </TABoxContainer>                       


        </div>
        ), width: 100, headerStyle: {textAlign: 'center'}},
        {'Header' : 'Action', Cell: row => (
        <div>

            {
                (() => {
                    if(this.state.initial_status === 'Inactive'){
                        return (<MRButton style={{marginLeft:'30px', backgroundColor: "grey"}}>View</MRButton>);
                    }else{
                        if(row.original.status === 'Incomplete'){
                            return (<MRButton  style={{marginLeft:'30px',cursor: 'pointer'}} onClick={() => this.openModalTA('ta', row.original.ta,row.original.due_date)}>View</MRButton>);
                        }else{
                           return ( <MRButton style={{marginLeft:'30px'}} onClick={() => this.openModalTAComp('ta_action', row.original.ta,row.original.due_date,row.original.task_id,)}>View</MRButton>)
                        }
                    }
                    //need to restrict by role
                    
                })()
            }
            {
                (() => {
                    //need to restrict by role
                    if(row.original.status === 'Incomplete'){
                        if(this.state.initial_status === 'Inactive'){
                            //NO DELE
                        }else{
                            return (<FaTrash title="delete this testing activity" onClick={this.openDeleteInterface(row.original.ta,row.original.ref_id,row.original.task_id,row.original.unique_risk_id,row.original.unique_subcat_id,row.original.unique_testing_act_id)} style={{cursor: 'pointer' , marginLeft:'20px', marginTop: "5px"}} />);
                        }
                        
                    }
                })()
            }   
            
            
                            

        </div>
        ), width: 270, headerStyle: {textAlign: 'center'}},
        ];
        let testing_activities = this.state.risk_tasks;
        testing_activities.sort(function(a, b){return new Date(a.due_date) - new Date(b.due_date);})
        // console.log('RAMODAL testing_activities::',testing_activities)

        let pass = 0;
        let today = moment().unix()
        let k = 1;
        for(var j = 0 ; j< testing_activities.length ; j++) {
            //console.log("testing_activities[j]::",testing_activities[j].due_date,testing_activities[j])
            if(testing_activities[j].cur_lane === 'COMP_CMP_COMPLETED')continue;
            let activity_status = (testing_activities[j].cur_lane === 'COMP_CMP_COMPLETED') ? 'Complete' : 'Incomplete';
            let color = this.compareDateGetColor(testing_activities[j].due_date,testing_activities[j].cur_lane);

            let gc_companies = Store.getStoreData('gc_companies')
            let parent_comp_id = gc_companies.find(d => d.is_gc === false).id

            let fs_due_date_obj = new Date(testing_activities[j].due_date);
            let fs_day = fs_due_date_obj.getDate();
            fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
            let fs_month = fs_due_date_obj.getMonth() + 1;
            fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
            let fs_year = fs_due_date_obj.getFullYear();

            let ref_id = this.props.plan.id+'-'+this.props.plan.gc_id+'-'+testing_activities[j].risk_id+'-'+testing_activities[j].subcat_id+'-'+testing_activities[j].testing_act_id+'-'+`${fs_year}${fs_month}${fs_day}`+'-'+parent_comp_id;

            let series_id = 'series_id' in testing_activities[j] ? testing_activities[j].series_id : testing_activities[j].risk_id+'-'+testing_activities[j].subcat_id.toString()+'-'+testing_activities[j].testing_act_id+'-'+testing_activities[j].plan_ref_id+'-'+this.props.plan.gc_id;
            testing_activities[j].series_id = series_id
            let show = this.makeDecisionToStayInTheList(testing_activities[j],series_id)




            // console.log('testing_activities RAMODAl::',testing_activities[j],testing_activities[j].due_date,moment(testing_activities[j].due_date).format("DD/MM/YYYY"))
            if(show){
                let task_id = testing_activities[j].task_id === null ? 0 : testing_activities[j].task_id

                let main_testing_activity = Store.getStoreData('testing_activities').filter(function (t) { return t.unique_testing_act_id === testing_activities[j].testing_act_id })[0];
                let testing_activity_name = task_id !== 0 ? main_testing_activity.testing_activity : testing_activities[j].name
                let cur_assigned_to = task_id !== 0 ? main_testing_activity.testing_owner : testing_activities[j].cur_assigned_to

                let elem = {'index':k,
                            'activity': testing_activity_name,
                            'assigned_to': Store.getStoreData('contacts')[cur_assigned_to],
                            'frequency': testing_activities[j].freq,
                            'due_date' : moment(testing_activities[j].due_date).format("DD/MM/YYYY"),
                            'due_date_timestamp' : moment(testing_activities[j].due_date).unix(),
                            'unique_testing_act_id': testing_activities[j].testing_act_id,
                            'unique_risk_id': testing_activities[j].risk_id,
                            'unique_subcat_id': testing_activities[j].subcat_id,
                            'ta':testing_activities[j],
                            'status':activity_status,
                            'color' : color,
                            'task_id' : testing_activities[j].task_id === null ? 0 : testing_activities[j].task_id,
                            'ref_id' : ref_id
                        }
                k++
                ret.data.push(elem);
            }
            // console.log("TAS:: all_hints::DATECHECK::",moment(testing_activities[j].due_date).unix() , today)

           // if(moment(testing_activities[j].due_date).unix() > today){
                
           //      pass = 1
           //  }

           //  if(pass === 1)break;
        }
        
        return ret;
    }
    makeDecisionToStayInTheList = (current_task,series_id) => {
        // console.log("makeDecisionToStayInTheList::",current_task,series_id)
        let all_tasks = this.state.risk_tasks;
        let same_series_tasks_array = all_tasks.filter(tact=>tact.series_id===series_id)
        

        let show = false;
        if(typeof same_series_tasks_array !== 'undefined' && same_series_tasks_array.length > 0){
            let current_index = 0;
            for(let i=0;i<same_series_tasks_array.length;i++){
                if('task_id' in current_task && current_task.task_id !== null){
                    if(current_task.task_id === same_series_tasks_array[i].task_id){
                        // console.log("makeDecisionToStayInTheList task id same_series_tasks_array::",i,same_series_tasks_array[i])
                        current_index = i;
                        break;
                    }               
                }else{
                    if(moment(current_task.due_date).unix() === moment(same_series_tasks_array[i].due_date).unix()){
                        // console.log("makeDecisionToStayInTheList date same_series_tasks_array::",i,same_series_tasks_array[i])
                        current_index = i;
                        break;
                    }               
                }

            }
            // console.log("makeDecisionToStayInTheList current_index::",current_index)
            if(same_series_tasks_array.length === 1){
                show = true;
            }else{
                for(let i=0;i<same_series_tasks_array.length;i++){
                    if(i === 0 && typeof current_index !== 'undefined' && current_index.toString() === i.toString()){
                        show = true;
                        break;
                    }
                    if(i > 0 &&  typeof current_index !== 'undefined' && current_index.toString() === i.toString()){
                        let previous_task_id = same_series_tasks_array[i-1].task_id;
                        if(same_series_tasks_array[i].cur_lane !== "COMP_CMP_COMPLETED" && same_series_tasks_array[i-1].cur_lane === "COMP_CMP_COMPLETED"){
                            show = true;
                            break;                      
                        }
                        if(same_series_tasks_array[i].cur_lane === "COMP_CMP_COMPLETED" ){
                            show = true;
                            break;                      
                        }

                    }
                }
                //console.log("HHH current_index=>",current_index , show , current_task.id , same_series_tasks_array);
            } 
        }
        return show;
    } 
    openDeleteInterface = (ta,ref_id,task_id,unique_risk_id,unique_subcat_id,unique_testing_act_id) =>(event)=>{
        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID
        if((role === 'risk_owner' && (ta.testing_owner !== user_id.toString() && ta.created_by !==  user_id.toString()))) {
            
                let alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
        }else{
            console.log("openDeleteInterface::",task_id,unique_risk_id,unique_subcat_id,unique_testing_act_id)
            let alert_param = {title: 'Confirm', message: 'Previous Testing Activities and remedial actions may not have been completed. Are you sure you want to delete this activity?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.processDeleteRequest, stack: {testing_activity:ta,ref_id:ref_id, task_id:task_id, risk_id : unique_risk_id , subact_id : unique_subcat_id , unique_testing_act_id : unique_testing_act_id} , continue_to_next_screen_if_yes: true , next_messages: [{value : 1 , msg : 'Delete this and all future instances of the task?'},{value : 2 , msg : 'Delete only this instance of the task?'}] , option_type: 'Radio'};
            this.setState({alert_param: alert_param});
        }
    }
    processDeleteRequest = (result , stack ) => {
        this.setState({alert_param: null} , ()=>{

            console.log("processDeleteRequest::",result , stack)

            // if(result === false) return;
            let all_testing_activities = Store.getStoreData('testing_activities')
            let ta = all_testing_activities.find(d => d.unique_testing_act_id === stack.unique_testing_act_id)
            console.log("processDeleteRequest ta 1==>",ta);


            let activity_flow = 'no_change';
            if('option_value' in stack ){ 
                if(parseInt(stack.option_value) === 0){ // no change in activity_flow
                    activity_flow = 'no_change';
                }else if(parseInt(stack.option_value) === 1){ // series change

					let delete_reason = {reason : 'acitivity_by_series' , starting_from : stack.ref_id.split('-')[5]}
					ta.is_deleted = true;
					ta.delete_reason = delete_reason

                    activity_flow = 'testing_activity_series_delete';
                }else if(parseInt(stack.option_value) === 2){ // instance date change
                	let exclusions = (typeof ta.exclusions !== 'undefined') ? ta.exclusions : {} ;
					exclusions[stack.ref_id.split('-')[5]] = 'single';
					ta.exclusions = exclusions;
                    activity_flow = 'testing_scheduled_instance_delete';
                }
            }
            console.log("processDeleteRequest ta 2 ==>",ta);
        	ta.ref_ids = ['testing_activities', ta.unique_testing_act_id, ta.testing_activity]

            //stack.testing_activity = ta
            if(activity_flow !== 'no_change'){
                this.setState({is_loader: true});
                let postData = {"command": "delete_testing_activites", "record":ta, "extra_info" : stack , "activity_flow" : activity_flow,last_action_time : moment().unix()};
                let api = new APICall();
                console.log("postData::",postData)
                api.command(postData, this.processTestingIndex);
                //this.setState({is_loader: false});
            }
        });
    }
    processTestingIndex = (result) =>{
        console.log("result::",result)
        let plan = this.state.plan

        this.setState({is_loader: false});
        const callback_values = {
            'plan_ref_id':plan.unique_plan_id,
            'dev_modal':'ra',
            'unique_risk_id':this.state.risk_area.unique_risk_id
        }
        EventBus.raiseEvent('data_risk_sub_reload', callback_values)
        EventBus.raiseEvent('testing_activity_updated', callback_values)


    }
    compareDateGetColor= (first_schedule_date,cur_lane) => {
      //console.log("TSTACT all_hintscur_lane==>",cur_lane,first_schedule_date);
      let color_code = '#04ADA8'
      let scheduled_date = moment(first_schedule_date, 'YYYY/MM/DD');
      let month = scheduled_date.format('M');
      let year  = scheduled_date.format('YYYY');

      let curr_month = moment().month()+1;
      let curr_year  = moment().year();

      // let current_date = moment().unix();
      // let due_date_unix =  moment(first_schedule_date).unix();

      let current_date = moment().format('YYYY-MM-DD');
      let due_date_unix =  moment(first_schedule_date).format('YYYY-MM-DD');

      if(cur_lane === "COMP_CMP_COMPLETED"){
        color_code = "#04ADA8"; // green
      }else{
        if(due_date_unix >= current_date){
          if(curr_month.toString() === month && curr_year.toString() === year){
            color_code = "#F39C12"; // due
          }else{
            color_code = "blue"; // Scheduled
          }
        }else{
          color_code = "red"; // overdue
        }
      }

    return color_code;
  }     
    openModal = (dev_modal, edit_object) => {
        this.setState({dev_modal, edit_object})
    } 
    openModalTA = (dev_modal, ta ,due_date) => {

        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID
        if((role === 'risk_owner' && (ta.testing_owner !== user_id.toString() && ta.created_by !==  user_id.toString()))) {
            
                let alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
        }else{

            let plan = this.state.plan

            let unique_testing_act_id = ta.testing_act_id

            let all_testing_activities = plan.testing_activities;
            let testing_activities = all_testing_activities.filter((t, i) => {
                return (t.unique_testing_act_id === unique_testing_act_id)
            })

            let dt = due_date.split('/')
            testing_activities[0].due_date = moment(new Date(dt[2]+'-'+dt[1]+'-'+dt[0]))
            //


            this.setState({dev_modal, edit_object:testing_activities[0]})
        }
    }
    openModalTAComp = (dev_modal, ta ,due_date,task_id) => {

        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID
        if((role === 'risk_owner' && (ta.testing_owner !== user_id.toString() && ta.created_by !==  user_id.toString()))) {
            
                let alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
        }else{

            let plan = this.state.plan

            let unique_testing_act_id = ta.testing_act_id

            let all_testing_activities = plan.testing_activities;
            let testing_activities = all_testing_activities.filter((t, i) => {
                return (t.unique_testing_act_id === unique_testing_act_id)
            })
            let risk_id = testing_activities[0].risk_id
            let risk_areas = Store.getStoreData('risk_areas')
              //console.log('risk_area::plan', risk_areas)
              let risk = risk_areas.filter((t, i) => {
                  return (t.unique_risk_id === risk_id)
              })

            let risk_area = risk      

            let dt = due_date.split('/')
            testing_activities[0].due_date = moment(new Date(dt[2]+'-'+dt[1]+'-'+dt[0]))
            //


            this.setState({dev_modal, edit_object:testing_activities[0],comp_risk_area:risk_area, task_id:0})
        }
    }  
    filesLoaded = (files) => {
        this.setState({binFiles: files});
    }

    saveRisk = (type) => {

        let all_risks = Store.getStoreData('risk_areas')

        let module_config = Store.getStoreData('module_config')
        let additional_fields = this.state.additional_fields;

        let message = '';
        let problems = false;

        if(this.state.risk_area.risk_area_name.trim() !== ''){
            let name = this.state.risk_area.risk_area_name;
            let risk_id = this.state.risk_id
            //this.setState({IsActionTitleProvided: true , show_messasge : true}); 
            if(all_risks.length > 0){
                let check = all_risks.find((obj) => obj.risk_area_name.trim() === name.trim())
                console.log("CHECK",check)
                if(check){
                    if(risk_id !== check.unique_risk_id){
                                    problems = true;
                                    message = 'Risk area name already exists for the plan.';
                        }
                }                
            }

        }

        if(module_config.custom_fields.enabled){
            let fields = [];
            // console.log("additional_fields:: ",additional_fields);
            if (module_config.custom_fields.additional_fields.length !== 0) {
                    additional_fields.forEach((item) => {
                        if (item.required === true && item.value === ""  && item.type !== 'Date') {
                            fields.push(item.nickname);
                        }
                        if (item.required === true && item.value === ""  && item.type === 'Date') {
                            item.value = new Date();
                        }
                    })
                }
                if (fields.length !== 0) {
                    let err_msg = "";
                    fields.forEach((item) => {
                        err_msg = err_msg + item + ", ";
                    })
                    err_msg = err_msg.substring(0, err_msg.length - 2);
                    if (fields.length === 1) {
                        err_msg = err_msg + " field is mandatory. ";
                    } else {
                        err_msg = err_msg + " fields are mandatory. ";
                    }
                    problems = true;
                    message += err_msg;
                }
        }
        console.log("additional_fields:: enable",additional_fields,problems,message)

        if(problems){
            //this.setState({IsRiskNameProvided: true , disabled_button: false});
            let alert_param = {title: 'ERROR', message: message, ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
            this.setState({alert_param,disabled_button: false});
        }else{
            if(this.state.risk_area.status.trim() == '' || this.state.risk_area.risk_impact.toString() === "0" || this.state.risk_area.risk_owner_id.toString() === "0" || this.state.risk_area.risk_area_name === ""){
                    // console.log("RAMODAL status::",this.state.risk_area.status)
                    // console.log("RAMODAL risk_impact::",typeof this.state.risk_area.risk_impact, this.state.risk_area.risk_impact)
                    // console.log("RAMODAL risk_owner_id::",typeof this.state.risk_area.risk_owner_id,this.state.risk_area.risk_owner_id)
                    // console.log("RAMODAL risk_area_name::",this.state.risk_area.risk_area_name)


                    
                    if(this.state.risk_area.risk_area_name.trim() == ''){
                        this.setState({IsActionTitleProvided: true , show_messasge : true}); 
                    }               

                    if(this.state.risk_area.risk_impact === '0' ){
                        this.setState({IsActionImpactToProvided: true ,  show_messasge : true})
                    }


                    if(this.state.risk_area.risk_owner_id ==='0'){
                        this.setState({IsActionAssignedToProvided: true ,  show_messasge : true})
                    }


                    if(this.state.risk_area.status === '' ){
                        this.setState({IsActionstatusProvided: true ,  show_messasge : true})
                    }
            }else{
                    let api = new APICall()
                    let record = this.state.risk_area
                    record.additional_fields = additional_fields
                    //console.log("record",record)
                    let plan = this.state.plan
                    let sub_command = this.state.risk_id === '0' ? 'add_record' : 'update_record'
                    record.ref_ids = ['risk_areas', record.unique_risk_id, record.risk_area_name]
                    // let attachments = [];
                    // for(let file in this.state.binFiles){
                    //     record.attachments.push({file_id: this.state.binFiles[file].uid, name: this.state.binFiles[file].name,bin_file:this.state.binFiles[file].bin_file})
                    // }
                    record.attachments = this.state.binFiles
                    var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
                    record.attachments = bin_files
                    // record.attachments = attachments;
                    this.setState({is_loader: true});
                    let postData = {command: 'plan', sub_command: sub_command, record: record, object_id: plan.unique_plan_id, section: 'ra'}
                    if(bin_files.length > 0){
                        postData['attachments'] = bin_files
                    }
                    console.log('postData', postData)
                    if(type === 'close'){
                        api.command(postData, this.processRiskClose)

                    }else{
                        api.command(postData, this.processRisk)

                    }
                        //api.command(postData, this.processRisk)

                }
        }
        
    }
    processRisk = (result) => {
        //alert('Risk Area successfully saved.')
        let alert_param = {
            title: 'Success', message: 'Risk Area successfully saved.', ok_text: 'Ok', confirm: false,
            alertHandler: this.AlertHandlerRisk, stack: {result:result}
        };
        this.setState({ alert_param, is_loader: false,binFiles:[] });
        
    }
    AlertHandlerRisk = (result, stack) => {
        //alert('Risk Area successfully saved.')
        console.log("result, stack",result, stack)
        let plan = this.state.plan
        console.log("plan", plan, result)

        if(this.props.create_new){
            let risk = stack.result.result.record
            this.setState({risk_area: risk,risk_id:risk.unique_risk_id,create_new_risk:false,is_loader:false,alert_param:null})
            const callback_values = {
                'plan_ref_id':plan.unique_plan_id,
                'dev_modal':'ra',
                'unique_risk_id':this.state.risk_area.unique_risk_id
            }
        EventBus.raiseEvent('data_risk_sub_reload', callback_values)
            //EventBus.raiseEvent('data_risk_sub_reload', plan.unique_plan_id)
        }else{
            this.setState({dev_modal: null, edit_object: null,is_loader:false,alert_param:null})
            EventBus.raiseEvent('plan_reload', plan.unique_plan_id)
        }

    }

    processRiskClose = (result) => {
        //alert('Risk Area successfully saved.')
        let alert_param = {
            title: 'Success', message: 'Risk Area successfully saved.', ok_text: 'Ok', confirm: false,
            alertHandler: this.AlertHandlerRiskClose, stack: {}
        };
        this.setState({ alert_param, is_loader: false,binFiles:[] });
        
    }
    AlertHandlerRiskClose = (result, stack) => {
        
        //window.location = '/select/ServiceBasedLanding';
        let plan = this.state.plan
            console.log("plan", plan, result)
            this.setState({dev_modal: null, edit_object: null,is_loader:false,alert_param: null })
            EventBus.raiseEvent('plan_reload', plan.unique_plan_id)
     
    }
    handleChange = (e) => {
        let field_name = e.target.name 
        //console.log('handleChange',field_name,e.target.value)
        let risk_area = this.state.risk_area
        risk_area[field_name] = e.target.value 

        let risk_area_name = this.state.risk_area_name
        let risk_area_desc = this.state.risk_area_desc

        if(field_name === 'risk_area_name'){

            risk_area_name = e.target.value 
            this.setState({risk_area,show_messasge:false,risk_area_name})

        }else if(field_name === 'description'){

            risk_area_desc = e.target.value 
            this.setState({risk_area,show_messasge:false,risk_area_desc})

        }else{
            this.setState({risk_area,show_messasge:false})
        }
        
    }

    closeModal = () => {
        let plan = this.state.plan

        this.setState({dev_modal: null, edit_object: null})
        const callback_values = {
            'plan_ref_id':plan.unique_plan_id,
            'dev_modal':'ra',
            'unique_risk_id':this.state.risk_area.unique_risk_id
        }
        this.setState({dev_modal: null, edit_object: null})

        EventBus.raiseEvent('data_risk_sub_reload', callback_values)
    }

    reloadTestingactivity = (plan_ref_id) =>{
        
        EventBus.raiseEvent('data_reload', plan_ref_id)
        let alert_param = {
                title: 'Success', message: 'Testing Activity successfully saved.', ok_text: 'Ok', confirm: false,
                alertHandler: this.AlertHandler, stack: {}
        };
        this.setState({dev_modal: null, edit_object: null,alert_param:alert_param})
    }
    
    handleCustomFieldsProcessor = (ret) =>{
        console.log("handle_custom_fields,",ret);
        let flag = ret.flag;
        switch(flag) {
            case 'TEXT_CHANGE': this.handleTextChange(ret.obj);
            break;
            case 'CUSTOM_DATE': this.onChangeCustomDate(ret.obj);
            break;
            case 'USER_LIST': this.handleUserListField(ret.obj);
            break;
            case 'ADDITIONAL_FIELD': this.handleAddlField(ret.obj);
            break;
        }
     } 
    onChangeCustomDate = (obj) => {
        let additional_fields = this.state.additional_fields;  
        //console.log("assign_data===>", assign_data) 
        additional_fields.forEach((item) => {
            if (item.name === obj[0]) {
                item.value = obj[1];
            }
        });         

        this.setState({additional_fields});
    }
    handleAddlField = (obj) => {
        //event.preventDefault();        
        let additional_fields = this.state.additional_fields;    
        //console.log("addtional_fields===>", obj );  
  
        if (obj[2] === "smcr_role") {
            additional_fields[obj[2]] = obj[3];
            additional_fields.forEach((item) => {
                if (item.name === obj[2]) {
                    item.value = obj[3];
                }
            })
        } else {
            additional_fields.forEach((item) => {
                //console.log("addtional_fields=>",item.name,obj[2]);
                if (item.name === obj[2]) {
                    item.value = obj[3];
                }
            });
        }   
        //console.log("addtional_fields===>", additional_fields);  
        //this.props.modifyAssignData(assign_data);

        this.setState({additional_fields});
    }
    handleTextChange = (obj) => {
        console.log("obj:", obj);       
        let additional_fields = this.state.additional_fields;
        console.log("additional_fields:", additional_fields);
        if(obj.entity === null) {
            additional_fields[obj.name] = obj.value;
        }else{
            console.log("here1,",obj);
            additional_fields.forEach(item => {
                console.log("here1,",item.name,obj.name);
                if(item.name===obj.name){
                    item[obj.name] = obj.value;
                    item.value = obj.value;
                }
            });
        }
        console.log("additional_fields:", additional_fields);       
        this.setState({additional_fields});
    }


    handleUserListField = (field_type, data_container) => (event) => {
        event.preventDefault();        
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)  
        this.props.modifyAssignData(assign_data);        
    }
    filesRemovedType2 = (jsonObj) => {
        console.log('filesRemovedType2::',jsonObj)
        let binFiles = this.state.binFiles.filter(item => item.uid !== jsonObj);
        this.setState({ binFiles });
    }
    render() {
        console.log("RAMODAL state::",this.state)
        if(!this.state.ready)return <div />
        let customFields = new CustomFields();
        return (<div ref={ this.componentRef }>

            {
                (() => {
                    if(this.state.is_loader){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }

            <AlertBox alertParam={this.state.alert_param} />
            {
                (() => {
                    if(this.state.dev_modal !== null) {
                        switch(this.state.dev_modal) {
                            case 'ta' : return <TAModal 
                                                    ta={this.state.edit_object} 
                                                    subcategory={null}
                                                    risk={this.state.risk_area}
                                                    plan={this.state.plan}
                                                    closeModal={this.closeModal} 
                                                    reloadTestingactivity={this.reloadTestingactivity}
                                                    gc_id={this.props.gc_id}/>; break;
                            case 'sc' : return <SCModal 
                                                    subcategory={this.state.edit_object} 
                                                    risk={this.state.risk_area} 
                                                    plan={this.state.plan}
                                                    closeModal={this.closeModal} 
                                                    create_new={this.state.edit_object === null ? true : false}
                                                    gc_id={this.props.gc_id}
                                                    from="ra"
                                                    />; break;
                            case 'ta_action' : return <CompleteModal 
                                                    testing_activity={this.state.edit_object} 
                                                    subcategory={null}
                                                    risk={this.state.comp_risk_area}
                                                    plan={this.state.plan}
                                                    unique_plan_id={this.state.plan.unique_plan_id}
                                                    gc_id={this.state.edit_object.gc_id}
                                                    closeModal={this.closeModal} 
                                                    // reloadTestingactivity={this.reloadTestingactivity}
                                                    tact_task_id={this.state.comp_task_id}/>; 
                                                    break;                        
                            default: return <div />
                        }
                    }
                })()
            }
            <InactiveOverlay style={{ height: "105%" }} />
            <MRModalContainer>
            <MRModalHeader>

                <MRModalHeaderText> {this.state.risk_id === "0" ? 'Create Risk Area' : 'Edit Risk Area'}  </MRModalHeaderText>
                <div style={{ clear: "both" }}></div>

                <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                <div style={{ clear: "both" }}></div>
                </MRModalHeader>
                <MRModalBody>
                        <MRModalLabel style={{ marginLeft: "35px" }}>Risk Area<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>

                        <MRModalInput name="risk_area_name" onChange={this.handleChange} value={this.state.risk_area.risk_area_name}
                            style={{ width: "calc(98% - 150px)", marginLeft: "35px" }} />
                        
                        {
                            (() => {
                                if(this.state.IsActionTitleProvided === true && this.state.show_messasge === true ) return (<>
                                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                        <div style={{ clear: "both" }}></div>
                                        <label style={{ color: "#FF0000" }}>Please provide risk area name.</label>
                                    </div>
                                </>)
                            })()
                        }

                        
                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                        <div>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Description</MRModalLabel>
                        </div>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                        <textarea name="description" rows="4" cols="100" onChange={this.handleChange} value={this.state.risk_area.description}
                            style={{ width: "calc(98% - 150px)", marginLeft: "35px", border: "1px solid #ffffff", boxShadow: "0 0 4px #c4c4c4", padding: "5px", color: "#000000", fontWeight: "bold", fontFamily: "Montserrat,sans-serif", resize: "vertical" }}>
                        
                        
                        </textarea>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                        <div>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Attachments</MRModalLabel>
                        </div>
                        <div style={{ marginLeft: "35px", width: "50%" }}>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.risk_area.attachments} height={"200px"} onRemoveFile={this.filesRemovedType2}/> 
                        </div>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                        <div style={{width: "calc(98% - 25px)"}}>
                        <div style={{ width: "32%", display: "inline-block"}}>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Risk Owner</MRModalLabel>
                            <div style={{ clear: "both", marginTop: "5px" }}></div>


                            <RiskSelect name="risk_owner_id" onChange={this.handleChange} value={this.state.risk_area.risk_owner_id} style={{ width: "80%",  marginLeft: "35px" }} >
                                <option value="0">Select risk owner...</option>
                                {
                                    this.state.risk_owners.map((r, i) => {
                                        return <option key={i} value={r.id}>{r.name}</option>
                                    })
                                }
                            </RiskSelect>                        


                        </div>
                        <div style={{ width: "32%", display: "inline-block"}}>
                            <MRModalLabel style={{ marginLeft: "17px" }}>Status</MRModalLabel>
                            <div style={{ clear: "both", marginTop: "5px" }}></div>
                            <RiskSelect style={{ width: "80%", marginLeft: "35px"  }} name="status" onChange={this.handleChange} value={this.state.risk_area.status}>
                                <option key="Active" value="Active">Active</option>
                                <option key="Inactive" value="Inactive">Inactive</option>
                            </RiskSelect>
                    
                        </div>
                        <div style={{ width: "32%", display: "inline-block"}}>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Risk Impact</MRModalLabel>
                            <div style={{ clear: "both", marginTop: "5px" }}></div>
                            <RiskSelect name="risk_impact" onChange={this.handleChange} value={this.state.risk_area.risk_impact} style={{ width: "80%", marginLeft: "35px" }}>
                                <option value="0">Select a risk impact...</option>
                                {
                                    this.state.risk_impacts.map((m, i) => {
                                        return <option key={i} value={m.id}>{m.name}</option>
                                    })
                                }
                            </RiskSelect>
         

                        </div>
                        </div>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>
                        <div style={{width: "calc(98% - 25px)"}}>
                        <div style={{ width: "32%", display: "inline-block"}}>
                         {
                            (() => {
                                if(this.state.IsActionAssignedToProvided === true  && this.state.show_messasge === true) return (<>
                                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                        <div style={{ clear: "both" }}></div>
                                        <label style={{ color: "#FF0000" }}>Please select Risk Owner.</label>
                                    </div>
                                </>)
                            })()
                        }


                        </div>
                        <div style={{ width: "32%", display: "inline-block"}}>
                            {
                                (() => {
                                    if(this.state.IsActionstatusProvided === true  && this.state.show_messasge === true) return (<>
                                        <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                            <div style={{ clear: "both" }}></div>
                                            <label style={{ color: "#FF0000" }}>Please select Risk Status.</label>
                                        </div>
                                    </>)
                                })()
                            }

                        </div>
                        <div style={{ width: "32%", display: "inline-block"}}>
                            {
                                (() => {
                                    if(this.state.IsActionImpactToProvided === true  && this.state.show_messasge === true) return (<>
                                        <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                            <div style={{ clear: "both" }}></div>
                                            <label style={{ color: "#FF0000" }}>Please select Risk Impact.</label>
                                        </div>
                                    </>)
                                })()
                            }

                        </div>
                        </div>


                        <div style={{clear: "both", paddingTop:"8px"}}></div>
                        {
                            (() => {
                                if (this.state.additional_fields.length !== 0) {
                                    let custom_html = [];
                                    this.state.additional_fields.forEach((item) => {
                                        let field = customFields.generateField(item, "additional_fields",this.handleCustomFieldsProcessor);
                                        custom_html.push(field);
                                    })
                                    return custom_html;
                                }
                            })()
                        }
                
                        <div style={{clear: "both", marginTop:"10px"}}></div>

                        <div style={{ clear: "both", marginTop: "10px" }}></div>
                        {
                            (() => {
                                if(this.state.risk_id !== "0") return (<>
                                    <div style={{ marginLeft: "30px", width: "90%" }}>
                                        <OverviewInfoHeader>
                                            <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Subcategories</div>
                                            <div style={{ float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer" }} onClick={() => this.openModal('sc', null)}><FaPlusCircle /></div>
                                            <div style={{ clear: "both" }}></div>
                                        </OverviewInfoHeader>
                                        <OverviewInfoBody>
                                            {/*<div style={{ textAlign: "center", fontStyle: "italic", color: "#000000" }}>To add Subcategories to this Risk Area you must Save the Risk Area first.</div>*/}
                                            <CSLTable add={false} processData={this.processSubcategoryData} headerText={''} tableRows="5" refreshCallback={this.refreshCallback} />                                                

                                        </OverviewInfoBody>

                                    </div> 


                                    <div style={{marginLeft : "35px", width : "90%"}}>
                                                <OverviewInfoHeader>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Testing Activities</div>
                                                     <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={() => this.openModal('ta', null)}><FaPlusCircle /></div>

                                                    
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader>
                                                <OverviewInfoBody>
                                                {/*<div style={{textAlign: "center",fontStyle: "italic", color: "#000000"}}>
                                                                        To add Testing Activities to this Risk Area you must Save the Risk Area first.
                                                </div>*/}
                                                         <CSLTable add={false} processData={this.processTestingActivitiesData} headerText={''} tableRows={10} refreshCallback={this.refreshCallback} />                                                 
                                                    
                                                </OverviewInfoBody>
                                                </div> 
                                   
                                    <div style={{ clear: "both", marginTop: "8px" }}></div>
                                </>)
                            })()
                        }

                        

                </MRModalBody>

                <MRModalFooter>

                        <div style={{ marginRight: "35px" }}>
                            <CatBtn onClick={() => this.saveRisk('close')} style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }}  >Submit & Close</CatBtn>
                           {
                            (() => {
                                if(this.state.create_new_risk){
                                    return(
                                        <CatBtn onClick={() => this.saveRisk('continue')}  style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }}  >Save & Continue</CatBtn>
                                        )
                                }
                            })()
                           }
                           
                            <CatBtn onClick={this.props.closeModal} style={{ backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right" }} >Cancel</CatBtn>
                        </div >
                        <div style={{ clear: "both" }}></div>
                    </MRModalFooter>

            </MRModalContainer>
        </div>)
    }
}

export default RAModal;