import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {FaTimes ,FaPlusCircle , FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';
import CSLLoader from '../../Common/CSLLoader.js';
import AlertBox from '../../Common/AlertBox.js';

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 995;
    opacity: 0.8;
    `;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: 430px;
    width: calc(100% - 650px);
    position: fixed;
    top: 80px;
    left: 300px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;

const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
    height:240px;
    overflow-y:auto
`;

const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
	font-weight: bold;
    margin-left: 24px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;

const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    margin-top: 10px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;


class APModal extends React.Component{
    state={plan_name: '', gc_id: null, gc_name: null, is_loader:false, alert_param: null}

    genKey(length){
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    setComponentState = () => {
        let gc_companies = Store.getStoreData('gc_companies')
        let gcs = {}
        let gc_id = this.props.gc_id

        for(let g of gc_companies) {
            if(!g.is_gc)gcs['0'] = g 
            else gcs[g.id] = g
        }

        console.log('this.props, gcs', this.props, gcs)
        let gc = gcs[gc_id]
        let gc_name = gc.is_active === 1 ? gc.company_name : gc.company_name+" (Inactive)"
        this.setState({gc_name, gc_id})
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
        this.setComponentState()
    }

    handleChange = (e) => {
        let field_name = e.target.name 
        let state = this.state
        state[field_name] = e.target.value 
        this.setState(state)
    }
    alertHandler = (result, stack) => {
        console.log(result, stack)
        this.setState({ alert_param: null })
    }
    savePlan = () => {
        if(this.state.plan_name === ""){
            let alert_param = {title: 'Message', message: 'Plan name is required.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.alertHandler, stack: {} };
            this.setState({alert_param: alert_param});
            return false;  
        }else{

                let plan_name = this.state.plan_name
                let gc_id = this.state.gc_id
                let check_val = false
                
                let all_plans = Store.getStoreData('gc_plans')[gc_id]
                console.log("APPLAN::all_plans::",all_plans)
                if(typeof all_plans !== 'undefined'){
                	let check = Object.values(all_plans).find((obj) => obj.name.trim() === plan_name.trim())
                	if(check){
                		check_val = true
                	}
                }
               

                // console.log("APPLAN::plan_name::",plan_name)
                // console.log("APPLAN::all_plans::",all_plans)
                console.log("APPLAN::check::",check_val)
                // return
                if(check_val){
                    let alert_param = {title: 'Message', message: 'Plan name already exists for the company.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.alertHandler, stack: {} };
                    this.setState({alert_param: alert_param});
                    return false;  
                }else{
                    let risk_areas_parent_id = this.genKey(21)
                    let testing_activities_parent_id = this.genKey(21)
                    let unique_plan_id = this.genKey(21)
                    let plan = {
                        version: '1.0',
                        gc_id: this.state.gc_id,
                        plan_name: this.state.plan_name,
                        source_id: 0,
                        unique_plan_id: unique_plan_id,
                        risk_areas_parent_id: risk_areas_parent_id,
                        testing_activities_parent_id: testing_activities_parent_id,
                        testing_activities: {__type: 'list', value: testing_activities_parent_id},
                        risk_areas: {__type: 'list', value: risk_areas_parent_id}
                    }
                    this.setState({is_loader:true})
                    let postData = {command: 'plan', sub_command: 'add_plan', plan: plan,plan_ref_id:unique_plan_id}
                    let api = new APICall()
                    console.log('postData', postData)
                    api.command(postData, this.processSavePlan)                  
                }

            }
    }

    processSavePlan = (result) => {
        console.log('result', result)
        this.setState({is_loader:false})
        this.props.processAddPlan(result.result.plan)
    }

	render() {
        if(this.state.gc_name === null) return <div />
        let dialog_title = `New Compliance Plan for "${this.state.gc_name}"`
		return (<>
             <AlertBox alertParam={this.state.alert_param} />
            {
                (() => {
                    if(this.state.is_loader){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <InactiveOverlay style={{ height: "105%" }} />

			<MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText title={dialog_title}> {dialog_title}</MRModalHeaderText>
                    <div style={{ clear: "both" }}></div>
                    <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{ clear: "both" }}></div>
                </MRModalHeader>
                <MRModalBody>
                    <MRModalLabel style={{ marginLeft: "35px" }}>Plan Name<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>

                        <MRModalInput name="plan_name" 
                            value={this.state.plan_name} onChange={this.handleChange}
                            style={{ width: "calc(98% - 150px)", marginLeft: "35px" }}></MRModalInput>
                </MRModalBody>
                <div style={{ clear: "both", marginTop: "10px" }}></div>
                <MRModalFooter>
                    <div style={{ marginRight: "35px" }}>
                        <CatBtn onClick={this.savePlan} style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }}  >Create</CatBtn> 
                        <CatBtn onClick={this.props.closeModal} style={{ backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right" }} >Cancel</CatBtn>
                    </div >
                    <div style={{ clear: "both" }}></div>
                </MRModalFooter>
            </MRModalContainer>
		</>)
	}
}

export default APModal;