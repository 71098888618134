import React from 'react';
import styled from 'styled-components';
// import { Icon } from 'react-icons-kit';
// import { plusCircle } from 'react-icons-kit/fa/plusCircle';
// import SaleDateField from './SaleDateField';
// import SingleCustomField from './SingleCustomField';
import Utils from '../../Common/Utils';
import Store from '../../Common/Store';
import Toggle from 'react-toggle';
import "./ReactToggle.css";

import { FaPlusCircle, FaEllipsisV, FaTrash } from "react-icons/fa";
import { MdEdit } from 'react-icons/md';
import CSLTable from '../Common/CSLTable';
//import VerticalEllipsisMenuFolderReasonBreach from "./VerticalEllipsisMenuFolderReasonBreach";
import CustomRagsConfigModal from "./CustomRagsConfigModal";
import AlertBox from '../Common/AlertBox';
import CSLLoader from './../Common/CSLLoader';
import CMPUtils from './../Common/CMPUtils';
// import ReactTooltip from "react-tooltip";
import { GoAlert } from "react-icons/go";

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 97%;
    margin-bottom: 25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

const MRModalLabel = styled.div`
	color: #1a3552;
	font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
`;
const MRModalSubLabel = styled.div`
	color: #838c91;
	font-weight: 500;
	margin-top: 10px;
	display: inline-block;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const MenuOuterDiv = styled.div`
  border: 1px solid #000000;
  width: 100px;
  opacity: 1;
  transform: none;
  transition: opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 84.0469px 26px;
`;
const MenuInnerDiv = styled.div`
  cursor: pointer;
  color: #000000;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: medium;
  line-height: 1.5;
  padding-top: 6px;
  padding-bottom: 6px;
  letter-spacing: 0.00938em;
`;

class CustomRags extends React.Component {
    state = {
        custom_rags: null,
        final_custom_rag: null,
        additional_rags: [],
        show_custom_rags_modal: false,
        curid: 0,
        headerText: "",
        content: null,
        alert_param: null,
        isLoadedIcon: false
    }

    componentDidMount() {
        let modify_additional_rags = [];
        for (let k of this.props.custom_rags.additional_rags) {
            if (!('isActive' in k)) {
                k.isActive = 1;
                k.isDelete = 0;
            }
            modify_additional_rags.push(k)
        }
        this.setState({ custom_rags: this.props.custom_rags, final_custom_rag: this.props.custom_rags, additional_rags: modify_additional_rags });
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            let modify_additional_rags = []
            for (let k of this.props.custom_rags.additional_rags) {
                if (!('isActive' in k)) {
                    k.isActive = 1;
                    k.isDelete = 0;
                }
                modify_additional_rags.push(k)
            }
            this.setState({ custom_rags: this.props.custom_rags, final_custom_rag: this.props.custom_rags, additional_rags: modify_additional_rags, isLoadedIcon: false });
        }
    }

    switchRadio = (key) => (event) => {
        event.preventDefault();
        let custom_rags = JSON.parse(JSON.stringify(this.state.custom_rags));
        custom_rags[key] = custom_rags[key] ? false : true;
        this.setState({ custom_rags, final_custom_rag: custom_rags });
    }

    handleToggleChange = (arg) => {
        let custom_rags = JSON.parse(JSON.stringify(this.state.custom_rags));
        custom_rags[arg] = custom_rags[arg] ? false : true;
        this.props.updateCustomRags(custom_rags)
        this.setState({ custom_rags, final_custom_rag: custom_rags });
    }


    closeModal = () => {
        this.setState({ show_custom_rags_modal: false })
    }

    closePage = () => {
        this.props.closePage()
    }

    saveCustomRagValue = (obj) => {
        this.setState({ show_custom_rags_modal: false, isLoadedIcon: true })
        this.props.saveCustomRagValue(obj)
    }

    updateCustomRagValue = (content) => {
        console.log("content===",content);
        let { additional_rags } = this.state;
        let newreason = [];
        additional_rags.forEach((item) => {
            if (item.id === content.id) {
                item = content;
            }
            newreason.push(item);
        })
        this.setState({ show_custom_rags_modal: false, isLoadedIcon: true })
        this.props.updateCustomRagValue(newreason);
    }

    addAdditionalRags = (event) => {
        event.preventDefault();
        console.log("addAdditionalRags");
        this.setState({ show_custom_rags_modal: true, curid: 0, headerText: "Add" })
    }

    updateCustomModuleconfig = () => {
        console.log("dip", this.state.final_custom_rag)
        // this.props.updateCustomRags(this.state.final_custom_rag);
    }

    handlenaSectionIncludedIsRequired = (section_id) => (event) => {
        console.log("content:", section_id);
        let { additional_rags } = this.state;
        //let newadditional_rags_array = [];
		let required_remedial_action = false
        additional_rags.forEach((item) => {
            if (item.id === section_id) {
                required_remedial_action = item.required_remedial_action ? false : true;
            }
            //newadditional_rags_array.push(item);
        })
        //console.log("emails:", newadditional_rags_array);
        this.setState({ isLoadedIcon: true })
        //this.props.updateAdditional_rags(newadditional_rags_array);
		this.props.updateAdditional_rags(required_remedial_action, section_id, 'required_remedial_action');
        
    }

    handlenaSectionIncluded = (section_id) => (event) => {
        console.log("content:", section_id);
        let { additional_rags } = this.state;
		let isActive = 0

        additional_rags.forEach((item) => {
            if (item.id === section_id) {
                isActive = item.isActive === 1 ? 0 : 1;
            }
            //newadditional_rags_array.push(item);
        })
        //console.log("emails:", newadditional_rags_array);
        this.setState({ isLoadedIcon: true })
		this.props.updateAdditional_rags(isActive, section_id, 'status');

    }
    customRagDecider = (module_configs,current_rag) => {
        //console.log("module_configs,",module_configs);
        let id_array = [];
        let rag_id = '';
        for(let item of module_configs.custom_rags.additional_rags){
            id_array.push(item.id);
            if(item.nickname === current_rag){rag_id = item.id;break;}
            if(item.rag_value === current_rag){rag_id = item.id;break;}
        }
        if(rag_id !== '') return rag_id;
        if(id_array.includes(current_rag)) return current_rag;
    }
    showConfigModal = (curid, isdelete,ragname,obj) => {
        //console.log("curid==>", curid);
        //console.log("obj==>", obj);
		// let cmputils= new CMPUtils();
		let tasks_ref_assoc = Store.getStoreData('tasks_ref_assoc');
		
        if (obj === 'Edit') {
            const content = this.state.additional_rags.find((item) => {
                return item.id === curid;
            })
            this.setState({ content, curid: curid, show_custom_rags_modal: true, headerText: "Edit" });
        } else if (obj === 'Delete') {
			let rags_for_completed_tasks = [];
			for(let ref_id in tasks_ref_assoc){
				if(tasks_ref_assoc[ref_id].cur_lane === 'COMP_CMP_COMPLETED')rags_for_completed_tasks.push(tasks_ref_assoc[ref_id].risk_result.rag);
			}
			
			let completed_tasks_rag_ids = [];
			for(let rag_pre of rags_for_completed_tasks){
				let rag_id = this.customRagDecider(this.props.module_config,rag_pre);
				completed_tasks_rag_ids.push(rag_id);
			}
			console.log("tasks_ref_assoc==>",tasks_ref_assoc,rags_for_completed_tasks,curid,ragname,completed_tasks_rag_ids);
			if(completed_tasks_rag_ids.length > 0 && completed_tasks_rag_ids.includes(curid)){
                isdelete = -100;
			}
            if (isdelete === 0) {
                let alert_param = {
                    title: 'Warning', message: 'This will delete this RAG option from future dropdowns, are you sure you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                    alertHandler: this.updatedeleteCustomRags, stack: { id: curid }
                }
                this.setState({ alert_param: alert_param })
            } else {
                let alert_param = {
                    title: 'ERROR', message: 'This record cannot be deleted as it is attached to completed records. Please update the status field to inactive to remove from all dropdowns.', ok_text: 'Ok', confirm: false,
                    alertHandler: this.updateafternodelete, stack: {}
                }
                this.setState({ alert_param: alert_param })
            }
        }
    };

    updatedeleteCustomRags = (result, stack) => {
        if (!result) {
            this.setState({ alert_param: null })
            return
        }
        console.log("delete===>,id", stack.id)
        let newreason = [];
        let name = ""
        for (let i = 0; i < this.state.additional_rags.length; i++) {
            if (this.state.additional_rags[i].id !== stack.id) {
                newreason.push(this.state.additional_rags[i])
            }else{
                name = this.state.additional_rags[i].nickname
            }
        }
        console.log("delete===>", newreason);
        this.setState({ alert_param: null })
        this.props.deleteCustomRagsValue(newreason,stack.id,name);
    }

    updateafternodelete = (result, stack) => {
        console.log(result, stack)
        this.setState({ alert_param: null })
    }

    tableData = () => {
        let ret = { data: [], columns: [] };
        ret.columns = [
            { Header: 'RAG NAME', accessor: 'ragname', minWidth: 150, headerStyle: { textAlign: 'left' } },
            {
                'Header': 'REQUIRED REMEDIAL ACTION?', Cell: row => (
                    <div>
                        <Toggle
                            style={{ float: 'left' }}
                            defaultChecked={row.original.isRequired ? true : false}
                            icons={false}
                            onChange={this.handlenaSectionIncludedIsRequired(row.original.id)}
                        />
                    </div>
                ), headerStyle: { textAlign: 'center' }, style: { textAlign: 'center' }
            },
            {
                'Header': 'STATUS', accessor: 'cstatus', Cell: row => (
                    <div>
                        <Toggle
                            style={{ float: 'left' }}
                            defaultChecked={row.original.status === "Active" ? true : false}
                            icons={false}
                            onChange={this.handlenaSectionIncluded(row.original.id)}
                        />
                    </div>
                ), headerStyle: { textAlign: 'center' }, style: { textAlign: 'center' }
            },
            {
                'Header': '', accessor: 'cblnk', Cell: row => (
					
                    <div style={{display: "flex" , width: "100%",justifyContent: "space-around" }}>
                                <MdEdit onClick={()=>this.showConfigModal(row.original.id, row.original.isdelete,row.original.ragname,'Edit')} style={{cursor: 'pointer' , float: "right"}} title="edit this RAG" />
                                <FaTrash title="Delete this RAG" onClick={()=>this.showConfigModal(row.original.id, row.original.isdelete,row.original.ragname,'Delete')} style={{cursor: 'pointer'}} />

                
                    </div>
                ), minWidth: 40, headerStyle: { textAlign: 'left' }, style: { textAlign: 'center' }
            }
        ];

        let additional_rags_sort_array = this.state.additional_rags.sort(function (a, b) { return (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0); });
        additional_rags_sort_array.forEach((item) => {
            let statusValue = item.isActive === 1 ? "Active" : "Inactive"
            let elem = { id: item.id, ragname: item.nickname, isRequired: item.required_remedial_action, status: statusValue, isdelete: item.isDelete };
            ret.data.push(elem);
        })
        return ret;
    }

/*
                    <div>
                        <VerticalEllipsisMenuFolderReasonBreach editDelete={this.showConfigModal(row.original.id, row.original.isdelete,row.original.ragname)} options={["Edit", "Delete"]} />
                    </div>
*/

    saveCustomRagsValue = (obj, id) => {
        this.setState({ show_custom_rags_modal: false})
        this.props.saveCustomRagsValue(obj, id)
    }
    render() {
        console.log("isActive=> ", this.state.custom_rags );
        if (this.state.custom_rags === null) {
            return (<div>Loading...</div>);
        }
        return (
            <div>
                {
                    (() => {
                        if (this.state.show_custom_rags_modal) {
                            return (<div><InactiveOverlay style={{ height: "105%" }} /><CustomRagsConfigModal saveCustomRagsValue={this.saveCustomRagsValue} headerText={this.state.headerText} data={this.state.custom_rags} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} saveCustomRagValue={this.saveCustomRagValue} updateCustomRagValue={this.updateCustomRagValue} /></div>)
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.isLoadedIcon) {
                            return <div><CSLLoader style={{ position: 'absolute' }} /></div>
                        }
                    })()
                }
                <EmailContainer>
                    <EmailInnerContainer>
                        <div style={{ marginBottom: "10px", display: "block" }}>
                            <MRModalLabel style={{ display: "block", color: "#1d3957a8", marginBottom: "10px" }}>Enable Custom RAGs?</MRModalLabel>
                            {/* <p style={{fontWeight: "600", color: "#595959"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                            <Toggle
                                icons={false}
                                onChange={() => this.handleToggleChange('enabled')}
                                defaultChecked={this.state.custom_rags.enabled ? true : false}
                            />
                        </div>
                        {
                            (() => {
                                if (this.state.custom_rags.enabled) {
                                    return (<div>
                                        <EmailTopHeader style={{ display: "inline-block", fontWeight: 700 }}>
                                            Custom RAGs
                                            <div style={{ float: 'right' }}>
                                                <div onClick={this.addAdditionalRags} style={{ float: "left", cursor: "pointer", fontSize: "25px", color: "#04ADA8" }}>
                                                    <FaPlusCircle />
                                                </div>
                                                <div style={{ float: "right", cursor: "pointer", fontSize: "25px", color: "#e4e4e4" }}>
                                                    <FaEllipsisV />
                                                </div>
                                            </div>
                                        </EmailTopHeader>
                                        <CSLTable add={false} processData={this.tableData} tableRows={10} />
                                    </div>)
                                }
                            })()
                        }
                    </EmailInnerContainer>
                </EmailContainer>
                {/*<FooterButtonsContainer>
                    <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                    <SaveBtn onClick={this.updateCustomModuleconfig}>Save</SaveBtn>
                </FooterButtonsContainer>*/}
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        )
    }
}

export default CustomRags;