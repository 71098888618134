import React from 'react';
import styled from 'styled-components';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb';
import { FaTimes,FaPlusCircle , FaPen ,FaTrash} from 'react-icons/fa';


const EmailContainer = styled.div`
	background-color: #ffffff;
	width: 70%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 20px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2600px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: 70px;  
  z-index: 1002;
  opacity: 0.8;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: 300px;
    position: absolute;
    z-index: 1005;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 50%;   
    margin-bottom: 10px;
	top: 20%;
	left: 23%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #DEDEDE;*/
    color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
	font-family: Montserrat,sans-serif;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    
    background-color: #EDF1F1;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
	font-family: Montserrat,sans-serif;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;    
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 3px;
    font-weight: bold;
    /*color: #979797;*/
    color: #000000;
`;

class Reminders extends React.Component
{
	state = {
		remainders: null , 
		reminders_remedial_actions: null , 
		show_add_modal : false , 
		reminder_name : '' , 
		id : '' , 
		period : 0,
		type: '',
		flag: ''
	};

	componentDidMount()
	{
		this.setState({remainders: this.props.reminders,reminders_remedial_actions: this.props.reminders_remedial_actions});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({remainders: this.props.reminders,reminders_remedial_actions: this.props.reminders_remedial_actions});
		}
		this.el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});		
	}

	genKey = (length) => {
	   var result           = '';
	   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	   var charactersLength = characters.length;
	   for ( var i = 0; i < length; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
	   }
	   return result;
	}

    showAddReminder = (event,type,flag) => {
		event.preventDefault();
		this.setState({show_add_modal: true, type, flag});
	}

	switchRadio = (key) => (event) => {
		console.log(key)
		event.preventDefault();
		let {remainders} = this.state;
		remainders[key] = remainders[key] ? false : true;
		this.setState({remainders: remainders});
		this.props.updateReminder(remainders);
	}
	switchRadioRemedial = (key) => (event) => {
		event.preventDefault();
		let {reminders_remedial_actions} = this.state;
		reminders_remedial_actions[key] = reminders_remedial_actions[key] ? false : true;
		this.setState({reminders_remedial_actions: reminders_remedial_actions});
		this.props.updateReminderRemedial(reminders_remedial_actions);	
	}

	submitAddReminderModal = ()=> {
		let {remainders ,reminders_remedial_actions, type, flag} = this.state;
		
		if(type === 'ta'){
			let newadvanceremainders = [];
			let newoverdueremainders = [];
			switch (flag) {
			  case "advance":
				remainders.advance_reminders_values.forEach((item) => {
					if (item.id === this.state.id) {
						item.period = this.state.period;
						item.name = this.state.reminder_name;
						//console.log("matched", item);
					}
					newadvanceremainders.push(item);
				});
				break;
			  case "overdue":
				remainders.remainder.forEach((item) => {
					if (item.id === this.state.id) {
						item.period = this.state.period;
						item.name = this.state.reminder_name;
						//console.log("matched", item);
					}
					newoverdueremainders.push(item);
				});
				break;
			  default:
				console.log("error occured out of switched context!");
			}
			if(this.state.id === '' && this.state.reminder_name.trim() !== ''){
				var unique_reminder_id = this.genKey(6);
				let jsonobj = { name : this.state.reminder_name , id : unique_reminder_id , period : this.state.period}
				if(flag === 'overdue')newoverdueremainders.push(jsonobj);
				if(flag === 'advance')newadvanceremainders.push(jsonobj);
			}
			if(flag === 'overdue')remainders.remainder = newoverdueremainders;
			if(flag === 'advance')remainders.advance_reminders_values = newadvanceremainders;
			console.log("remainders,",remainders);
			this.props.updateReminder(remainders);
		}
		if(type === 'rem'){
			let newadvanceremainders = [];
			let newoverdueremainders = [];
			switch (flag) {
			  case "advance":
				reminders_remedial_actions.advance_reminders_values.forEach((item) => {
					if (item.id === this.state.id) {
						item.period = this.state.period;
						item.name = this.state.reminder_name;
						//console.log("matched", item);
					}
					newadvanceremainders.push(item);
				});
				break;
			  case "overdue":
				reminders_remedial_actions.remainder.forEach((item) => {
					if (item.id === this.state.id) {
						item.period = this.state.period;
						item.name = this.state.reminder_name;
						//console.log("matched", item);
					}
					newoverdueremainders.push(item);
				});
				break;
			  default:
				console.log("error occured out of switched context!");
			}
			if(this.state.id === '' && this.state.reminder_name.trim() !== ''){
				var unique_reminder_id = this.genKey(6);
				let jsonobj = { name : this.state.reminder_name , id : unique_reminder_id , period : this.state.period}
				if(flag === 'overdue')newoverdueremainders.push(jsonobj);
				if(flag === 'advance')newadvanceremainders.push(jsonobj);
			}
			if(flag === 'overdue')reminders_remedial_actions.remainder = newoverdueremainders;
			if(flag === 'advance')reminders_remedial_actions.advance_reminders_values = newadvanceremainders;
			this.props.updateReminderRemedial(reminders_remedial_actions);			
		}

		this.setState({show_add_modal: false , period: '', reminder_name : '' , id : '' , flag: '' , type: ''});
		
	}

	editReminderModal  = (jsonobj,type,flag) => {
		this.setState({period: jsonobj.period , id : jsonobj.id, reminder_name : jsonobj.name , show_add_modal : true , type , flag});
	}

	removeReminder  = (jsonobj,type,flag) => {
		let {remainders,reminders_remedial_actions} = this.state;
		if(type === 'ta'){
			if(flag === 'advance'){
				let newremainders = [];
				remainders.advance_reminders_values.forEach((item) => {
					if (item.id !== jsonobj.id) {
						newremainders.push(item);
					}
				});
				remainders.advance_reminders_values = newremainders;
			}
			if(flag === 'overdue'){
				let newremainders = [];
				remainders.remainder.forEach((item) => {
					if (item.id !== jsonobj.id) {
						newremainders.push(item);
					}
				});
				remainders.remainder = newremainders;
			}
			this.props.updateReminder(remainders);	
		}
		if(type === 'rem'){
			if(flag === 'advance'){
				let newremainders = [];
				reminders_remedial_actions.advance_reminders_values.forEach((item) => {
					if (item.id !== jsonobj.id) {
						newremainders.push(item);
					}
				});
				reminders_remedial_actions.advance_reminders_values = newremainders;
			}
			if(flag === 'overdue'){
				let newremainders = [];
				reminders_remedial_actions.remainder.forEach((item) => {
					if (item.id !== jsonobj.id) {
						newremainders.push(item);
					}
				});
				reminders_remedial_actions.remainder = newremainders;
			}
			this.props.updateReminderRemedial(reminders_remedial_actions);				
		}
	
		
	}


	onChangeIntervalModalSlider = (values, handle) => {
		let value = Math.floor(parseFloat(values[handle]));

		
		this.setState({period: value});
	}

	onChangeInterval = (id,type,flag)=> (values, handle) => {
		let value = Math.floor(parseFloat(values[handle]));

		let {remainders,reminders_remedial_actions} = this.state;

		if(type === 'ta'){
			if(flag === 'advance'){
				let newremainders = [];
				remainders.advance_reminders_values.forEach((item) => {
					if (item.id === id) {
						item.period = value;
						//console.log("matched", item);
					}
					newremainders.push(item);
				});
				remainders.advance_reminders_values = newremainders;
			}
			if(flag === 'overdue'){
				let newremainders = [];
				remainders.remainder.forEach((item) => {
					if (item.id === id) {
						item.period = value;
						//console.log("matched", item);
					}
					newremainders.push(item);
				});
				remainders.remainder = newremainders;
			}
			this.props.updateReminder(remainders);
		}
		if(type === 'rem'){
			if(flag === 'advance'){
				let newremainders = [];
				reminders_remedial_actions.advance_reminders_values.forEach((item) => {
					if (item.id === id) {
						item.period = value;
						//console.log("matched", item);
					}
					newremainders.push(item);
				});
				reminders_remedial_actions.advance_reminders_values = newremainders;
			}
			if(flag === 'overdue'){
				let newremainders = [];
				reminders_remedial_actions.remainder.forEach((item) => {
					if (item.id === id) {
						item.period = value;
						//console.log("matched", item);
					}
					newremainders.push(item);
				});
				reminders_remedial_actions.remainder = newremainders;
			}
			this.props.updateReminderRemedial(reminders_remedial_actions);
			
		}
	}
    closeAddReminderModal = (event) => {
		event.preventDefault();
        
        this.setState({show_add_modal : false, period: '', reminder_name : '' , id : '' , flag: '', type: ''});
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, is_form_modified: true});            
    }



	render()
	{
		if (this.state.remainders === null) {
			return (
				<div>Loading...</div>
			);
		}
		let tooltips = [wNumb({decimals: 0, suffix: 'days'})];
		return (
			<div >
				<EmailContainer >
					<EmailInnerContainer ref={el => { this.el = el; }}>
						<EmailBoldLabel style={{display: "inline-block"}}>Enable Reminders for Testing Activities</EmailBoldLabel>
						{
							(() => {
								if (this.state.remainders.remainder_enabled) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('remainder_enabled')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('remainder_enabled')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
					</EmailInnerContainer>
				{/*
					(() => {
						if (this.state.remainders.remainder_enabled) {
							return <div style={{marginLeft: "30px" , float: "left", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New Reminder" onClick={this.showAddReminder}><FaPlusCircle /></div>
						}

					})()
				*/}
				{
					(() => {
						if (this.state.show_add_modal) {
						  let str = (this.state.flag === 'advance') ? 'before the due date' : 'after the overdue date';
						  return(
							  <div>
								<InactiveOverlay />
								<MRModalContainer>
									<MRModalHeader>
										<MRModalHeaderText>Add Reminder</MRModalHeaderText>
										<MRModalHeaderCloseBtn onClick={this.closeAddReminderModal}><FaTimes /></MRModalHeaderCloseBtn>
							            <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
									</MRModalHeader>
									<MRModalBody>
										<RegModalContainertop style={{marginTop: "20px"}}>
										    <RegModalNameLabelDiv>Reminder Name</RegModalNameLabelDiv>
										    <MRModalInput style={{width: "90%",marginLeft: "25px" , fontWeight: "bold" , color: "#000000"}} name="reminder_name" onChange={this.handleChange} value={this.state.reminder_name}/>
										</RegModalContainertop>
										<RegModalContainertop style={{marginTop: "20px"}}>
										     <RegModalNameLabelDiv style={{width: "590px"}}>Select number of days {str} to trigger an email</RegModalNameLabelDiv>
											 
										</RegModalContainertop>
										<RegModalContainertop style={{marginTop: "20px"}}>
											 <GeneralsubLabel></GeneralsubLabel>
										     <Nouislider range={{ min: 0, max: 30 }} start={this.state.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeIntervalModalSlider} />
											 
										</RegModalContainertop>
									</MRModalBody>
									<div style={{clear: "both"}} ></div>
									<MRModalFooter style={{marginTop: "20px"}}>
											<MRModalNextBtn  style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7" }} onClick={this.submitAddReminderModal}>Submit</MRModalNextBtn>
											<MRModalCancelBtn style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black"}} onClick={this.closeAddReminderModal}>Cancel</MRModalCancelBtn>
									</MRModalFooter>
									<div style={{clear: "both"}} ></div>
								</MRModalContainer>
							  </div>
							  )
						}

					})()
				}
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							return (
								<div>
									<div style={{marginTop: "10px"}}>
										<div style={{marginLeft: "30px" , float: "left", color: "#000000", fontWeight: "bold" , fontSize: "14px"}}>In Advance Reminders for Testing Activities</div>
										<div style={{marginRight: "30px" , float: "right", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New Advance Reminder" onClick={(e) => this.showAddReminder(e, 'ta', "advance")}><FaPlusCircle /></div>
									</div>
									<div
									style={{
									  marginLeft: "30px" ,
									  float: "left",
									  fontWeight: "600",
									  color: "#595959",
									  marginTop: "5px",
									  marginRight: "30px"
									}}
									>
									Email 'Testing activity is due' will be sent in advance of a Testing activity becoming due, please set reminders as required (note: these are calendar days)
									</div>
								</div>
							)
								
						}

					})()
				}
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							return <div style={{clear: "both",marginTop: "85px"}}></div>
								
						}

					})()
				}
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							//console.log("this.state.remainders.remainder.length==>",this.state.remainders.remainder.length);
							//console.log("this.state.remainders.remainder==>",this.state.remainders);
								return(
									<div>
										{
											this.state.remainders.advance_reminders_values.map((resultItem, index) => {
												    if(this.state.remainders.remainder.length > 1){
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'ta','advance')} style={{cursor: "pointer" }}/> &nbsp; <FaTrash title="remove this reminder" style={{cursor: "pointer"}} onClick={()=>this.removeReminder(resultItem,'ta','advance')}/></EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days before the testing activity is due to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 90 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'ta','advance')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}else{
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'ta','advance')} style={{cursor: "pointer"}}/> </EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days before the testing activity is due to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 90 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'ta','advance')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}
												})
										}
									</div>

									)
						 }


					})()
				}
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							return (
								<div
								style={{
								marginTop: "20px",
								height: "2rem",
								backgroundColor: "#4473C5"
								}}
								></div>
							);
								
						}

					})()
				}
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							return (
								<div>
								<div style={{marginTop: "10px"}}>
									<div style={{marginLeft: "30px" , float: "left", color: "#000000", fontWeight: "bold" , fontSize: "14px"}}> Overdue Reminders for Testing Activities</div>
									<div style={{marginRight: "30px" , float: "right", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New Overdue Reminder" onClick={(e) => this.showAddReminder(e, 'ta', "overdue")}><FaPlusCircle /></div>
								</div>
								<div
								style={{
								  marginLeft: "30px" ,
								  float: "left",
								  fontWeight: "600",
								  color: "#595959",
								  marginTop: "5px",
								  marginRight: "30px",
								  marginBottom: "20px"
								}}
								>
								Email 'A Testing activity is overdue' will be sent in when a Testing activity becomes overdue, please set reminders as required (note: these are calendar days)
								</div>
								</div>
							)
								
						}

					})()
				}
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							//console.log("this.state.remainders.remainder.length==>",this.state.remainders.remainder.length);
							//console.log("this.state.remainders.remainder==>",this.state.remainders);
								return(
									<div>
										{
											this.state.remainders.remainder.map((resultItem, index) => {
												    if(this.state.remainders.remainder.length > 1){
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'ta','overdue')} style={{cursor: "pointer" }}/> &nbsp; <FaTrash title="remove this reminder" style={{cursor: "pointer"}} onClick={()=>this.removeReminder(resultItem,'ta','overdue')}/></EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days after the testing activity is overdue to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 30 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'ta','overdue')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}else{
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'ta','overdue')} style={{cursor: "pointer"}}/> </EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days after the testing activity is overdue to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 30 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'ta','overdue')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}
												})
										}
									</div>

									)
						 }
					})()
				}
				<div style={{clear: "both",marginTop: "50px"}}></div>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Enable Reminders for Remedial Actions</EmailBoldLabel>
					{
						(() => {
							if (this.state.reminders_remedial_actions.remainder_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadioRemedial('remainder_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadioRemedial('remainder_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							return (
								<div>
									<div style={{marginTop: "10px"}}>
										<div style={{marginLeft: "30px" , float: "left", color: "#000000", fontWeight: "bold" , fontSize: "14px"}}>In Advance Reminders for Remedial Actions</div>
										<div style={{marginRight: "30px" , float: "right", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New Advance Reminder" onClick={(e) => this.showAddReminder(e, 'rem', "advance")}><FaPlusCircle /></div>
									</div>
									<div
									style={{
									  marginLeft: "30px" ,
									  float: "left",
									  fontWeight: "600",
									  color: "#595959",
									  marginTop: "5px",
									  marginRight: "30px"
									}}
									>
									Email 'Remedial Action Required' will be sent in advance of a Remedial Action becoming due, please set reminders as required (note: these are calendar days)
									</div>
								</div>
							)
								
						}

					})()
				}
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							return <div style={{clear: "both",marginTop: "85px"}}></div>
								
						}

					})()
				}
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							//console.log("this.state.remainders.remainder.length==>",this.state.remainders.remainder.length);
							//console.log("this.state.remainders.remainder==>",this.state.remainders);
								return(
									<div>
										{
											this.state.reminders_remedial_actions.advance_reminders_values.map((resultItem, index) => {
												    if(this.state.reminders_remedial_actions.remainder.length > 1){
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'rem','advance')} style={{cursor: "pointer" }}/> &nbsp; <FaTrash title="remove this reminder" style={{cursor: "pointer"}} onClick={()=>this.removeReminder(resultItem,'rem','advance')}/></EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days before the remedial action is due to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 90 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'rem','advance')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}else{
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'rem','advance')} style={{cursor: "pointer"}}/> </EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days before the remedial action is due to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 90 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'rem','advance')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}
												})
										}
									</div>

									)
						 }


					})()
				}
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							return (
								<div
								style={{
								marginTop: "20px",
								height: "2rem",
								backgroundColor: "#4473C5"
								}}
								></div>
							);
								
						}

					})()
				}
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							return (
								<div>
								<div style={{marginTop: "10px"}}>
									<div style={{marginLeft: "30px" , float: "left", color: "#000000", fontWeight: "bold" , fontSize: "14px"}}> Overdue Reminders for Remedial Actions</div>
									<div style={{marginRight: "30px" , float: "right", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New Overdue Reminder" onClick={(e) => this.showAddReminder(e, 'rem', "overdue")}><FaPlusCircle /></div>
								</div>
								<div
								style={{
								  marginLeft: "30px" ,
								  float: "left",
								  fontWeight: "600",
								  color: "#595959",
								  marginTop: "5px",
								  marginRight: "30px",
								  marginBottom: "20px"
								}}
								>
								Email 'Remedial Action Overdue' will be sent in when a Remedial action becomes overdue, please set reminders as required (note: these are calendar days)
								</div>
								</div>
							)
								
						}

					})()
				}
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							return <div style={{clear: "both",marginTop: "85px"}}></div>
								
						}

					})()
				}
				{
					(() => {
						if (this.state.reminders_remedial_actions.remainder_enabled) {
							//console.log("this.state.remainders.remainder.length==>",this.state.remainders.remainder.length);
							//console.log("this.state.remainders.remainder==>",this.state.remainders);
								return(
									<div>
										{
											this.state.reminders_remedial_actions.remainder.map((resultItem, index) => {
												    if(this.state.reminders_remedial_actions.remainder.length > 1){
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'rem','overdue')} style={{cursor: "pointer" }}/> &nbsp; <FaTrash title="remove this reminder" style={{cursor: "pointer"}} onClick={()=>this.removeReminder(resultItem,'rem','overdue')}/></EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days after the remedial action is overdue to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 90 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'rem','overdue')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}else{
														return (
															<div key={index}>
																<EmailInnerContainer>
																	<EmailBoldLabel>{resultItem.name} &nbsp; <FaPen title="edit this reminder" onClick={()=>this.editReminderModal(resultItem,'rem','overdue')} style={{cursor: "pointer"}}/> </EmailBoldLabel> 
																	<GeneralsubLabel>Select the no. of days after the remedial action is overdue to send a reminder email</GeneralsubLabel>
																	<div style={{paddingTop: '40px'}}>
																		<Nouislider range={{ min: 0, max: 90 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id,'rem','overdue')} />
																	</div>
																</EmailInnerContainer>
															</div>
														)
													}
												})
										}
									</div>

									)
						 }


					})()
				}
				</EmailContainer>
			</div>
		);
	}
}

export default Reminders;