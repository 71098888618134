import React from 'react';
import Store from '../../Common/Store'
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import General from './General';
import Permissions from './Permissions';
import Reminders from './Reminders';
import RiskImpact from './RiskImpact';
import CustomFields from './CustomFields';
import CustomRags from './CustomRags';
import Email from './Email';
import APICall from '../../Common/APICall.js';
import AlertBox from '../Common/AlertBox';
import Reload from '../../Common/Reload';
import CSLLoader from '../Common/CSLLoader'

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 70%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const ReviewTab_inactive = styled.div`
    background-color: #ffffff;
    color: #dedede;
    padding: 10px 20px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    float: left;
`;

class ModuleConfigHOC extends React.Component
{
    state = {
        active_section_id: "general",
        dirty: false,
        permissions: {},
        module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        standard_Type: null,
        ready: false,
        importStandardTypeButton: true
    };

    setComponentState = () => {
        const postData = { command: "list_company_and_users" };
        const api = new APICall();
        api.command(postData, this.getInitialPermission);
    }

    getInitialPermission = (result) => {
        let contactlist = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : {};
        let user_roles = contactlist.result.user_roles['cmp'];
        let cls = contactlist.result.contactlist;
        let company_users = [];
        for(let key in cls){
            if(cls[key].IsActive === true && cls[key].IsDeleted === false){
                let cu = {};
                cu['email'] = cls[key].EmailAddress;
                cu['user_id'] = key;
                cu['name'] = cls[key].ContactName;
                cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
                company_users.push(cu)
            }
        }
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users

        };
        console.log("result.moduleconfig", result)
        console.log("permissions", permissions)
        this.setState({permissions:permissions, module_config: result.moduleconfig, all_company_users: company_users});
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return

        this.setComponentState()
    }

    returnSection = (section) => (event) => {
        event.preventDefault();
        this.setState({active_section_id: section});
    }

    updateGeneral = (obj) => {
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.general = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        this.setState({module_config});
    }

    updateEmail = (obj) => {
        // console.log("result.moduleconfig", obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.email = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        this.setState({module_config});
    }

    updateReminderRemedial = (obj) => {
        console.log("updateReminderRemedial", obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.reminders_remedial_actions = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        this.setState({module_config});
    }

    updateReminder = (obj) => {
        console.log("updateReminderRemedial", obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.reminders = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        this.setState({module_config});
    }
    updateCustomRags = (obj) => {
        console.log("CUSTF::", obj)
        // let {module_config, dirty} = this.state;
        // const old_config = {...module_config};
        // module_config.custom_rags = obj;
        // const new_config = {...module_config};
        // if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
        //     dirty = true;
        // }
        // //console.log("CUSTF dirty::", dirty)

        // this.setState({module_config});
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'enable_customerag'
        postData['enabled'] = obj.enabled
        api.command(postData, this.processGeneral)
    }

    saveCustomRagsValue = (obj, id) =>{
        console.log("isActive==>", obj)

        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'save_customeRag_value'
        postData['custom_rag'] = obj
        postData['curid'] = id
        api.command(postData, this.processGeneral)

    }

    updateCustomRagValue = (obj) => {
        console.log("CUSTF::", obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.custom_rags.additional_rags = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        //console.log("CUSTF dirty::", dirty)

        this.setState({module_config});
    }
    saveCustomRagValue = (obj) =>{
        console.log("CUSTF SAVE::",obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.custom_rags.additional_rags.push(obj)
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        this.setState({module_config});

    }

    deleteCustomRagsValue = (obj,id,name) => {
        console.log("CUSTF::", obj)
        // let {module_config, dirty} = this.state;
        // const old_config = {...module_config};
        // module_config.custom_fields.additional_fields = obj;
        // const new_config = {...module_config};
        // if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
        //     dirty = true;
        // }
        //console.log("CUSTF dirty::", dirty)

        // this.setState({module_config});

        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'delete_custom_rag'
        postData['value'] = obj
        postData['id'] = id
        postData['name'] = name
        console.log(postData)
        api.command(postData, this.processGeneral)
    }

    updateCustomFields = (obj) => {
        /*console.log("CUSTF::", obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.custom_fields = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        //console.log("CUSTF dirty::", dirty)

        this.setState({module_config});*/
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'enable_customefield'
        postData['enabled'] = obj.enabled
        api.command(postData, this.processGeneral)
    }

    updateCustomFieldsValue = (obj) => {
        console.log("CUSTF::", obj)
        let {module_config, dirty} = this.state;
        const old_config = {...module_config};
        module_config.custom_fields.additional_fields = obj;
        const new_config = {...module_config};
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        //console.log("CUSTF dirty::", dirty)

        this.setState({module_config});
    }

    deleteCustomFieldsValue = (obj) => {
        console.log("CUSTF::", obj)
        // let {module_config, dirty} = this.state;
        // const old_config = {...module_config};
        // module_config.custom_fields.additional_fields = obj;
        // const new_config = {...module_config};
        // if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
        //     dirty = true;
        // }
        //console.log("CUSTF dirty::", dirty)
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'delete_additinal_field'
        postData['value'] = obj
        console.log(postData)
        api.command(postData, this.processGeneral)

        // this.setState({module_config});
    }

    updateAdditionalState = (obj, id, flag) => {
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'save_additinal_state'
        postData['value'] = obj
        postData['name'] = id
        postData['status'] = flag
        console.log(postData)
        api.command(postData, this.processGeneral)
    }

    updateAdditional_rags = (obj, id, flag) => {
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'save_rag_state'
        postData['value'] = obj
        postData['id'] = id
        postData['status'] = flag
        console.log(postData)
        api.command(postData, this.processGeneral)
    }

    saveCustomFieldsValue = (obj, id) =>{
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'save_customefield_value'
        postData['custom_field'] = obj
        postData['curid'] = id
        api.command(postData, this.processGeneral)

    }
    updatePermissions = (obj, single_obj) => {
        console.log("single_obj:", obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;

        this.setState({permissions: obj, changed_user_roles});
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }


    updateModuleconfig = (event) => {
        event.preventDefault();
        let {module_config, changed_user_roles} = this.state;
        const api = new APICall();
        let postData = {command: 'moduleconfig'}
        if(this.state.active_section_id === "permissions") {
            postData['sub_command'] = 'save_user_role'
            postData['changed_user_roles'] = changed_user_roles
            api.command(postData, this.processChangeRole)
            Reload.RestoreStorage(changed_user_roles);
        }else{
            postData['sub_command'] = 'save_remindars'
            postData['reminders'] = module_config.reminders
            postData['reminders_remedial_actions'] = module_config.reminders_remedial_actions
            api.command(postData, this.processGeneral)
        }
    }

    updateRiskImpacts = (obj, id) => {
        let api = new APICall();
        let postData = {command: 'moduleconfig'}
        postData['sub_command'] = 'save_riskimpact'
        postData['risk'] = obj
        postData['id'] = id
        api.command(postData, this.processGeneral)
    }

    processChangeRole = (result) => {
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
        if(result.error_code > 0) {
            alert_param['message'] = result.error_msg;
        }
        this.setState({alert_param: alert_param});
    }

    processGeneral = (result) => {
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                          alertHandler: this.alertremoveHandler, stack: {id: 0}}
        if(result.error_code > 0) {
            alert_param['message'] = result.error_msg;
        }
        this.setState({module_config: result.result, alert_param: alert_param});
    }

    alertremoveHandler = (result, stack) => {
        this.setState({alert_param: null});
    }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        window.location.replace(url);
    }

    render()
	{

	if (Store.getStoreData('role') !== 'admin_manager') {
		return (
			<ErrorBar>
				<div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
				<div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
				<div style={{clear: "both"}}></div>
			</ErrorBar>
		);
	 }
     console.log("MODC CUSTF stateA::",this.state.module_config)
    if (this.state.module_config === null) {
        return (<div><CSLLoader /></div>);
     }
     console.log("MODC CUSTF stateB::",this.state.module_config.custom_fields)
	return (
                <div style={{padding: "10px 10px 20px 10px" , backgroundColor: "rgb(242, 242, 243)" , height: "2000px"}}>
                    <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
                    <div style={{clear: "both"}}></div>
                    <ReviewBodyContainer >
                        <ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                            <ReviewTab onClick={this.returnSection("general")} style={{marginTop: "2px"}}>
                                {
                                    (() => {
                                        if (this.state.active_section_id === "general") {
                                            return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                        } else {
                                            return (<div style={{float: "left"}}>General</div>);
                                        }
                                    })()
                                }
                                <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                <div style={{clear: "both"}}></div>
                            </ReviewTab>
                            <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
                                {
                                    (() => {
                                        if (this.state.active_section_id === "email") {
                                            return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
                                        } else {
                                            return (<div style={{float: "left"}}>Email</div>);
                                        }
                                    })()
                                }
                                <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                <div style={{clear: "both"}}></div>
                            </ReviewTab>
                            <ReviewTab onClick={this.returnSection("reminders")} style={{marginTop: "2px"}}>
                                    {
                                        (() => {
                                            if (this.state.active_section_id === "reminders") {
                                                return (<div style={{float: "left", fontWeight: "600"}}>Reminders</div>);
                                            } else {
                                                return (<div style={{float: "left"}}>Reminders</div>);
                                            }
                                        })()
                                    }
                                    <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                    <div style={{clear: "both"}}></div>
                            </ReviewTab>
                            {/*<ReviewTab onClick={this.returnSection("permissions")} style={{marginTop: "2px"}}>*/}
                            {/*    {*/}
                            {/*        (() => {*/}
                            {/*            if (this.state.active_section_id === "permissions") {*/}
                            {/*                return (<div style={{float: "left", fontWeight: "600"}}>Permissions</div>);*/}
                            {/*            } else {*/}
                            {/*                return (<div style={{float: "left"}}>Permissions</div>);*/}
                            {/*            }*/}
                            {/*        })()*/}
                            {/*    }*/}
                            {/*    <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>*/}
                            {/*    <div style={{clear: "both"}}></div>*/}
                            {/*</ReviewTab>*/}
                            <ReviewTab onClick={this.returnSection("risk_impact")} style={{marginTop: "2px"}}>
                                {
                                    (() => {
                                        if (this.state.active_section_id === "risk_impact") {
                                            return (<div style={{float: "left", fontWeight: "600"}}>Risk Impact</div>);
                                        } else {
                                            return (<div style={{float: "left"}}>Risk Impact</div>);
                                        }
                                    })()
                                }
                                <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                <div style={{clear: "both"}}></div>
                            </ReviewTab>
                            <ReviewTab onClick={this.returnSection("custom_fields")} style={{marginTop: "2px"}}>
                                {
                                    (() => {
                                        if (this.state.active_section_id === "custom_fields") {
                                            return (<div style={{float: "left", fontWeight: "600"}}>Custom Fields</div>);
                                        } else {
                                            return (<div style={{float: "left"}}>Custom Fields</div>);
                                        }
                                    })()
                                }
                                <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                <div style={{clear: "both"}}></div>
                            </ReviewTab>
                            <ReviewTab onClick={this.returnSection("custom_rags")} style={{marginTop: "2px"}}>
                                {
                                    (() => {
                                        if(this.state.active_section_id === "custom_rags"){
                                            return (<div style={{float: "left", fontWeight: "600"}}>Custom RAGs</div>);
                                        } else {
                                            return (<div style={{float: "left"}}>Custom RAGs</div>);
                                        }
                                    })()
                                }
                                <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                <div style={{clear: "both"}}></div>
                            </ReviewTab>
                        </ReviewLeftContainer>
                        <ReviewRightContainer>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral}/>);
                                    }
                                    if (this.state.active_section_id === "email") {
                                        return (<Email email={JSON.parse(JSON.stringify(this.state.module_config.email))} variables={this.state.module_config.variables} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} updateEmail={this.updateEmail} />);
                                    }
                                    if(this.state.active_section_id === "reminders") {
                                            return (<Reminders reminders={JSON.parse(JSON.stringify(this.state.module_config.reminders))} reminders_remedial_actions={JSON.parse(JSON.stringify(this.state.module_config.reminders_remedial_actions))} updateReminder={this.updateReminder} updateReminderRemedial={this.updateReminderRemedial} />);
                                    }
                                    if (this.state.active_section_id === "permissions") {
                                        return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
                                    }
                                    if (this.state.active_section_id === "risk_impact") {
                                        return (<RiskImpact risk_impact={JSON.parse(JSON.stringify(this.state.module_config.risk_impact))} updateRiskImpacts={this.updateRiskImpacts} />);
                                    }
                                    if (this.state.active_section_id === "custom_fields") {
                                        return (<CustomFields custom_fields={JSON.parse(JSON.stringify(this.state.module_config.custom_fields))} updateCustomFields={this.updateCustomFields} saveCustomFieldsValue={this.saveCustomFieldsValue}  deleteCustomFieldsValue={this.deleteCustomFieldsValue} updateAdditionalState={this.updateAdditionalState}/>);
                                    }
                                {/*closePage={this.closePageCustomField} saveCustomFieldsValue={this.saveCustomFieldsValue} deleteCustomFieldsValue={this.deleteCustomFieldsValue} updateCustomFieldsValue={this.updateCustomFieldsValue} updateAdditional_fields={this.updateAdditional_fields}*/}
                                    if (this.state.active_section_id === "custom_rags") {
                                        return (<CustomRags custom_rags={JSON.parse(JSON.stringify(this.state.module_config.custom_rags))} module_config={this.state.module_config} updateCustomRags={this.updateCustomRags} saveCustomRagsValue={this.saveCustomRagsValue} closePage={this.closePageCustomRag} saveCustomRagValue={this.saveCustomRagValue} deleteCustomRagsValue={this.deleteCustomRagsValue} updateCustomRagValue={this.updateCustomRagValue} updateAdditional_rags={this.updateAdditional_rags}/>);
                                    }

                                })()
                            }
                            {
                                (() => {

                                    if (this.state.active_section_id !== "email" && this.state.active_section_id !== "general" && this.state.active_section_id !== "risk_impact" && this.state.active_section_id !== "custom_fields" && this.state.active_section_id !== "custom_rags") {
                                        return (
                                            <FooterButtonsContainer>
                                                <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                                                <SaveBtn onClick={this.updateModuleconfig}>Save</SaveBtn>
                                            </FooterButtonsContainer>
                                        )
                                    }
                                })()
                            }


                        </ReviewRightContainer>
                        <AlertBox alertParam = {this.state.alert_param} />
                        <div style={{clear: "both"}}></div>
                    </ReviewBodyContainer>
                </div>
	);
}
}

export default ModuleConfigHOC;
