import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import styled from 'styled-components';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #727272;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

class RemovalConfigModal extends React.Component
{
	state = {ready: false,reason_name: "",isActive: 1,isDescription: 1,isDelete: 0,reasonid: null};

	componentDidMount()
	{        
        if(this.props.curid !== 0) {
            console.log("content==>",this.props.content)            
            this.setState({ready:true,reasonid: this.props.content.reasonID,reason_name: this.props.content.reasonName,
            isActive: this.props.content.isActive,isDelete: this.props.content.isDelete,isDescription: this.props.content.isDescription})
        }else{
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            this.setState({ready:true,reasonid: randNo})
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    handleisDescription = (value) => (event) => {
        //event.preventDefault();
        console.log("value",value)  
        let data = value === 1 ? 0 : 1;      
        this.setState({isDescription: data})        
    }

    submitReasonRemoval = (event) => {
        event.preventDefault();
        if(this.state.reason_name === "")
        {
            alert("Please provide a reason name.");
        }else{
            let resonObject = {}
            resonObject.reasonID = this.state.reasonid;
            resonObject.reasonName = this.state.reason_name;
            resonObject.isDescription = this.state.isDescription;
            resonObject.isActive = parseInt(this.state.isActive);
            resonObject.isDelete = parseInt(this.state.isDelete);
            //console.log("resonObject==>",resonObject)
            if(this.props.curid !== 0) {
                this.props.updateReasonValue(resonObject);
            }else{                
                this.props.saveReasonValue(resonObject);
            }
        }
    }

	

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}

		return (
			<EmailContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Reason for Removal</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>            
                <MRModalBody>
                    <MRModalLabel style={{display: "inline-block"}}>Name</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                    <MRModalInput name="reason_name" value={this.state.reason_name} onChange={this.handleChange}/>
                    {
                        (() => {
                            if(this.state.isDescription === 1){
                                return(<div><div style={{cursor: "pointer", fontSize:"21px", marginTop: "20px",width: "21px",display: "inline-block"}} onClick={this.handleisDescription(1)}><IoIosCheckboxOutline /></div>
                                <MRModalLabel style={{display: "inline-block",marginLeft: "10px"}}>Require Description when selected</MRModalLabel></div>)
                            }else{
                                return(<div><div style={{cursor: "pointer", fontSize:"21px", marginTop: "20px",width: "21px",display: "inline-block"}} onClick={this.handleisDescription(0)}><IoIosSquareOutline /></div>
                                <MRModalLabel style={{display: "inline-block",marginLeft: "10px"}}>Require Description when selected</MRModalLabel></div>)
                            }
                        })()
                    }                    
                    <MRModalLabel style={{marginTop: "30px",display: "inline-block"}}>Status</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                    <MRModalSelect onChange={this.handleChange} name="isActive" value={this.state.isActive}>
                        <option  value="1">Active</option>
                        <option  value="0">Inactive</option>   
                    </MRModalSelect>
                    <div style={{float: "right",marginTop: "30px"}}>
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitReasonRemoval}>Submit</SaveBtn>
                    </div>
                </MRModalBody>                
                <div style={{clear: "both"}}></div> 
			</EmailContainer>
		);
	}
}

export default RemovalConfigModal;