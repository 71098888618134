import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {FaTimes ,FaPlusCircle , FaStarOfLife, FaCalendarAlt,FaTrash, FaUndoAlt} from 'react-icons/fa';
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';
import DatePicker from 'react-date-picker';
import AlertBox from '../../Common/AlertBox.js';
import CSLLoader from '../../Common/CSLLoader.js';
import EventBus from '../../../Common/EventBus.js';
import '../../Common/DateSelect.css';
import CMPUtils from '../../Common/CMPUtils.js';

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 995;
    opacity: 0.8;
    `;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 155px);
    width: calc(100% - 450px);
    position: fixed;
    top: 68px;
    left: 300px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;

const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
    height:calc(100vh - 340px);
    overflow-y:auto
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;

const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;

const RiskSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 14px;
    font-weight: bold;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 5px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: calc(50% - 100px);
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 5px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;
const TabPanel = styled.div`
margin-top: 15px;
width: 100%;
box-sizing: border-box;
width: 77%;
margin-left:35px;
padding:10px 0px 10px 0px;
margin-bottom:50px
`;
const ListBox = styled.div`
background-color: rgb(255, 255, 255);
padding: 5px;
width: 90%;
float:left;
padding:5px
`;
const ListBoxHeader = styled.div`
background-color: rgb(243, 247, 251);
padding: 15px;
width: 95%;
float:left

`;

const ListBoxPanel = styled.div`
background-color: white;
padding: 15px;
width: 95%;
float:left

`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
	font-weight: bold;
    margin-left: 24px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;


const validator_json = {
    plan: {
        testing_activities: {
            testing_owner: {
                invalid_value: '0',
                msg: 'Testing owner can not be empty',
                child0: {},
                status_check:{
                    key:'is_deleted',
                    value:'true'
                }
            }
        },
        risk_areas: {
            risk_impact: {
                invalid_value: '0',
                msg: 'Risk impact can not be empty',
                child0: {},
                status_check:{
                    key:'status',
                    value:'Deleted'
                }
            },
            risk_owner_id: {
                invalid_value: '0',
                msg: 'Risk owner can not be empty',
                child0: {},
                status_check:{
                    key:'status',
                    value:'Deleted'
                }
            },
            subcategories: {
                invalid_value: '0',
                msg: 'Risk owner can not be empty',
                child0: {

                    subcat_assignto: {
                        invalid_value: '0',
                        msg: 'Subcategory owner can not be empty',
                        child1: {},
                        status_check:{
                            key:['subcat_status' ,'status'],
                            value:'Deleted'
                        }
                    }
                }
            }

        }
    }
}

class TADatePicker extends React.Component{
    state = {dt: null}

    setComponentState = () => {
        let dt = this.transformDate(this.props.date_str)
        this.setState({dt})
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
        this.setComponentState()
    }

    transformDate = (dt) => {
        let dts = dt.split('/')
        return `${dts[2]}-${dts[1]}-${dts[0]}T12:01:01.000`
    }
    onScheduleDateChange = (date) => {

        this.setState({plan_start_date:date});
    }
    changeTaDate = (v) => {
        
        
        let dts = v.toString().split(' ')
        let m = {'Jan': '01', 'Feb' : '02', 'Mar' : '03', 'Apr' : '04', 'May' : '05', 'Jun' : '06', 
                    'Jul' : '07', 'Aug' : '08', 'Sep' : '09', 'Oct' : '10', 'Nov' : '11', 'Dec' : '12'}
        let dt = `${dts[2]}/${m[dts[1]]}/${dts[3]}`
        console.log('v', dt)
        this.props.onDateChange(dt, this.props.ta_index)
    }

    render() {
        if(this.state.dt === null)return <div />
        
        return (<>
            <DatePicker
                clearIcon={null}
                calendarIcon={null}
                locale={"en-GB"}
                value={new Date(this.state.dt)}
                onChange={this.changeTaDate}
                style={{border:"none" ,  fontWeight: "bold"}}
                className="csldatecontainer"
            />

        </>)
    }
}

class CPModal extends React.Component{
    state = { tabIndex: 1, saved_subcategories: [], gc_name: null, alert_param: null,
                gc_id: null, plan: null, gcs_companies:[], risk_owners: [], is_loader:false,
                subcat_owners:[], test_owners:[],comp_user:[], risk_impacts:[], plan_start_date:new Date(),to_be_deleted:[] }

    setComponentState = () => {
        console.log("CPMODAL copy props::",this.props)
        let gc_companies = Store.getStoreData('gc_companies')
        let gcs = {}
        let gc_id = this.props.gc_id
        //let comp_user = this.userbycompany(gc_id)

        let plan = JSON.parse(JSON.stringify(this.props.plan))
        if(this.props.mode === 'copy') {
            plan.gc_id = '-1'
            delete plan.risk_areas_parent_id
            delete plan.testing_activities_parent_id
        }

        for(let r of plan.risk_areas) {
            r.changed = false
            if(this.props.mode === 'copy') {
                delete r.subcategories_parent_id
                delete r.__parent_id
                delete r.__record_id
                r.risk_impact = "0"
                r.risk_owner_id = "0"
                
            }
            r.orig_risk_impact = r.risk_impact
            r.orig_risk_owner_id = r.risk_owner_id
            r.deleted = false
            // console.log("CPMODAL copy subcategories::",r.subcategories)
            if(typeof r.subcategories !== 'undefined'){
                for(let s of r.subcategories) {
                    s.changed = false
                    if(this.props.mode === 'copy') {
                        delete s.__parent_id
                        delete s.__record_id
                        s.subcat_assignto = "0"
                    }
                    s.orig_subcat_assignto = s.subcat_assignto 
                    s.deleted = false
                }                
            }

        }

        for(let t of plan.testing_activities) {
            t.changed = false
            if(this.props.mode === 'copy') {
                delete t.__parent_id
                delete t.__record_id
                t.testing_owner = "0"
            }
            t.orig_testing_owner = t.testing_owner
            t.orig_testing_freq = t.testing_freq
            t.orig_first_schedule_dt_str = t.first_schedule_dt_str
            t.orig_first_schedule = t.first_schedule
            
            t.deleted = false

            if(this.props.mode === 'copy') {
                let freq = 0

                switch(t.testing_freq) {
                    case 'Annually': freq = 365*24*60*60*1000; break;
                    case 'Semi-Annually': freq = 180*24*60*60*1000; break;
                    case 'Quarterly': freq = 120*24*60*60*1000; break;
                    case 'Every-Two-Months': freq = 60*24*60*60*1000; break;
                    case 'Monthly': freq = 30*24*60*60*1000; break;
                    case 'Bi-weekly': freq = 14*24*60*60*1000; break;
                    case 'Weekly': freq = 7*24*60*60*1000; break;
                    case 'Daily': freq = 1*24*60*60*1000; break;
                    case 'Once': freq = 0; break;

                }


                let target_date = new Date();
                


                let obj = CMPUtils.findNextDueDate(t.testing_freq, target_date, t.orig_first_schedule)


                console.log("COPYPLAN findNextDueDate::",t.testing_freq,obj)
                // return;


                let yyyy = obj.yy;
                let mm = obj.mm; // Months start at 0!
                let dd = obj.dd;

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                t.first_schedule_dt_str = dd + '/' + mm + '/' + yyyy;
                t.first_schedule = yyyy +'-'+ mm +'-'+ dd +'T00:00:00';
            }
        }
        
        for(let g of gc_companies) {
            if(!g.is_gc)gcs['0'] = g 
            else gcs[g.id] = g
        }
        console.log("CPMODAL gcs",gcs)
        let gcs_companies = []
        for(let k in gcs){
            console.log("CPMODAL k",k)
            //if(parseInt(k) !== parseInt(gc_id))gcs_companies.push(gcs[k])
                gcs_companies.push(gcs[k])
        }
        console.log("CPMODAL gcs_companies",gcs_companies)

        let gc = gcs[gc_id]
        let gc_name = gc.is_active === 1 ? gc.company_name : gc.company_name+" (Inactive)"

        let mc = Store.getStoreData('module_config')
        let risk_impacts = []
        for(let im of mc.risk_impact) {
            if(im.status === 'Active')risk_impacts.push(im)  
        }

        let saved_subcategories = [];
        let r_i = 0
        for(let r of plan.risk_areas){
            let s_i = 0
            if(typeof r.subcategories !== 'undefined'){
                for(let s of r.subcategories){
                    s.r_i = r_i 
                    s.s_i = s_i 
                    saved_subcategories.push(s)
                    s_i++
                }
            }
            r_i++
        }

        if(this.props.mode === 'copy'){
             this.setState({gc_name, saved_subcategories, gc_id, plan, gcs_companies, risk_impacts})
        }else{
            let r = this.showRisksOwners(gc_id)
            this.setState({gc_name, saved_subcategories, gc_id, plan, gcs_companies, risk_impacts, comp_user:r.comp_user,risk_owners: r.risk_owners,subcat_owners: r.subcat_owners,test_owners:r.test_owners})

        }
        // console.log("CPMODAL plan::", r)
        // 
    }


    editPlan = () => {
        let plan = this.state.plan 

        let plan_name = plan.plan_name
        let plan_id = plan.unique_plan_id
        let gc_id = this.state.gc_id
        let all_plans = Store.getStoreData('gc_plans')[gc_id]
        let exist = false;
        let check = Object.values(all_plans).find((obj) => obj.name.trim() === plan_name.trim())


           console.log("editPlan::",check,plan)

        if(check){
           if(check.plan_ref_id !== plan_id){
             exist = true
           }
        }

        if(exist){
            let alert_param = {title: 'Alert', message: 'Plan name already exists for the company.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} };
            this.setState({alert_param: alert_param});
            return false;  
        }else{
            let records  = []
            let to_be_deleted = []
            for(let t of plan.testing_activities) {
                if(t.deleted || t.changed) {
                    t.is_deleted = t.deleted
                    if(t.is_deleted) to_be_deleted.push({'id':t.unique_testing_act_id,type:'ta'})
                    delete t.deleted
                    delete t.changed
                    delete t.orig_testing_owner
                    delete t.orig_testing_freq
                    delete t.orig_first_schedule_dt_str
                    delete t.orig_first_schedule
                    
                }
                records.push(t)
            }
            for(let r of plan.risk_areas) {
                let risk_record = JSON.parse(JSON.stringify(r))
                if(risk_record.deleted || risk_record.changed) {
                    // risk_record.status = risk_record.deleted ? 'Inactive' : 'Active'
                    if(risk_record.deleted) risk_record.status = 'Deleted'
                    if(risk_record.deleted) to_be_deleted.push({'id':risk_record.unique_risk_id,type:'ra'})
                    delete risk_record.subcategories
                    delete risk_record.changed
                    delete risk_record.deleted
                    delete risk_record.orig_risk_impact
                    delete risk_record.orig_risk_owner_id
                    
                    for(let s of r.subcategories) {
                        let sc_record = JSON.parse(JSON.stringify(s))
                        // sc_record.status = sc_record.deleted ? 'Inactive' : 'Active'
                        if(sc_record.deleted) sc_record.subcat_status = 'Deleted'
                        if(sc_record.deleted) sc_record.status = 'Deleted'
                        if(sc_record.deleted) to_be_deleted.push({'id':sc_record.unique_subcat_id,type:'sc'})
                        delete sc_record.testing_activities
                        delete sc_record.changed
                        delete sc_record.deleted
                        delete sc_record.orig_subcat_assignto
                        records.push(sc_record)
                    }
                }
                records.push(risk_record)
                console.log("editPlan::records",records)
            }

            console.log('records', records)
            this.setState({is_loader:true})
            let api = new APICall()
            let postData = {command: 'plan', sub_command: 'update_records', plan_name: plan.plan_name,
                            records: records, gc_id: plan.gc_id, plan_ref_id: plan.unique_plan_id,to_be_deleted:to_be_deleted}
            console.log('to_be_deleted::',to_be_deleted)
            api.command(postData, this.processEditPlan)            
        }


    }

    processEditPlan = (result) => {
        if(result.error_code > 0) {
            alert('ERROR: Plan could not be updated. Please try after some time.')
            this.props.planModified(null, null)
            return
        }
        let api = new APICall()
        let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: this.state.plan.unique_plan_id}
        api.command(postData, this.processEditPlanFinal)
    }

    processEditPlanFinal = (result) => {
        if(result.error_code > 0) {
            alert('ERROR: Plan was updated, but could not be fetched. Please refresh the browser.')
            this.props.planModified(null, null)
            return
        }
        this.setState({is_loader:false})

        // alert('Successfully modified the plan.')
        this.props.planModified(result.result.plan, result.result.plan_tasks)
    }

    copyPlan = () => {
        let plan = this.state.plan 

        if(plan.gc_id === "-1"){
            let alert_param = {title: 'Alert', message: 'Target company required.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} };
            this.setState({alert_param: alert_param});          
        }else{

           
            
            let validation_msg = ''
            let validation = true
            let CMPStatejson = this.state
            Object.keys(validator_json).map((key_0, i) => {
                Object.keys(validator_json[key_0]).map((key_1, j) => {
                    Object.keys(validator_json[key_0][key_1]).map((key_2, k) => {
                            for (let elem of CMPStatejson[key_0][key_1]) {
                                if(Object.keys(validator_json[key_0][key_1][key_2]['child0']).length === 0){
                                 let status_check = validator_json[key_0][key_1][key_2]['status_check']
                                 if(elem.status !== 'Deleted' ){

                                    if (elem[key_2] === validator_json[key_0][key_1][key_2].invalid_value) {
                                        console.log("RISK AREA IMPACT CHECK::",key_1,key_2,elem)
                                        validation = false
                                        validation_msg = validator_json[key_0][key_1][key_2].msg
                                        break;
                                    }
                                 }else{
                                    console.log('status deleted ****')
                                 }
                                    
                           
                                }else{
                                    
                                    Object.keys(validator_json[key_0][key_1][key_2]['child0']).map((key_3, m) => {
                                        for(let child_obj of elem[key_2] ){
                                            console.log('RISK AREA IMPACT CHECK::child_obj', validator_json[key_0][key_1][key_2]['child0'])
                                            let status_check = validator_json[key_0][key_1][key_2]['child0'][key_3]['status_check']
                                            let deleted = false
                                            if(child_obj.subcat_status && child_obj.subcat_status === 'Deleted' ){
                                                deleted = true
                                            }else if(child_obj.status && child_obj.status === 'Deleted'){
                                                deleted = true

                                            }else{

                                            
                                            if (child_obj[key_3] === validator_json[key_0][key_1][key_2]['child0'][key_3].invalid_value) {
                                                console.log("RISK AREA IMPACT CHECK::",key_1,key_2,elem)
                                                validation = false
                                                validation_msg = validator_json[key_0][key_1][key_2]['child0'][key_3].msg
                                                break;
                                            }
                                        } 
                                        }
                                    })
    
                                    
                                }
                           
                            }
                        
                        
                    })
    
                })
                
            })
            let plan_name = plan.plan_name
            // let plan_id = plan.unique_plan_id
            let gc_id = this.state.gc_id 
            let all_plans = Store.getStoreData('gc_plans')[gc_id]
 
            let check = Object.values(all_plans).find((obj) => obj.name.trim() === plan_name.trim())


               console.log("editPlan::",check)

            if(check){
               // if(check.plan_ref_id !== plan_id){
                 validation = false
                 validation_msg = 'Plan name already exists for the company.';
               // }    
            }
  
            if(!validation){
                let alert_param = {title: 'Alert', message: validation_msg, ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} };
                this.setState({alert_param: alert_param});
            }else{

            let new_unique_plan_id = this.genKey(21)

            let risk_arr = []
            let subcat_arr = []

            let risk_areas = []
            // console.log('CPMODAL copy before change', plan)
            for(let r of plan.risk_areas) {
                if(!r.deleted) {
                    r.plan_ref_id = new_unique_plan_id
                    let new_unique_risk_id = this.genKey(21)
                    // risk_arr[r.unique_risk_id] = new_unique_risk_id
                    risk_arr.push({ key: r.unique_risk_id, value: new_unique_risk_id })
                    r.unique_risk_id = new_unique_risk_id

                    delete r.deleted
                    delete r.changed
                    let subcategories = []
                    for(let s of r.subcategories) {
                        s.plan_ref_id = new_unique_plan_id
                        s.unique_risk_id = new_unique_risk_id
                        let new_unique_sub_id = this.genKey(21)
                        //subcat_arr[s.unique_subcat_id] = new_unique_sub_id
                        subcat_arr.push({ key: s.unique_subcat_id, value: new_unique_sub_id })
                        s.unique_subcat_id = new_unique_sub_id

                        if(!s.deleted) {
                            delete s.deleted
                            delete s.changed
                            subcategories.push(s)
                        }
                    }
                    risk_areas.push(r)
                }
            }
            // console.log("CPMODAL::risk_arr::",risk_arr)
	        let testing_activities = []
	        for(let t of plan.testing_activities) {
                t.plan_ref_id = new_unique_plan_id
                t.unique_testing_act_id = this.genKey(21)

                let risk_Value = risk_arr.find(obj => obj.key === t.unique_risk_id).value;

                let subcat_value = t.unique_subcat_id.toString() === "0" ? "0" : subcat_arr.find(obj => obj.key === t.unique_subcat_id).value;

                t.unique_risk_id = risk_Value
                t.unique_subcat_id = subcat_value

	            if(!t.deleted) {
	                delete t.deleted;
	                delete t.changed;
	                testing_activities.push(t)
	            }
	        }
	        plan.testing_activities = testing_activities

	        plan.risk_areas = risk_areas
	        plan.unique_plan_id = new_unique_plan_id
	        // console.log('CPMODAL copy after change', plan)
            this.setState({is_loader:true})

	        let api = new APICall()
	        let postData = {command: 'plan', sub_command: 'copy_plan', plan: plan}
	        console.log("CPMODAL postData::",plan)
	        api.command(postData, this.processCopyPlan)
    	}
    } 
    }
    genKey(length){
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    AlertHandler = (result, stack) => {
        this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
    }

    processCopyPlan = (result) => {
        console.log('result', result)
        this.setState({is_loader:false})

        this.props.planCopied(result.result)
    }

    savePlan = () => {
        switch(this.props.mode) {
            case 'edit': this.editPlan();break; 
            case 'copy': this.copyPlan();break; 
        }
    }

    showRisksOwners = (gc_id) => {
        let res = {}
        let gc_companies = Store.getStoreData('gc_companies')

        if(gc_id === '0'){
            gc_id = gc_companies.find(x=>x.is_gc===false).id
        }
        let user_info = Store.getStoreData('user_info')
        let user_id = user_info.ID.toString()
        let role = Store.getStoreData('role')
        let contacts = Store.getStoreData('contacts')
        let user_roles = Store.getStoreData('user_roles')
        // let gc_companies = Store.getStoreData('gc_companies')
        let company_users_list = {}
        for(let gc of gc_companies) {
            company_users_list[gc.id.toString()] = gc.users
        }
        let company_users = company_users_list[gc_id.toString()] 
        let risk_owners = []
        let subcat_owners = []
        let test_owners = []
        for(let u_id in contacts) {
            if(user_roles[u_id] === 'admin_manager'){
                risk_owners.push({id: u_id, name: contacts[u_id]})
                subcat_owners.push({id: u_id, name: contacts[u_id]})
                test_owners.push({id: u_id, name: contacts[u_id]})
            }
            if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner') {
                if(u_id in company_users){
                    risk_owners.push({id: u_id, name: contacts[u_id]})
                }
            }
            if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner' || user_roles[u_id] === 'subcat_owner') {
                if(u_id in company_users){
                    subcat_owners.push({id: u_id, name: contacts[u_id]})
                }
            }
            if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner' || user_roles[u_id] === 'subcat_owner' || user_roles[u_id] === 'team') {
                if(u_id in company_users){
                    test_owners.push({id: u_id, name: contacts[u_id]})
                }
            }
        }

        risk_owners.sort(function(a, b) {
            return a.name.localeCompare(b.name)
        }); 
        subcat_owners.sort(function(a, b) {
            return a.name.localeCompare(b.name)
        }); 
        test_owners.sort(function(a, b) {
            return a.name.localeCompare(b.name)
        }); 

        res.risk_owners = risk_owners
        res.subcat_owners = subcat_owners
        res.test_owners = test_owners
        res.comp_user = test_owners

        return res;
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
        this.setComponentState()
    }

    handleChange = (e) => {
        let field_name = e.target.name 
        let plan = this.state.plan
        plan[field_name] = e.target.value 
        console.log("editPlan::plan",plan)
        this.setState({plan})

    }
    handleCompChange = (e) => {
        // console.log("userbycompany name::",e.target.name)
        // console.log("userbycompany value::",e.target.value)

        let gc_id = e.target.value

        let users = this.userbycompany(gc_id)

        // console.log("userbycompany gc_id::",gc_id)
        // console.log("userbycompany users::",users)

        let field_name = e.target.name 
        let plan = this.state.plan
        plan[field_name] = e.target.value 
        this.setState({plan,comp_user:users})
    }
    userbycompany = (gc_id) =>{
        console.log("userbycompany gc::",gc_id)
        let gc_companies = Store.getStoreData('gc_companies')
        let is_gc = parseInt(gc_id) === 0 ? false : gc_companies.filter(function (gc) { return gc.id == gc_id })[0].is_gc;
        if(!is_gc){
            gc_id = 0
        }

        let user_info = Store.getStoreData('user_info')
        let user_id = user_info.ID.toString()
        let role = Store.getStoreData('role')
        let contacts = Store.getStoreData('contacts')
        let user_roles = Store.getStoreData('user_roles')
        // let gc_companies = Store.getStoreData('gc_companies')
        let company_users_list = {}
        for(let gc of gc_companies) {
            if(gc.is_gc) {
                company_users_list[gc.id.toString()] = gc.users
            } else {
                company_users_list['0'] = gc.users
            }
        }
        let company_users = company_users_list[gc_id.toString()] 
        let risk_owners = []
        let found  = false
        for(let u_id in contacts) {
            if(user_roles[u_id] === 'admin_manager'){
                risk_owners.push({id: u_id, name: contacts[u_id]})

            } else if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner' || user_roles[u_id] === 'subcat_owner' || user_roles[u_id] === 'team') {
                if(u_id in company_users){
                    risk_owners.push({id: u_id, name: contacts[u_id]})

                }
            }
        }
        console.log("userbycompany risk_owners::",risk_owners)

        return risk_owners;

    }
    changeTab = (i) => {
        this.setState({ tabIndex: i })
    }
    handlegcChange = (e) => {
        if(e.target.value.toString() !== "-1"){
        	let gc_id = e.target.value 
        	let gc_companies = Store.getStoreData('gc_companies')
	        let is_gc = parseInt(gc_id) === 0 ? false : gc_companies.filter(function (gc) { return gc.id == gc_id })[0].is_gc;
	        if(!is_gc){
	            gc_id = "0"
	        }
	        let field_name = e.target.name 
            let plan = this.state.plan
            plan[field_name] = gc_id
            let r = this.showRisksOwners(e.target.value)
            let comp_user_sorted =   r.comp_user.sort((a, b) => a.name.localeCompare(b.name))

            this.setState({plan, comp_user:comp_user_sorted,risk_owners: r.risk_owners,subcat_owners: r.subcat_owners,test_owners:r.test_owners})
        }
    }

    onDateChange = (dt, index) => {
        let plan = this.state.plan
        let t = plan.testing_activities[index]
        t.first_schedule_dt_str = dt
        t.changed = (t.orig_testing_owner === t.testing_owner && t.orig_first_schedule_dt_str === t.first_schedule_dt_str && t.orig_testing_freq === t.testing_freq) ? false : true
        console.log('t', t)
        this.setState({plan})
    }

    handleTAChange = (index, e) => {
        console.log('e, index', e, index)
        let field_name = e.target.name 
        let plan = this.state.plan
        let t = plan.testing_activities[index]
        t[field_name] = e.target.value
        console.log('t, index', t, index)
        t.changed = (t.orig_testing_owner === t.testing_owner && t.orig_first_schedule_dt_str === t.first_schedule_dt_str && t.orig_testing_freq === t.testing_freq) ? false : true
        this.setState({plan})
    }

    handleRAChange = (index, e) => {
        //console.log('e, index', e, index)
        let field_name = e.target.name 
        let plan = this.state.plan
        let t = plan.risk_areas[index]
        t[field_name] = e.target.value
        t.changed = (t.orig_risk_owner_id === t.risk_owner_id && t.orig_risk_impact === t.risk_impact) ? false : true
        this.setState({plan})
    }

    handleSCChange = (scat, e) => {
        let field_name = e.target.name 
        let plan = this.state.plan
        let r = plan.risk_areas[scat.r_i]
        let sc = r.subcategories[scat.s_i]
        sc[field_name] = e.target.value
        sc.changed = (sc.orig_subcat_assignto === sc.subcat_assignto) ? false : true
        let saved_subcategories = [];
        let r_i = 0
        for(let r of plan.risk_areas){
            let s_i = 0
            for(let s of r.subcategories){
                s.r_i = r_i 
                s.s_i = s_i 
                saved_subcategories.push(s)
                s_i++
            }
            r_i++
        }
        this.setState({plan, saved_subcategories})
    }

    deleteItem = (c, d) => {
        console.log('c,d', c, d)
        let plan = this.state.plan
        let saved_subcategories = this.state.saved_subcategories
        let r = null
        switch(c) {
            case 'ra' : r = plan.risk_areas[d]
                        r.deleted = r.deleted ? false : true;
                        for(let t of plan.testing_activities) {
                            if(t.unique_risk_id === r.unique_risk_id) {
                                t.deleted = r.deleted
                            }
                        }; 
                        for(let s of r.subcategories) {
                            if(s.unique_risk_id === r.unique_risk_id) {
                                s.deleted = r.deleted
                            }
                        }; 
                        break;
            case 'ta' : r = plan.testing_activities[d]
                        r.deleted = r.deleted ? false : true; break;
            case 'sc' : r = plan.risk_areas[d.r_i]
            			
                        let sc = r.subcategories[d.s_i]
                        if(r.deleted && sc.deleted) {
                            alert('You cannot undelete this Sub Category as the parent Risk Area is deleted.')
                            return
                        }
                        sc.deleted = sc.deleted ? false : true;
                        sc.changed = sc.changed ? false : true;
                        r.changed = r.changed ? false : true;
                        console.log('c,d',r)
                        for(let t of plan.testing_activities) {
                            if(t.unique_risk_id === r.unique_risk_id && t.unique_subcat_id === sc.unique_subcat_id) {
                                t.deleted = sc.deleted
                            }
                        }
                        saved_subcategories = [];
                        let r_i = 0
                        for(let r of plan.risk_areas){
                            let s_i = 0
                            for(let s of r.subcategories){
                                s.r_i = r_i 
                                s.s_i = s_i 
                                saved_subcategories.push(s)
                                s_i++
                            }
                            r_i++
                        } break;
        }
        console.log('plan after delete risk', plan)
        this.setState({plan, saved_subcategories})
    }

    processcontactrole = () => {
        //alert(123)
        let roles = Store.getStoreData('user_roles');
        //console.log("RA:: roles",roles)
        let contacts = Store.getStoreData('contacts');
        let contactmap = [];

        for (const role in roles) {
          //console.log(`${role}: ${roles[role]}`);
          let uid = role;
          let urole = roles[role]
          
          if ( typeof contacts[uid] !== 'undefined' && (urole === 'admin_manager' || urole === 'risk_owner' || urole === 'team' || urole === 'plan_admin' || urole === 'subcat_owner')) {
            contactmap.push({ID:uid,name:contacts[uid]}) 
          }
        }
        //console.log("RA::contactmap",contactmap)
        return contactmap;

    } 
    GetSortOrder = (prop) => {
        return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
        }    
    }
    changeOwner = (e) => {
        let alert_param = {title: 'Confirm', message: 'This will change all owner fields, are you happy to progress?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.processChangeOwner, stack: {value : e.target.value} };
        this.setState({alert_param: alert_param});
        //console.log('e', e.target.name,e.target.value)
    }
    processChangeOwner = (result,stack) => {
        this.setState({alert_param: null}); 
        if(result === false) return;
        console.log('result,stack',result,stack)
        let value = stack.value
        let plan = this.state.plan;
        let risk_areas = plan.risk_areas;
        let testing_activities = plan.testing_activities;


        for(let i=0; i < testing_activities.length;i++){
            testing_activities[i].testing_owner = value;
        }



        for(let j=0; j < risk_areas.length;j++){
            risk_areas[j].risk_owner_id = value;

            let subcategories = risk_areas[j].subcategories;

            for(let k =0;k< subcategories.length;k++){
                subcategories[k].subcat_assignto = value
            }
        }
        
        //console.log(plan)
        this.setState({plan})

    }
    onScheduleDateChange = (date) => {
        let alert_param = {title: 'Confirm', message: 'This will change first schedule date fields, are you happy to progress?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.processChangeDate, stack: {value : date} };
        this.setState({alert_param: alert_param});
    }
    processChangeDate = (result,stack) => {
        console.log(result,stack)
        this.setState({alert_param: null}); 
        if(result === false) return;

        let date = stack.value
        let plan = this.state.plan;
        let testing_activities = plan.testing_activities;
        const selected_date = new Date(date);
        console.log('CPMODAL selected_date',selected_date)
        

        for(let i=0; i < testing_activities.length;i++){
            let obj = CMPUtils.findNextDueDate(testing_activities[i].testing_freq, selected_date, testing_activities[i].orig_first_schedule)
            console.log('COPYPLAN findNextDueDate:: target_date',obj, testing_activities[i].first_schedule_dt_str)
            let yyyy = obj.yy;
            let mm = obj.mm;
            let dd = obj.dd;
            if (obj.dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            testing_activities[i].first_schedule_dt_str = dd + '/' + mm + '/' + obj.yy;
            testing_activities[i].first_schedule = yyyy +'-'+ mm +'-'+ dd +'T00:00:00';

        }

        this.setState({plan_start_date:date,plan});
    }

    deletePlan = ()=>{
        let alert_param = {title: 'Confirm', message: 'Clicking this button will delete this plan and all related records. Once deleted this plan cannot be recovered, are you sure you want to proceed?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.deleteConfirm, stack: {} };
        this.setState({alert_param: alert_param});
    }

    deleteConfirm = (result, stack)=>{
    	this.setState({alert_param: null}); 
        if(result === false) return;

        let plan = this.state.plan
        let postData = {command: 'plan', sub_command: 'delete_plan', plan_id: plan.id, gc_id: plan.gc_id, plan_ref_id: plan.unique_plan_id}

        this.setState({is_loader:true})

        let api = new APICall()
        api.command(postData, this.processDeletePlan)
       

    }

    processDeletePlan = (result) => {
        console.log('result_deleteConfirm' , result)
        // this.setState({is_loader:false , alert_param:null})
        let alert_param = {title: 'Confirm', message: 'Plan successfully deleted', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.deleteResult, stack: {} };
        this.setState({is_loader:false ,alert_param: alert_param});

    }
    deleteResult = (result, stack)=>{
        this.setState({ alert_param:null})
        window.location.reload(false);
    }
	render() {
        console.log("CPMODAL::",this.state)
        if(this.state.plan === null) return <div />
        let dialog_title = `Copy Plan "${this.state.plan.plan_name}" from "${this.state.gc_name}"`
        if(this.props.mode === 'edit') dialog_title = `Edit Plan "${this.state.plan.plan_name}" in "${this.state.gc_name}"`
        let subcategories = this.state.saved_subcategories;
        let all_gcs = this.state.gcs_companies
        let elligible_gcs = []
        let gc_plans = Store.getStoreData('gc_plans')
        for(let g of all_gcs) {
            //if(g.id.toString() in gc_plans && !(this.state.plan.unique_plan_id in gc_plans[g.id.toString()]))
                elligible_gcs.push(g)
        }

		return (<>
            <AlertBox alertParam = {this.state.alert_param}/>
            {
                (() => {
                    if(this.state.is_loader){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <InactiveOverlay style={{ height: "105%" }} />

			<MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText> {dialog_title} </MRModalHeaderText>
                    <div style={{ clear: "both" }}></div>
                    <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{ clear: "both" }}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{ marginTop: "15px" }}>
                        <div style={{ float: "left", width: "100%" }}>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Plan name</MRModalLabel>
                            <div style={{ clear: "both", marginTop: "5px" }}></div>
                            <MRModalInput name="plan_name" 
                            value={this.state.plan.plan_name} onChange={this.handleChange}
                            style={{ width: "calc(98% - 150px)", marginLeft: "35px" }}></MRModalInput>
                        </div>
                    </div>
                    {
                        (() => {
                            if(this.props.mode === 'copy'){
                                return (<>
                                <div style={{ marginTop: "25px" }}>
                                    <div style={{ float: "left", width: "100%" , marginTop: "20px" }}>
                                        <MRModalLabel style={{ marginLeft: "35px" }}>Select Company to copy this plan to</MRModalLabel>
                                        <div style={{ clear: "both", marginTop: "5px" }}></div>
                                        <RiskSelect style={{ width: "80%", marginLeft: "35px" }} 
                                        name="gc_id" value={this.state.plan.gc_id} onChange={this.handlegcChange} >
                                        <option value="-1">Select a target company...</option>
                                        {
                                            elligible_gcs.map((r, i) => {
                                                let id = r.is_gc === false ? '0': r.id
                                                if(r.is_active === 1){
                                                    return <option key={i} value={id}>{r.company_name}</option>
                                                }
                                                
                                            })
                                        }
                                        </RiskSelect>

                                    </div>
                                </div>


                     
			                    <div style={{ clear: "both" }}></div>
			                    <div style={{ marginTop: "15px" }}>
			                        <div style={{ float: "left", width: "100%" }}>
			                        <div style={{fontSize: "14px", fontWeight: "600", marginTop: "15px",marginLeft: "35px"}}>Template details need to be reassigned</div>
			                        <div style={{fontSize: "11px", marginTop: "5px",marginLeft: "35px"}}>As this is a copied plan, the various risk areas, subcategories and activities will need to be reassigned.</div>
			                        </div>
			                    </div> 
                            </>)
                            }else{
                            	return (<>
                            	<div style={{ clear: "both",marginTop: "100px" }}></div>

                            	 </>)
                            }
                        })()
                    }
                    <div>
                        <ListBoxPanel style={{width:"88%",marginLeft: "35px", display: "flex",justifyContent: "space-between",alignItems: "center"}}>
                    {
                        (() => {
                            if(this.props.mode === 'copy')
                                return (<>
                                    <div style={{fontSize: "14px", fontWeight: "600", marginTop: "15px",marginLeft: "35px"}}>Plan Start Date:
                                     <CSLDateCover style={{ display: "inline-block", marginLeft: "35px", width:"100px" }}>
                                                <DatePicker
                                                    onChange={this.onScheduleDateChange}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    locale={"en-GB"}
                                                    value={this.state.plan_start_date}
                                                    style={{ border: "none", fontWeight: "bold" }}
                                                    className="csldatecontainer"
                                                />
                                    </CSLDateCover>
                                    </div>
                                 </>)
                        })()
                    }

                        <div style={{width:'fit-content',marginRight:'50px'}}>
                            <div style={{fontSize: "14px", fontWeight: "600", marginTop: "15px"}}>
                            Assign all Activities to:
                            </div>
                            <RiskSelect  style={{marginLeft:'0px'}} onChange={(e) => this.changeOwner(e)}>
                                
                                {/*
                                    (() => {
                                        let contactsdropdown = [];
                                        let contacts = this.processcontactrole();
                                        contacts.sort(this.GetSortOrder("name"));
                                        contactsdropdown.push(<option key={0} value={0}>Select User</option>)
                                        contacts.forEach((item) => {
                                            contactsdropdown.push(<option key={item.ID} value={item.ID}>{item.name}</option>)
                                        })
                                        return contactsdropdown;                                                            
                                    })()
                                */}
                                <option value="0">Select User</option>
                                {
                                    this.state.comp_user.map((r, i) => {
                                        return <option key={i} value={r.id}>{r.name}</option>
                                    })
                                }

                            </RiskSelect>
                        </div>
                        </ListBoxPanel>

                    </div>
                    <div>
                            <TabPanel>
                             
                                <MRModalLabel style={{ float: 'left',cursor:'pointer', marginRight: '30px', color: this.state.tabIndex === 1 ? 'rgb(64, 182, 176)' : 'rgb(0, 0, 0)' }} onClick={() => this.changeTab(1)}>Risk Areas</MRModalLabel>
                                <MRModalLabel style={{ float: 'left',cursor:'pointer', marginRight: '30px', color: this.state.tabIndex === 2 ? 'rgb(64, 182, 176)' : 'rgb(0, 0, 0)' }} onClick={() => this.changeTab(2)}>Subcategories</MRModalLabel>
                                <MRModalLabel style={{ float: 'left',cursor:'pointer', color: this.state.tabIndex === 3 ? 'rgb(64, 182, 176)' : 'rgb(0, 0, 0)' }} onClick={() => this.changeTab(3)}>Activites</MRModalLabel>
                            </TabPanel>
                            

                            {
                                (() => {
                                    if (this.state.tabIndex === 1) {
                                        return (
                                            <div>
                                            <ListBox style={{ marginLeft: "35px" }}>
                                            <div style={{width: "100%", boxSizing: "border-box", backgroundColor: "#F3F7FB"}}>
                                                <div style={{float: "left", width: "40%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Risk Area</div>
                                                <div style={{float: "left", width: "30%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Owner</div>
                                                <div style={{float: "left", width: "30%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Risk Impact</div>
                                                <div style={{clear: "both"}}></div>
                                            </div>
                                                <ListBoxPanel>
                                                {
                                                    this.state.plan.risk_areas.map((ra, index) => {
                                                        if(ra.status !== 'Deleted'){
                                                            let color = 'white'
                                                            if(ra.changed)color='#26ADA7'
                                                            if(ra.deleted)color='#FF5555'
                                                            let TrashIcon = ra.deleted ? <FaUndoAlt /> : <FaTrash />
                                                            return (                                               
                                                                <div key={index} style={{width: "100%", boxSizing: "border-box", 
                                                                        backgroundColor: color, paddingBottom: 6}}>
                                                                    <div style={{float: "left", width: "40%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>{ra.risk_area_name}</div>
                                                                    <div style={{float: "left", width: "25%", fontSize: "12px", fontWeight: "600", boxSizing: "border-box"}}>
                                                                        <RiskSelect 
                                                                        onChange={(e) => this.handleRAChange(index, e)}
                                                                        name="risk_owner_id"
                                                                        style={{fontSize: "12px"}} value={ra.risk_owner_id}>
                                                                            <option value="0">Unassigned</option>
                                                                            {
                                                                                this.state.risk_owners.map((r, i) => {
                                                                                    return <option key={i} value={r.id}>{r.name}</option>
                                                                                })
                                                                            }
                                                                        </RiskSelect>
                                                                    </div>
                                                                    <div style={{float: "left", width: "25%", fontSize: "12px", fontWeight: "600", boxSizing: "border-box"}}>
                                                                        <RiskSelect name="risk_impact" 
                                                                        onChange={(e) => this.handleRAChange(index, e)} value={ra.risk_impact} style={{fontSize: "12px"}}>
                                                                            <option value="0">Select a risk impact...</option>
                                                                            {
                                                                                this.state.risk_impacts.map((m, i) => {
                                                                                    return <option key={i} value={m.id}>{m.name}</option>
                                                                                })
                                                                            }
                                                                        </RiskSelect>
                                                                    </div>
                                                                    <div style={{ float: "right", marginTop: "24px"}}>
                                                                        <div 
                                                                        onClick={() => this.deleteItem('ra', index)}
                                                                        title="delete this risk area" style={{color: "rgb(26, 53, 82)", 
                                                                                cursor: 'pointer', paddingRight: 5}}>
                                                                            {TrashIcon}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>)
                                                        }
                                                        
                                                    })
                                                }
                                                </ListBoxPanel>
                                            </ListBox> 
                                            </div>
                                        )
                                    }else if (this.state.tabIndex === 2) {
                                        return (
                                            <ListBox style={{ marginLeft: "35px" }}>
                                            <div style={{width: "100%", boxSizing: "border-box", backgroundColor: "#F3F7FB"}}>
                                                <div style={{float: "left", width: "60%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Subcategories</div>
                                                <div style={{float: "left", width: "40%", fontSize: "14px", fontWeight: "600", padding: "15px 0px", boxSizing: "border-box"}}>Owner</div>
                                                <div style={{clear: "both"}}></div>
                                            </div>
                                                <ListBoxPanel>
                                                {
                                                    subcategories.map((sa,index) => {
                                                        let stat = "subcat_status" in sa ? sa.subcat_status :sa.status
                                                        if(stat !== "Deleted"){
                                                            let color = 'white'
                                                            if(sa.changed)color='#26ADA7'
                                                            if(sa.deleted)color='#FF5555'
                                                            let TrashIcon = sa.deleted ? <FaUndoAlt /> : <FaTrash />
                                                            return (                                                
                                                                <div key={index} style={{width: "100%", boxSizing: "border-box", 
                                                                backgroundColor: color, paddingBottom: 6, height: 55}}>
                                                                    <div style={{float: "left", width: "55%", fontSize: "12px", fontWeight: "600", padding: "21px 0px 0px 15px", boxSizing: "border-box"}}>{sa.risk_subcategory_name}</div>
                                                                    <RiskSelect 
                                                                    onChange={(e) => this.handleSCChange(sa,e)}
                                                                    style={{ width: "150px", marginLeft: "35px", marginRight: "16px", 
                                                                    float: "left" }} name="subcat_assignto" value={sa.subcat_assignto}>
                                                                        <option value="0">Unassigned</option>
                                                                        {
                                                                            this.state.subcat_owners.map((r, i) => {
                                                                                return <option key={i} value={r.id}>{r.name}</option>
                                                                            })
                                                                        }
                                                                    </RiskSelect>
                                                                    <div style={{ float: "right", marginTop: "24px"}}>
                                                                        <div 
                                                                            onClick={() => this.deleteItem('sc', sa)}
                                                                            title="delete this risk area" 
                                                                            style={{color: "rgb(26, 53, 82)", 
                                                                            cursor: 'pointer', paddingRight: 5}}>
                                                                            {TrashIcon}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>)
                                                        
                                                        }
                                                    })
                                                } 
                                                </ListBoxPanel>
                                            </ListBox>
                                        )
                                    } else {
                                        return (
                                            <ListBox style={{ marginLeft: "35px" }}>
                                            <div style={{width: "100%", boxSizing: "border-box", backgroundColor: "#F3F7FB"}}>
                                                <div style={{float: "left", width: "30%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Activity Name</div>
                                                <div style={{float: "left", width: "25%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>First Scheduled Date</div>
                                                <div style={{float: "left", width: "25%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 15px 15px", boxSizing: "border-box"}}>Frequency</div>
                                                <div style={{float: "left", width: "15%", fontSize: "14px", fontWeight: "600", padding: "15px 0px 0px 20px", boxSizing: "border-box"}}>Owner</div>
                                                <div style={{clear: "both"}}></div>
                                            </div>
                                                <ListBoxPanel>
                                                {
                                                    this.state.plan.testing_activities.map((ta, index) => {
                                                    	if(!ta.is_deleted){

	                                                        let color = 'white'
	                                                        if(ta.changed)color='#26ADA7'
	                                                        if(ta.deleted)color='#FF5555'
	                                                        let TrashIcon = ta.deleted ? <FaUndoAlt /> : <FaTrash />
	                                                        // let first_date = this.changeDate(ta.first_schedule_dt_str)
	                                                        return(<div key={index} style={{width: "100%", boxSizing: "border-box",
	                                                                backgroundColor: color, height: 55,
	                                                                paddingBottom: 6}}>
	                                                            <MRModalInput onChange={(e) => this.handleTAChange(index, e)}
	                                                            name="testing_activity" style={{ width: "22%", marginRight: '30px' , marginLeft:'0px', float: "left" }} value={ta.testing_activity}></MRModalInput>
	                                                            <CSLDateCover style={{float: "left", width: "15%", fontSize: "12px", fontWeight: "600", boxSizing: "border-box" , marginLeft : "45px"}}>
	                                                                <TADatePicker
	                                                                    ta_index={index} 
	                                                                    date_str={ta.first_schedule_dt_str}
	                                                                    //date_str={target_date}  
	                                                                    onDateChange={this.onDateChange}
	                                                                />
	                                                    
	                                                            </CSLDateCover>
	                                                            <div style={{float: "left", width: "20%", fontSize: "12px", fontWeight: "600", boxSizing: "border-box" , marginLeft : "60px", marginTop : "-6px"}}>
	                                                                <RiskSelect 
	                                                                onChange={(e) => this.handleTAChange(index, e)}
	                                                                name="testing_freq"
	                                                                style={{fontSize: "12px"}} value={ta.testing_freq} >
	                                                                    <option key="Annually" value="Annually">Annually</option>
	                                                                    <option key="Semi-Annually" value="Semi-Annually">Semi-Annually</option>
	                                                                    <option key="Quarterly" value="Quarterly">Quarterly</option>
	                                                                    <option key="Every-Two-Months" value="Every-Two-Months">Every-Two-Months</option>
	                                                                    <option key="Monthly" value="Monthly">Monthly</option>
	                                                                    <option key="Bi-weekly" value="Bi-weekly">Every-Two-Weeks</option>
	                                                                    <option key="Weekly" value="Weekly">Weekly</option>
	                                                                    <option key="Daily" value="Daily">Daily</option>
	                                                                    <option key="Once" value="Once">Once</option>
	                                                                </RiskSelect>
	                                                            </div>
	                                                            <RiskSelect onChange={(e) => this.handleTAChange(index,e)}
	                                                                style={{ width: "150px", marginLeft: "35px", marginRight: "16px", float: "left" }} 
	                                                                name="testing_owner" 
	                                                                value={ta.testing_owner}>
	                                                                <option value="0">Unassigned</option>
	                                                                {
	                                                                    this.state.test_owners.map((r, i) => {
	                                                                        return <option key={i} value={r.id}>{r.name}</option>
	                                                                    })
	                                                                }
	                                                            </RiskSelect>
	                                                            <div onClick={() => this.deleteItem('ta', index)} 
	                                                                style={{float: "right", width: "3%",marginTop: "20px", 
	                                                                cursor: 'pointer', paddingRight: 5}}>
	                                                                {TrashIcon}
	                                                            </div>
	                                                        </div>)
														}
                                                    })
                                                }

                                                </ListBoxPanel>
                                            </ListBox>
                                        )
                                    }
                                 
                                })()
                            }
                    </div>
                </MRModalBody> 
                <div style={{ clear: "both", marginTop: "10px" }}></div>
                <MRModalFooter style={{backgroundColor: 'white'}}>
                    {
                        // <div>
                        //     <div style={{float: "left" , fontSize: "12px", fontWeight: "600" ,  marginLeft : "15px" , marginTop : "17px"}}>
                        //         Plan Start Date : 
                        //     </div>
                        //     <CSLDateCover style={{float: "left" , width: "15%", fontSize: "12px", fontWeight: "600", boxSizing: "border-box" , marginLeft : "20px" }}>
                        //         <DatePicker
                                         
                        //                 clearIcon={null}
                        //                 calendarIcon={null}
                        //                 locale={"en-GB"}
                        //                 value={""}
                        //                 style={{border:"none" ,  fontWeight: "bold"}}
                        //                 />
                        //     </CSLDateCover> 
                        // </div>
                    }
                    <div style={{ marginRight: "35px" }}>
                    	{
                            (() => {
                            	if(this.props.mode === 'edit'){
                            		return(<CatBtn onClick={this.deletePlan} style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "left", marginLeft: "10px" }}  >Delete Plan</CatBtn>)
                            	}
                       			
  							})()
                        }
                        
                        <CatBtn onClick={this.savePlan} style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }}  >Submit</CatBtn>
                        
                        <CatBtn onClick={this.props.closeModal}  style={{ backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right" }} >Cancel</CatBtn>
                    </div >
                    <div style={{ clear: "both" }}></div>
                </MRModalFooter>    
            </MRModalContainer>
		</>)
	}
}

export default CPModal;