import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import moment from 'moment';
import Cookies from 'universal-cookie';

class CMPUtils {
	ListGroupCompanies = (result) => {
		let ret = {}
		let companies_raw = result.result.gcs;
		let users = result.result.gc_users;
		let tags_config = result.result.tag_configs;
		let parent_contacts = Store.getStoreData('user_roles');
		let company = result.result.company
		let user_ids = Object.keys(users)
		let companies = []
		let parent_company = {id: company.ClientNumber, company_name: company.CompanyName, users: parent_contacts, is_gc: false, is_active: 1, sort_field: company.CompanyName}
		companies.push(parent_company)
		for(let i=0; i< companies_raw.length; i++) {
		    let elem = companies_raw[i];
			elem.sort_field = elem.company_name;
		    elem.tags_config = JSON.parse(companies_raw[i].tags_config)
		    elem.users = {}
		    for(let id of user_ids) {
		        for(let t of tags_config) {
		            let tag_id = Object.keys(t)[0]
		            if ((tag_id in elem.tags_config) && (tag_id in users[id].tags)) {
		                if(elem.tags_config[tag_id] in users[id].tags[tag_id]){elem.users[id] = 1}
		            }
		        }
		        if(elem.id in users[id].companies){elem.users[id] = 1}
		    }
		    if (result.role === 'admin_manager') {
		        elem.is_gc = true
		        companies.push(elem)
		    } else if (result.result.user_info.ID in elem.users) {
		        elem.is_gc = true
		        companies.push(elem)
		    }
		}
		// companies.sort(this.fieldCompare);
		let ret_gcs = this.getGcsBasedOnRole(companies_raw, companies, company, result.result.user_info.ID, result.role);
		ret['gc_companies'] = companies
		ret['gcs_plans'] = ret_gcs.gcs_plans
		ret['gcs_tasks'] = ret_gcs.gcs_tasks
		return ret;
	}

	getGcsBasedOnRole = (group_companies, gc_contacts, parent_company, contact_id, role) => {
		let gcs_tasks = []
		let gcs_plans = []
		let gc_companies = ''
		switch (role) {
			case "admin_manager":
			   gc_companies = '';
				if(group_companies.length > 0) {
					// gc_companies =  '(';
					gc_companies =  '(' + parent_company.ClientNumber + ',';
					gcs_tasks.push(parent_company.ClientNumber);
					gcs_plans.push(0);
					for(let i = 0; i < group_companies.length; i++) {
						gc_companies += (parseInt(parent_company.ClientNumber) * 100000 + parseInt(group_companies[i].id)).toString();
						gcs_tasks.push(parseInt(parent_company.ClientNumber) * 100000 + parseInt(group_companies[i].id));
						gcs_plans.push(parseInt(group_companies[i].id));
						if(i < group_companies.length - 1) {
							gc_companies += ','
						}
					}
					gc_companies += ')'
				}else {
					gc_companies = '(' + parent_company.ClientNumber + ')';
					gcs_tasks.push(parent_company.ClientNumber);
					gcs_plans.push(0);
				}
				break;
			case "risk_owner":
			   gc_companies = '(';
				if (group_companies.length > 0) {
					for(let i = 0; i < gc_contacts.length; i++) {
						if (contact_id in gc_contacts[i].users) {
							gc_companies += gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)).toString();
							gcs_tasks.push(gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)));
							gcs_plans.push(gc_contacts[i].is_gc === false ? 0 : (parseInt(gc_contacts[i].id)));
							if (i < gc_contacts.length - 1) {
								gc_companies += gc_contacts[i].is_gc === false ? "," : ',';
							}
						}
					}
					gc_companies += ')';
				} else {
					gc_companies = '(' + parent_company.ClientNumber + ')';
					gcs_tasks.push(parent_company.ClientNumber);
					gcs_plans.push(0);
				}
				break;
			case "plan_admin":
			   gc_companies = '(';
				if (group_companies.length > 0) {
					for(let i = 0; i < gc_contacts.length; i++) {
						if (contact_id in gc_contacts[i].users) {
							gc_companies += gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)).toString();
							gcs_tasks.push(gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)));
							gcs_plans.push(gc_contacts[i].is_gc === false ? 0 : (parseInt(gc_contacts[i].id)));
							if (i < gc_contacts.length - 1) {
								gc_companies += gc_contacts[i].is_gc === false ? "," : ',';
							}
						}
					}
					gc_companies += ')';
				} else {
					gc_companies = '(' + parent_company.ClientNumber + ')';
					gcs_tasks.push(parent_company.ClientNumber);
					gcs_plans.push(0);
				}
				break;
			case "subcat_owner":
			   gc_companies = '(';
				if (group_companies.length > 0) {
					for(let i = 0; i < gc_contacts.length; i++) {
						if (contact_id in gc_contacts[i].users) {
							gc_companies += gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)).toString();
							gcs_tasks.push(gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)));
							gcs_plans.push(gc_contacts[i].is_gc === false ? 0 : (parseInt(gc_contacts[i].id)));
							if (i < gc_contacts.length - 1) {
								gc_companies += gc_contacts[i].is_gc === false ? "," : ',';
							}
						}
					}
					gc_companies += ')';
				} else {
					gc_companies = '(' + parent_company.ClientNumber + ')';
					gcs_tasks.push(parent_company.ClientNumber);
					gcs_plans.push(0);
				}
				break;				
			case "team":
			   gc_companies = '(';
				if (group_companies.length > 0) {
					for(let i = 0; i < gc_contacts.length; i++) {
						if (contact_id in gc_contacts[i].users) {
							gc_companies += gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)).toString();
							gcs_tasks.push(gc_contacts[i].is_gc === false ? parent_company.ClientNumber : ((parseInt(parent_company.ClientNumber) * 100000) + parseInt(gc_contacts[i].id)));
							gcs_plans.push(gc_contacts[i].is_gc === false ? 0 : (parseInt(gc_contacts[i].id)));
							if (i < gc_contacts.length - 1) {
								gc_companies += gc_contacts[i].is_gc === false ? "," : ',';
							}
						}
					}
					gc_companies += ')';
				} else {
					gc_companies = '(' + parent_company.ClientNumber + ')';
					gcs_tasks.push(parent_company.ClientNumber);
					gcs_plans.push(0);
				}
				break;				
			default:
				break;
		}
		let ret = {gcs_tasks : gcs_tasks , gcs_plans : gcs_plans } ;
        return ret;
	}
	customRagDecider = (module_configs,current_rag) => {
		//console.log("module_configs,",module_configs);
		let id_array = [];
		let rag_id = '';
		for(let item of module_configs.custom_rags.additional_rags){
			id_array.push(item.id);
			if(item.nickname === current_rag){rag_id = item.id;break;}
			if(item.rag_value === current_rag){rag_id = item.id;break;}
		}
		if(rag_id !== '') return rag_id;
		if(id_array.includes(current_rag)) return current_rag;
    }
	getCustomRagAssoc = (module_configs) =>{
		let addtional_rags_assoc = {};
		for(let item of module_configs.custom_rags.additional_rags){
			addtional_rags_assoc[item.id] = item;
		}
		return addtional_rags_assoc;
	}
    riskImpactDecider = (current_risk_impact,module_configs) => {
		let id_array = [];
		let risk_impact_id = '';
		module_configs.risk_impact.forEach((item) => {
			id_array.push(item.id);
			let name_arr = item.name.split(current_risk_impact);
			if(name_arr.length > 1)risk_impact_id = item.id;
		});
		//if(risk_impact_id === '')return id_array[0];
		if(risk_impact_id !== '') return risk_impact_id;
		if(id_array.includes(current_risk_impact)) return current_risk_impact;

    }
    getRiskImpactValue = (current_risk_impact,module_configs) => {
		let risk_impact_values_assoc = {};
		module_configs.risk_impact.forEach((item) => {
			let id = ('id' in item) ? item.id : item.name;
			risk_impact_values_assoc[id] = item.name;
		});
		return risk_impact_values_assoc[current_risk_impact];

    }

    findNextDueDate_old = (frequency, plan_start_date, ta_shedule_date) => {
    	console.log("COPYPLAN findNextDueDate:: frequency, plan_start_date, ta_shedule_date", frequency, plan_start_date, ta_shedule_date)
    	let by_days = false 
    	let freq = 0
    	switch(frequency) {
    	    case 'Annually': freq = 12; break;
    	    case 'Semi-Annually': freq = 6; break;
    	    case 'Quarterly': freq = 3; break;
    	    case 'Every-Two-Months': freq = 2; break;
    	    case 'Monthly': freq = 1; break;
    	    case 'Bi-weekly': by_days = true; freq = 14; break;
    	    case 'Weekly': by_days = true; freq = 7; break;
    	    case 'Daily': by_days = true; freq = 1; break;
    	}

    	let fs_date = moment(plan_start_date);
    	let fy_date = moment(ta_shedule_date);
    	let diff = fs_date.diff(fy_date, "days");

    	if(!by_days){
    		let x = moment(ta_shedule_date).daysInMonth()
    		freq = x * freq
    	}
    	// console.log("COPYPLAN findNextDueDate::freq",freq)
    	// console.log("COPYPLAN findNextDueDate::diff",diff)
    	let fd = freq - (diff % freq);
    	// console.log("COPYPLAN findNextDueDate::fd",fd)
    	let next_due_date = moment(fs_date).add(fd, "days");
    	

    	let obj = {}
    	obj['dd'] = next_due_date.date()
    	obj['mm'] = next_due_date.month() + 1
    	obj['yy'] = next_due_date.year()

    	// console.log("COPYPLAN findNextDueDate::next_due_date",obj)
    	return obj
    }
    findNextDueDate = (frequency, plan_start_date, ta_shedule_date) => {
    	// console.log("COPYPLAN findNextDueDate:: frequency, plan_start_date, ta_shedule_date", frequency, plan_start_date, ta_shedule_date)
    	let by_days = false 
    	let freq = 0
    	let freq_m = 0
    	switch(frequency) {
    	    case 'Annually': freq = 360; freq_m = 12; break;
    	    case 'Semi-Annually': freq = 180; freq_m = 6; break;
    	    case 'Quarterly': freq = 90; freq_m = 3; break;
    	    case 'Every-Two-Months': freq = 60; freq_m = 2; break;
    	    case 'Monthly': freq = 30; freq_m = 1; break;
    	    case 'Bi-weekly': by_days = true; freq = 14; break;
    	    case 'Weekly': by_days = true; freq = 7; break;
    	    case 'Daily': by_days = true; freq = 1; break;
	        case 'Once': freq = 0; break;
    	}
    	// let selected_date = new Date(ta_shedule_date);
    	let the_date = new Date(ta_shedule_date).getDate();
    	let m = new Date(ta_shedule_date).getMonth()+1;
    	let y = new Date(ta_shedule_date).getFullYear();


    	let next_due_date = new Date(ta_shedule_date)
    	// console.log('next_due_date 1',next_due_date)
    	if(freq === 0){
    		let next_due_date = new Date(plan_start_date);
    	}else{
	    	if(moment(next_due_date).unix() < moment(plan_start_date).unix()){
	    		while (new Date(next_due_date).getTime() <= new Date(plan_start_date).getTime()) {
				  // Add the specified number of days to the date
				  // console.log('next_due_date 1A',next_due_date)
				  // console.log('next_due_date 1b',plan_start_date)
				  if(by_days){
				  	next_due_date.setDate(next_due_date.getDate() + freq);
				  }else{
				  	// 4 + 6 = 10
				  	// 10 + 6 = 16 => 16 - 12 = 4
				  	//let y = the_year
				  	let d = the_date
				  	m = m + freq_m
				  	// console.log('COPYPLAN d,m,y',d,m,y)
				  	if(m > 12){
				  		m = m - 12
				  		y = y + 1;
				  	}
				  	// console.log('COPYPLAN CALCULL',d,m,y)
				  	next_due_date = new Date(y+'/'+m+'/'+d)
				  }

				  
				}

				// Output the final date
				// console.log('next_due_date 2',next_due_date)
	    	}    		
    	}

    	

    	let obj = {}

    	obj['dd'] = by_days ? next_due_date.getDate() : the_date
    	// obj['dd'] = by_days ? next_due_date.getDate() : the_date
    	obj['mm'] = next_due_date.getMonth() + 1
    	obj['yy'] = next_due_date.getFullYear()

    	// console.log("COPYPLAN findNextDueDate:: findNextDueDate::next_due_date",obj)
    	return obj
    }
    fieldCompare = (a,b) => {
    	console.log('sortAnObject::fieldCompare',a,b)
        return a.sort_field?.localeCompare(b.sort_field)
        // return a.sort_field.localeCompare(b.sort_field)
    }
    sortAnObject = (obj) => {
    	console.log('sortAnObject::',obj)
        let sortable = [];
		for (let v in obj) {
			obj[v].key = v;
			sortable.push(obj[v]);
		}
		console.log('sortAnObject::sortable',sortable)
		sortable.sort(this.fieldCompare);
		return sortable;
    }
	setPlanCookie = (plan_ref_id,gc_id,year) =>{
		let cookies = new Cookies();
		let user_info = Store.getStoreData('user_info');
		let select_plan_cookie_value = year + '-' + plan_ref_id + '-' + gc_id + '-' + user_info.ClientCompanyID + '-' + user_info.ID;
		console.log('setPlanCookie::',select_plan_cookie_value)
		cookies.set('last-working-plan-info', select_plan_cookie_value, { path: '/' });
	}
	getPlanCookie = () =>{
		let cookies = new Cookies();
		let user_info = Store.getStoreData('user_info');
		let last_working_plan_info = cookies.get('last-working-plan-info');
		//console.log("last_working_plan_info",last_working_plan_info);
		if(typeof last_working_plan_info === 'undefined' || last_working_plan_info.split('-')[4].toString() !== user_info.ID.toString() || user_info.ClientCompanyID.toString() !== last_working_plan_info.split('-')[3].toString())return null;
		return last_working_plan_info;
	}
}


export default new CMPUtils();