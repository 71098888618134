import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import CSLHeader from '../Common/CSLHeader';
import WelcomeBanner from '../Common/WelcomeBanner';
import APICall from '../../Common/APICall.js';
import CompleteModalHOC from './CompleteModalHOC.js'
import CompleteActionModalHOC from './CompleteActionModalHOC.js'
import AddActions from './AddActionHOC.js';
import CSLLoader from '../Common/CSLLoader.js';

// import MGRIndex from '../MGR/MGRIndex';
// import TeamIndex from '../Team/TeamIndex';

class CmpHOC extends React.Component
{
    state = {ready: false ,cur_view: null,task_id:0,module_config:[],modal_view:null , refId:null, task_id:'0',rem_cur_lane:'',rem_task:[]};

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        console.log("HOC props",this.props)

        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return

        this.setComponentState()
    }
    setComponentState = () => {
        let api = new APICall()
        let postData = {command: 'get_details_hoc'}
         if('taskId' in this.props.match.params){
            postData['task_id'] = this.props.match.params.taskId
            //modal_view = 'remidial_action'
        }
        // console.log('MGRIndex postData', postData)
        if(!this.list_plans_called)api.command(postData, this.processList)
        this.list_plans_called = true
    }
    processList = (result) => {
        let module_config = result.result.module_config
        // let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
        // console.log("HOC task_id",task_id)
        let cur_view = 'mgr_index';
        let role = Store.getStoreData('role');
        if(role === 'team')cur_view = 'team_index'
        Store.updateStore('module_config', module_config)

        let refId = null;
        let task_id = "0";
        let modal_view = [];
        let rem_cur_lane = '';
        let rem_task = [];
        console.log("STATE::0",this.props)

        if('refId' in this.props.match.params){
            refId = this.props.match.params.refId;
            modal_view = 'testing_activity'
        }

        if('taskId' in this.props.match.params){
            task_id = this.props.match.params.taskId
            modal_view = 'remidial_action'
            rem_cur_lane = result.result.rem_cur_lane
            rem_task = result.result.complete_followup_task[0]
        }
        this.setState({ready: true,cur_view : cur_view, task_id, module_config, modal_view, task_id, refId,rem_cur_lane,rem_task});
    }
    closeModal = () => {
        if (document.referrer.includes("/tmview")) {
          window.location.assign(document.referrer);
        }else{
          window.location.assign('/');
        }
    }
    render()
    {   console.log("STATE::",this.state)
        // if(this.state.module_config.length === 0 )return(<div></div>)
        if (!this.state.ready) {
          return (<div><CSLLoader /></div>
          );
        }
        if(this.state.task_id === "0" && this.state.refId === null)return(<div></div>)


        return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
            <CSLHeader homeText={'Monitoring Programme'} />
            <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('user_info').ContactName} />
                <div style={{padding: "10px", boxSizing: "border-box"}}>
                {
                    (() => {
                        if(this.state.modal_view === 'testing_activity'){
                            return(<CompleteModalHOC refId={this.state.refId} reloadTestingactivity={this.closeModal} closeModal={this.closeModal} />)
                        }else{
                            if(this.state.rem_cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE'){
                                let gc_id =  this.state.rem_task.company_id > 100000 ? (this.state.rem_task.company_id%1e5) : '0'
                                return(<AddActions followup_task={this.state.rem_task} task_id={this.state.task_id} parent_task_id={this.state.rem_task.parent_task_id} gc_id={gc_id} closeModal={this.closeModal}/>)
                            }else{
                                return(<CompleteActionModalHOC task_id={this.state.task_id} closeModal={this.closeModal} />)
                            }

                        }
                    })()
                }

                {
                    (() => {
                        /*switch(this.state.cur_view) {
                            case "mgr_index":
                                return <MGRIndex />;
                                    break;
                            case "team_index":
                                return <TeamIndex />;
                                    break;
                            default:
                                return <div></div>;*/

                    })()
                }
                </div>
            </div>
        );
    }
}

export default CmpHOC;
