import React from 'react';
import styled from 'styled-components';
import { FiEdit, FiChevronUp, FiChevronDown } from "react-icons/fi";
import Store from '../../Common/Store.js';
import EventBus from '../../Common/EventBus.js';
import APICall from '../../Common/APICall.js';
import CSLTable from '../Common/CSLTable.js';
import moment from 'moment';
import { MdEdit } from 'react-icons/md';
import TAModal from './TAModal.js';
import CompleteModal from '../MGR/Modals/CompleteModal.js';
import CSLRegisterTable from '../RDR/CSLRegisterTable';
import reportData from '../RDR/reportData';
import CSLLoader from '../Common/CSLLoader';
import filters from '../RDR/reportFilters.js';
import ExcelGenerator from './ExcelGenerator';
import AlertBox from '../Common/AlertBox.js';
import CMPUtils from '../Common/CMPUtils.js';




const colorwiseTextStatus = { '#04ADA8' : 'Completed' , 'blue' : 'Scheduled' , '#F39C12' : 'Due' , 'red' : 'Overdue' };
const m_list = {'Jan': '0', 'Feb' : '1', 'Mar' : '2', 'Apr' : '3', 'May' : '4', 'Jun' : '5', 
                    'Jul' : '6', 'Aug' : '7', 'Sep' : '8', 'Oct' : '9', 'Nov' : '10', 'Dec' : '11'}

function includeTasks(visible_year, tas, months,risk_areas) {

    // console.log("TAS::",tas)
    // console.log("TAS::months",months)

    let ret = []
    let risk_area = risk_areas
    let gc_companies = Store.getStoreData('gc_companies');
    let parent_comp = gc_companies.filter(function (comp) { return comp.is_gc === false })[0].id;
    //console.log('parent_comp::',parent_comp)
    let month_days = [31,28,31,30,31,30,31,31,30,31,30,31]
    if((0 == visible_year % 4) && (0 != visible_year % 100) || (0 == visible_year % 400)) month_days[1] = 29
    let all_physical_tasks = Store.getStoreData('plan_tasks')
    for(let t of tas) {

        let exclusions = 'exclusions' in t ? t.exclusions:{};


        let stop_at = 100000000
        if('delete_reason' in t && t.delete_reason.reason === 'acitivity_by_series'){
        stop_at = ('delete_reason' in t && t.delete_reason.starting_from !== null) ? parseInt(t.delete_reason.starting_from) : stop_at

        }else{
        stop_at = ('delete_reason' in t && t.delete_reason.date_change_series !== null) ? parseInt(t.delete_reason.date_change_series.starting_from) : stop_at
        stop_at = ('delete_reason' in t && 
                    t.delete_reason.frequency_change !== null && 
                    parseInt(t.delete_reason.frequency_change.starting_from) < stop_at) ? parseInt(t.delete_reason.frequency_change.starting_from) : stop_at
        }

       // console.log("stop_at",stop_at,",FOR ",t.testing_activity,'_',t.first_schedule_dt_str)
       let replaces = {}
        if('delete_reason' in t && typeof t.delete_reason.date_scheduled_change_single !== 'undefined' && t.delete_reason.date_scheduled_change_single !== null) {
            for(let ma of t.delete_reason.date_scheduled_change_single.missing_at) {
                let actual = ma.actual.toString()
                // console.log("TAS:: replaces::",ma.tobeShown)
                let tobeShown = ma.tobeShown.split('T')[0]
                let y_str = actual.substring(0, 4)
                // let m_str = actual.substring(4, 2)
                // let d_str = actual.substring(6, 2)
                let m_str = actual.substring(4, 6)
                let d_str = actual.substring(6, 8)
                // console.log("TAS:: y_str::",y_str)
                // console.log("TAS:: m_str::",m_str)
                // console.log("TAS:: d_str::",d_str)
                let actual_id = `${t.unique_testing_act_id}_${y_str}-${m_str}-${d_str}`
                let replace_id = `${t.unique_testing_act_id}_${tobeShown}`
                let ts = tobeShown.split('-')
                let m = parseInt(ts[1]) - 1
                let y = parseInt(ts[0])
                let d = parseInt(ts[2])
                replaces[actual_id] = {y:y, m:m, d:d, id: replace_id}
            }
        }
    //console.log("TAS:: replaces::",replaces)
      //console.log('TSTACT t ',t.name,'=========================================================')
        //console.log('TSTACT t',  moment(new Date(t.first_schedule)).format("DD/MM/YYYY"))
        // let first_date_parts = moment(new Date(t.first_schedule)).format("DD/MM/YYYY")
        // let first_date_parts_split = first_date_parts.split('/')

        // let first_day = parseInt(first_date_parts_split[0])
        // let first_month = parseInt(first_date_parts_split[1])
        // let first_year = parseInt(first_date_parts_split[2])
        // let start_at = first_year * 10000 + first_month * 100 + first_day


        let first_date_parts = ''
        let first_day = ''
        let first_month = ''
        let first_year = ''
        if('first_schedule_dt_str' in t){
            first_date_parts = t.first_schedule_dt_str.split('/')

            first_day = parseInt(first_date_parts[0])
            first_month = parseInt(first_date_parts[1])
            first_year = parseInt(first_date_parts[2])
        }else{
            first_date_parts = t.first_schedule.split('T')[0].split('-')

            first_day = parseInt(first_date_parts[2])
            first_month = parseInt(first_date_parts[1])
            first_year = parseInt(first_date_parts[0])
        }
        let start_at = first_year * 10000 + first_month * 100 + first_day
        
        let by_days = false 
        let freq = 0
        switch(t.testing_freq) {
            case 'Annually': freq = 12; break;
            case 'Semi-Annually': freq = 6; break;
            case 'Quarterly': freq = 3; break;
            case 'Every-Two-Months': freq = 2; break;
            case 'Monthly': freq = 1; break;
            case 'Bi-weekly': by_days = true; freq = 14; break;
            case 'Weekly': by_days = true; freq = 7; break;
            case 'Daily': by_days = true; freq = 1; break;
        }
        //console.log('TSTACT t',t.freq,freq)
        let riskarea_key = risk_area.filter(function (risk) { return risk.unique_risk_id === t.unique_risk_id });
        // console.log("riskarea_key::", riskarea_key,riskarea_key.length)
        if(typeof riskarea_key === 'undefined')continue;
        if(riskarea_key.length === 0)continue;
        let riskarea_name = riskarea_key[0].risk_area_name;
        // console.log("TSTACT riskarea_key",riskarea_key)
        // console.log("TSTACT risakarea_name",risakarea_name)
        // console.log("TSTACT unique_subcat_id",t.unique_subcat_id)

        let subcat_name = t.unique_subcat_id.toString() === '0' ? '' : riskarea_key[0].subcategories.filter(function (sub) { return sub.unique_subcat_id === t.unique_subcat_id })[0].risk_subcategory_name;
        //console.log("TSTACT subcat_name",subcat_name)
        let plan_gc_id = Store.getStoreData('role') === 'team' ? Store.getStoreData('team_gcid') : Store.getStoreData('plan_gc_id')
         // console.log("Hints plan_gc_id::",plan_gc_id)
        let company_id = plan_gc_id.toString() === '0' ? parent_comp : (parent_comp * 1e5) + parseInt(plan_gc_id)
        // console.log("Hints company_id::",company_id)
        let first_schedule_dt_str = moment(t.first_schedule_dt_str).unix()
        //console.log("first_schedule_dt_str unix::",first_schedule_dt_str)

        let base_task = {
                        // due_date: first_year*10000 + first_month*100 + first_day,
                        name: t.testing_activity,
                        subcat_id: t.unique_subcat_id,
                        testing_act_id: t.unique_testing_act_id,
                        task_id: null,
                        freq: t.testing_freq,
                        cur_lane: 'COMP_CMP_ASSIGNMENT',
                        completed_date: null,
                        testing_owner: t.testing_owner,
                        is_task: false,
                        risakarea_name: riskarea_name,
                        subcat_name:subcat_name,
                        company_id:company_id,
                        cur_assigned_to:t.testing_owner,
                        risk_id: t.unique_risk_id,
                        first_schedule_dt_str: t.first_schedule_dt_str
                   }

        if(by_days) {
            //let first_schedule_obj = new Date(t.first_schedule_dt_str.split('/')[2],t.first_schedule_dt_str.split('/')[1]-1,t.first_schedule_dt_str.split('/')[0]);    // yy mm dd
            let fs_date =  moment(t.first_schedule)
            // let fs_date =  moment(first_schedule_obj)
            // if(t.unique_testing_act_id === 'Cey5Hn7wNz9D')console.log("TSTACT fs_date::",fs_date,moment(t.first_schedule))
            let fv_date = fs_date
            if(visible_year >= first_year) {                
                let obj = CMPUtils.findNextDueDate(t.testing_freq, moment([visible_year]), t.first_schedule)
                let yyyy = obj.yy;
                let mm = obj.mm; // Months start at 0!
                let dd = obj.dd;

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                fv_date =moment( yyyy +'-'+ mm +'-'+ dd +'T00:00:00');
                // let fy_date = moment([visible_year])
                // fv_date = fy_date
                // if(freq > 1) {
                //     let diff = fy_date.diff(fs_date, 'days')
                //     let fd = freq - (diff % freq)
                //     fv_date = moment([visible_year, 0, fd])
                // }
                let d = fv_date
                // console.log('includeTasks::Years',new Date(fv_date),d.year(),visible_year)

                while(d.year() === visible_year) {
                    // if(t.unique_testing_act_id === 'Cey5Hn7wNz9D')console.log("TSTACT DDDD::",d.year(),d.month(),d.date())
                    let m = d.month()
                    let task_id = `${t.unique_testing_act_id}_${d.format('YYYY-MM-DD')}`
                    let task = JSON.parse(JSON.stringify(base_task))


                    if(task_id in replaces) {
                        
                        let rep = replaces[task_id]
                        task.due_date = rep.y +'/'+ (rep.m + 1) +'/' + rep.d
                        let due_date = rep.y * 10000 + (rep.m + 1) * 100 + rep.d

                        let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                        let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                        let exclude_dt = rep.y.toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                        }
                        //if(t.unique_risk_id === "Sc0tVrlZ")console.log('includeTasks task, start_at, stop_at', task, start_at, stop_at)
                        
                    } else {
                        
                        task.due_date = d.year() + '/' + (d.month() + 1) + '/' + d.date()
                        let due_date = d.year() * 10000 + (d.month() + 1) * 100 + d.date()

                        let mm_str = (d.month() + 1) < 10 ? '0' + (d.month() + 1) : (d.month() + 1).toString()
                        let this_day_str = d.date() < 10 ? '0' + d.date() : d.date().toString()
                        let exclude_dt = d.year().toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[m].tasks[task_id] = task                          
                        }
                        //if(t.unique_risk_id === "Sc0tVrlZ")console.log('includeTasks task, start_at, stop_at', task, start_at, stop_at)
                    }

                    // task.due_date = d.year() +'/' + (d.month() + 1) +'/' + d.date()

                    // months[m].tasks[task_id] = task

                    d.add(freq, 'days')
                }
            }
        } else {
          
            if(freq === 0) {
                if(first_year === visible_year) {
                    let task = JSON.parse(JSON.stringify(base_task))
                    task.due_date = first_year +'/' + first_month +'/'+ first_day
                    let due_date = first_year*10000 + first_month*100 + first_day

                    let task_id = `${t.unique_testing_act_id}_${first_date_parts[2]}-${first_date_parts[1]}-${first_date_parts[0]}`
                    
                    let physical_task_for_this_hint = Object.keys(all_physical_tasks).find(x => t.unique_testing_act_id === all_physical_tasks[x].testing_act_id && first_month === all_physical_tasks[x].month && visible_year === all_physical_tasks[x].year);
                    // let physical_task_for_this_hint1 = all_physical_tasks.filter(function (dt) { t.unique_testing_act_id === dt.testing_act_id && mm === dt.month});
                    if( t.unique_testing_act_id === 'sVKS1Y8MhVSK')console.log('includeTasks task::: physical_task_for_this_hint', physical_task_for_this_hint)
                    
                    if(typeof physical_task_for_this_hint !== 'undefined'){
                        task_id = physical_task_for_this_hint
                        // this_day = parseInt(physical_task_for_this_hint.split('-')[2])
                    }

                    if(task_id in replaces) {
                        let rep = replaces[task_id]
                        task.due_date = rep.y +'/' + (rep.m + 1) +'/' + rep.d
                        let due_date = rep.y * 10000 + (rep.m + 1) * 100 + rep.d

                        let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                        let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                        let exclude_dt = rep.y.toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                        }

                        
                    } else {
                        let mm_str = (first_month) < 10 ? '0' + (first_month) : (first_month).toString()
                        let this_day_str = first_day < 10 ? '0' + first_day : first_day.toString()
                        let exclude_dt = first_year.toString() + mm_str + this_day_str

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[first_month - 1].tasks[task_id] = task
                        }
                        
                    }
                    //months[first_month - 1].tasks[task_id] = task
                }
            } else {
                for(let m of months) {
                    //console.log("TAS:: m",m)
                    let mm = m.m + 1
                    let month_diff = (m.y - first_year - 1) * 12 + 12 - first_month + mm
                    if(month_diff >= 0 && month_diff % freq === 0) {
                        let task = JSON.parse(JSON.stringify(base_task))
                        let this_day = first_day > month_days[m.m] ? month_days[m.m] : first_day
                        let this_day_str = this_day < 10 ? '0' + this_day : this_day.toString()
                        let this_month_str = mm < 10 ? '0' + mm : mm.toString()
                        let task_id = `${t.unique_testing_act_id}_${m.y}-${this_month_str}-${this_day_str}`
                        //console.log("TAS:: task_id::",task_id)
                        // console.log("exclusions A::",exclusions)
                    
	                    let physical_task_for_this_hint = Object.keys(all_physical_tasks).find(x => t.unique_testing_act_id === all_physical_tasks[x].testing_act_id && mm === all_physical_tasks[x].month && visible_year === all_physical_tasks[x].year);
	                    // let physical_task_for_this_hint1 = all_physical_tasks.filter(function (dt) { t.unique_testing_act_id === dt.testing_act_id && mm === dt.month});
	                    // if( t.unique_testing_act_id === 'sVKS1Y8MhVSK')console.log('includeTasks task::: physical_task_for_this_hint', physical_task_for_this_hint)
	                    
	                    if(typeof physical_task_for_this_hint !== 'undefined'){
	                        task_id = physical_task_for_this_hint
	                        this_day = parseInt(physical_task_for_this_hint.split('-')[2])
	                    }

                        if(task_id in replaces) {

                            let rep = replaces[task_id]
                            task.due_date = rep.y +'/'+ (rep.m + 1) +'/' + rep.d
                            let due_date = rep.y * 10000 + (rep.m + 1) * 100 +  rep.d

                            let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                            let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                            let exclude_dt = rep.y.toString() + mm_str + this_day_str

                            if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                              //exclude
                            }else{
                              if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                            }
                            
                        } else {
                            task.due_date = m.y +'/'+ mm +'/'+ this_day
                            let due_date = m.y*10000 + mm*100 + this_day
                            
                            let mm_str = mm < 10 ? '0' + mm : mm.toString()
                            let this_day_str = this_day < 10 ? '0' + this_day : this_day.toString()

                            let exclude_dt = m.y.toString()+ mm_str + this_day_str
                            
                            if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                              //exclude
                            }else{
                                if(due_date >= start_at && due_date < stop_at)m.tasks[task_id] = task
                            }
                        }
                        //task.due_date = m.y+'/' + mm+'/' + this_day

                        //m.tasks[task_id] = task
                    }
                }
            }
             //console.log("at false TSTACT months::",months)
        }
      
    }
    let plan_tasks = Store.getStoreData('plan_tasks')
    if(plan_tasks){
        for(let pid in plan_tasks) {
           //console.log("INCLUDE tas plan",plan_tasks[pid].risk_id,risk_id,plan_tasks[pid].subcat_id,subcat_id)
            //if(plan_tasks[pid].risk_id.toString() === risk_id.toString() && plan_tasks[pid].subcat_id.toString() === subcat_id.toString()){
                    
                    let t_dt = pid.split('_')[1]
                    let t_dts = t_dt.split('-')
                    let m_num = parseInt(t_dts[1]) - 1
                    let y_num = parseInt(t_dts[0]) 
                    // if(risk_id === 'n1T7p0Ei')console.log('INCLUDE tas pid, m_num, months', pid, m_num,  months[m_num].tasks)
                    if(pid in months[m_num].tasks) {
                        months[m_num].tasks[pid].completed_date = plan_tasks[pid].completed_date
                        months[m_num].tasks[pid].cur_lane = plan_tasks[pid].cur_lane
                        months[m_num].tasks[pid].task_id = plan_tasks[pid].task_id
                        months[m_num].tasks[pid].is_task = true 
                        months[m_num].tasks[pid].approver_id = "approver_id" in plan_tasks[pid] ? plan_tasks[pid].approver_id : 0

                    } else if(y_num === visible_year){
                        // console.log('includeTasks pid, plan_tasks[pid]', pid, plan_tasks[pid])
                        months[m_num].tasks[pid] = plan_tasks[pid]
                        months[m_num].tasks[pid].is_task = true
                    }
            //}
        }        
    }
    // console.log("TALLY months::",months)
    return months;
}

const TABoxContainer = styled.div`
  box-sizing: border-box;
    /*border-right: 1px solid #dadbdc;*/
    padding: 5px;
`;
const TABox = styled.div`
  background-color: #04ADA8;
  width: 83% ;
  height: 25px;
  border-radius: 4px;
  /*cursor: pointer;*/
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const RiskBtn = styled.button`
  background-color: #213E61;
  border: 1px solid #213E61;
  border-radius: 4px;
  padding: 9px 37px;
  color: #ffffff;
  cursor: pointer;
`;
const InactiveOverlay2 = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1800px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1000;
  opacity: 0.8;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh-200px);
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 1000;
    opacity: 0.8;
    top: 0px;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
    float: left;
`;
const StatusButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  border-radius: 4px;
  padding: 4px;
  height : 25px;
  width : 90%;
  textAlign: center; 
  color : #FFFFFF;
`;
Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}

class TestingActivityList extends React.Component
{

  state = { 
    ready : false ,
    tasks :[] ,
    main_result :[] ,
    risk_areas :[] ,
    testing_activities:[],
    plan_ref_id: null,
    pdf : false,
    show_completed_tasks: false,
    filter_end_date_to_show_in_table:moment().format("DD/MM/YYYY"),
    filter_start_date_to_show_in_table:moment().format("DD/MM/YYYY"),
    header_text:'My Testing Activities',
    role:Store.getStoreData('role'),
    dev_modal: null,
    edit_object: null,
    plan: null ,
    tact_risk_area:null,
    tact_task_id:null,
    advancedFilterData: {},
    toggle_state : false,
    is_loader:false,
    filter_start_date: moment().format("DD/MM/YYYY") , 
    filter_end_date: moment().format("DD/MM/YYYY") , 
    future_days : moment().unix() , 
    past_days : moment().unix(), 
    filters : JSON.parse(JSON.stringify(filters)), 
    download_excel : false ,
    show_sit_tight : false, 
    xls_data : {},
    alert_param: null,
    show_inactive: true
  }

  genKey(length){
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }

  componentDidMount = () => {
      EventBus.registerEvent('plan_selected', 'TestingActivityList', this.planSelected)
      EventBus.registerEvent('show_inactive', 'TestingActivityList', this.show_inactive)
      EventBus.registerEvent('gc_clicked', 'TestingActivityList', this.gc_clicked)
      EventBus.registerEvent('testing_activity_updated', 'TestingActivityList', this.testing_activity_updated)


      this.setComponentState()
  }

  planSelected = (plan) => {
    // console.log('planSelected::',plan) 
    if(plan !== null){
      let risk_areas = [];
      if(Store.getStoreData('risk_areas')){
        risk_areas = Store.getStoreData('risk_areas')
      }

      this.setState({plan,plan_ref_id: plan.unique_plan_id,risk_areas:risk_areas,testing_activities:plan.testing_activities,show_completed_tasks:false,toggle_state:false,advancedFilterData:{}});
    }else{
      this.setState({plan:null,plan_ref_id: null,risk_areas:[],testing_activities:[],toggle_state:false,advancedFilterData:{}});
    }
	  
  
    this.setComponentState()
   
  }

  show_inactive = (bool) => {
        //console.log("TATESTACT  this.state act",bool)
      let show_inactive = bool
      let risk_areas = [];
      if(Store.getStoreData('risk_areas')){
        risk_areas = Store.getStoreData('risk_areas')
      }
    this.setState({show_inactive,risk_areas})
  }
  gc_clicked = (gc_id) => { 
    this.setState({risk_areas:[],testing_activities:[],tasks :[],plan_ref_id:null}) 
    //this.setComponentState()
   
  }
  testing_activity_updated = ()=>{
      let show_status = this.state.show_completed_tasks;
      // alert("came to test activity")
      if(show_status){
        this.showCompletedTasks()
      }else{
        this.setComponentState()
      }
  }
  componentDidUpdate = (prev_props) => {
      if(prev_props === this.props) return
          
      this.setComponentState()
  }

  setComponentState = () => {

        // console.log("MAAA",this.state)
        // console.log("setComponentState::",this.state,Object.keys(this.state.advancedFilterData).length)


        let api = new APICall();
        let postData = { command: "list_ta_tasks"};
        postData['role'] = Store.getStoreData('role')
        postData['gcs_tasks'] = Store.getStoreData('gcs_tasks')
        postData['show_completed_tasks'] = this.state.show_completed_tasks
        postData['plan_ref'] = this.state.plan !== null ? this.state.plan_ref_id : null
        let plan_gc_id = Store.getStoreData('role') === 'team' ? Store.getStoreData('team_gcid') : Store.getStoreData('plan_gc_id')
		postData['plan_gc_id'] = plan_gc_id

         let filter_start = this.state.filter_start_date_to_show_in_table.split('/')
         let start_unix = moment(filter_start[2]+'/'+filter_start[1]+'/'+filter_start[0]).unix();

         let filter_end = this.state.filter_end_date_to_show_in_table.split('/')
         let end_unix = moment(filter_end[2]+'/'+filter_end[1]+'/'+filter_end[0]).unix();

        //console.log("setComponentState::",postData)
        api.command(postData, this.processTasks);
     
  }
  processTasks = (result) => {

        let main_result = result
        // Re-fill filter data based on company change or all-tasks toggle
        this.populateFilterData()
        let filters_init = JSON.parse(JSON.stringify(this.state.filters));
        // console.log("TATESTACT PROPS::3")
        let role = Store.getStoreData('role');
        let loggedin_user_info = Store.getStoreData('user_info');

        let today = moment();
        today = today.set('hour', 0).set('minute', 0).set('second', 0);

        let future_days = moment().endOf('isoWeek');
        let filter_end_date = future_days.format("DD/MM/YYYY");
        let filter_end_year = parseInt(filter_end_date.split("/")[2]);
        future_days = future_days.set('hour', 23).set('minute', 59).set('second', 59);

        let future_js_date = future_days.toDate();
        future_days = future_days.unix();

        let past_days = moment().startOf('isoWeek');
        let filter_start_date = past_days.format("DD/MM/YYYY");
        past_days = past_days.set('hour', 0).set('minute', 0).set('second', 0);

        let past_js_date = past_days.toDate();
        past_days = past_days.unix();
        today = today.unix();

        // let filter_start_year = moment().year() - 3;
        // let filter_end_year = moment().year() + 3;
        // let visible_year = moment().year();
        

        // console.log('visible_year::',visible_year)
        // for(let i=0; i < 12; i++) month_tasks.push({m: i, y: visible_year, tasks: {}})

		let testing_activities = role === 'team' ? this.props.testing_activities : this.state.testing_activities


        let risk_areas =  role === 'team' ? this.props.risk_areas : this.state.risk_areas
		// console.log("TSTACT testing_activities", testing_activities)
        // let all_hints = this.sort_hints(filter_start_year,filter_end_year,testing_activities,risk_areas)
        // console.log("TSTACT all_hints BF",all_hints)
        // console.log("mo.tasks::",result)
       //  let plan_tasks = Store.getStoreData('plan_tasks')
       //  for(let mo of all_hints) {
       //    for(let id in mo.tasks) {
           
       //      if(!plan_tasks.hasOwnProperty(id)){
       //           result.push(mo.tasks[id])
       //      }
       //    }
      	// }


        // console.log('trackToggleProgress::',result)
        // console.log('JAIMA:::STATE advancedFilterData',this.state.advancedFilterData)

        if(Object.keys(this.state.advancedFilterData).length > 0){
        	 if(Object.keys(this.state.advancedFilterData.due_date.selections).length > 0){
	        	 filter_start_date = this.state.advancedFilterData.due_date.selections.from_date
	        	 filter_end_date = this.state.advancedFilterData.due_date.selections.to_date
        	}
        }
        
        result.sort(function(a, b){return new Date(a.due_date) - new Date(b.due_date);})
        // console.log('JAIMA:::STATE result',result)

        if(role === 'team'){

            // result.sort(this.fieldCompare)

            let filtered_tasks = result.filter(task => task.cur_lane !== 'COMP_CMP_COMPLETED' && task.testing_owner.toString() === loggedin_user_info.ID.toString());
            // console.log("TestingActivityList filtered_tasks==>",filtered_tasks,loggedin_user_info);
            if(filtered_tasks.length > 0){
                if(moment(filtered_tasks[0].due_date).unix() <= moment().unix()){
                    filter_start_date = moment(filtered_tasks[0].due_date).format('DD/MM/YYYY');
                    past_days = moment(filtered_tasks[0].due_date);
                    past_days = past_days.set('hour', 0).set('minute', 0).set('second', 0);
                    past_js_date = past_days.toDate();

                }
                // console.log("TATESTACT this.state PASTDAY",past_days,past_js_date)
                //filter_end_date = moment(filtered_tasks[filtered_tasks.length-1].due_date).format('DD/MM/YYYY');
            }
        }

        filters_init.due_date.options[past_js_date] = {name : filter_start_date};
        filters_init.due_date.options[future_js_date] = {name : filter_end_date};

        // let testing_activities = Store.getStoreData('testing_activities')
        let toggle_state = this.state.toggle_state
        let applied_filters = filters_init;
        let advancedFilterData = filters_init
      
       if(Store.getStoreData('ta_advancedFilterData') !== null){
        	
            advancedFilterData = Store.getStoreData('ta_advancedFilterData')
            applied_filters = Store.getStoreData('ta_advancedFilterData')
            if(Object.keys(advancedFilterData.due_date.selections).length > 0){
	        	  filter_start_date = advancedFilterData.due_date.selections.from_date
	              filter_end_date = advancedFilterData.due_date.selections.to_date
        	}
        }
        if(Store.getStoreData('ta_toggle_state') !== null){
            toggle_state = Store.getStoreData('ta_toggle_state')
        }
     


      this.setState({tasks:result, ready: true, testing_activities, advancedFilterData,applied_filters,toggle_state,
            filter_end_date : filter_end_date , 
            filter_start_date : filter_start_date ,
            filter_end_date_to_show_in_table : filter_end_date , 
            filter_start_date_to_show_in_table : filter_start_date ,                
            future_days: future_js_date , 
            past_days: past_js_date ,
            main_result,
            filters: filters_init,risk_areas});
        // console.log("TSTACT all_hints AF",result)

    }


    sort_hints = (filter_start_year,filter_end_year,testing_activities,risk_areas) => {
        // console.log("JAIMA::sort_hints pram",filter_start_year,filter_end_year,testing_activities,risk_areas)
        let visible_year = [];
        let month_tasks = []
        visible_year.push(parseInt(filter_start_year))
        for(let i=0; i < 12; i++) month_tasks.push({m: i, y: parseInt(filter_start_year), tasks: {}})

        let inc_filter_start_year = filter_start_year
        if(filter_start_year !== filter_end_year){

            while(inc_filter_start_year < filter_end_year) {
                inc_filter_start_year++
                visible_year.push(parseInt(inc_filter_start_year))
                for(let i=0; i < 12; i++) month_tasks.push({m: i, y: parseInt(inc_filter_start_year), tasks: {}})
            }
        }

        // console.log('JAIMA::: testing_activities',testing_activities)
        // console.log("sort_hints::: visible_year",visible_year)       
        // console.log('sort_hints::: month_tasks::',month_tasks)
        let all_hints = []

        for(let x=0; x < visible_year.length; x++){
            let curr_month_tasks = []
            for(let i=0; i < 12; i++) curr_month_tasks.push({m: i, y: parseInt(visible_year[x]), tasks: {}})
            let curr_year_hints = includeTasks(parseInt(visible_year[x]), testing_activities, curr_month_tasks,risk_areas)
            // console.log('sort_hints::: curr_year_hints::',curr_year_hints)
            // console.log('sort_hints::: all_hints1::',all_hints)
            all_hints = [...all_hints,...curr_year_hints]
            // consoimport CMPUtils from '../Common/CMPUtils.js';import CMPUtils from '../Common/CMPUtils.js';import CMPUtils from '../Common/CMPUtils.js';import CMPUtils from '../Common/CMPUtils.js';le.log('sort_hints::: all_hints2::',all_hints)
        }
        //let all_hints = includeTasks(visible_year, testing_activities, month_tasks,risk_areas)
        return all_hints;
    }        

    fieldCompare = (a,b) => {
        return a.sort_field.localeCompare(b.sort_field)
    }
    showCompletedTasks = () => {
        let api = new APICall();
        let show_completed_tasks = this.state.show_completed_tasks ? false : true;
        this.setState({show_completed_tasks: show_completed_tasks})
        let postData = { command: "list_ta_tasks"};
        postData['role'] = Store.getStoreData('role')
        postData['gcs_tasks'] = Store.getStoreData('gcs_tasks')
        postData['show_completed_tasks'] = show_completed_tasks
        postData['plan_ref'] = this.state.plan !== null ? this.state.plan_ref_id : null
        let plan_gc_id = Store.getStoreData('role') === 'team' ? Store.getStoreData('team_gcid') : Store.getStoreData('plan_gc_id')
		    postData['plan_gc_id'] = plan_gc_id
        api.command(postData, this.processTasks);
    }
    compareDateGetColor= (first_schedule_date,cur_lane) => {
      //console.log("TSTACT all_hintscur_lane==>",cur_lane,first_schedule_date);
      let color_code = '#04ADA8'
      let scheduled_date = moment(first_schedule_date, 'YYYY/MM/DD');
      let month = scheduled_date.format('M');
      let year  = scheduled_date.format('YYYY');

      let curr_month = moment().month()+1;
      let curr_year  = moment().year();

      // let current_date = moment().unix();
      // let due_date_unix =  moment(first_schedule_date).unix();

      let current_date = moment().format('YYYY-MM-DD');
      let due_date_unix =  moment(first_schedule_date).format('YYYY-MM-DD');

      if(cur_lane === "COMP_CMP_COMPLETED"){
        color_code = "#04ADA8"; // green
      }else{
        if(due_date_unix >= current_date){
          if(curr_month.toString() === month && curr_year.toString() === year){
            color_code = "#F39C12"; // due
          }else{
            color_code = "blue"; // Scheduled
          }
        }else{
          color_code = "red"; // overdue
        }
      }

        return color_code;
    }

    processTableData = () => {
    // alert(1)
      let phy_result = this.state.tasks;
      let all_companies = Store.getStoreData('gc_companies')
      let all_contacts = Store.getStoreData('contacts')
      let risk_areas = this.state.risk_areas
      let role = this.state.role;
      let user_id = Store.getStoreData('user_info')?.ID
      let show_inactive = this.state.show_inactive

    // let start_year
    let end_year = moment().year();
    let start_year = moment().year();
         
    let all_testing_activities = this.state.testing_activities

        if(Object.keys(this.state.advancedFilterData).length > 0 && Object.keys(this.state.advancedFilterData.due_date.selections).length > 0){
           start_year = this.state.advancedFilterData.due_date.selections.from_date.split('/')[2]
           end_year = this.state.advancedFilterData.due_date.selections.to_date.split('/')[2]
        }else{
            start_year = new Date(
            Math.min(
                ...all_testing_activities.map(element => {
                  return new Date(element.first_schedule);
                }),
              ),
            ).getFullYear();
        }

       let all_tasks = [] 
       all_tasks = this.sort_hints(start_year,end_year,all_testing_activities,risk_areas)
       // console.log("JAIMA::all_tasks",all_tasks)
        let plan_tasks = Store.getStoreData('plan_tasks')
        //let tasks
        let all_tasks_hint = []
        for(let mo of all_tasks) {
          for(let id in mo.tasks) {
           
            if(!plan_tasks.hasOwnProperty(id)){
                 
                 //tasks = [...tasks,...mo.tasks[id]]
                 all_tasks_hint.push(mo.tasks[id])
            }
          }
        }


      let tasks = [...all_tasks_hint,...phy_result]
      tasks.sort(function(a, b){return new Date(a.due_date) - new Date(b.due_date);})
      let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
      let i = 1;
      
      if(risk_areas.length > 0){
          ret.columns =[   
                          {Header: 'Activity', id: 'activity' , accessor: 'activity' , Cell: c => <div >{c.original.activity}&nbsp;<div style={{fontWeight : "20"}}><b><i>{c.original.approval_string}</i></b></div></div> , minWidth: 270, headerStyle: {textAlign: 'left'}},    
                          {Header: 'Risk Areas', accessor: 'risk_area', minWidth: 170, headerStyle: {textAlign: 'left'}},      
                          {Header: 'Subcategory', accessor: 'subcat_name', minWidth: 140, headerStyle: {textAlign: 'left'}},      
                          {Header: 'Company', accessor: 'company', minWidth: 170, headerStyle: {textAlign: 'left'}},      
                          {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 140, headerStyle: {textAlign: 'left'}},
                          {Header: 'Due Date', accessor: 'due_date_timestamp', Cell: c => <div style={{borderRadius: "4px" , padding: "4px", height : "25px" ,width : "100%" , textAlign: 'center' , color : "#111111"}}>{c.original.due_date}</div>, minWidth: 120, headerStyle: {textAlign: 'left'},
                            sortType: 'datetime'},
                          {Header: 'Status', id: 'colorwise_text' , accessor: 'colorwise_text' , Cell: c => <div style={{borderRadius: "4px" , padding: "4px", height : "25px" ,width : "100%" , background: c.original.colorwise_text_color , textAlign: 'center' , color : "#FFFFFF"}}>{c.original.colorwise_text}</div> , minWidth: 120, headerStyle: {textAlign: 'left'}},
                          {Header : 'Action', Cell: row => (
                            <div>
                              {
                                (() => {
                                    if(row.original?.activity_status === "Incomplete" && (role === 'admin_manager' || role === 'plan_admin') && row.original.risk_status === 'Active'){
                                        return (<MdEdit onClick={() => this.openTestingActivities('ta', row.original.due_date, row.original.testing_act_id,row.original.unique_risk_id,row.original.task_id,row.original.approver_id)} style={{cursor: 'pointer' , float: "right"}} title="edit this testing activity" />);
                                    }

                                    if(row.original?.risk_status === 'Active' && row.original?.activity_status !== "Incomplete"){
                                        if(role === 'admin_manager' && row.original?.cur_lane === 'COMP_CMP_COMPLETED'){
                                            return(<>
                                                <MRButton onClick={() => this.openTestingActivities('ta_action', row.original.due_date, row.original.testing_act_id,row.original.unique_risk_id,row.original.task_id,row.original.approver_id)} style={{cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>View</MRButton>
                                                <MdEdit onClick={() => this.openTestingActivities('ta_edit', row.original.due_date, row.original.testing_act_id,row.original.unique_risk_id,row.original.task_id,row.original.approver_id)} style={{cursor: 'pointer' , marginLeft: "36px"}} title="edit this completed activity" />
                                                </>)
                                        }
                                        return (<MRButton onClick={() => this.openTestingActivities('ta_action', row.original.due_date, row.original.testing_act_id,row.original.unique_risk_id,row.original.task_id,row.original.approver_id)} style={{cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>View</MRButton>);
                                    }
                                    
                                  })()
                              } 
                              {
                                  (() => {
                                    if(row.original?.activity_status === "Incomplete"){

                                        if(row.original.risk_status !== 'Active'){
                                            if(role === 'view_only_manager' && parseInt(row.original?.testing_owner) !== user_id){
                                               return (<MRButton style={{ float: "left" , backgroundColor: "grey", marginLeft: "15px"}}>View</MRButton>);
                                            }
                                            return (<MRButton style={{ float: "left" , backgroundColor: "grey", marginLeft: "15px"}}>Action</MRButton>);
                                        }
                                        if(role === 'view_only_manager' && parseInt(row.original?.testing_owner) !== user_id){
                                            return (<MRButton onClick={() => this.openTestingActivities('ta_action', row.original?.due_date, row.original?.testing_act_id,row.original?.unique_risk_id,row.original?.task_id,row.original?.approver_id)} style={{cursor: 'pointer' , float: "left",marginLeft: "15px"}}>View</MRButton>);
                                        }
                                        return (<MRButton onClick={() => this.openTestingActivities('ta_action', row.original?.due_date, row.original?.testing_act_id,row.original?.unique_risk_id,row.original?.task_id,row.original?.approver_id)} style={{cursor: 'pointer' , float: "left",marginLeft: "15px"}}>Action</MRButton>);
                                    }
                                    return (<MRButton style={{border: "1px solid #FFFFFF" , backgroundColor: "#FFFFFF"}}>ViewC</MRButton>);
                                })()
                              }
                            </div>
                            ), width: 180, headerStyle: {textAlign: 'center'}},
                        ];

        
        // console.log("TATESTACT DATECHECK advancedFilterData::",this.state.filter_start_date_to_show_in_table,this.state)

        let stdt = this.state.filter_start_date_to_show_in_table.split('/')
        let endt = this.state.filter_end_date_to_show_in_table.split('/')

        let strt_from = moment(stdt[2] + '/' + stdt[1] + '/' + stdt[0]).unix()
        let end_at  =  moment(endt[2] + '/'+ endt[1] + '/' + endt[0]).unix()

        let tstactids = []
        let risk_id = []
        let subcat_id = []
        let assgnto = []
        let status = []
        let rskowner = []
          let riskImpact = []


        if(Object.keys(this.state.advancedFilterData).length > 0){
            if(Object.keys(this.state.advancedFilterData.risk_impact.selections).length > 0){
                riskImpact = Object.keys(this.state.advancedFilterData.risk_impact.selections)
            }
			if(Object.keys(this.state.advancedFilterData.activity.selections).length > 0){
        	 	tstactids = Object.keys(this.state.advancedFilterData.activity.selections)
        	 }
			if(Object.keys(this.state.advancedFilterData.risk_area.selections).length > 0){
        	 	risk_id = Object.keys(this.state.advancedFilterData.risk_area.selections)
        	 }
			if(Object.keys(this.state.advancedFilterData.subcat_name.selections).length > 0){
        	 	subcat_id = Object.keys(this.state.advancedFilterData.subcat_name.selections)
        	 }
			if(Object.keys(this.state.advancedFilterData.assigned_to.selections).length > 0){
        	 	assgnto = Object.keys(this.state.advancedFilterData.assigned_to.selections)
        	 }
			if(Object.keys(this.state.advancedFilterData.colorwise_text.selections).length > 0){
        	 	status = Object.keys(this.state.advancedFilterData.colorwise_text.selections)
        	 }
			if(Object.keys(this.state.advancedFilterData.risk_owner.selections).length > 0){
        	 	rskowner = Object.keys(this.state.advancedFilterData.risk_owner.selections)
        	 }
        	 if(Object.keys(this.state.advancedFilterData.due_date.selections).length > 0){

	        	  stdt = this.state.advancedFilterData.due_date.selections.from_date.split('/')
	              endt = this.state.advancedFilterData.due_date.selections.to_date.split('/')
	              strt_from = moment(stdt[2] + '/' + stdt[1] + '/' + stdt[0]).unix()
	              end_at = moment(endt[2] + '/'+ endt[1] + '/' + endt[0]).unix()
        	}
        }

        // console.log("TEAM",tasks,risk_id,subcat_id)
        for(let m = 0 ; m <tasks.length; m++){
		// if(tasks[m].task_id !== null) console.log("TATESTACT  task",tasks[m])
          let include = true;
          if(strt_from <= moment(tasks[m].due_date).unix() && end_at >= moment(tasks[m].due_date).unix() ){


          let color = tasks[m].task_id === null ? this.compareDateGetColor(tasks[m].due_date,tasks[m].cur_lane) : this.compareDateGetColor(tasks[m].first_schedule,tasks[m].cur_lane);
          //console.log('color::',color)
        
        
          const company = tasks[m].company_id > 100000 ? (tasks[m].company_id % 100000) : tasks[m].company_id;
          const comp_key = Object.keys(all_companies).find(x => all_companies[x].id === company);
          // console.log("comp_key 12",comp_key,typeof company,company,tasks[m])
          let activity_status = (tasks[m].cur_lane === 'COMP_CMP_COMPLETED') ? 'Complete' : 'Incomplete';
          // 
          // if(risk_areas.length > 0 && this.state.testing_activities.length > 0){
              let riskarea_key = risk_areas.filter(function (risk) { return risk.unique_risk_id === tasks[m].risk_id });
              // console.log('TEAMSCHECK risakarea_key::',risakarea_key,tasks[m].testing_act_id)
              let testact_key = this.state.testing_activities.filter(function (t) { return t.unique_testing_act_id === tasks[m].testing_act_id })[0];
              // console.log('TEAMSCHECK testact_key::',testact_key)


                  if(riskarea_key.length !== 0 && typeof testact_key !== 'undefined' &&testact_key.length !== 0){

              	  if(tstactids.length > 0 && !tstactids.includes(tasks[m].testing_act_id.toString())){
              	  	include = false;
              	  }


              	  if(risk_id.length > 0 && !risk_id.includes(tasks[m].risk_id.toString())){
              	  	include = false;
              	  }

              	  if(subcat_id.length > 0 && !subcat_id.includes(tasks[m].subcat_id.toString())){
              	  	include = false;
              	  }

              	  if(assgnto.length > 0 && !assgnto.includes(all_contacts[testact_key.testing_owner])){
              	  	include = false;
              	  }

              	  if(status.length > 0 && !status.includes(colorwiseTextStatus[color])){
              	  	include = false;
              	  }
                    
                    if(riskImpact.length > 0 && !riskImpact.includes(riskarea_key[0].risk_impact)){
                        include = false
                    }

                        if(rskowner.length > 0 && !rskowner.includes(riskarea_key[0].risk_owner_id)){
                            include = false;
                        }
                        let subcat_key = tasks[m].subcat_id.toString() === '0' ? [] : riskarea_key[0].subcategories.filter(function (sub) { return sub.unique_subcat_id === tasks[m].subcat_id });

                        // console.log("subcat_key",subcat_key,subcat_key.length)
                        let risk_status = riskarea_key.length > 0 ? riskarea_key[0].status : ''

    	          let mod_conf = Store.getStoreData('module_config')
                  
                  let risk_impact = []
                        let risk_area_risk_impact = 'risk_area_risk_impact' in tasks[m] ? tasks[m].risk_area_risk_impact : riskarea_key[0].risk_impact;
                        if('risk_impact' in riskarea_key[0]){
                            risk_impact =  mod_conf.risk_impact.filter(function (risk) { return risk.id === risk_area_risk_impact })
                  }
              	  
                  let risk_impact_nm = typeof risk_impact !== 'undefined'  ? (risk_impact.length > 0 ? risk_impact[0].name : '') : "";
                  // console.log('risakarea_key risk_impact',risk_impact,risk_impact_nm)
                  let approval_string = (tasks[m].cur_lane === 'COMP_CMP_APPROVAL_REQUEST') ? '(Approval Required)' : '';
                  //if(tasks[m].task_id > 0)console.log("APPROVE TASK::",tasks[m])
                  // console.log("subcat_owner::",include,role,user_id.toString(),testact_key.testing_owner.toString(),subcat_key)
                  if(role === 'subcat_owner' && include){
                    if(subcat_key.length > 0 && (user_id.toString() !== testact_key.testing_owner.toString() && user_id.toString() !== subcat_key[0].subcat_assignto.toString() && subcat_key[0].created_by !== user_id.toString())){
                            include = false
                            // console.log("Include false at 931")
                    }else{
                        if(user_id.toString() !== testact_key.testing_owner.toString()){
                            include = false
                            // console.log("Include false at 934")
                        }
                    }
                    
                  }

                  // console.log("subcat_owner::",include)
                    // if(subcat_key.length > 0)console.log("ALL keys subcat_key",subcat_key[0].subcat_status,subcat_key[0].status)

                    let show_inactive_include = false
                    if(show_inactive){
                            if(tasks[m].subcat_id.toString() === "0"){ 
                                // console.log("ALL keys 869")
                                if(riskarea_key[0].status === 'Active'){

                                    show_inactive_include = true
                                }
                            }else{
                                if(subcat_key.length > 0){

                                    if('subcat_status' in subcat_key[0]){
                                        // console.log("ALL keys 877")
                                        if(subcat_key[0].subcat_status === 'Active' && riskarea_key[0].status === 'Active'){
                                            
                                           show_inactive_include = true
                                        }
                                    }else{
                                        // console.log("ALL keys 882")
                                        if(subcat_key[0].status === 'Active' && riskarea_key[0].status === 'Active'){
                                            
                                           show_inactive_include = true
                                        }                                
                                    }                               
                                }
                            }
                      }else{
                            if(tasks[m].subcat_id.toString() === "0"){
                                // console.log("ALL keys 893")
                                if(riskarea_key[0].status !== 'Deleted'){

                                    show_inactive_include = true
                                }
                            }else{
                                if(subcat_key.length > 0){
                                    // console.log("ALL keys 900")
                                    if('subcat_status' in subcat_key[0]){
                                        // console.log("ALL keys 901")
                                        if(subcat_key[0].subcat_status !== 'Deleted' && riskarea_key[0].status !== 'Deleted'){
                                            show_inactive_include = true
                                        }
                                    }else{
                                        // console.log("ALL keys 907")
                                        if(subcat_key[0].status !== 'Deleted' && riskarea_key[0].status !== 'Deleted'){
                                            show_inactive_include = true
                                        }
                                    }
                                }
                            }
                      }
                      // && !testact_key.is_deleted
                      //console.log("TALLY:: show_inactive_include",show_inactive_include, tasks[m].testing_act_id+"_"+ tasks[m].due_date+'_'+tasks[m].name)
                  let risk_name = riskarea_key.length > 0 ? riskarea_key[0].risk_area_name : ''
                  if('risk_name' in tasks[m]){
                    risk_name = tasks[m].risk_name
                  }

                  let subcat_name = subcat_key.length > 0 ? subcat_key[0].risk_subcategory_name : ''
                  if('subcat_name' in tasks[m]){
                    subcat_name = tasks[m].subcat_name
                  }
                  let additional_fields = []
                  let version = 0
                  if(tasks[m].task_id === 54176){console.log('CHECKTHID::',tasks[m])} //risk_impact_nm
                  if('additional_fields' in tasks[m]){
                    additional_fields.push(tasks[m].additional_fields)
                    version = 1
                  }
                  let risk_owner_id = 'risk_owner_id' in tasks[m] ? tasks[m].risk_owner_id : riskarea_key[0].risk_owner_id
    	          if(include && show_inactive_include){
    	          			  let child_task_count = 0
    	          			  if(tasks[m].task_id > 0 && tasks[m].child_task_ids !== null){
    	          			  	let child_tasks = JSON.parse(tasks[m].child_task_ids)
    	          			  	child_task_count = child_tasks.length
    	          			  }

                              let testing_owner = testact_key === undefined ? "" : all_contacts[testact_key.testing_owner];
                              if(tasks[m].cur_lane === 'COMP_CMP_COMPLETED'){
                                testing_owner = all_contacts[tasks[m].testing_owner]
                              }
    	          	          let elem = {'index': m,
    	          	          'activity': tasks[m].cur_lane === 'COMP_CMP_COMPLETED' ? tasks[m].name : testact_key.testing_activity,
                              'risk_area' : risk_name,
                              'subcat_name' : subcat_name,
    	          	          'company' : all_companies[comp_key].company_name,
    	                      'company_id': company,
    	          	          'assigned_to' : testing_owner,
    	                      'testing_owner' : tasks[m].cur_lane === 'COMP_CMP_COMPLETED' ? tasks[m].testing_owner : testact_key.testing_owner,
                              'testing_description' : tasks[m].cur_lane !== 'COMP_CMP_COMPLETED' ? testact_key.testing_description : 'additional_guidance' in tasks[m] ? tasks[m].description : testact_key.testing_description,
                              'additional_guidance' : tasks[m].cur_lane !== 'COMP_CMP_COMPLETED' ? testact_key.additional_guidance : 'additional_guidance' in tasks[m] ? tasks[m].additional_guidance : testact_key.additional_guidance,
                              'testing_method' : tasks[m].cur_lane !== 'COMP_CMP_COMPLETED' ? testact_key.testing_method : 'testing_method' in tasks[m] ? tasks[m].testing_method : testact_key.testing_method,
                              'testing_objectives' : tasks[m].cur_lane !== 'COMP_CMP_COMPLETED' ? testact_key.testing_objectives : 'testing_objectives' in tasks[m] ? tasks[m].testing_objectives : testact_key.testing_objectives,
                              'due_date' : moment(tasks[m].due_date).format("DD/MM/YYYY"),
                              'due_date_timestamp' : moment(tasks[m].due_date).unix(),
    	          	          'colorwise_text' : colorwiseTextStatus[color],
                              'colorwise_text_color' : color,
                              //'colorwise_text': { text: colorwiseTextStatus[color], color: color },
    	          	          'activity_status' : activity_status,
    	          	          'risk_status': risk_status,
    	          	          'testing_act_id' : tasks[m].testing_act_id,
    	          	          'unique_risk_id' : tasks[m].risk_id,
    	                      'unique_subcat_id' : tasks[m].subcat_id,
    	          	          'task_id': tasks[m].task_id,
    	                      'risk_owner_name': all_contacts[risk_owner_id],
    	                      'risk_owner_id': risk_owner_id,
    	                      'risk_impact': 'risk_impact' in riskarea_key[0] ? riskarea_key[0].risk_impact : '',
    	                      'risk_impact_nm':risk_impact_nm,
                              'risk_additional_val': "additional_fields" in riskarea_key[0] ? riskarea_key[0].additional_fields : [],
                              'subcat_additional_vals' : subcat_key.length > 0 && "additional_fields" in subcat_key[0]  ? subcat_key[0].additional_fields : [],
                              // 'subcat_additional_vals_obj' :subcat_additional_vals,
                              // 'risk_additional_val_obj':risk_additional_val
                              'approval_string' : approval_string,
                              'approver_id': 'approver_id' in tasks[m] ? tasks[m].approver_id : 0,
                              'num_of_remedial' : child_task_count,
                              'additional_fields' : additional_fields,
                              'version' : version,
                              'cur_lane' : tasks[m]?.cur_lane
    	          	          }
    	          	          ret.data.push(elem);
    	                      ret.raw_data.push(elem);
    	                      ret.row_filters[tasks[m].task_id] = {};
    	            }else{
                        // console.log('excluding:::',moment(tasks[m].due_date).format("DD/MM/YYYY"),'for',tasks[m].name, "because include is",include,subcat_key[0],risakarea_key[0])
                    }
    		  }

            // }
		}
          //
          //`<div style="border-radius: 4px; padding: 4px; height: 25px; width: 100%; background: ${color}; text-align: center; color: rgb(255, 255, 255);">${colorwiseTextStatus[color]}</div>`
        }
      }
      // console.log("risakarea_key::ret",ret)
      return ret;  
    }
    filterRow = (row) => {
          let filters = this.state.applied_filters;
          if(Object.keys(filters).length === 0)return true

          let has_activity = Object.keys(filters.activity.selections).length > 0 ? row.unique_testing_act_id in filters.activity.selections : true;
          let has_riskarea = Object.keys(filters.risk_area.selections).length > 0 ? row.unique_risk_id in filters.risk_area.selections : true;
          let has_subcat = Object.keys(filters.subcat_name.selections).length > 0 ? row.unique_subcat_id in filters.subcat_name.selections : true;
          let has_company = Object.keys(filters.company.selections).length > 0 ? row.company_id in filters.company.selections : true;
          let has_assigned_to = Object.keys(filters.assigned_to.selections).length > 0 ? row.testing_owner in filters.assigned_to.selections : true;
          let has_colorwise_text = Object.keys(filters.colorwise_text.selections).length > 0 ? row.colorwise_text in filters.colorwise_text.selections : true;
          let has_risk_owner = Object.keys(filters.risk_owner.selections).length > 0 ? row.risk_owner_id in filters.risk_owner.selections : true;
          let has_risk_impact = Object.keys(filters.risk_impact.selections).length > 0 ? row.risk_impact in filters.risk_impact.selections : true;
          let has_due_date_in_range = false;
		  let role = Store.getStoreData('role');
		  let today = moment();
		  today = today.set('hour', 0).set('minute', 0).set('second', 0).unix();
		  let due_dt_arr = row.due_date.split('/');
		  let due_dt_string = due_dt_arr[2]+'-'+due_dt_arr[1]+'-'+due_dt_arr[0];
		  let due_date_moment = moment(due_dt_string).unix();
		  if(Object.keys(filters.due_date.selections).length > 0){
			let from_date_arr = filters.due_date.selections.from_date.split('/');
			let to_date_arr = filters.due_date.selections.to_date.split('/');
			let from_date_string = from_date_arr[2]+'-'+from_date_arr[1]+'-'+from_date_arr[0];
			let to_date_string = to_date_arr[2]+'-'+to_date_arr[1]+'-'+to_date_arr[0];
			let past_days = moment(from_date_string).unix();
			let future_days = moment(to_date_string).unix();
			//if(role === 'team'){
			//	if(due_date_moment <= future_days ){
			//		has_due_date_in_range = true;					
			//	}
			//}else{
				//if( ((due_date_moment >= today && due_date_moment <= future_days) ||  (due_date_moment <= today && due_date_moment >= past_days)) ){
				if(due_date_moment <= future_days &&  due_date_moment >= past_days){
					has_due_date_in_range = true;
				}
			//}
		  }else{
			let keys = [];
			for(let k in this.state.filters.due_date.options){
				let u_key = moment(k).unix();
				keys.push(u_key);
			}
			keys.sort();
			let past_days = keys[0];
			let future_days = keys[1];

			//console.log("filterRow",keys,future_days,row.due_date,due_date_moment,due_date_moment <= future_days);

			//if(role === 'team'){
			//	if(due_date_moment <= future_days){
			//		has_due_date_in_range = true;					
			//	}
			//}else{
				//if( ((due_date_moment >= today && due_date_moment <= future_days) ||  (due_date_moment <= today && due_date_moment >= past_days)) ){
				if(due_date_moment <= future_days &&  due_date_moment >= past_days){
					has_due_date_in_range = true;
				}
			//}
		  }
          //console.log("has_activity,",this.state.filters.due_date,has_activity,has_riskarea,has_subcat,has_company,has_assigned_to,has_colorwise_text,has_risk_owner,has_risk_impact,has_due_date_in_range);
          //console.log("has_activity,",this.state.filters.due_date,has_activity,has_riskarea,has_subcat,has_company,has_assigned_to,has_colorwise_text,has_risk_owner,has_risk_impact,has_due_date_in_range);
          return has_activity && has_riskarea && has_subcat && has_company && has_assigned_to && has_colorwise_text && has_risk_owner && has_risk_impact && has_due_date_in_range;
      }
    openTestingActivities =(dev_modal,due_date, test_act_id,unique_risk_id,task_id,approver_id)=>{
         // console.log("openTestingActivities::::",dev_modal,due_date, test_act_id,unique_risk_id,task_id,approver_id)
        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID
          
        //let plan = Store.getStoreData('selected_plan')

        let all_testing_activities = Store.getStoreData('testing_activities')


        //let all_testing_activities = plan.testing_activities;
          let testing_activities = all_testing_activities.filter((t, i) => {
          return (t.unique_testing_act_id === test_act_id)
         })
        // console.log("openTestingActivities:::testing_activities",testing_activities)
        let testing_owner = testing_activities[0].testing_owner
        // console.log("openTestingActivities:::testing_owner",testing_activities[0].testing_owner)

        // console.log("openTestingActivities:::task_id",task_id)
        // console.log("openTestingActivities testing_activities[0]",testing_activities[0])
        if(task_id > 0){
        // console.log("openTestingActivities testing_activities[0]",Store.getStoreData('tasks_by_id')[task_id])
            
            testing_owner = Store.getStoreData('tasks_by_id')[task_id].cur_assigned_to
        }
         // console.log("openTestingActivities:::testing_owner",testing_owner)
       
        //console.log("openTestingActivities:::",testing_activities[0],user_id,role,testing_owner)
        //console.log("openTestingActivities:::",(testing_owner.toString() !== user_id.toString()),(testing_activities[0].created_by.toString() !==  user_id.toString()))
        //console.log("openTestingActivities:::",(role === 'subcat_owner' && (testing_activities[0].testing_owner !== user_id.toString && testing_activities[0].created_by.toString() !==  user_id.toString)))
        if((role === 'risk_owner' && (testing_owner.toString() !== user_id.toString() && ("created_by" in testing_activities[0] && testing_activities[0].created_by.toString() !==  user_id.toString()) && approver_id.toString() !== user_id.toString())) || 
          (role === 'subcat_owner' && (testing_owner.toString() !== user_id.toString() && ("created_by" in testing_activities[0] && testing_activities[0].created_by.toString() !==  user_id.toString()) && approver_id.toString() !== user_id.toString())) ) {
            
                let alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
        }else{
          // console.log("openTestingActivities::::",plan)

          let risk_id = unique_risk_id
          // let risk_areas = plan.risk_areas;
          let risk_areas = Store.getStoreData('risk_areas')
          //console.log('risk_area::plan', risk_areas)
          let risk = risk_areas.filter((t, i) => {
              return (t.unique_risk_id === risk_id)
          })

          let risk_area = risk

          let dt = due_date.split('/')
          testing_activities[0].due_date = moment(new Date(dt[2]+'-'+dt[1]+'-'+dt[0]))

          this.setState({dev_modal, edit_object:testing_activities,tact_risk_area:risk_area[0],tact_task_id:task_id})
        }


    }
    closeModal = () => {
        this.setState({dev_modal: null, edit_object: null,tact_risk_area:null,tact_task_id:null})
    }

    reloadTestingactivity = (plan_ref_id) =>{

        if (plan_ref_id === 'error') {
          let alert_param = {title: 'ERROR', message: 'Issue connecting to the Job Manager, please contact your admin', ok_text: 'Ok', confirm: false, alertHandler: this.AlertHandler, stack: {}};
          this.setState({dev_modal: null, edit_object: null,tact_risk_area:null,tact_task_id:null,alert_param:alert_param})
          return
        }
        
        EventBus.raiseEvent('data_reload', plan_ref_id)

        if(Store.getStoreData('role') === 'team'){
             EventBus.raiseEvent('Tact_save_team',Store.getStoreData('team_gcid'))
        }
        
        let show_status = this.state.show_completed_tasks;
        
        let alert_param = {title: 'Success', message: 'Testing Activity successfully saved.', ok_text: 'Ok', confirm: false, alertHandler: this.AlertHandler, stack: {}};
        this.setState({dev_modal: null, edit_object: null,tact_risk_area:null,tact_task_id:null,alert_param:alert_param})
        if(show_status){
          this.showCompletedTasks()
        }else{
          this.setComponentState()
        }
    }

    openCompleteModal =(ref_id)=>(event) =>{
        event.preventDefault();
        //console.log('Clicked')
        //this.setState({dev_modal, edit_object:testing_activities,tact_risk_area:risk_area[0]})
    }
    toggleCallback = (event) => {
        event.preventDefault();
        this.setState({is_loader : true});
        setTimeout(() => {
            this.trackToggleProgress()
        }, 30);

    }
   trackToggleProgress = () => {
        let {toggle_state} = this.state;
        toggle_state = toggle_state ? false : true;
        this.populateFilterData()

        let records = this.processTableData().data;
        let advancedFilterData = this.state.filters;
        Store.updateStore('ta_toggle_state',toggle_state)
        this.setState({toggle_state , advancedFilterData,is_loader : false});
   }


    populateFilterData = () => {
        let filterSchema = JSON.parse(JSON.stringify(filters))

        const status = ['Scheduled','Due','Overdue','Completed']
        const contacts = Store.getStoreData('contacts')
        const riskAreas = this.state.risk_areas
        let allTasks = [...this.state.testing_activities,...this.state.risk_areas]

        let assignedTo = {}
        allTasks.forEach(task => {
            if (!contacts.hasOwnProperty(task.testing_owner)) {
                return
            }
            const key = task.testing_owner
            assignedTo[contacts[key]] = contacts[key]
        })
        Object.keys(assignedTo).forEach((key) => {
            filterSchema.assigned_to.options[assignedTo[key]] = { name: assignedTo[key] }
        })

        riskAreas.forEach(area =>{
            if(area.subcategories.length === 0 || area.is_deleted){
                return
            }
            Object.keys(area.subcategories).forEach((key) => {
                if(area.subcategories[key].subcat_status === "Inactive"){
                    return
                }
                filterSchema.subcat_name.options[area.subcategories[key].unique_subcat_id] =
                    {name: area.subcategories[key].risk_subcategory_name}
            })
        })

        let riskOwners = {}
        riskAreas.forEach(area=> {
            if(area.status === "Inactive"){
                return
            }
            const key = area.risk_owner_id
            riskOwners[key] = {name: contacts[key]}
        })

        Object.keys(riskOwners).forEach((key) => {
            filterSchema.risk_owner.options[key] = riskOwners[key]
        })

        Object.keys(riskAreas).forEach((key) => {
            if(riskAreas[key].status === "Inactive"){
                return
            }
            filterSchema.risk_area.options[riskAreas[key].unique_risk_id] = { name: riskAreas[key].risk_area_name}
        })

        const riskImpact = Store.getStoreData('module_config')?.risk_impact
        Object.keys(riskImpact).forEach((key) => {
            filterSchema.risk_impact.options[riskImpact[key].id] = { name: riskImpact[key].name}
        })

        status.forEach((value) => {
            filterSchema.colorwise_text.options[value] = {name:value}
        })

        let testingActivities = this.state.testing_activities
        Object.keys(testingActivities).forEach((key)=>{
            if(testingActivities[key].is_deleted){
                return
            }
            filterSchema.activity.options[testingActivities[key].unique_testing_act_id] = {name: testingActivities[key].testing_activity}
        })

        filterSchema.due_date.options[this.state.future_days] = {name: this.state.filter_end_date}
        filterSchema.due_date.options[this.state.past_days] = {name: this.state.filter_start_date}

        Object.assign(this.state.filters,filterSchema)
    }

   applyFilter = (filters) => {

	  let applied_filters = JSON.parse(JSON.stringify(filters))
      // console.log("applied_filters", applied_filters);
      let filter_start_date_to_show_in_table = (typeof applied_filters.due_date.selections.from_date !== 'undefined') ? applied_filters.due_date.selections.from_date : this.state.filter_start_date;
      let filter_end_date_to_show_in_table = (typeof applied_filters.due_date.selections.to_date !== 'undefined') ? applied_filters.due_date.selections.to_date: this.state.filter_end_date;
      
      Store.updateStore('ta_advancedFilterData',applied_filters) 
      this.setState({applied_filters , filter_start_date_to_show_in_table , filter_end_date_to_show_in_table , completed_tasks_clicked : false})

      ///this.setComponentState();
    }
   clearAdvancedFilter = () => {
      let applied_filters = JSON.parse(JSON.stringify(filters));
      applied_filters.due_date.selections = {};

      let filters_state = JSON.parse(JSON.stringify(this.state.filters));
      filters_state.due_date.selections = {from_date : this.state.filter_start_date , to_date : this.state.filter_end_date};
      let filter_start_date_to_show_in_table = this.state.filter_start_date;
      let filter_end_date_to_show_in_table = this.state.filter_end_date;
      Store.updateStore('ta_advancedFilterData',applied_filters)
      this.setState({applied_filters , filters : filters_state , filter_start_date_to_show_in_table ,filter_end_date_to_show_in_table}) 
   }
   downloadTestingActivitiesExcelPre = () =>  {
		this.setState({is_loader: true});
		setTimeout(() => {
				this.downloadTestingActivitiesExcel();
		}, 10);
     
    }
    downloadTestingActivitiesExcel = () =>  {
     	let tasks = this.processTableData().data;
        let mc = Store.getStoreData('module_config')
        let contact_list = Store.getStoreData('contacts')
        let all_risk = Store.getStoreData('risk_areas')
        let role = Store.getStoreData('role')
        let loggedin_user_info = Store.getStoreData('user_info')?.ID;
        
        let show_testing_comments_status = true
        if(!mc.general.show_testing_comments_and_details_to_users_when_completing_remedial_actions && Store.getStoreData('role')=== 'team'){
          show_testing_comments_status = false
        }
          
     	

     	let dt = {
		  file_name: "activity_xls",
		  cols: ['CMP #','Activity','Description','Risk Area','Risk Owner','Risk Impact','Subcategory','Frequency','Testing Activity Assigned To','Due Date',
		  'Testing Objectives','Testing Method','Additional Guidance','Attachments','Status','Testing Activities Undertaken','RAG','Requires Remediation',
		  'No of Actions Assigned'],
		  data: [
		  ]
		}
        if(show_testing_comments_status === false){
            dt.cols = ['CMP #','Activity','Description','Risk Area','Risk Owner','Risk Impact','Subcategory','Frequency','Testing Activity Assigned To','Due Date',
          'Testing Objectives','Testing Method','Additional Guidance','Attachments','Status']
        }
        let risk_area_additional_fields = {};
        let subcat_additional_fields = {};

        // console.log("downloadTestingActivitiesExcel 1::",mc.custom_fields.additional_fields.length)
        if(mc.custom_fields.additional_fields.length !== 0 && mc.custom_fields.enabled) {
            mc.custom_fields.additional_fields.forEach((item) => {
                let copy_item = JSON.parse(JSON.stringify(item));
                if('displayin' in item && (item.displayin === 'All Records' || item.displayin === 'Risk Area only') && item.isActive === 1 && item.isDelete === 0){
                    risk_area_additional_fields[item.name] = item.nickname;
                }
                if('displayin' in item && (item.displayin === 'All Records' || item.displayin === 'Subcategory only') && item.isActive === 1 && item.isDelete === 0){
                    subcat_additional_fields[item.name] = item.nickname;
                }
            })
        }
        // console.log("downloadTestingActivitiesExcel 2 risk_area_additional_fields,",risk_area_additional_fields,subcat_additional_fields);
        if(Object.keys(risk_area_additional_fields).length>0 && Object.keys(subcat_additional_fields).length>0){
            let cols_1 = ['CMP #','Activity','Description','Risk Area','Risk Owner'];
            let cols_2 = ['Risk Impact','Subcategory'];
            let cols_3 = ['Frequency','Testing Activity Assigned To','Due Date','Testing Objectives','Testing Method','Additional Guidance','Attachments','Status','Testing Activities Undertaken','RAG','Requires Remediation','No of Actions Assigned'];
            if(show_testing_comments_status === false){
                cols_3 = ['Frequency','Testing Activity Assigned To','Due Date','Testing Objectives','Testing Method','Additional Guidance','Attachments','Status'];
            }
            for(let r in risk_area_additional_fields){
                cols_1.push(risk_area_additional_fields[r]);
            }
            for(let r in subcat_additional_fields){
                cols_2.push(subcat_additional_fields[r]);
            }
            dt.cols = [...cols_1,...cols_2,...cols_3];          
        }
        if(Object.keys(risk_area_additional_fields).length > 0 && Object.keys(subcat_additional_fields).length === 0){
            let cols_1 = ['CMP #','Activity','Description','Risk Area','Risk Owner'];
            let cols_2 = ['Risk Impact','Subcategory','Frequency','Testing Activity Assigned To','Due Date','Testing Objectives','Testing Method','Additional Guidance','Attachments','Status','Testing Activities Undertaken','RAG','Requires Remediation','No of Actions Assigned'];
            if(show_testing_comments_status === false){
               cols_2 = ['Risk Impact','Subcategory','Frequency','Testing Activity Assigned To','Due Date','Testing Objectives','Testing Method','Additional Guidance','Attachments','Status'];
            }
            for(let r in risk_area_additional_fields){
                cols_1.push(risk_area_additional_fields[r]);
            }
            dt.cols = [...cols_1,...cols_2];                
        }
        if(Object.keys(risk_area_additional_fields).length === 0 && Object.keys(subcat_additional_fields).length > 0){
            let cols_1 = ['CMP #','Activity','Description','Risk Area','Risk Owner','Risk Impact','Subcategory'];
            let cols_2 = ['Frequency','Testing Activity Assigned To','Due Date','Testing Objectives','Testing Method','Additional Guidance','Attachments','Status','Testing Activities Undertaken','RAG','Requires Remediation','No of Actions Assigned'];
            if(show_testing_comments_status === false){
                cols_2 = ['Frequency','Testing Activity Assigned To','Due Date','Testing Objectives','Testing Method','Additional Guidance','Attachments','Status'];
            }
            for(let r in subcat_additional_fields){
                cols_1.push(subcat_additional_fields[r]);
            }
            dt.cols = [...cols_1,...cols_2];
        }


		for(let i = 0; i<tasks.length; i++){
        let viewOnlyManagerAllowed = true;

        if(role === 'view_only_manager' && parseInt(loggedin_user_info) !== parseInt(tasks[i]?.testing_owner)){
            viewOnlyManagerAllowed = false;
        }
        let testing_activities =this.state.testing_activities.filter(function (t) { return t.unique_testing_act_id === tasks[i].testing_act_id })[0];
        let task_id = tasks[i].task_id === null? 0 : tasks[i].task_id
        // console.log("ph_task task_id::",task_id) 

        let ph_task = task_id === 0 ? [] : Store.getStoreData('tasks_by_id')[task_id]
        // console.log("ph_task::",ph_task)
        if(typeof(ph_task) === 'undefined' )continue;
        if(task_id===54176)console.log("downloadTestingActivitiesExcel::ph_task",tasks[i])
        
        //let risakarea_key = risk_areas.filter(function (risk) { return risk.unique_risk_id === tasks[m].risk_id });

        // console.log('downloadTestingActivitiesExcel',testing_activities)
			let activity_name = tasks[i].activity
			// let description = this.changeStringToAtob(testing_activities.testing_description)
            let description = this.changeStringToAtob(tasks[i].testing_description)
			let risk_area = tasks[i].risk_area

            let risk_additional_val =[]
			let risk_owner = tasks[i].risk_owner_name
			let risk_impact = tasks[i].risk_impact_nm
			let subcat_name = tasks[i].subcat_name

            let task_testing_description = tasks[i].testing_description
            let task_additional_guidance = tasks[i].additional_guidance
            let task_testing_method = tasks[i].testing_method
            let task_testing_objectives = tasks[i].testing_objectives


			let Frequency = testing_activities.testing_freq
			let assign_to = tasks[i].assigned_to
			let due_date = tasks[i].due_date
            
			// let testing_objectives = testing_activities.testing_objectives.trim() === "" ? "" : this.changeStringToAtob(testing_activities.testing_objectives)
			// let testing_method = testing_activities.testing_method.trim() === "" ? "" : this.changeStringToAtob(testing_activities.testing_method)
			// let additional_guidance = testing_activities.additional_guidance.trim() === "" ? "" : this.changeStringToAtob(testing_activities.additional_guidance)
			
            let testing_objectives = tasks[i].testing_objectives.trim() === "" ? "" : this.changeStringToAtob(tasks[i].testing_objectives)
            let testing_method = tasks[i].testing_method.trim() === "" ? "" : this.changeStringToAtob(tasks[i].testing_method)
            let additional_guidance = tasks[i].additional_guidance.trim() === "" ? "" : this.changeStringToAtob(tasks[i].additional_guidance)
            
            let attachments = ""
			let status = tasks[i].colorwise_text

            let testing_activity_undertaken_values_arr = [];
            let testing_activity_undertaken_vals = [];
            let testing_activity_undertaken_vals_text = "";

            if(task_id !== 0){
                        testing_activity_undertaken_values_arr = ph_task.result[0].testing_activity_undertaken_values_arr;
                        testing_activity_undertaken_vals = [];
                        testing_activity_undertaken_vals_text = this.changeStringToAtob(ph_task.result[0].testing_activities_undertaken);
                        if(typeof testing_activity_undertaken_values_arr !== 'undefined' && testing_activity_undertaken_values_arr.length > 0){
                        for(let r of testing_activity_undertaken_values_arr){

                                let tau_text = this.changeStringToAtob(r.tau_text);
                                let text_created_by = contact_list[r.created_by.toString()];
                                let div = '';
                                div = div + '<div >'+ tau_text +'';
                                div = div + '<b><i>Updated by ' + text_created_by + ' - ' + r.created_on + '</i></b>';
                                div = div + '</div>';
                                testing_activity_undertaken_vals.push(div)
                            }
                            testing_activity_undertaken_vals_text = testing_activity_undertaken_vals.join('');
                        }
                    }

            let ta_act_undrtkn = tasks[i]?.task_id === null ? '' : testing_activity_undertaken_vals_text
            if(!viewOnlyManagerAllowed){
                ta_act_undrtkn = 'N/A'
            }


            let rag = tasks[i]?.task_id === null || ph_task.result[0]?.rag.toString() === '0' ? '' : mc.custom_rags?.additional_rags.filter(function (mc) { return mc.id === ph_task.result[0]?.rag })[0]?.nickname;
            if(!viewOnlyManagerAllowed){
                rag = 'N/A'
            }

            let require_rem = tasks[i]?.task_id === null ? '' : ph_task.result[0]?.require_remediation
            let num_of_remedial = tasks[i]?.num_of_remedial
            if(!viewOnlyManagerAllowed){
                require_rem = 'N/A'
                num_of_remedial = 'N/A'
            }

           let arr = [i,activity_name,description,risk_area,risk_owner,risk_impact,subcat_name,Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status,ta_act_undrtkn,rag,require_rem,num_of_remedial]
           if(show_testing_comments_status === false){
               arr = [i,activity_name,description,risk_area,risk_owner,risk_impact,subcat_name,Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status]
           }


                if(Object.keys(risk_area_additional_fields).length>0 && Object.keys(subcat_additional_fields).length>0){
                    let cols_1 = [i,activity_name,description,risk_area,risk_owner];
                    let cols_2 = [risk_impact,subcat_name];
                    let cols_3 = [Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status,ta_act_undrtkn,rag,require_rem,num_of_remedial];
                    if(show_testing_comments_status === false){
                        cols_3 = [Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status];
                    }
                    // console.log('downloadTestingActivitiesExcel 6 ridk',tasks[i].risk_additional_val)
                    if(tasks[i].additional_fields.length > 0 && tasks[i].version === 1){ 
                        
                        if(Object.keys(tasks[i].additional_fields[0].risk_area).length > 0){

                            for(let r in risk_area_additional_fields){
                                // console.log('downloadTestingActivitiesExcel ridk',r,(r in tasks[i].risk_additional_val))

                                
                                    let key = Object.values(tasks[i].additional_fields[0].risk_area).filter(function (ad) { console.log('additional_fields::loop',ad,r);return ad.name === r })[0]
                                    //let key = tasks[i].risk_additional_val.find(x => tasks[i].risk_additional_val[x].name === r);
                                    // console.log('additional_fields::key',key)
                                    if(key){

                                        // console.log("downloadTestingActivitiesExcel key",key)
                                        if(key.type === 'Date'){
                                            cols_1.push(moment(key.value).format('DD/MM/YYYY'))
                                        }else{
                                            cols_1.push(key.value)                               
                                        }
                                    }else{
                                        cols_1.push('')
                                    }
                                   // (r in tasks[i].risk_additional_val) ? cols_1.push() : cols_1.push('') ;
                                }
                        }else{
                            for(let r in risk_area_additional_fields){
                                cols_1.push('') ;
                            }
                        }
                    }else{
                        if(tasks[i].risk_additional_val.length > 0){
                            for(let r in risk_area_additional_fields){
                                // console.log('downloadTestingActivitiesExcel ridk',r,(r in tasks[i].risk_additional_val))

                                
                                    let key = tasks[i].risk_additional_val.filter(function (ad) { return ad.name === r })[0]
                                    //let key = tasks[i].risk_additional_val.find(x => tasks[i].risk_additional_val[x].name === r);
                                    if(key){

                                        // console.log("downloadTestingActivitiesExcel 7 key",key)
                                        if(key.type === 'Date'){
                                            cols_1.push(moment(key.value).format('DD/MM/YYYY'))
                                        }else{
                                            cols_1.push(key.value)                               
                                        }
                                    }else{
                                        cols_1.push('')
                                    }
                                    
                                
                                   // (r in tasks[i].risk_additional_val) ? cols_1.push() : cols_1.push('') ;
                                }
                                                  
                        }else{
                            for(let r in risk_area_additional_fields){
                                cols_1.push('') ;
                            }                       
                        }
                    }
                    // console.log('downloadTestingActivitiesExcel 8 subcat',tasks[i].subcat_additional_vals)
                    if(tasks[i].additional_fields.length > 0 && tasks[i].version === 1){
                        if(Object.keys(tasks[i].additional_fields[0].subcat).length > 0){
                            for(let r in subcat_additional_fields){
                                // console.log('downloadTestingActivitiesExcel subcat',tasks[i].subcat_additional_vals)

                                let key = Object.values(tasks[i].additional_fields[0].subcat).filter(function (ad) { return ad.name === r })[0]
                                    //let key = tasks[i].risk_additional_val.find(x => tasks[i].risk_additional_val[x].name === r);
                                    if(key){

                                        // console.log("downloadTestingActivitiesExcel key",key)
                                        if(key.type === 'Date'){
                                            cols_2.push(moment(key.value).format('DD/MM/YYYY'))
                                        }else{
                                            cols_2.push(key.value)                               
                                        }
                                    }else{
                                        cols_2.push('')
                                    }

                                //(r in tasks[i].subcat_additional_vals) ? cols_2.push(tasks[i].subcat_additional_vals[r]) : cols_2.push('') ;
                            }                      
                        }else{
                            for(let r in subcat_additional_fields){
                                cols_2.push('') ;
                            }                       
                        }   
                    }else{
                        if(tasks[i].subcat_additional_vals.length > 0){
                            for(let r in subcat_additional_fields){

                                let key = tasks[i].subcat_additional_vals.filter(function (ad) { return ad.name === r })[0]
                                    //let key = tasks[i].risk_additional_val.find(x => tasks[i].risk_additional_val[x].name === r);
                                    if(key){

                                        // console.log("downloadTestingActivitiesExcel key",key)
                                        if(key.type === 'Date'){
                                            cols_2.push(moment(key.value).format('DD/MM/YYYY'))
                                        }else{
                                            cols_2.push(key.value)                               
                                        }
                                    }else{
                                        cols_2.push('')
                                    }

                                //(r in tasks[i].subcat_additional_vals) ? cols_2.push(tasks[i].subcat_additional_vals[r]) : cols_2.push('') ;
                            }                      
                        }else{
                            for(let r in subcat_additional_fields){
                                cols_2.push('') ;
                            }                       
                        }
                    }
                    arr = [...cols_1,...cols_2,...cols_3];
                }
                //if(risk_area_additional_fields_value_array.length > 0 && subcat_additional_fields_value_array.length === 0){
                
                if(Object.keys(risk_area_additional_fields).length>0 && Object.keys(subcat_additional_fields).length===0){
                    let cols_1 = [i,activity_name,description,risk_area,risk_owner];
                    let cols_2 = [risk_impact,subcat_name,Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status,ta_act_undrtkn,rag,require_rem,num_of_remedial];
                    if(show_testing_comments_status === false){
                        cols_2 = [risk_impact,subcat_name,Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status];
                    }
                    // console.log('downloadTestingActivitiesExcel 10:: ',tasks[i].risk_additional_val)
                    // console.log('additional_fields::Risk', tasks[i].version,tasks[i].additional_fields.length)
                    if(tasks[i].additional_fields.length > 0 && tasks[i].version === 1){ 
                        // console.log('additional_fields::Risk',Object.keys(tasks[i].additional_fields[0].risk_area).length,Object.keys(tasks[i].additional_fields[0].risk_area))
                        if(Object.keys(tasks[i].additional_fields[0].risk_area).length > 0){

                            for(let r in risk_area_additional_fields){
                                // console.log('downloadTestingActivitiesExcel ridk',r,(r in tasks[i].risk_additional_val))

                                
                                    let key = Object.values(tasks[i].additional_fields[0].risk_area).filter(function (ad) { return ad.name === r })[0]
                                    //let key = tasks[i].risk_additional_val.find(x => tasks[i].risk_additional_val[x].name === r);
                                    if(key){

                                        // console.log("downloadTestingActivitiesExcel key",key)
                                        if(key.type === 'Date'){
                                            cols_1.push(moment(key.value).format('DD/MM/YYYY'))
                                        }else{
                                            cols_1.push(key.value)                               
                                        }
                                    }else{
                                        cols_1.push('')
                                    }
                                   // (r in tasks[i].risk_additional_val) ? cols_1.push() : cols_1.push('') ;
                                }
                        }else{
                            for(let r in risk_area_additional_fields){
                                cols_1.push('') ;
                            }
                        }
                    }else{
                         if(tasks[i].risk_additional_val.length > 0){
                            for(let r in risk_area_additional_fields){
                                // console.log('downloadTestingActivitiesExcel ridk',r,(r in tasks[i].risk_additional_val))

                                
                                    let key = tasks[i].risk_additional_val.filter(function (ad) { return ad.name === r })[0]
                                    //let key = tasks[i].risk_additional_val.find(x => tasks[i].risk_additional_val[x].name === r);
                                    if(key){

                                        // console.log("downloadTestingActivitiesExcel key",key)
                                        if(key.type === 'Date'){
                                            cols_1.push(moment(key.value).format('DD/MM/YYYY'))
                                        }else{
                                            cols_1.push(key.value)                               
                                        }
                                    }else{
                                        cols_1.push('')
                                    }
                                   // (r in tasks[i].risk_additional_val) ? cols_1.push() : cols_1.push('') ;
                                }
                                                  
                        }else{
                            for(let r in risk_area_additional_fields){
                                cols_1.push('') ;
                            }                       
                        }             
                    }

                    arr = [...cols_1,...cols_2];                
                }
                
                if(Object.keys(risk_area_additional_fields).length===0 && Object.keys(subcat_additional_fields).length>0){
                    let cols_1 = [i,activity_name,description,risk_area,risk_owner,risk_impact,subcat_name];
                    let cols_2 = [Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status,ta_act_undrtkn,rag,require_rem,num_of_remedial];
                   	
                    if(show_testing_comments_status === false){
                       cols_2 = [Frequency,assign_to,due_date,testing_objectives,testing_method,additional_guidance,attachments,status]; 
                    }

                    if(tasks[i].additional_fields.length > 0 && tasks[i].version === 1){
                        if(Object.keys(tasks[i].additional_fields[0].subcat).length > 0){
                            for(let r in subcat_additional_fields){
                                
                                let key = Object.values(tasks[i].additional_fields[0].subcat).filter(function (ad) { return ad.name === r })[0]
                                if(key){
                                    if(key.type === 'Date'){
                                        cols_1.push(moment(key.value).format('DD/MM/YYYY'))
                                    }else{
                                        cols_1.push(key.value)                               
                                    }
                                }else{
                                    cols_1.push('')
                                }
                            }                      
                        }else{
                            for(let r in subcat_additional_fields){
                                cols_1.push('') ;
                            }                       
                        }   
                    }else{
                        if(tasks[i].subcat_additional_vals.length > 0){
                            for(let r in subcat_additional_fields){
                                
                                let key = tasks[i].subcat_additional_vals.filter(function (ad) { return ad.name === r })[0]
                                if(key){

                                    if(key.type === 'Date'){
                                        cols_1.push(moment(key.value).format('DD/MM/YYYY'))
                                    }else{
                                        cols_1.push(key.value)                               
                                    }
                                }else{
                                    cols_1.push('')
                                }
                            }                      
                        }else{
                            for(let r in subcat_additional_fields){
                                cols_1.push('') ;
                            }                       
                        }                        
                    }

                    arr = [...cols_1,...cols_2];
                }

            dt.data.push(arr);
		   
        }
        let fileName = 'myactivitylist';
        let format1 = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format1);
        let file_name = fileName + '-' + tm ;
        dt.file_name = file_name;
        this.setState({download_excel: true , is_loader : false , xls_data: dt}); 


     }

    isBase64(str) {
        // console.log('isBase64::=>',str)
        if(typeof str === 'undefined'){ return false; }
        if (str ==='' || str.trim() ==='' || str === 'undefined'){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    changeStringToAtob = (str_value) => {
        let str_text = str_value === null ? '' : str_value;
        // console.log('str_text::',str_text,this.isBase64(str_text))
        if(this.isBase64(str_text)){
          str_text = window.decodeURIComponent(atob(str_text))
        }
        return str_text;
    }
    toggleExcel = () =>  {
        this.setState({download_excel: false});        
    }
    AlertHandler = (result, stack) => {
        this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
    }
  render()
  {
    console.log('TATESTACT  this.state', this.state)
    if(!this.state.ready)return <div/>
    let role = Store.getStoreData('role');
    let header_text = (role === 'team') ? `My Testing Activities : ${this.state.filter_start_date_to_show_in_table} - ${this.state.filter_end_date_to_show_in_table}` : `Testing Activities : ${this.state.filter_start_date_to_show_in_table} - ${this.state.filter_end_date_to_show_in_table}` ;

    return (
      <>
      <AlertBox alertParam={this.state.alert_param} />
        {
            (() => {
                if(this.state.is_loader){
                    return <div><CSLLoader style={{position: 'absolute'}}/></div>
                }
            })()
        }
       {
                (() => {
                    if(this.state.dev_modal !== null) {
                        switch(this.state.dev_modal) {
                            case 'ta' : return <TAModal 
                                                    ta={this.state.edit_object[0]} 
                                                    subcategory={null}
                                                    risk={this.state.tact_risk_area}
                                                    plan={this.state.plan}
                                                    closeModal={this.closeModal} 
                                                    reloadTestingactivity={this.reloadTestingactivity}/>;
                            case 'ta_action' : return <CompleteModal 
                                                    testing_activity={this.state.edit_object[0]} 
                                                    subcategory={null}
                                                    risk={this.state.tact_risk_area}
                                                    plan={this.state.plan}
                                                    unique_plan_id={this.state.edit_object[0].plan_ref_id}
                                                    gc_id={this.state.edit_object[0].gc_id}
                                                    closeModal={this.closeModal} 
                                                    reloadTestingactivity={this.reloadTestingactivity}
                                                    tact_task_id={this.state.tact_task_id}/>;
                            case 'ta_edit' : return <CompleteModal 
                                                    testing_activity={this.state.edit_object[0]} 
                                                    subcategory={null}
                                                    risk={this.state.tact_risk_area}
                                                    plan={this.state.plan}
                                                    unique_plan_id={this.state.edit_object[0].plan_ref_id}
                                                    gc_id={this.state.edit_object[0].gc_id}
                                                    closeModal={this.closeModal} 
                                                    reloadTestingactivity={this.reloadTestingactivity}
                                                    tact_task_id={this.state.tact_task_id}
                                                    editProp={true}/>;
                            default: return <div />
                        }
                    }
                })()
            }

      <div>
              
      <OverviewInfoBody>
        {
          (() =>{

                return(
                <div style={{float : "right" , marginRight: "5px" }}>
                   Show Completed Tasks &nbsp; <input type="checkbox" checked={this.state.show_completed_tasks} onChange={this.showCompletedTasks} value="1" />
                 </div>

              );
            })()
        }
        <div style={{clear: "both", marginTop:"5px"}}></div>
          {/*My Testing Activities*/}
              {/*<CSLTable 
                add={false} 
                processData={this.processTableData} 
                headerText={this.state.header_text} 
                tableRows={10}
              />*/}
                                        <CSLRegisterTable
                                        role={Store.getStoreData('role')}
                                        processData={this.processTableData}
                                        headerText={header_text}
                                        tableRows={10}
                                        data={reportData}
                                        filters={this.state.advancedFilterData}
                                        applyFilter={this.applyFilter}
                                        clearAdvancedFilter={this.clearAdvancedFilter}
                                        reportButton={true}
                                        export={true}
                                        task_length={this.state.tasks.length}
                                        exportxls={this.downloadTestingActivitiesExcelPre}
                                        toggle_state={this.state.toggle_state} 
                                        toggle_text={'Show All Activities'} 
                                        toggleCallback={this.toggleCallback}
                                      />
        </OverviewInfoBody>
                    {
                        (()=>{
                                if(this.state.download_excel){
                                    return (
                                        <div>
                                        <InactiveOverlay2 />
                                        <div style={{position:'fixed', top:'140px', width:'calc(100%-100px)', height: 'calc(100vh-180px)' , zIndex : 1001 , border: "solid 3px" , borderColor: "red"}}>
                                            

                                            <ExcelGenerator toggleExcel={this.toggleExcel} xls_data={this.state.xls_data}/>
                                        </div>
                                        </div>
                                    );
                                }
                        })()
                    }
      </div>
    </>);
  }
}

export default TestingActivityList;
