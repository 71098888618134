import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import moment from 'moment';
import {FaTimes ,FaPlusCircle , FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import CSLTextInput from './CSLTextInput';
import DatePicker from 'react-date-picker';
import './DateSelect.css';

const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
	font-weight: bold;
    margin-left: 35px;
    margin-top: 5px;
    font-family: Montserrat,sans-serif
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: "Roboto-Regular", "Roboto", sans-serif;
    color: #758597;      
    padding: 10px;
    font-weight: 600;
`;
const CSLDateCover = styled.div`   
    width: 85%;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: #758597; 
`;
const UserHr = styled.div`
	border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;


class CustomFields {

	generateField = (data, data_container,call_back) => {
		//console.log("generateField,",data, data_container,call_back);
		return (
			<div key={data.name}>
				<MRModalLabel>
				{
					(() => {
						return (<div style={{clear: "both" , paddingTop: "8px"}}></div>);
					})()
				}
				{
					(() => {
						if (data.required) {
							return (<div style={{marginLeft:"35px"}}>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "6px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
						} else {
							return <div style={{marginLeft:"35px"}}>{data.nickname}</div>;
						}
					})()
				}
				{
					(() => {
						return (<div style={{marginLeft:"35px" , clear: "both" , paddingTop: "8px"}}></div>);
					})()
				}
				</MRModalLabel>
				{
					(() => {
						let fieldhtml = <div ></div>;
						switch(data.type) {
							case "Alpha":
								fieldhtml = this.createAlphaField(data, data_container,call_back);
								break;
							case "Numeric":
								fieldhtml = this.createNumericField(data, data_container,call_back);
								break;    						
							case "Dropdown":
								fieldhtml = this.createDropdownField(data, data_container,call_back);
								break;                            
							case "Date":
								fieldhtml = this.createDateField(data, data_container,call_back);
								break;                            
							case "User List":
								fieldhtml = this.createUserListField(data, data_container,call_back);
								break;                            
							default:
								fieldhtml = fieldhtml;
						}
						if (data_container === "additional_fields")
						return fieldhtml;
					})()
				}
			</div>
		);
	}
	createAlphaField = (data, data_container,call_back) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" call_back={call_back}/>);        
    }

    createDateField = (data, data_container,call_back) => {
        // console.log("Data==>", data)
        let curValue = data.value !== '' ? new Date(data.value) : new Date();        
		return (<CSLDateCover id={"date-"+data.name} style={{display: "inline-block",marginLeft: "35px"}}>
                    <DatePicker
                            onChange={this.onChangeCustomDate(data.name,call_back)}
                            clearIcon={null}                            
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={curValue}
                            className="csldatecontainer"

                    />
                </CSLDateCover>);        
    }

    createUserListField = (data, data_container,call_back) => {        
    	return (           
        		<MRModalSelect id={"userlist-"+data.name} onChange={this.handleUserListField(data.type, data_container,call_back)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        this.state.conductRuleUserLists.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt.ID}>{opt.ContactName}</option>
        		            );
        		        })
        		    }                    
        		</MRModalSelect>
    	);
    }
	createNumericField = (data, data_container,call_back) => {
    	return (<MRModalInput id={"number-"+data.name} type="number" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container,call_back)} />);
    }

    createDropdownField = (data, data_container,call_back) => {
		// console.log("createDropdownField,",data,data_container);
    	return (
            <div style={{marginLeft: "35px", width: "85%"}}>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container,call_back)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>                                                
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value" call_back={call_back}/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

	handleTextChange = (obj,call_back) => {
		/*console.log("obj:", obj);		
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));		
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}		
        this.props.modifyAssignData(assign_data);*/
		let ret = {obj: obj , flag: 'TEXT_CHANGE'};
		call_back(ret);
	}
    onChangeCustomDate = (name,call_back) => (date) => {
        //event.preventDefault();        
        /*let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));  
        console.log("assign_data===>", assign_data) 
        assign_data.additional_fields.forEach((item) => {
            if (item.name === name) {
                item.value = date;
            }
        })        	
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.props.modifyAssignData(assign_data);
		*/

		let ret = {obj: [name , date] , flag: 'CUSTOM_DATE'};
		call_back(ret);
    }
	handleUserListField = (field_type, data_container,call_back) => (event) => {
        event.preventDefault();        
        /*let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        console.log("event.target.name===>", event.target.name)    
        console.log("assign_data===>", assign_data)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === event.target.name) {
                item.value = event.target.value;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)	
        this.props.modifyAssignData(assign_data);
		*/
		let ret = {obj: [field_type, data_container, event] , flag: 'USER_LIST'};
		call_back(ret);
    }
	handleAddlField = (field_type, data_container,call_back) => (event) => {
        event.preventDefault();
		let name = event.target.name;
		let value = event.target.value;
        //let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));    
        // console.log("event.target.name===>", event.target.name , event.target.value)   
        //console.log("assign_data===>", assign_data)    
        //console.log("data_container===>", data_container)    
        /*if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }	
        //console.log("ModifyAssigndata+++==>", assign_data)	
        //this.props.modifyAssignData(assign_data); 
		*/
		let ret = {obj: [field_type, data_container ,name , value] , flag: 'ADDITIONAL_FIELD'};
		call_back(ret);
    }
}

export default CustomFields;