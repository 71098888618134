import React from 'react';
import styled from 'styled-components';
import { FaEllipsisV } from "react-icons/fa";
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import CSLLoader from '../Common/CSLLoader';
import GCPanel from '../Includes/GCPanel';
import TestingActivityList from '../Includes/TestingActivityList';
import FollowupActionsList from '../Includes/FollowupActionsList';
import EventBus from '../../Common/EventBus.js';
import filters from '../RDR/reportFilters.js';

class TeamIndex extends React.Component
{
    state = {ready: false ,cur_view: null,testing_activities:[],risk_areas:[],gc_id:"0"};

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // console.log('TTTTEAT',this.props)
        EventBus.registerEvent('gc_clicked_team', 'TeamIndex', this.changeGC)
        EventBus.registerEvent('Tact_save_team', 'TeamIndex', this.tact_save_team)
        // EventBus.registerEvent('data_reload_team', 'TeamIndex', this.changeGC)
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
        // console.log('TTTTEAT1',this.props)
        this.setComponentState()
    }

    setComponentState = () => {
        console.log('TTTTEAT2')

        let api = new APICall()
        let gc_id = this.state.gc_id
        let postData = {command: 'plan', sub_command: 'list_test_activities', gc_id:gc_id }
        api.command(postData, this.processListTA)

   }
    processListTA = (result) =>{
        console.log("TEAMINDX::",result)

        let testing_activities = result.result.testing_activities
        let  risk_areas = result.result.risk_areas

        let plan_tasks = result.result.plan_tasks
        let tasks_by_id = {}
        for(let tid in plan_tasks) {
            let t = plan_tasks[tid]
            tasks_by_id[t.task_id.toString()] = t
        }

        Store.updateStore('plan_tasks', plan_tasks)
        Store.updateStore('tasks_by_id', tasks_by_id)
        Store.updateStore('module_config', result.result.module_config)
        Store.updateStore('testing_activities', testing_activities)
        Store.updateStore('risk_areas', risk_areas)
        Store.updateStore('team_plans', result.result.plans)
        Store.updateStore('team_gcid', this.state.gc_id)




        this.setState({ready: true,testing_activities,risk_areas});

    }
    tact_save_team = (gc_id) => {
        let api = new APICall()
        let gc_id_state = this.state.gc_id
        let postData = {command: 'plan', sub_command: 'list_test_activities', gc_id:gc_id_state }
        
        let that = this;

        api.command(postData, function callback(result){that.processReload(result,gc_id)});    

        // api.command(postData, this.processListTA)
    }
    processReload = (result,gc_id) =>{
        // alert("TeamGC:",gc_id)
        console.log("TEAMINDX::",gc_id,result)

        let testing_activities = result.result.testing_activities
        let  risk_areas = result.result.risk_areas
        
        let plan_tasks = result.result.plan_tasks
        let tasks_by_id = {}
        for(let tid in plan_tasks) {
            let t = plan_tasks[tid]
            tasks_by_id[t.task_id.toString()] = t
        }


        // Store.updateStore('team_gcid', gc_id.toString())

        Store.updateStore('plan_tasks', result.result.plan_tasks)
        Store.updateStore('tasks_by_id', tasks_by_id)
        Store.updateStore('module_config', result.result.module_config)
        Store.updateStore('testing_activities', testing_activities)
        Store.updateStore('risk_areas', risk_areas)
        Store.updateStore('team_plans', result.result.plans)

        this.setState({ready: true,testing_activities,risk_areas,gc_id});

    }
    changeGC = (gc_id) => {
        let api = new APICall()
        // let gc_id = gc_id
        let postData = {command: 'plan', sub_command: 'list_test_activities', gc_id:gc_id }
        
        let that = this;

        api.command(postData, function callback(result){that.processChangeGC(result,gc_id)});    

        // api.command(postData, this.processListTA)
    }
    processChangeGC = (result,gc_id) =>{
        // alert("TeamGC:",gc_id)
        console.log("TEAMINDX::",gc_id,result)

        let testing_activities = result.result.testing_activities
        let  risk_areas = result.result.risk_areas
        
        let plan_tasks = result.result.plan_tasks
        let tasks_by_id = {}
        for(let tid in plan_tasks) {
            let t = plan_tasks[tid]
            tasks_by_id[t.task_id.toString()] = t
        }


        Store.updateStore('team_gcid', gc_id.toString())

        Store.updateStore('plan_tasks', result.result.plan_tasks)
        Store.updateStore('tasks_by_id', tasks_by_id)
        Store.updateStore('module_config', result.result.module_config)
        Store.updateStore('testing_activities', testing_activities)
        Store.updateStore('risk_areas', risk_areas)
        Store.updateStore('team_plans', result.result.plans)
        Store.updateStore('ta_toggle_state',false)
        Store.updateStore('ta_advancedFilterData',null)
        // Store.updateStore('ta_advancedFilterData',JSON.parse(JSON.stringify(filters)))
        EventBus.raiseEvent('plan_selected',null)



        this.setState({ready: true,testing_activities,risk_areas,gc_id});

    }
    render()
    {   
        console.log("TEAMINDEX::",this.state)
        if (!this.state.ready) {
          return (<div><CSLLoader /></div>
          );
        }
        return (
            <div>
                <div style={{width: "100%", height: "46px", backgroundColor: "#E8F0F7", padding: "5px 15px", boxSizing: "border-box", marginBottom: "20px"}}>
                    <GCPanel />
                    <div style={{clear: "both"}}></div>
                </div>
                <div style={{clear: "both"}}></div>
                <TestingActivityList testing_activities={this.state.testing_activities} risk_areas={this.state.risk_areas} gc_id ={this.state.gc_id}/>
                <FollowupActionsList />

            </div>
        );
    }
}

export default TeamIndex;