import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import EventBus from '../../Common/EventBus.js';
import { FiEdit, FiChevronUp, FiChevronDown , FiChevronRight, FiChevronLeft , FiUndo } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";
import filters from '../RDR/reportFilters.js';
import CMPUtils from '../Common/CMPUtils.js';

const GCPanelBody = styled.div`
  background-color: rgb(232, 240, 247);
  width: calc(100% - 60px);
  padding: 0px 0px 0px 0px;
  margin-top: 5px;
  float: left;
`;
const GCPanelArrow = styled.div`
  background-color: rgb(232, 240, 247);
  width: 20px;
  padding: 0px 0px 0px 0px;
  margin-top: 5px;
  float: left;
  cursor: pointer;
`;

const GCPillsContainer = styled.div`
  background-color: rgb(232, 240, 247);
  width: calc(100% - 420px);
  height: 20px;
  padding: 0px 0px 0px 0px;
  margin-top: 2px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
`;

const ParentPill = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 400;
	font-size: 17px;
	color: #7ACAC9;
	cursor: pointer;
`
const ParentPillActive = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 700;
	font-size: 17px;
	color: #435870;
`

const GCPill = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 400;
	font-size: 15px;
	color: #7ACAC9;
	cursor: pointer;
`
const GCPillActive = styled.div`
	width: 120px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	font-weight: 700;
	font-size: 15px;
	color: #435870;
	cursor: pointer;
`
////
const RadioBlock = styled.div`
	/*margin-top: 10px;*/
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

const GCPanelSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    width: 24%;
    margin-right: 4px;
    position: absolute;
    left: 12px;
    height: 30px;
`;



class GCPanel extends React.Component {
	state = {gc_list: [], offset: 0, current_gcid: '0', gcs: null, gc_plans: null}
	constructor(props) {
	    super(props);
	}

	componentDidMount = () => {
	    this.setComponentState()
	}

	componentDidUpdate = (prev_props) => {
	    if(prev_props === this.props) return
	    this.setComponentState()
	}
	fieldCompare = (a,b) => {
    	// console.log('sortAnObject::fieldCompare',a,b)
        return a.company_name.localeCompare(b.company_name)
        // return a.sort_field.localeCompare(b.sort_field)
    }
	setComponentState = () => {
			let gc_plans = Store.getStoreData('gc_plans')
			let gc_companies = Store.getStoreData('gc_companies');
			let last_working_plan_info = CMPUtils.getPlanCookie();
			// console.log('last_working_plan_info::',last_working_plan_info)
			let gcs = {}
			let gc_list = []
			let is_first = true
			let current_gcid = '0'
			for(let gc of gc_companies) {
					let gc_id = (gc.is_gc) ? gc.id : '0';
					if(is_first) {
							is_first= false
							current_gcid = gc_id
							console.log('GCchange plans:: gc id::',gc_id,current_gcid)
							EventBus.raiseEvent('gc_clicked', current_gcid)
					}
					let company_name = gc.is_active === 1 ? gc.company_name : gc.company_name+" (Inactive)"
					gcs[gc_id] = {company_name: company_name, name: company_name, id: gc_id, is_gc: gc.is_gc}
					if(gc.is_active === 1){gc_list.push(gcs[gc_id])}
			}
			gc_list.sort(this.fieldCompare);
			if(last_working_plan_info !== null)current_gcid=last_working_plan_info.split('-')[2];
			// console.log('last_working_plan_info::current_gcid',last_working_plan_info,current_gcid)

        	Store.updateStore('current_gcid', current_gcid)

			this.setState({gc_list, current_gcid, gcs, gc_plans})
	}

	setComponentStateOld = () => {
		let gc_companies = Store.getStoreData('gc_companies')
		let gc_list = [];
		let company = {};
		let filter_gcs = this.state.filter_gcs;
		for(let gc of gc_companies) {
			let gc_id = (gc.is_gc) ? gc.id : 0;
			filter_gcs[gc_id] = (gc_id in filter_gcs) ? filter_gcs[gc_id] : 0;
			if(gc.is_active === 1){
				gc_list.push({company_name: gc.company_name, name: gc.company_name, id: gc_id, is_gc: gc.is_gc })
			}else{
				gc_list.push({company_name: gc.company_name+" (Inactive)", name: gc.company_name+" (Inactive)", id: gc_id, is_gc: gc.is_gc })
			}			
		 }
		gc_list.sort(this.fieldCompare);
		this.setState({gc_list:gc_list, offset: 0, filter_gcs: filter_gcs})
	}

	// changeGC = (current_gcid) => {
	// 	EventBus.raiseEvent('gc_clicked', current_gcid)
	// 	EventBus.raiseEvent('gc_clicked_team', current_gcid)

 
 //    	this.setState({current_gcid})
	// }
	changeGC = (event) => {
		let current_gcid = event.target.value
		console.log('current_gcid' , current_gcid)
        Store.updateStore('current_gcid',current_gcid)
        Store.updateStore('ta_toggle_state',false)
        Store.updateStore('ta_advancedFilterData',JSON.parse(JSON.stringify(filters)))
		EventBus.raiseEvent('gc_clicked', current_gcid)
		EventBus.raiseEvent('gc_clicked_team', current_gcid)


    	this.setState({current_gcid})
	}

	scrollPanel = (dir) => {
    	let len = this.state.gc_list.length
    	let offset = this.state.offset
    	switch(dir) {
    		case 'left': if(offset - 2 >= 0){
    						offset -= 2;
    					} else {
    						offset = 0
    					}
    					break;
    		case 'right': if(offset + 2 < len){
    						offset += 2;
    					} else {
    						offset = len - 2
    					}
    					break;
    	}
    	this.setState({offset: offset})
  }

	render() {
		if(this.state.gcs === null) return <div />
		let cur_gc = this.state.gcs[this.state.current_gcid]
		console.log('cur_gc::',this.state)
		console.log('cur_gc::',cur_gc)
		console.log('cur_gc::',this.state.gc_list)
		return (
			<div>
				<GCPanelBody>
					{/*<GCPanelArrow onClick={() => this.scrollPanel('left')}><FiChevronLeft /></GCPanelArrow>
					<GCPillsContainer>
						<GCPillActive title={cur_gc.name}>{cur_gc.name}</GCPillActive>
						{

							this.state.gc_list.map((gc, index) => {
								// console.log('index, gc', index, gc)
								if(index >= this.state.offset && gc.id !== this.state.current_gcid) {
									return <GCPill key={index} title={gc.name} onClick={() => this.changeGC(gc.id)}>{gc.name}</GCPill>
								}
							})
						}
					</GCPillsContainer>
					<GCPanelArrow onClick={() => this.scrollPanel('right')}><FiChevronRight /></GCPanelArrow>*/}
					<GCPanelSelect 
                                        name="gc_id"  onChange={this.changeGC} value={this.state.current_gcid}>
                                        {/*<option value={cur_gc.id}>{cur_gc.name}</option>*/}
                                        {
                                            this.state.gc_list.map((gc, index) => {
												//if(index >= this.state.offset && gc.id.toString() !== this.state.current_gcid.toString()) {
													return <option key={index} title={gc.name} value={gc.id} >{gc.name}</option>
												//}
                                                
                                            })
                                        }
                    </GCPanelSelect>
				</GCPanelBody>
			</div>
		)
	}
}

export default GCPanel;