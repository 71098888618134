import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FiEdit, FiChevronUp, FiChevronDown } from "react-icons/fi";
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall.js';
import CSLTable from '../Common/CSLTable.js';
import EventBus from '../../Common/EventBus.js';
import { MdEdit , MdDelete } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import AlertBox from '../Common/AlertBox.js';
import AddAction from '../MGR/Modals/AddAction.js';
import CompleteActionModal from '../MGR/Modals/CompleteActionModal.js';
import filters from '../RDR/reportFiltersRemedial.js';
import CSLRegisterTable from '../RDR/CSLRegisterTable';
import remReportData from '../RDR/remReportData';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

const colorwiseTextStatus = { '#04ADA8' : 'Completed' , 'blue' : 'In-Progress', '#F39C12' : 'Due' , 'red' : 'Overdue' };
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 10px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 2200px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -100px;
  z-index: 1001;
  opacity: 0.8;
`;
const RiskBtn = styled.button`
  background-color: #213E61;
  border: 1px solid #213E61;
  border-radius: 4px;
  padding: 9px 37px;
  color: #ffffff;
  cursor: pointer;
`;

const Box =  styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .2);
`;
const StatusButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  border-radius: 4px;
  padding: 4px;
  height : 25px;
  width : 90%;
  textAlign: center; 
  color : #FFFFFF;
`;
class FollowupActionsList extends React.Component
{
	state = { 
		ready : false ,
		tasks :[] ,
    risk_areas :[] ,
    plan_ref_id: null,
		pdf : false,
		show_completed_tasks: false,
    show_from_all_plans:false,
    	role:Store.getStoreData('role'),
		alert_param : null,
    dev_modal: null,
    edit_object: null,
    plan: null,
    task_id:0,
    parent_task_id:0,
    select_testing_activity:[],
    select_risk_area:[],
    filter_start_date: moment().format("DD/MM/YYYY") , 
    filter_end_date: moment().format("DD/MM/YYYY") , 
    future_days : moment().unix() , 
    past_days : moment().unix(), 
    advancedFilterData: {},
    filters : JSON.parse(JSON.stringify(filters)) , 
    applied_filters: JSON.parse(JSON.stringify(filters)),
    toggle_state : false,
    selected_plan_ref: '0'
	}

	componentDidMount = () => {
    EventBus.registerEvent('plan_selected', 'FollowupActionsList', this.planSelected)
    EventBus.registerEvent('gc_clicked', 'FollowupActionsList', this.gc_clicked)
    EventBus.registerEvent('Followup_updated', 'FollowupActionsList', this.Followup_updated)

	    this.setComponentState()
	}

  gc_clicked = (gc_id) => {
    this.setState({tasks:[],plan_ref_id:null,risk_areas:[],testing_activities:[]}) 
    //this.setComponentState()
   
  }
  Followup_updated = ()=>{
    this.processSaveAction()
  }
  planSelected = (plan) => {
    //console.log('plan_selected::',plan)
    if(plan !== null){
       this.setState({plan,plan_ref_id: plan.unique_plan_id,risk_areas:Store.getStoreData('risk_areas'),testing_activities:plan.testing_activities,
                        filter_start_date: moment().format("DD/MM/YYYY") , 
                        filter_end_date: moment().format("DD/MM/YYYY") , 
                        future_days : moment().unix() , 
                        past_days : moment().unix(), 
                        advancedFilterData: {},
                        filters : JSON.parse(JSON.stringify(filters)) , 
                        applied_filters: JSON.parse(JSON.stringify(filters)),
                        toggle_state : false
                      })
    }else{
       this.setState({plan:null,plan_ref_id: null,risk_areas:[],testing_activities:[]}) 
    }
   
    this.setComponentState()
  }
	componentDidUpdate = (prev_props) => {
	    if(prev_props === this.props) return
	        
	    this.setComponentState()
	}
  processSaveAction = () =>{
    // console.log("RA:: Props::",this.state)
    if(this.state.plan_ref_id !== null){
        EventBus.raiseEvent('data_reload', this.state.plan_ref_id)
      }

     if(this.state.show_from_all_plans){
      this.showFromAllPlans()
     }else{
      if(this.state.show_completed_tasks){
        this.showCompletedTasks()
      }else{
        this.setComponentState()
      }

     }
  }
	setComponentState = () => { 
	    let api = new APICall();
        let postData = { command: "list_followup_tasks"};
        postData['role'] = Store.getStoreData('role')
        postData['gcs_tasks'] = Store.getStoreData('gcs_tasks')
        postData['show_completed_tasks'] = this.state.show_completed_tasks
     	  postData['plan_ref'] = this.state.plan !== null ? this.state.plan_ref_id : null
      	let plan_gc_id = Store.getStoreData('plan_gc_id')
      	postData['plan_gc_id'] = plan_gc_id
        if(Store.getStoreData('role') === 'team'){
          postData['team_gc'] = Store.getStoreData('team_gcid')
        }
        // console.log(postData)
        api.command(postData, this.processTasks);
	}
    processTasks = (result) => {
      // console.log('processTasks followup:: 1')
      let filters_init = JSON.parse(JSON.stringify(filters));
      // console.log('processTasks followup:: 2')
      let today = moment();
      today = today.set('hour', 0).set('minute', 0).set('second', 0);
      let future_days = moment().add(1, 'years');
      let filter_end_date = future_days.format("DD/MM/YYYY");
      future_days = future_days.set('hour', 23).set('minute', 59).set('second', 59);
      let future_js_date = future_days.toDate();
      future_days = future_days.unix();
      let past_days = moment().subtract(1, 'years');
      let filter_start_date = past_days.format("DD/MM/YYYY");
      past_days = past_days.set('hour', 0).set('minute', 0).set('second', 0);
      let past_js_date = past_days.toDate();
      past_days = past_days.unix();
      today = today.unix();

      filters_init.due_date.options[past_js_date] = {name : filter_start_date};
      filters_init.due_date.options[future_js_date] = {name : filter_end_date};
      if(Object.keys(this.state.advancedFilterData).length > 0){
            if(Object.keys(this.state.advancedFilterData.due_date.selections).length > 0){
               filter_start_date = this.state.advancedFilterData.due_date.selections.from_date;
               filter_end_date = this.state.advancedFilterData.due_date.selections.to_date;
            }
      }
      let role = Store.getStoreData('role')
      let risk_areas =  role === 'team' ? result.result.risk_areas : this.state.risk_areas
      let testing_activities = role === 'team' ? result.result.testing_activities : this.state.testing_activities
// console.log('processTasks followup:: 3')
      this.setState({
        filter_end_date : filter_end_date , 
        filter_start_date : filter_start_date ,
        filter_end_date_to_show_in_table : filter_end_date , 
        filter_start_date_to_show_in_table : filter_start_date ,        
        future_days: future_js_date , 
        past_days: past_js_date , 
        future_days_unix: future_days , 
        past_days_unix: past_days , 
        filters: filters_init , 
        ready : true,
        tasks:result.result.raw_tasks,
        risk_areas,
        testing_activities
      });
        //console.log("FOLLOWUP TAKEY processTasks", result)
        // this.setState({tasks:result});
    }
    processTasksAllPlan = (result) => {
        // console.log("FOLLOWUP TAKEY processTasks", result)
        this.setState({tasks:result,show_from_all_plans:true,show_completed_tasks:false});
    }
    showCompletedTasks = (e) => {
		e.persist();

		let role = Store.getStoreData('role')
		if(role !== 'team' && this.state.plan === null){
			let alert_param = {title: 'ERROR', message: 'Please select a Plan.', ok_text: 'Ok', confirm: false, 
                      alertHandler: this.AlertHandler, stack: {}};
              this.setState({alert_param});
		}else{
	        let show_completed_tasks = this.state.show_completed_tasks? false : true; 
	        this.setState({show_completed_tasks: show_completed_tasks,show_from_all_plans:false});
	        let api = new APICall();
	        let postData = { command: "list_followup_tasks"};
	        postData['role'] = Store.getStoreData('role')
	        postData['gcs_tasks'] = Store.getStoreData('gcs_tasks')
	        postData['show_completed_tasks'] = show_completed_tasks
	        postData['plan_ref'] = this.state.plan !== null ? this.state.plan_ref_id : null
	        let plan_gc_id = Store.getStoreData('plan_gc_id')
	        postData['plan_gc_id'] = plan_gc_id

	        if(role === 'team'){
	          postData['team_gc'] = Store.getStoreData('team_gcid')
	        }
	        api.command(postData, this.processTasks);
	    }
    }
    showFromAllPlans = () => {

        let show_from_all_plans = this.state.show_from_all_plans ? false : true; 
        //this.setState({show_from_all_plans: show_from_all_plans})
        if(show_from_all_plans){
                let api = new APICall();
                //this.setState({show_from_all_plans: show_from_all_plans})
                //let show_completed_tasks = this.state.show_completed_tasks; 
        
                let postData = { command: "list_followup_tasks_all_plan"};
                postData['role'] = Store.getStoreData('role')
                postData['gcs_tasks'] = Store.getStoreData('gcs_tasks')
                //postData['show_completed_tasks'] = show_completed_tasks
                //postData['plan_ref'] = this.state.plan !== null ? this.state.plan_ref_id : null
                let plan_gc_id = Store.getStoreData('plan_gc_id')
                postData['plan_gc_id'] = plan_gc_id
                api.command(postData, this.processTasksAllPlan);
        }else{
          this.setState({show_from_all_plans: show_from_all_plans,show_completed_tasks:false})
          this.setComponentState()

        }
    }
  compareDateGetColorRemedial = (due_date,cur_lane) => {
    // console.log("color::==>"+cur_lane);
      let color_code = '#04ADA8'
      let scheduled_date = moment(due_date, 'YYYY/MM/DD');
      let month = scheduled_date.format('M');
      let year  = scheduled_date.format('YYYY');

      let curr_month = moment().month()+1;
      let curr_year  = moment().year();

      // let current_date = moment().unix();
      // let due_date_unix =  moment(due_date).unix();
      let current_date = moment().format('YYYY-MM-DD');
      let due_date_unix =  moment(due_date).format('YYYY-MM-DD');

      if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
        color_code = "#04ADA8"; // green
      }else{
        if(due_date_unix >= current_date){
          if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED'){
            color_code = "blue"; // Scheduled
          }else{
            if(curr_month.toString() === month && curr_year.toString() === year){
              color_code = "#F39C12"; // due
            }else{
              color_code = "blue"; // Scheduled
            }
          }
        }else{
          color_code = "red"; // overdue
        }
      }

    //return color_code;
    //if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED' && color_code === "#F39C12")color_code='blue';
    return color_code;
  }

    processTableData = () => {
      let tasks = this.state.tasks;
      tasks.sort(function(a, b){return new Date(a.due_date) - new Date(b.due_date);})
      let all_companies = Store.getStoreData('gc_companies')
      let all_contacts = Store.getStoreData('contacts')
      let role = this.state.role;
      let userId = Store.getStoreData('user_info')?.ID
      let testing_activities = this.state.testing_activities
      let mod_conf = Store.getStoreData('module_config')

      let action_column_width = 200 ; //for admin
      if(role === 'risk_owner') action_column_width = 175;
      if(role === 'team') action_column_width = 130;

      let ret = {data: [], columns: []};
      let i = 1;
      if(tasks.length > 0 && typeof testing_activities !== 'undefined'){ 
        ret.columns =[
                        {Header: 'Activity', id: 'activity' , accessor: 'activity', minWidth: 270, headerStyle: {textAlign: 'left'}},      
                        {Header: 'Title', accessor: 'title', minWidth: 170, headerStyle: {textAlign: 'left'}},      
                        {Header: 'Description', accessor: 'description', minWidth: 140, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 150, headerStyle: {textAlign: 'left'}},
                        {Header: 'Created By', accessor: 'created_by', minWidth: 150, headerStyle: {textAlign: 'left'}},
                        {Header: 'Due Date', accessor: 'due_date_timestamp', Cell: c => <div style={{borderRadius: "4px" , padding: "4px", height : "25px" ,width : "100%" , textAlign: 'center' , color : "#111111"}}>{c.original.due_date}</div>, minWidth: 120, headerStyle: {textAlign: 'left'},
                        sortType: 'datetime'},
                        {Header: 'Status', id: 'colorwise_text' , accessor: 'colorwise_text' , Cell: c => <div style={{borderRadius: "4px" , padding: "4px", height : "25px" ,width : "100%" , background: c.original.color , textAlign: 'center' , color : "#FFFFFF"}}>{c.original.colorwise_text}</div> , minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Action', Cell: row => (
                          <div>
                            {
                              (() => {
                                if(row.original?.risk_status !== 'Active'){
                                  return (<MRButton style={{ backgroundColor: "grey", float: "left",paddingLeft:"23px",marginLeft: "45px"}}>Action</MRButton>);
                                }

                                if(role === 'view_only_manager' && parseInt(row.original?.cur_assigned_to) !==  userId && row.original?.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE'){
                                  return (<MRButton style={{ backgroundColor: "grey", float: "left",paddingLeft:"3px",marginLeft: "23px"}}>Action</MRButton>);
                                }
                                
                                if(row.original?.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE'){
                                  return (<div style={{float: "left", paddingLeft:"23px", cursor: 'pointer'}}><MRButton onClick={this.openFollowupCompleteModal('ta_action', row.original?.task_id, row.original?.parent_task_id, row.original?.task, row.original?.risk_area, row.original?.testing_activity, row.original?.cur_assigned_to,row.original?.risk_owner_id, row.original?.subcat_owner_id)} >Action</MRButton></div>);
                                }
                            })()
                          }
                            {
                                (() => {
                                  
                      return (
                        <div style={{float: "right"}}>

                          {
                            (() => {
                              if(row.original.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE' && row.original.risk_status === 'Active' && (role === 'admin_manager' || role === 'plan_admin' )){
                                return (
                                  <div style={{float: "right", marginTop : "4px"}}>
                                    <ViewDiv style={{ curser: "pointer", color : "#1A3552" }} onClick={ this.deleteConfirm(row.original.task_id)}   title="delete this remedial action" ><MdDelete /></ViewDiv>
                                  </div>
                                );                                
                              }

                            })()
                          }
                          {
                            (() => {
                              if(row.original.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE' && row.original.risk_status === 'Active' && (role === 'admin_manager' || role === 'plan_admin')){
                                return(
                                  <div style={{float: "right", marginRight : "15px" , marginTop : "4px"}}>
                                    <ViewDiv style={{ curser: "pointer",color : "#1A3552" }} onClick={() => this.viewAction_pre("ra",row.original.task)}   title="edit this remedial action"><MdEdit /></ViewDiv>
                                  </div>
                                );                                
                              }
                            })()
                          }
                          {
                            (() => {
                              if(row.original?.cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE' && row.original?.risk_status === 'Active'){
                                let margin_right = '29px';
                                if(role === 'risk_owner')margin_right = '15px';
                                if(role === 'view_only_manager' && parseInt(row.original?.cur_assigned_to) !==  userId){
                                    return(
                                        <div style={{float: "right", marginRight : margin_right , marginTop : "4px"}}>
                                          <ViewDiv style={{ curser: "pointer",color : "grey" }} title="view this remedial action" ><FaEye /></ViewDiv>
                                        </div>
                                    );    

                                }
                                return(
                                    <div style={{float: "right", marginRight : margin_right , marginTop : "4px"}}>
                                      <ViewDiv style={{ curser: "pointer", color : "#1A3552" }} onClick={() => this.viewAction_pre("ra",row.original?.task)}   title="view this remedial action" ><FaEye /></ViewDiv>
                                    </div>
                                    );                                

                              }
                            })()
                          }

                        </div>);
                                })()
                            }
                          </div>
                        ), width: action_column_width, headerStyle: {textAlign: 'center'}},
                      ];


                      let stdt = this.state.filter_start_date_to_show_in_table.split('/')
                      let endt = this.state.filter_end_date_to_show_in_table.split('/')

                      let strt_from = moment(stdt[2] + '/' + stdt[1] + '/' + stdt[0]).unix()
                      let end_at  =  moment(endt[2] + '/'+ endt[1] + '/' + endt[0]).unix()
                      let activity_list = []
                      let assgnto = []
                      let title = []

                      if(Object.keys(this.state.advancedFilterData).length > 0){
                          if(Object.keys(this.state.advancedFilterData.activity.selections).length > 0){
                                activity_list = Object.keys(this.state.advancedFilterData.activity.selections)
                          }
                          
                          if(Object.keys(this.state.advancedFilterData.assigned_to.selections).length > 0){
                                assgnto = Object.keys(this.state.advancedFilterData.assigned_to.selections)
                          }
                          
                          if(Object.keys(this.state.advancedFilterData.title.selections).length > 0){
                                title = Object.keys(this.state.advancedFilterData.title.selections)
                          }
                          
                          if(Object.keys(this.state.advancedFilterData.due_date.selections).length > 0){
                              stdt = this.state.advancedFilterData.due_date.selections.from_date.split('/')
                              endt = this.state.advancedFilterData.due_date.selections.to_date.split('/')
                              strt_from = moment(stdt[2] + '/' + stdt[1] + '/' + stdt[0]).unix()
                              end_at = moment(endt[2] + '/'+ endt[1] + '/' + endt[0]).unix()
                          }
                      }


        for(let m = 0 ; m <tasks.length; m++){
                      // console.log("FOLLOWUP DATESELECT ",moment(tasks[m].due_date).unix(),strt_from,end_at)

          let include = true;
          if(strt_from <= moment(tasks[m].due_date).unix() && end_at >= moment(tasks[m].due_date).unix() ){

            if(typeof this.state.risk_areas !== "undefined"){
                      let color = this.compareDateGetColorRemedial(tasks[m].due_date,tasks[m].cur_lane);
                      // console.log('FOLLOWUP TAKEY state::',this.state)

                      // console.log('FOLLOWUP TAKEY TASKS::',tasks[m])

                      if(activity_list.length > 0 && !activity_list.includes(tasks[m].testing_act_id.toString())){
                        include = false;
                      }


                      if(assgnto.length > 0 && !assgnto.includes(all_contacts[tasks[m].cur_assigned_to])){
                        include = false;
                      }

                      if(title.length > 0 && !title.includes(tasks[m].name.toString())){
                        include = false;
                      }


                      // console.log('color::colorwiseTextStatus',colorwiseTextStatus,testing_activities)
                      const company = tasks[m].company_id > 100000 ? (tasks[m].company_id % 100000) : tasks[m].company_id;
                      const comp_key = Object.keys(all_companies).find(x => all_companies[x].id === company);
                      let ta_key = []
                      let risakarea_key = []
                      if(this.state.show_from_all_plans){
                        ta_key=tasks[m].ta_key
                      }else{
                        ta_key = testing_activities.filter(function (ta) { 
                          // console.log('FOLLOWUP TAKEY filter',ta.unique_testing_act_id,tasks[m].testing_act_id )
                          return ta.unique_testing_act_id === tasks[m].testing_act_id 
                        });

                      }
                      // let ta_key = testing_activities.filter(function (ta) { return ta.unique_testing_act_id === tasks[m].testing_act_id });
                      // console.log("FOLLOWUP TAKEY res",ta_key)
                      if(ta_key.length !== 0 ){
                        // console.log("ta_key::",ta_key)
                        if(this.state.show_from_all_plans){
                          risakarea_key = tasks[m].ra_key
                        }else{
                          // if(Store.getStoreData('role') === 'team'){
                          //   risakarea_key = Store.getStoreData('risk_areas').filter(function (risk) { return risk.unique_risk_id === ta_key[0].unique_risk_id });
                          // }else{
                            risakarea_key = this.state.risk_areas.filter(function (risk) { return risk.unique_risk_id === ta_key[0].unique_risk_id });
                          // }
                        }
                        // let risakarea_key = this.state.risk_areas.filter(function (risk) { return risk.unique_risk_id === ta_key[0].unique_risk_id });
                      if(risakarea_key.length > 0){
                        // console.log("FOLLOWUP RAKEY",risakarea_key,ta_key)
                        let subcat_key = ta_key[0].unique_subcat_id.toString() === '0' ? [] : risakarea_key[0].subcategories.filter(function (sub) { return sub.unique_subcat_id === ta_key[0].unique_subcat_id });
                         // console.log("FOLLOWUP RAKEY",risakarea_key,ta_key,subcat_key)

                        const due_date = new Date(tasks[m].due_date);
                        
                        const yyyy = due_date.getFullYear();
                        let mm = due_date.getMonth() + 1; // Months start at 0!
                        let dd = due_date.getDate();

                        if (dd < 10) dd = '0' + dd;
                        if (mm < 10) mm = '0' + mm;

                        const formatteddue_date = dd + '/' + mm + '/' + yyyy;
                        
                        let status = (tasks[m].cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE') ? 'Complete' : 'Incomplete' ;

                        let risk_impact_key = 'risk_impact' in risakarea_key[0] ? mod_conf.risk_impact.filter(function (risk) { return risk.id === risakarea_key[0].risk_impact }):[];
                        let risk_impact_nm = risk_impact_key.length > 0 ? risk_impact_key[0].name : '';
                        let action_note_undertaken = tasks[m].result.length > 0 ? tasks[m].result[0].action_note_undertaken : ''
                        let risk_status = risakarea_key.length > 0 ? risakarea_key[0].status : '';

                        if(role === 'view_only_manager'){
                          let user_id = Store.getStoreData('user_info')?.ID
                          
                          if(parseInt(ta_key[0].testing_owner ) !== parseInt(user_id) && parseInt(tasks[m].cur_assigned_to ) !== parseInt(user_id)){
                            include = false
                          }
                        }
                        if(include){
                            let elem = {'index': m,
                            'activity': ta_key[0].testing_activity,
                            'title' : tasks[m].name,
                            'description' : tasks[m].description,
                            'assigned_to' : all_contacts[tasks[m].cur_assigned_to],
                            'cur_assigned_to' : tasks[m].cur_assigned_to,
                            'created_by' : all_contacts[tasks[m].contact_id],
                            'due_date' : formatteddue_date,
                            'due_date_timestamp' : moment(due_date).unix(),
                            'colorwise_text' :  colorwiseTextStatus[color],
                            'color' : color,
                            'cur_lane' : tasks[m].cur_lane,
                            'task_id' : tasks[m].task_id,
                            'parent_task_id' : tasks[m].parent_task_id,
                            'task':tasks[m],
                            'risk_area' : risakarea_key,
                            'risk_owner_id' : risakarea_key.risk_owner_id,
                            'subcat_owner_id' : subcat_key.length > 0 ? subcat_key[0].subcat_assignto : '0',
                            'risk_status' : risk_status,
                            'testing_activity':ta_key,
                            'unique_plan_id': tasks[m].plan_ref,
                            'unique_testing_act_id' : tasks[m].testing_act_id,
                            'status':status,
                            'risk_impact' : risk_impact_nm,
                            'risk_area_name' : risakarea_key[0].risk_area_name,
                            'completed_by': (typeof tasks[m].completed_by === 'undefined' || tasks[m].completed_by === 0) ? '-' : all_contacts[tasks[m].completed_by],
                            'completed_date': tasks[m].completed_date,
                            'action_note_undertaken' : action_note_undertaken

                
                            }
                            ret.data.push(elem);
                          }
                        }
                      }
  	         }
         }
          //
          //`<div style="border-radius: 4px; padding: 4px; height: 25px; width: 100%; background: ${color}; text-align: center; color: rgb(255, 255, 255);">${colorwiseTextStatus[color]}</div>`
        }
    }
      return ret;
    }
    openFollowupCompleteModal =(dev_modal,task_id,parent_task_id,task,risk_area,testing_activity,cur_assigned_to,risk_owner_id,subcat_owner_id)=>(event) =>{
      event.preventDefault();
      let role = Store.getStoreData('role')
      let user_id = Store.getStoreData('user_info').ID  
      // console.log("openFollowupCompleteModal::",cur_assigned_to.toString() !== user_id.toString())
      // console.log("openFollowupCompleteModal::",cur_assigned_to,user_id)
      // console.log("openFollowupCompleteModal::",testing_activity.testing_owner,testing_activity.created_by)
      // console.log("openFollowupCompleteModal::",(role === 'risk_owner' && (testing_activity.testing_owner !== user_id.toString() && testing_activity.created_by !==  user_id.toString() && cur_assigned_to.toString() !== user_id.toString())))
      // console.log("openFollowupCompleteModal::",(role === 'subcat_owner' && (testing_activity.testing_owner !== user_id.toString() && testing_activity.created_by !==  user_id.toString() && cur_assigned_to.toString() !== user_id.toString())))
      if((role === 'risk_owner' && (testing_activity.testing_owner !== user_id.toString() && testing_activity.created_by !==  user_id.toString() && cur_assigned_to.toString() !== user_id.toString() && risk_owner_id.toString() !== user_id.toString())) || 
        (role === 'subcat_owner' && (testing_activity.testing_owner !== user_id.toString() && testing_activity.created_by !==  user_id.toString() && cur_assigned_to.toString() !== user_id.toString() && subcat_owner_id.toString() !== user_id.toString())) ) {
          
              let alert_param = {title: 'ERROR', message: 'You have no permission to access this Remedial Actions.', ok_text: 'Ok', confirm: false, 
                      alertHandler: this.AlertHandler, stack: {}};
              this.setState({alert_param,disabled_button: false});
          
      }else{
        this.setState({dev_modal, edit_object:task,select_risk_area:risk_area,task_id:task_id,parent_task_id:parent_task_id,select_testing_activity:testing_activity})
      }

  }

  AlertHandler = (result, stack) => {
        this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
  }

	deleteConfirm = (task_id) => (event) => {
	    event.preventDefault();
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this action?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
						   alertHandler: this.deleteProcessor, stack: {task_id: task_id}}
		this.setState({alert_param: alert_param})
	}

	deleteProcessor = (result, stack) => {
		if(!result || stack.prevent) {
			this.setState({alert_param: null})
			return
		}
		let task_id = stack.task_id;
		// console.log("deleteProcessor task_id,",task_id);
		let postData = { command: "delete_followup_task", task_id: task_id };
		let api = new APICall();
		api.command(postData, this.processDeleteAction);
		this.setState({alert_param: null})
	}

	
	processDeleteAction = (result ) =>{
		 this.processSaveAction();
	}

	viewAction_pre = (dev_modal,edit_object) => {
	    this.setState({dev_modal,edit_object});
  	}

  closeModal = () => {
      this.setState({dev_modal: null, edit_object: null,tact_risk_area:null})
      this.processSaveAction();
  }
   applyFilter = (filters) => {
    let applied_filters = JSON.parse(JSON.stringify(filters))
    // console.log("applied_filters", applied_filters);
    let filter_start_date_to_show_in_table = (typeof applied_filters.due_date.selections.from_date !== 'undefined') ? applied_filters.due_date.selections.from_date : this.state.filter_start_date;
    let filter_end_date_to_show_in_table = (typeof applied_filters.due_date.selections.to_date !== 'undefined') ? applied_filters.due_date.selections.to_date: this.state.filter_end_date;
    this.setState({applied_filters , filter_start_date_to_show_in_table , filter_end_date_to_show_in_table , completed_tasks_clicked : false})
  }
 clearAdvancedFilter = () => {
    let applied_filters = JSON.parse(JSON.stringify(filters));
    applied_filters.due_date.selections = {};

    let filters_state = JSON.parse(JSON.stringify(this.state.filters));
    filters_state.due_date.selections = {from_date : this.state.filter_start_date , to_date : this.state.filter_end_date};
    let filter_start_date_to_show_in_table = this.state.filter_start_date;
    let filter_end_date_to_show_in_table = this.state.filter_end_date;
    this.setState({applied_filters , filters : filters_state , filter_start_date_to_show_in_table ,filter_end_date_to_show_in_table}) 
 }
  toggleCallback = (event) => {
    event.preventDefault();
    this.setState({is_loader : true});
    setTimeout(() => {
      this.trackToggleProgress()
    }, 30);

  }
  trackToggleProgress = () => {
    let {toggle_state} = this.state;
    toggle_state = toggle_state ? false : true;

    let records = this.processTableData().data;
    let advancedFilterData = this.populateFilterData(records);

    this.setState({toggle_state , advancedFilterData,is_loader : false});
  }
     populateFilterData = (records) => {

  let filterData = JSON.parse(JSON.stringify(this.state.filters));
      for(let r of records) {
         filterData.activity.options[r.unique_testing_act_id] = {name: r.activity};    
         filterData.title.options[r.title] = {name: r.title};    
         //filterData.risk_area.options[r.unique_risk_id] = {name: r.risk_area};    
         //filterData.subcat_name.options[r.unique_subcat_id] = {name: r.subcat_name};    
         //filterData.company.options[r.company_id] = {name: r.company};    
         filterData.assigned_to.options[r.assigned_to] = {name: r.assigned_to};    
         //filterData.colorwise_text.options[r.colorwise_text] = {name: r.colorwise_text};    
         //filterData.risk_owner.options[r.risk_owner_id] = {name: r.risk_owner_name};    
         //filterData.risk_impact.options[r.risk_impact] = {name: r.risk_impact};    

      }
    let future_days = this.state.future_days;
    let past_days = this.state.past_days;
    filterData.due_date.options[future_days] = {name: this.state.filter_end_date};    
    filterData.due_date.options[past_days] = {name: this.state.filter_start_date};    
         
    return filterData;
  }
  downloadActionsExcel = () =>  {
      let actions = this.processTableData().data;
      let contact_list = Store.getStoreData('contacts');            
      let mod_conf = Store.getStoreData('module_config')

              

      // console.log("downloadActionsExcel::",actions)
      let filtered_actions = [];

      for(let m = 0 ; m < actions.length ; m++) 
      {  

        let completed_date = '-';
        if(actions[m].completed_date !== null){
          completed_date = (actions[m].completed_date.split('T')[0].toString().length === 10) ? moment(actions[m].completed_date).format("DD/MM/YYYY") : moment(`${actions[m].completed_date}000`).format("DD/MM/YYYY");
        }
        completed_date = (completed_date === 'Invalid date') ? '-' : completed_date;

        let elem = {
          'task id': actions[m].task_id,
          'Remedial Action Task Title': actions[m].title,
          'Remedial Action Task Description': actions[m].description,
          'Assigned to': actions[m].assigned_to,
          'Created by': actions[m].created_by,
          'Due date': actions[m].due_date,
          'Testing Activity': actions[m].activity,
          'Risk Area': actions[m].risk_area_name,
          'Risk Impact': actions[m].risk_impact,
          'Status' : actions[m].status,
          'Completion Comments': actions[m].action_note_undertaken,
          'Completed By': (typeof actions[m].completed_by === 'undefined') ? '-' : actions[m].completed_by,
          'Completion Date': completed_date,

        };

        filtered_actions.push(elem);
      }
      // console.log("filtered_actions,",filtered_actions);
      let fileName = 'remedial_actions_list';

      const format1 = "DD-MM-YYYY HH:mm:ss";
      let tm = moment().format(format1);

      const ws = XLSX.utils.json_to_sheet(filtered_actions);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);  
  }
	render()
	{ 
    // console.log("FollowuplistState::",this.state)
    let role = Store.getStoreData('role');
    let header_text = (role === 'team') ? `My Remedial Actions : ${this.state.filter_start_date_to_show_in_table} - ${this.state.filter_end_date_to_show_in_table}` : `Remedial Actions : ${this.state.filter_start_date_to_show_in_table} - ${this.state.filter_end_date_to_show_in_table}` ;
    
		return (
			<>
      <AlertBox alertParam={this.state.alert_param} />
       		{
                (() => {
                    if(this.state.dev_modal !== null) {
                        switch(this.state.dev_modal) {
                            case 'ra' : return <AddAction 
                                                    followup_task={this.state.edit_object}
                                                    closeModal={this.closeModal} 
                                                    refreshAddActionValueTask={this.closeModal}
                                                    //plan_ref_id={this.state.plan_ref_id}
                                                    task_id={this.state.edit_object.task_id}
                                                    parent_task_id={this.state.edit_object.parent_task_id} />;
                                                    break;
                            case 'ta_action' : return <CompleteActionModal 
                                                    followup_task={this.state.edit_object}
                                                    testing_activity={this.state.select_testing_activity} 
                                                    subcategory={null}
                                                    risk={this.state.select_risk_area}
                                                    //plan={this.state.plan}
                                                    closeModal={this.closeModal} 
                                                    processSaveAction={this.processSaveAction}
                                                    task_id={this.state.task_id}
                                                    parent_task_id={this.state.parent_task_id}/>; 
                                                    break;
                            default: return <div />
                        }
                    }
                })()
            }

     
			<div>
				<OverviewInfoBody>
					{
						(() =>{
                  if(Store.getStoreData('role') === "team"){
                    return(
                      <div style={{float : "right" , marginRight: "5px" }}>
                         Show Completed Actions &nbsp; <input type="checkbox" name='task_change' checked={this.state.show_completed_tasks} onChange={this.showCompletedTasks} value="1" />
                        </div>
                    );
                  }else{
                    return(
                      <div style={{float : "right" , marginRight: "5px" }}>
                         Show Completed Tasks &nbsp; <input type="checkbox" name='task_change' checked={this.state.show_completed_tasks} onChange={this.showCompletedTasks} value="1" />
					  {/*&nbsp; Show Actions From All Plans &nbsp; <input type="checkbox" name='task_change' checked={this.state.show_from_all_plans} onChange={this.showFromAllPlans} value="1" />*/}
                         
                      </div>
                    );                   
                  }

							})()
					}
					<div style={{clear: "both", marginTop:"5px"}}></div>
						{/*Show All Actions*/}
		            {/*<CSLTable 
		                add={false} 
		                processData={this.processTableData} 
		                headerText={'Remedial Actions'} 
		                tableRows={10}
		              />*/}
                          <CSLRegisterTable
                            role={role}
                            processData={this.processTableData}
                            headerText={header_text}
                            tableRows={10}
                            data={remReportData}
                            filters={this.state.advancedFilterData}
                            applyFilter={this.applyFilter}
                            clearAdvancedFilter={this.clearAdvancedFilter}
                            reportButton={true}
                            export={true}
                            task_length={this.state.tasks.length}
                            exportxls={this.downloadActionsExcel}
                            toggle_state={this.state.toggle_state} 
                            toggle_text={'Show All Actions'} 
                            toggleCallback={this.toggleCallback}
                            />
					</OverviewInfoBody>


			</div>
		</>);
	}
}

export default FollowupActionsList;