import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import './DateSelect.css';

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 40%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #282828;
  background-color: #ffffff;
  height: 25px;
  padding-top: 2px;
  padding-left: 2px;
  float: left;
`;


class DateSelect extends React.Component
{
	state = {items: {}, from_date: new Date(), to_date: new Date()};

	setComponentState = () => {
		let items = JSON.parse(JSON.stringify(this.props.items));
		let selectedItems = JSON.parse(JSON.stringify(this.props.selectedItems));
		if(Object.keys(selectedItems).length > 0){
			let from_date_arr = selectedItems.from_date.split('/');
			let to_date_arr = selectedItems.to_date.split('/');
			let from_date_string = from_date_arr[2]+'-'+from_date_arr[1]+'-'+from_date_arr[0];
			let to_date_string = to_date_arr[2]+'-'+to_date_arr[1]+'-'+to_date_arr[0];
			let from_date_moment = moment(from_date_string).unix();
			let to_date_moment = moment(to_date_string).unix();
			this.setState({items: items , from_date: new Date(from_date_moment*1000) , to_date: new Date(to_date_moment*1000)});			
		}else{
			let keys = [];
			for(let key in items){
				let u_key = moment(key).unix();
				keys.push(u_key);
			}
			keys.sort();
			// console.log("from_date 44,",new Date(keys[0]*1000));
			this.setState({items: items , from_date: new Date(keys[0]*1000) , to_date: new Date(keys[1]*1000)});
		}
	}
	componentDidMount()
	{
		this.setComponentState()
	}

	componentDidUpdate(prevprops) {
		if(this.props !== prevprops) {
			this.setComponentState()
		}
	}

	handleFromDateChange = (date) => {
		if (!moment.isDate(date)) {
	      return;
	    }
		let date_range = {};
		date_range['from_date'] = moment(date).format('DD/MM/YYYY');
		date_range['to_date'] = moment(this.state.to_date).format('DD/MM/YYYY');
		let stack = 'stack' in this.props ? this.props.stack : null
		//this.props.deselectCallback(date_range, stack)
		this.props.selectCallback(date_range, stack)
		this.setState({from_date: date});
	}

	handleToDateChange = (date) => {
		if (!moment.isDate(date)) {
	      return;
	    }
		let date_range = {};
		date_range['from_date'] = moment(this.state.from_date).format('DD/MM/YYYY');
		date_range['to_date'] = moment(date).format('DD/MM/YYYY');
		let stack = 'stack' in this.props ? this.props.stack : null
		//this.props.deselectCallback(date_range, stack)
		this.props.selectCallback(date_range, stack)		
		this.setState({to_date: date});
	}

	render()
	{
		if (this.state.data === null) {
			return (<div>Loading...</div>);
		}

		// console.log('DateSelect props', this.props)
		// console.log('DateSelect state', this.state)
		return (
			<div style={{marginTop: "8px"}}>
                        <CSLDateCover>
                            <DatePicker
                                      onChange={this.handleFromDateChange}
                                      clearIcon={null}
                                      calendarIcon={null}
                                      locale={"en-GB"}
                                      value={this.state.from_date}
                                      className="csldatecontainer"
                                    />
                        </CSLDateCover> 
                        <div style={{float: 'left', paddingTop: 17, marginLeft: 10, marginRight: 10}}>To</div>
                        <CSLDateCover>
                            <DatePicker
                                      onChange={this.handleToDateChange}
                                      clearIcon={null}
                                      calendarIcon={null}
                                      locale={"en-GB"}
                                      value={this.state.to_date}
                                      className="csldatecontainer"
                                    />
                        </CSLDateCover> 
                        <div style={{clear: 'both'}} />
			</div>
		);
	}
}

export default DateSelect;

