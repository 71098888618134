import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import RemovalConfigModal from './RemovalConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import APICall from '../../Common/APICall.js';
import AlertBox from '../Common/AlertBox.js';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1003;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 70%;
	top: 100px;
    left: 10%;
`;

const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 22px;
    color: #a0a0a0;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 15px;
    font-family: Montserrat,sans-serif
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const RiskSelect = styled.select`
    margin-top: 15px;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
	font-weight: bold;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1300px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: 70px;  
  z-index: 1002;
  opacity: 0.8;
`;


const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const GeneralSubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 5px;
	font-style : italic;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
class General extends React.Component
{
	state = {general: null,alert_param: null};

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
    	this.setState({general: this.props.general});
    }

    switchRadio = (key) => (event) => {
    	event.preventDefault();
    	let {general} = this.state;
    	general[key] = general[key] ? false : true;
    	console.log("general", general)
    	this.props.updateGeneral(general);
    	this.setState({general: general});
    	let api = new APICall()
    	let postData = {command: 'moduleconfig', sub_command: 'update_general', general:general}
    	api.command(postData, this.processGeneral)
    }

    processGeneral = (result) =>{
    	console.log("result", result)
    }



	render()
	{
		console.log("states :" , this.state);
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
        let users = Store.getStoreData('users');
		return (
			<div>

			<GeneralContainer>
				<GeneralInnerContainer>
					<GeneralLabel>Allow Completion of Testing Activities where Remedial Actions are outstanding</GeneralLabel>
					{
						(() => {
							if (this.state.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding === true) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('allow_completetion_of_testing_activities_where_remedial_actions_outstanding' )}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('allow_completetion_of_testing_activities_where_remedial_actions_outstanding' )}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Enable Approval</GeneralLabel>
					{
						(() => {
							if (this.state.general.enable_approval === true) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('enable_approval' )}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('enable_approval' )}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Allow Rolling Comments for Testing Activities</GeneralLabel>
					{
						(() => {
							if (this.state.general.allow_rolling_comments_for_testing_activities === true) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('allow_rolling_comments_for_testing_activities' )}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('allow_rolling_comments_for_testing_activities' )}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					
					<GeneralLabel>Show Testing Comments and Details to users when completing Remedial Actions</GeneralLabel>
						{
							(() => {
								if (this.state.general.show_testing_comments_and_details_to_users_when_completing_remedial_actions === true) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('show_testing_comments_and_details_to_users_when_completing_remedial_actions')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('show_testing_comments_and_details_to_users_when_completing_remedial_actions')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
				</GeneralInnerContainer>
		
			</GeneralContainer>
				<AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default General;