let filters = {
    activity: {name: 'activity', 'nickname': 'Activity', type: 'multi', options: {},selections: {}, size: 1},
    risk_area: {name: 'risk_area', 'nickname': 'Risk Areas', type: 'multi', options: {},selections: {}, size: 1},
    subcat_name: {name: 'subcat_name', 'nickname': 'Subcategory', type: 'multi', options: {},selections: {}, size: 1},
    assigned_to: {name: 'assigned_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 1},
    colorwise_text: {name: 'colorwise_text', 'nickname': 'Status', type: 'multi', options: {},selections: {}, size: 1},
    risk_owner: {name: 'risk_owner', 'nickname': 'Risk Owner', type: 'multi', options: {},selections: {}, size: 1},
    risk_impact: {name: 'risk_impact', 'nickname': 'Risk Impact', type: 'multi', options: {},selections: {}, size: 1},
    due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2},
    //month: {name: 'month', 'nickname': 'Meeting Month', type: 'multi', options: {},selections: {}, size: 2},
}

module.exports = filters    
