import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import CSLHeader from './Common/CSLHeader';
import WelcomeBanner from './Common/WelcomeBanner';
import MGRIndex from './MGR/MGRIndex';
import TeamIndex from './Team/TeamIndex';

class Index extends React.Component
{
    state = {ready: false ,cur_view: null};

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
        let cur_view = 'mgr_index';
        let role = Store.getStoreData('role');
        if(role === 'team')cur_view = 'team_index'
        this.setState({ready: true,cur_view : cur_view});
    }

    render()
    {
        return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
            <CSLHeader homeText={'Monitoring Programme'} />
            <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('user_info').ContactName} />
                <div style={{padding: "10px", boxSizing: "border-box"}}>
                {
                    (() => {
                        switch(this.state.cur_view) {
                            case "mgr_index":
                                return <MGRIndex />;
                                    break;
                            case "team_index":
                                return <TeamIndex />;
                                    break;                
                            default:
                                return <div></div>;
                        }
                    })()
                }
                </div>
            </div>
        );
    }
}

export default Index;