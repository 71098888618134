import React from 'react';
import styled from 'styled-components';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import Utils from './Common/Utils.js';
import CMPUtils from './Components/Common/CMPUtils.js';
import ComDecom from './Common/ComDecom.js';
import Index from './Components/Index';
import * as Style from './Components/Common/StyledComponents';
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CSLLoader from './Components/Common/CSLLoader';
import CSLPageloader from './Components/Common/CSLPageloader'
import AlertBox from './Components/Common/AlertBox';
import Reload from './Common/Reload.js';
import CmpHOC from "./Components/Tasks/CmpHOC";
import authService from 'mcc-front-aux/dist/authService'
import siteLayout from 'mcc-front-aux/dist/siteLayout'
import CompleteActionModalHOC from "./Components/Tasks/CompleteActionModalHOC";
import CompleteModalHOC from "./Components/Tasks/CompleteModalHOC";

/*global localStorage*/

const moment = require('moment');
const last_action_time = moment().unix()



class AppContent extends React.Component
{
  state = {
    OverlayHeight: null,
    ready: false,
    alert_param: null
  };
  constructor(props) {
    super(props);
    Utils.setEnv('cmp');
  }

  componentDidMount() {
    let api = new APICall();
    let postData = { command: "index"};
    api.command(postData, this.processIndex);
  }

  alertHandler = (result, stack) => {
    Reload.ReloadPage();
  }


  processIndex = (result) => {
    //console.log('processIndex result', result)
    if(result.error_code === 0) {
      if(result.role === "no_access") {
        let alert_param = {title: 'No Access', message: '', ok_text: 'Ok', confirm: false,
                           alertHandler: this.alertHandler, stack: {id: 0}};
        alert_param["message"] = result.error_msg
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
        return
      }

      let user_info = 'user_info' in result.result ? result.result.user_info : {ID: 3968, ClientCompanyID: 2686, ContactName: 'Black'}

      Store.updateStore('role', result.role);
      Store.updateStore('user_info', user_info);
      let r = {};
      r['result'] = {};
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      let user_roles = {};
      let list_contacts = [];
      let contacts = {}
      let active_contacts = {};
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles['cmp'];
        for(let k in cts.result.contactlist){
          if(cts.result.contactlist[k.toString()].IsDeleted === false && cts.result.contactlist[k.toString()].IsActive === true){
            contacts[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
          }
        }
      }
      Store.updateStore('contacts', contacts);
      Store.updateStore('user_roles', user_roles);
      let gcs = CMPUtils.ListGroupCompanies(result)
      //console.log('gcs', gcs)
      Store.updateStore('gc_companies', gcs.gc_companies)
      Store.updateStore('gcs_plans', gcs.gcs_plans)
      Store.updateStore('gcs_tasks', gcs.gcs_tasks)
      this.setState({ ready: true});
    }else{
      let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                 alertHandler: this.alertHandler, stack: {id: 0}}
      alert_param["message"] = result.error_msg
      this.setState({ ready: true, alert_param: alert_param, task_loading: false});
    }
  }


  refreshState = () => {
    this.setState({refreshCount : this.state.refreshCount + 1})
  }

  render()
  {

    if (Store.getStoreData("cmp-index-select") === null) {
      return (
        <div>
          <CSLPageloader />
        </div>
      );
    } else {
      if (!this.state.ready) {
        return (
          <div>
            <CSLLoader />
          </div>
        );
      }
    }

    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }

    return (
      <Router>
        <Switch>
          <Route path="/moduleconfig" exact>
            <ModuleConfigHOC />
          </Route>
          <Route path="/" exact>
            <Index />
          </Route>

          <Route path="/remedial/:taskId" exact component={CmpHOC} />
          <Route path="/:refId" exact component={CmpHOC} />
        </Switch>
      </Router>
    );

  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = async () => {
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('api_url', api_url)
    await authService.refresh()
    await siteLayout()
    this.setState({ready: true})
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>
    return <AppContent />
  }
}


export default App;
