import React from 'react';
import { FaTimes, FaTrashAlt, FaQuestionCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import Utils from '../../Common/Utils';
import CslSimpleDropDown from './CslSimpleDropDown';

const EmailContainer = styled.div`
	width: 596px;
	//height:700px;
	top:75px;
	position: absolute;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
    color: #1a3552;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 95%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 40px 40px 15px 40px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #1b3452
    font-weight: 600;
    font-size: 20px;
    font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
    font-weight: 700;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #1b3452
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 40px 0px 40px;
    height:474px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #727272;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 95%;
`;

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;

class CustomRagsConfigModal extends React.Component {
    state = {
        id: "0",
        ready: false,
        alert_param: null,
        nickname: "",
        required_remedial_action: false,
        rag_value: "",
        isActive: 1,
        isDelete: 0,
        custom_rags: null,
    }

    componentDidMount() {
        if (this.props.curid.toString() !== "0") {
            this.setState({
                ready: true,
                id: this.props.content.id,
                nickname: this.props.content.nickname,
                rag_value: this.props.content.rag_value,
                required_remedial_action: this.props.content.required_remedial_action,
                isActive: this.props.content.isActive,
                isDelete: this.props.content.isDelete,
                custom_rags: this.props.data
            })
        } else {
            //const utils = new Utils(8);
            const randtxt = this.genKey(8);
            this.setState({ id: randtxt, ready: true, custom_rags: this.props.data });
        }
    }

    genKey = (length) => {
         var result           = '';
         var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
         var charactersLength = characters.length;
         for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
         }
         return result;
    }
         
    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
    }

    handleRequiredToggleChange = (arg) => {
        let { required_remedial_action } = this.state;
        required_remedial_action = required_remedial_action ? false : true;
        this.setState({ required_remedial_action })
    }

    handleToggleChange = (arg) => {
        let { isActive } = this.state;
        isActive = isActive === 1 ? 0 : 1;
        console.log("isActive",isActive);
        this.setState({ isActive })
    }

    submitCustom_validation = (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        if (this.state.nickname === "") {
            problems = true
            message += 'Please provide a custom rag name.'
        }
        if (problems) {
            let alert_param = {
                title: 'ERROR', message: message, ok_text: 'Ok', confirm: false,
                alertHandler: this.checkvalidation, stack: {}
            }

            this.setState({ alert_param: alert_param })
        } else {
            this.checkvalidation(false, {})
        }
    }

    checkvalidation = (result, stack) => {
        this.setState({ alert_param: null })
    }

    submitCustomRag = (event) => {
        event.preventDefault();   
        
        if (this.state.nickname.trim() === "") {
            let alert_param = {title: 'ERROR', message: "Please provide a custom rag name.", ok_text: 'Ok', confirm: false,alertHandler: this.checkvalidation, stack: {}}
            this.setState({ alert_param: alert_param })
            return
        }
        let custom_rag_obj = {}
        custom_rag_obj.id = this.state.id;
        custom_rag_obj.nickname = this.state.nickname;
        custom_rag_obj.rag_value = this.state.rag_value;
        custom_rag_obj.required_remedial_action = this.state.required_remedial_action;
        custom_rag_obj.isActive = parseInt(this.state.isActive);
        custom_rag_obj.isDelete = parseInt(this.state.isDelete);
        // if (this.props.curid.toString() !== "0") {
        //     alert("00",this.state.id)
        //     custom_rag_obj.id = this.state.id;
        // } else {
        //     alert("11",this.state.id)

        //     custom_rag_obj.id = this.genKey(8);
        // }
        console.log("isActive==>",custom_rag_obj);

        this.props.saveCustomRagsValue(custom_rag_obj, this.props.curid.toString());       

    }

    render() {
        console.log("isActive--->",this.state)
        if (!this.state.ready) {
            return (<div>Loading...</div>);
        }
        return (
            <div>

                <EmailContainer id="root_cause">
                    <MRModalHeader>
                        <MRModalHeaderText>{this.props.headerText} Custom Rag</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>
                    <ScoreBodyContainer>
                        <MRModalBody>
                            <MRModalLabel style={{ display: "block" }}>CUSTOM RAG NAME <span style={{ marginLeft: "5px", color: "red" }}>*</span></MRModalLabel>
                            <MRModalInput name="nickname" value={this.state.nickname} onChange={this.handleChange} />

                            <MRModalLabel style={{ marginTop: "30px", display: "block" }}>REQUIRED REMEDIAL ACTION?</MRModalLabel>
                            <Toggle name="isActive"
                                defaultChecked={this.state.required_remedial_action ? true : false}
                                icons={false}
                                onChange={() => this.handleRequiredToggleChange('required')} />

                            <MRModalLabel style={{ marginTop: "30px", display: "block" }}>STATUS</MRModalLabel>
                            <Toggle name="isActive"
                                defaultChecked={this.state.isActive === 1 ? true : false}
                                icons={false}
                                onChange={() => this.handleToggleChange('isActive')} />
                        </MRModalBody>
                    </ScoreBodyContainer>
                    <div style={{ clear: "both" }}></div>
                    <DocFooter style={{ float: "right", marginTop: "30px" }}>
                        <FooterButtonsContainer>
                            <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                            <SaveBtn onClick={this.submitCustomRag}>Submit</SaveBtn>
                        </FooterButtonsContainer>
                    </DocFooter>

                </EmailContainer>
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        )
    }
}

export default CustomRagsConfigModal;