import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils.js';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import RemovalConfigModal from './RemovalConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import APICall from '../../Common/APICall.js';



////

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1003;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 70%;
	top: 100px;
    left: 10%;
`;

const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 22px;
    color: #a0a0a0;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 15px;
    font-family: Montserrat,sans-serif
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const RiskSelect = styled.select`
    margin-top: 15px;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
	font-weight: bold;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1300px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: 70px;  
  z-index: 1002;
  opacity: 0.8;
`;

////

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
class RiskImpact extends React.Component
{
	state = {general: null , show_risk : false , risk_impacts : [] , risk_impact : null , id : '0' , name : '' , status : 'Active' , existing_names : [] , show_inactive : false};

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

	componentDidMount()
	{	
        let existing_names = [];
		this.props.risk_impact.forEach(ap => {
			existing_names.push(ap.name);
		});

		this.setState({
			risk_impacts: this.props.risk_impact , 
			existing_names: existing_names , 

			});
		console.log("risk_impact===>",this.props.risk_impact);
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let existing_names = [];
			this.props.risk_impact.forEach(ap => {
				existing_names.push(ap.name);
			});
			this.setState({risk_impacts: this.props.risk_impact , existing_names: existing_names });
			//console.log("reason_for_removal===>",this.props.reason_for_removal);
			// this.setState({general: this.props.general,reason_for_removal: this.props.reason_for_removal, reason_for_removal_search: this.props.reason_for_removal});
		}
	}

	genKey = (length) => {
	   var result           = '';
	   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	   var charactersLength = characters.length;
	   for ( var i = 0; i < length; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
	   }
	   return result;
	}

	

    openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.risk_impacts.find((item) => {
			return item.id === id;
		});
		//this.props.editAssurancePartner({id : id});
		if(typeof content === 'undefined'){
			this.setState({show_risk : true  });
		}else{
			this.setState({show_risk : true , risk_impact : content , id : id  , name : content.name , status : content.status });
		}
	}
    closeModal = (event) => {
		event.preventDefault();     
		
		this.setState({show_risk : false , risk_impact : null , id : '0' , name : '' , status : 'Active'});
	}
	tableData = () => {
			let ret = {data: [], columns: []};
			ret.columns =[
							{Header: 'Risk Impact', accessor: 'name', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
							//{Header: 'Req. Description', accessor: 'req_description', minWidth: 140, headerStyle: {textAlign: 'left'}},

							{Header: 'Status', accessor: 'status', minWidth: 40, headerStyle: {textAlign: 'left'}, style: { textAlign: 'center' }},
							{'Header' : 'Edit Details', Cell: row => (
								<div>
									<FaPencilAlt  onClick={this.openModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#b4b5b4',display :'inline-block',marginRight: "5px"}} /> 
									
								</div>
							  ), minWidth: 40, headerStyle: {textAlign: 'center'} , style: { textAlign: 'center' }}, 
							];
			this.state.risk_impacts.forEach((item) => {
				let statusValue = item.status === 'Active' ? "Active" : "Inactive"
				let elem = {id: item.id, name: item.name, status: statusValue};
                let show = 0;
				if(!this.state.show_inactive && statusValue === 'Active'){
					show =1;
				}
				if(this.state.show_inactive ){
					show =1;
				}
				if(show === 1)ret.data.push(elem);
			})        
			return ret;
	}
    handleChange = (event) => {
		event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }


    addRiskImpact = (event) => {
        event.preventDefault();
        if (this.state.name.trim() === "") {
            alert("Please enter name.");
            return;
        }
        if (this.state.id === '0' && this.state.existing_names.includes(this.state.name.trim())) {
            alert("This Risk Impact name already exists. Please enter another");
            return;
        }

		//console.log(this.state.partner_info);
		let updated_risk_impacts = [];
		let risk_impact = {};

		if(this.state.id === '0'){
			risk_impact.id = this.genKey(8);
			risk_impact.name = this.state.name.trim();
			risk_impact.status = this.state.status;
			updated_risk_impacts = this.state.risk_impacts;
			updated_risk_impacts.push(risk_impact);
		}else{
			risk_impact.id = this.state.id;
			risk_impact.name = this.state.name.trim();
			risk_impact.status = this.state.status;
			let risk_impacts = this.state.risk_impacts;
			
			risk_impacts.forEach(ap => {
				if(ap.id === this.state.id ){
					ap = risk_impact;
				}
				updated_risk_impacts.push(ap);
			});
		}

        console.log("updated_risk_impacts", updated_risk_impacts, this.state.id, risk_impact);
		//return;
        this.props.updateRiskImpacts(risk_impact, this.state.id);
		this.setState({show_risk : false , risk_impact : null , id : '0' , name : '' , status : 'Active' , show_inactive : false});
    }
	showInActives = (event) => {
		event.persist();
		this.setState(({ show_inactive }) => ({ show_inactive: !this.state.show_inactive }));
		
	}

	render()
	{
		if (this.state.risk_impacts.length === 0) {
			return (<div>Loading...</div>);
		}
        let users = Store.getStoreData('users');
		return (
			<div>

			<GeneralContainer>
				<GeneralInnerContainer>
					
				{
					(() => {
						if (this.state.show_risk) {
						  return(
							  <div>
                                    <InactiveOverlay />
									<MRModalContainer>
										<div style={{height: "80px", width: "100%", borderLeft: "14px solid #2AACA5", boxSizing: "border-box"}}>
											<div style={{float: "left", fontSize: "16px", fontWeight: "600", padding: "28px"}}>{(this.state.id !== '0') ? 'Edit' : 'Add'} Risk Impact</div>
											<MRModalHeaderCloseBtn onClick={this.closeModal} style={{padding: "28px"}}><FaTimes /></MRModalHeaderCloseBtn>
											<div style={{clear: "both"}}></div>
										</div>
										<div style={{backgroundColor: "#F1F6F7", padding: "25px 0px 25px 43px"}}>
											<div style={{fontSize: "14px", fontWeight: "600"}}>Name</div>
											<MRModalInput type="text" onChange={this.handleChange} name="name" value={this.state.name} />
											<div style={{fontSize: "14px", fontWeight: "600", marginTop: "15px"}}>Status</div>
											<RiskSelect value={this.state.status} onChange={this.handleChange} name="status" style={{width: "25%"}}>
												<option value="Active">Active</option>
												<option value="Inactive">Inactive</option>
											</RiskSelect>


										</div>

										<div style={{padding: "15px 25px 15px 0px"}}>
											<CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginRight: "10px"}} onClick={this.addRiskImpact}>Submit</CatBtn>
											<CatBtn style={{backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right"}} onClick={this.closeModal}>Cancel</CatBtn>
											<div style={{clear: "both"}}></div>
										</div>
									</MRModalContainer>
							  </div>
							  )
						}

					})()
				}
				{
					(() => {

							return <div style={{marginRight: "7px" , float: "right", color: "#04ADA8", fontSize: "17px", cursor: "pointer"}} title="Add New" onClick={this.openModal(0)}><FaPlusCircle /></div>

					})()
				}
				{
					(() =>{
							return (
							<div style={{float : "right" , marginRight: "25px" }}>
								 Show Inactive &nbsp; <input type="checkbox" checked={this.state.show_inactive} onChange={this.showInActives} value="1" />
							</div>

								);

					})()
					
				}
				{
					(() => {

							return <div style={{clear: "both" , marginTop: "5px"}}></div>

					})()
				}
				{
					(() => {
						return(
							<div>

								<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="8" refreshCallback={this.refreshState} />
							</div>
						);
					})()
				}							
				</GeneralInnerContainer>
		
			</GeneralContainer>
			</div>
		);
	}
}

export default RiskImpact;