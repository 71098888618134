import React from 'react';
import styled from 'styled-components';
// import Store from '../../../common/Store.js';
import { FaAngleDown } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';

// import Dropzone from '../../common/Dropzone.js';
// import '../../common/Dropzone.css';
// import * as Style from '../../common/StyledComponents';
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';
import HtmlEditor from '../../Common/HtmlEditor';
import DatePicker from 'react-date-picker';
import Dropzone from '../../Common/Dropzone.js'
import * as Style from '../../Common/StyledComponents';
import EventBus from '../../../Common/EventBus.js';
import AlertBox from '../../Common/AlertBox.js';
import CSLLoader from '../../Common/CSLLoader.js';



const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 75%;
    left: 15%;
	top : 60px;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 995;
    opacity: 0.8;
    `;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: 600;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 98%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin:10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 77%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-left: 89px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;
const MRModalDivider = styled.div`
    background-color: #EBEBEB;
    height:3px;
    margin-top: 15px;
    margin-bottom: 15px;
	width: 97.5%;
	margin-left: 12px;
`;
const MRModalLabelTitle = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const TestingSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 78%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 73px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 10px 32px;
    color: #ffffff;
    cursor: pointer;
`;

const MRSubcatHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #26ADA7;
    padding: 13px;
    padding-bottom:27px;
    border: 1px solid #EBEBEB;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left : 17px;
    margin-top:10px;
`;
const MRSubcatList = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #FFFFFF;
    padding: 13px;    
    border: 1px solid #EBEBEB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left : 17px;
`;
const MRModalCircle = styled.label`
    height: 22px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    float: right;
    padding-bottom: 9px;
    padding-left: 5px;
    margin-bottom:2px;
    cursor:pointer;
`;
const MRTestingLabel = styled.label`
    display: inline-block;
    box-sizing: border-box;
    width: 78%;
    background-color: #EEEEEE;
    padding: 14px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-left: 71px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 210px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;
const TestingTextArea = styled.textarea`
    width:70%;
    margin-left:71px;
    margin-top: 15px;
    border: 1px solid #ffffff; 
    borderRadius: 3px; 
    boxShadow: 0 0 4px #c4c4c4;
    padding: 5px; 
    color: #979797; 
    fontFamily: Montserrat,sans-serif;
`;
const PartialDiv = styled.div`
    display:inline-block;
`;
const CompleteModalSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 86%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 20%;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

class CompleteActionModal extends React.Component {
    bgColor = { 'Amber': '#F39C12', 'Yellow': 'Yellow', 'Red': 'Red', 'Green': '#04ADA8' }
    state = {
        ready: false,
        task_data: null,
        risk_area_name: '',
        testing_activity: null,
        risk_area:null,
        testing_description: '30122022 TA 1',
        testing_freq: 'Monthly',
        testing_owner: '',
        testing_objectives: '',
        testing_method: '',
        additional_guidance: '',
        testing_activity_undertaken: '',
        testing_activity_undertaken_values_arr: [],
        review_attachment: [],
        rag: '',
        ragBgColor: '',
        require_remediation: 'No',
        submit_msg: '',
        unique_testing_act_id: null,
        task_id: 0,
        parent_task_id: 0,
        binFiles: [],
        followup_task:null,
        curBinFiles: [],
        cur_files_json: [],
        followupBinFiles: [],
        actions: [],
        action_title: "",
        action_description: "",
        action_note_undertaken: "",
        current_task: {},
        current_task_Id: 0,
        actionBinFiles: [],
        IsNoteProvided: false,
        is_form_modified: false,
        parent_ref_id: '',
        ready: false,
        testing_description:null,
        testing_objectives:null,
        additional_guidance:null,
        testing_method:null,
        unique_plan_id:null,
        rag:null,
        ragBgColor:'',
        require_remediation:'',
        alert_param:null,
        is_loader:false,
        useful_resources:[],
        extra_resources:[],
        action_resources:[]
    }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prev_props) {
        if(prev_props === this.props) return
        this.setComponentState()

    }
    setComponentState = () => {
        console.log("ACT:: Props::",this.props)
        let testing_activity = this.props.testing_activity[0]
        let risk_area = this.props.risk[0];
        let followup_task = this.props.followup_task
        let task_id = this.props.task_id
        let parent_task_id = this.props.parent_task_id;
        let useful_resources = testing_activity.attachments ? testing_activity.attachments : []



        this.setState({testing_activity,risk_area,followup_task,task_id,parent_task_id,useful_resources}) 
        let postData = {"command": "get_task", "task_id": parent_task_id,'get_followup_files':task_id};
        let api = new APICall();
        //let that = this;
        api.command(postData, this.setTaskDetails)

    }

    setTaskDetails = (result)=>{
        console.log("setTaskDetails::",result)
        console.log("setTaskDetails::",this.props)
        let module_configs = Store.getStoreData('module_config')

        let parent_task = result.result.task.parenttask
        let child_task = result.result.task.childtask.result.find(o => o.id === this.props.task_id);
        let ta_task_details = JSON.parse(parent_task.task_json)
        let current_task = JSON.parse(child_task.task_json)
        let action_note_undertaken = 'result' in current_task.object_data ? current_task.object_data.result[0].action_note_undertaken : '';

        let extra_resources = ta_task_details.object_data.bin_files;

        let testing_activity_undertaken = this.changeStringToAtob(ta_task_details.object_data.result[0].testing_activity_undertaken);
        let testing_activity_undertaken_values_arr = [];
        if(module_configs.general.allow_rolling_comments_for_testing_activities){
            testing_activity_undertaken = '';
        }
        if('testing_activity_undertaken_values_arr' in ta_task_details.object_data.result[0]){
            testing_activity_undertaken_values_arr =  ta_task_details.object_data.result[0].testing_activity_undertaken_values_arr;
        } 

        //let testing_activity_undertaken_values_arr = ta_task_details.object_data.result[0].testing_activity_undertaken_values_arr
        
        const risk_json = ta_task_details?.object_data?.headerinfo?.risk_json;
        const subcategories = risk_json.subcategories;
        let testing_activity = "testing_activities" in subcategories ? subcategories.testing_activities[0] : risk_json.testing_activities[0];
            
        let testing_description = this.changeStringToAtob(testing_activity.testing_description);
        let testing_objectives  = this.changeStringToAtob(testing_activity.testing_objectives);
        let additional_guidance = this.changeStringToAtob(testing_activity.additional_guidance);
        let testing_method = this.changeStringToAtob(testing_activity.testing_method);
        let unique_plan_id = testing_activity.plan_ref_id; 
        

        //let module_configs = Store.getStoreData('module_config');
        let rag = ta_task_details.object_data.result[0].rag === '0' ? '' : this.customRagDecider(module_configs,ta_task_details.object_data.result[0].rag)
        let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);
        let ragBgColor =  rag === '' ? '' : addtional_rags_assoc[rag].rag_value;
        let require_remediation = ta_task_details.object_data.result[0].require_remediation


        let ref_id = '';                
        if('ref_id' in ta_task_details.task_data){
            ref_id = ta_task_details.task_data.ref_id;
        }
        let action_resources = []
        let cur_files_json = []
        if('followup_bin_files' in  result) {
            result.followup_bin_files.forEach((item) => {
                if(!("type" in item)){
                    action_resources.push(item);
                }else{
                    cur_files_json.push(item);
                }
            });                 
            //actionBinFiles = current_task.object_data.bin_files;
        }
        // let action_resources = result.followup_bin_files.filter(function (atch) { return "type" in atch && atch.type === "followup_action_resource"});
        // let cur_files_json = result.followup_bin_files.filter(function (atch) { return "type" in atch && atch.type === "followup_action_resource"});
        let ready = true
        let rag_nickname = rag === '' ? '' : addtional_rags_assoc[rag].nickname
        this.setState({ready,current_task,require_remediation,extra_resources,action_resources,cur_files_json,action_note_undertaken, parent_ref_id : ref_id,rag:rag_nickname,ragBgColor,testing_description,testing_objectives,additional_guidance,testing_method,unique_plan_id,testing_activity_undertaken,testing_activity_undertaken_values_arr})
    }
    isBase64(str) {
        if (str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }
    changeStringToAtob = (str_value) => {
        let str_text = str_value === null || str_value === "" ? "" : str_value;
        if(this.isBase64(str_text)){
          str_text = window.decodeURIComponent(atob(str_text))
        }
        return str_text;
    }

    filesLoadedType2 = (jsonObj) => {
        // let me = Store.getStoreData('cmp-loggedin_user_info');
        let cur_files_json = this.state.curBinFiles;
        //console.log('files in Modal size 1==>', curBinFiles.length);
        let file_exists = {};
        let curBinFiles_1 = [];
        
            for (let i = 0; i < jsonObj.length; i++) {
                console.log("curBinFiles_1",jsonObj[i])
                Object.assign(jsonObj[i], {user_id: Store.getStoreData('user_info').ID});
                Object.assign(jsonObj[i], {upload_date:  Date.now()});
                Object.assign(jsonObj[i], {type : "followup_action_resource"});

                if (typeof file_exists[jsonObj[i].name] === "undefined") {
                    cur_files_json.push(jsonObj[i]);
                    file_exists[jsonObj[i].name] = true;
                }
                curBinFiles_1 = cur_files_json.filter((cur_files_json, index, self) => index === self.findIndex((t) => (t.name === cur_files_json.name && t.uid === cur_files_json.uid)));
                
            }
        
             this.setState({ curBinFiles: curBinFiles_1, is_form_modified: true });
    }

    filesRemovedType2 = (jsonObj) => {
        console.log('filesRemovedType2::',jsonObj)
        let curBinFiles = this.state.curBinFiles.filter(item => item.uid !== jsonObj);


        if (jsonObj.files_to_show_size === 0) {
            this.setState({ is_form_modified: false,curBinFiles });
        }else{
            this.setState({ curBinFiles });
        }
        
    }
    customRagDecider = (module_configs,current_rag) => {
        //console.log("module_configs,",module_configs);
        let id_array = [];
        let rag_id = '';
        for(let item of module_configs.custom_rags.additional_rags){
            id_array.push(item.id);
            if(item.nickname === current_rag){rag_id = item.id;break;}
            if(item.rag_value === current_rag){rag_id = item.id;break;}
        }
        if(rag_id !== '') return rag_id;
        if(id_array.includes(current_rag)) return current_rag;
    }
    getCustomRagAssoc = (module_configs) =>{
        let addtional_rags_assoc = {};
        for(let item of module_configs.custom_rags.additional_rags){
            addtional_rags_assoc[item.id] = item;
        }
        return addtional_rags_assoc;
    }
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value , is_form_modified : true});
    }
    saveAction = (event) =>{
        event.preventDefault();     
        if(this.state.action_note_undertaken.trim() == ''){
            this.setState({IsNoteProvided: true})
        }else{
            // var bin_files = [...this.state.actionBinFiles, ...this.state.followupBinFiles];
            this.setState({is_loader: true});
            var bin_files = [...this.state.curBinFiles,...this.state.action_resources,...this.state.cur_files_json]
            var current_task = this.state.current_task;
            var action_data = {action: 'COMP_CMP_FOLLOWUP_SAVED',actiondate :Date.now(), assigned_to: current_task.task_data.cur_assigned_to, cur_lane : 'COMP_CMP_FOLLOWUP_SAVED',"performed_by": Store.getStoreData('user_info').ID};
            
            delete current_task.object_data.bin_files;
            var result = [{action_note_undertaken:this.state.action_note_undertaken}];
            current_task.object_data.result = result;
            current_task.object_data.bin_files = bin_files;
            current_task.action_data = action_data;
            //console.log("current_task in saveAction==>"+JSON.stringify(current_task));
            
            var task_data = {};
            task_data.task_json = current_task;
            task_data.id = this.state.task_id;
            task_data.assigned_to = this.state.testing_activity.testing_owner;
            task_data.performed_by = Store.getStoreData('user_info').ID;
            
            console.log("saveAction task_data::",task_data)
            console.log("saveAction this.state.parent_ref_id::",this.state.parent_ref_id)


            let postData = {"command": "saveFollowupActionTask", 
            "task_id" : task_data.id, "task_json" :task_data.task_json ,
            "assigned_to" :task_data.assigned_to , "parent_ref_id" : this.state.parent_ref_id,
            "parent_task_id":this.state.parent_task_id};
            let api = new APICall();
            //api.command(postData, this.processSaveCompleteAction);
            api.command(postData, this.updateTaskResult);

            //this.props.saveAction([task_data , this.state.parent_ref_id]);
            
            //this.refreshPage();
            //console.log("current_task in saveAction==>"+JSON.stringify(task_data));
        }           
    }
    
    completeAction = (event) =>{
        if(this.state.action_note_undertaken.trim() == ''){
            this.setState({IsNoteProvided: true})
        }else{          
            event.preventDefault();
            this.setState({is_loader: true});
            var bin_files = [...this.state.curBinFiles,...this.state.action_resources,...this.state.cur_files_json]
            // var bin_files =  [...this.state.actionBinFiles, ...this.state.followupBinFiles];
            let current_task = this.state.current_task;
            delete current_task.object_data.bin_files;
            var result = [{action_note_undertaken:this.state.action_note_undertaken}];
            current_task.object_data.result = result;
            current_task.object_data.bin_files = bin_files;
            //current_task.task_data = { "module_id": "cmp", "last_action": "COMP_CMP_FOLLOWUP_COMPLETE", "actiondate": Date.now(), 'task_type' : 'COMP_CMP_FOLLOWUP', 'cur_lane': 'COMP_CMP_FOLLOWUP_COMPLETE', completed_date: Date.now()};
            current_task.task_data.actiondate = Date.now();
            current_task.task_data.cur_lane = 'COMP_CMP_FOLLOWUP_COMPLETE';
            current_task.task_data.completed_date = Date.now();
            
            var action_data = {action: 'COMP_CMP_FOLLOWUP_COMPLETE',actiondate :Date.now(), assigned_to: current_task.task_data.cur_assigned_to, cur_lane : 'COMP_CMP_FOLLOWUP_COMPLETE' , performed_by: Store.getStoreData('user_info').ID};
            current_task.action_data = action_data;
            var task_data = {};
            task_data.task_json = current_task;
            task_data.id = this.state.task_id;
            task_data.assigned_to = this.state.testing_activity.testing_owner;
            task_data.performed_by = Store.getStoreData('user_info').ID;


            
            // console.log("completeAction task_data::",task_data)
            //console.log("completeAction this.state.parent_ref_id::",this.state.parent_ref_id)
            //console.log("current_task_data==>"+JSON.stringify(task_data));
            //this.props.saveCompleteAction([task_data,this.state.testing_activity]);
            //this.closeModal(event);
            //this.refreshPage();

            let postData = {"command": "updateFollowupActionTask", 
                            "flag":"complete_followup",
                            "task_id" : task_data.id, 
                            "task_json" :task_data.task_json , 
                            "assigned_to" :task_data.assigned_to, 
                            "testing_activity": this.state.testing_activity,
                            "parent_task_id":this.state.parent_task_id};
            let api = new APICall();
            // console.log("updateFollowupActionTask::",postData)
            api.command(postData, this.updateTaskResult);
            //api.command(postData, this.setTaskDetails)
        }
    }
    updateTaskResult = (result) =>{
        let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok', confirm: false, alertHandler: this.closeModal, stack: {result : result} }
        this.setState({alert_param,is_loader:false})
    }
    closeModal=()=>{
        this.setState({alert_param: null})
        this.props.closeModal();
    }
    render() {
        let module_configs = Store.getStoreData('module_config')
        let show_testing_comments_status = true
        if(!module_configs.general.show_testing_comments_and_details_to_users_when_completing_remedial_actions && Store.getStoreData('role')=== 'team'){
            show_testing_comments_status = false
        }

        let role = Store.getStoreData('role');
        let userId = Store.getStoreData('user_info')?.ID
        let viewManagePermission = true;
        if(role === 'view_only_manager' && parseInt(userId) !== parseInt(this.state.testing_owner)){
            viewManagePermission = false
        }


        if(!this.state.ready){
            return <div />   
        }
        return (
            <div>
            {
                (() => {
                    if(this.state.is_loader){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <AlertBox alertParam={this.state.alert_param} />
                <InactiveOverlay style={{ height: "105%" }} />
            
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Services</MRModalHeaderText>
                        <div style={{ clear: "both" }}></div>
                        <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>

                    <MRModalBody>
                        <MRModalSummary>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Testing Activity</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.testing_activity.testing_activity}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Description</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.testing_description }} />
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Risk Area</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.risk_area.risk_area_name}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Frequency</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.testing_activity.testing_freq}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Owner </MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{Store.getStoreData('contacts')[this.state.testing_activity.testing_owner]}</MRModalLabel>
                            </PartialDiv>

                            <div style={{display:show_testing_comments_status === false ?'none': ''}}>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Testing </MRModalLabelTitle>
                                <div style={{ clear: "both" }}></div>
                                <MRModalLabelTitle>Objectives</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.testing_objectives }} />
                            </PartialDiv>
                            </div>

                            <div style={{display:show_testing_comments_status === false ?'none': ''}}>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>High Level </MRModalLabelTitle>
                                <div style={{ clear: "both" }}></div>
                                <MRModalLabelTitle>Testing Method</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.testing_method }} />
                            </PartialDiv>
                            </div>

                            <div style={{display:show_testing_comments_status === false ?'none': ''}}>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Additional</MRModalLabelTitle>
                                <div style={{ clear: "both" }}></div>
                                <MRModalLabelTitle>Guidance</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.additional_guidance }} />
                            </PartialDiv>
                            </div>

                            <div style={{display:show_testing_comments_status === false ?'none': ''}}>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle >Useful Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%", float: "right" }}>
                                <div style={{ padding: "10px" }}>
                                    <Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
                                    <Style.ModalNameInputDiv>
                                     {
                                    (() => {
                                        if(this.state.useful_resources.length > 0){
                                            return(
                                            <Dropzone onFilesAdded={this.filesLoadedType2} showFileButton={false} initFiles={this.state.useful_resources} onRemoveFile={this.filesRemovedType2} />
                                                
                                                )
                                            }  
                                        })()
                                    }
                                    </Style.ModalNameInputDiv>
                                </div>
                                <div style={{ clear: "both", marginTop: "10px" }}></div>

                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>

                            {
                                (() => {
                                    if(viewManagePermission){
                                        return(
                                            <div>
                                                <PartialDiv style={{ width: "22%" }}>
                                                    <MRModalLabelTitle>Extra Resources</MRModalLabelTitle>
                                                </PartialDiv>
                                                <PartialDiv style={{ width: "78%", float: "right" }}>
                                                    <div style={{ padding: "10px" }}>
                                                        <Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
                                                        <Style.ModalNameInputDiv>
                                                            <Dropzone onFilesAdded={this.filesLoadedType2} showFileButton={false} initFiles={this.state.extra_resources} onRemoveFile={this.filesRemovedType2} />
                                                        </Style.ModalNameInputDiv>
                                                    </div>
                                                    <div style={{ clear: "both", marginTop: "10px" }}></div>
                                                </PartialDiv>

                                                <div style={{ clear: "both", marginTop: "25px" }}></div>
                                            </div>

                                        )

                                    }
                                })()
                            }
                               
                            {
                                (() => {
                                    if(viewManagePermission){
                                        if(!module_configs?.general?.allow_rolling_comments_for_testing_activities){
                                            return (
                                                <div>
                                                    <PartialDiv style={{width:"22%"}}>
                                                        <MRModalLabelTitle>Testing Activities</MRModalLabelTitle>
                                                        <div style={{clear: "both"}}></div>
                                                        <MRModalLabelTitle>Undertaken</MRModalLabelTitle>
                                                    </PartialDiv>
                                                    <div style={{marginLeft : "245px" , marginTop : "-100px", width:"78%"}}>
                                                        <div dangerouslySetInnerHTML={{__html: this.state.testing_activity_undertaken}} />
                                                    </div>                                          
                                                </div>
                                            );
                                        }
                                        
                                        if(this.state.testing_activity_undertaken_values_arr.length > 0){
                                            return(
                                                <div >
                                                    <div style={{clear: "both",marginTop: "10px",height: "10px"}}></div>
                                                    <div style={{ width:"100%" ,overflowY: "scroll",height: "170px"}}>
                                                    {
                                                        (() => {
                                                            let tau_vals_div = [];
                                                            for(let r of this.state.testing_activity_undertaken_values_arr){
                                                                let tau = this.changeStringToAtob(r.tau_text);
                                                                let text_created_by = Store.getStoreData('contacts')[r.created_by.toString()];
                                                                tau_vals_div.push(
                                                                    <div style={{backgroundColor: "rgb(232, 240, 247)" }}>
                                                                        <div style={{height: "10px"}}>&nbsp;</div>
                                                                        <div style={{marginLeft: "20px"}}><b>Testing Activity Undertaken</b></div>
                                                                        <div style={{marginLeft: "20px"}} dangerouslySetInnerHTML={{__html: tau}} />
                                                                        <div style={{marginLeft: "20px"}}><b><i>Testing Activity Updated by {text_created_by} - {r.created_on}</i></b></div>
                                                                        <div style={{height: "10px"}}>&nbsp;</div>
                                                                        <div style={{clear: "both",marginTop: "20px"}}></div>
                                                                    </div>                                                              
                                                                );
                                                            }
                                                            return  <div > {tau_vals_div} </div>;
                                                        })()
                                                    }   
                                                    </div>
                                                    <div style={{clear: "both",marginTop: "10px"}}></div>
                                                </div>

                                            );
                                        }                                      
                                    }
                                })()
                            }           

                              
                            </div>
                            {
                                (() => {
                                    if(viewManagePermission){
                                        return(     
                                            <div>
                                                <div >

                                                    <div style={{ clear: "both", marginTop: "10px", height: "10px" }}></div>

                                                    <div style={{ clear: "both", marginTop: "10px" }}></div>
                                                </div>
                                                <div style={{ clear: "both", marginTop: "85px" }}></div>
                                                
                                                <PartialDiv style={{ width: "22%" }}>
                                                    <MRModalLabelTitle>Requires</MRModalLabelTitle>
                                                    <div style={{ clear: "both" }}></div>
                                                    <MRModalLabelTitle>Remediation?</MRModalLabelTitle>
                                                </PartialDiv>
                                                <PartialDiv style={{ width: "78%" }}>
                                                    <MRModalLabel>{this.state.require_remediation}</MRModalLabel>
                                                    
                                                    <div style={{display:show_testing_comments_status === false ?'none': ''}}>
                                                    <div style={{ width: "50%", float: "right" }}>
                                                        <div style={{ float: "left", width: "25%", fontWeight: "bold", color: "#000000" }}>Risk Rating</div>
                                                        <div style={{ width: "75%" }}><div style={{ float: "right", width: "40px", marginRight: "100px", fontWeight: "bold" }}>{this.state.rag}</div><div style={{ marginLeft: "100px", marginTop: "1px", width: "10px", height: "10px", backgroundColor: this.state.ragBgColor, borderRadius: "50%" }}></div ></div>
                                                        <div style={{ clear: "both" }}></div>
                                                    </div>
                                                    </div>
                                                </PartialDiv>
                                            </div>
                                        )
                                    }
                                })()
                            }

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Action Title</MRModalLabelTitle>

                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.followup_task.name}</MRModalLabel>

                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Action Description</MRModalLabelTitle>

                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.followup_task.description}</MRModalLabel>

                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Action Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%", float: "right" }}>
                                <div style={{ padding: "10px" }}>
                                    <Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
                                    <Style.ModalNameInputDiv>
                                    <Dropzone onFilesAdded={this.filesLoadedType2} showFileButton={false} initFiles={this.state.action_resources} onRemoveFile={this.filesRemovedType2} />
                                    </Style.ModalNameInputDiv>
                                </div>
                                <div style={{ clear: "both", marginTop: "10px" }}></div>

                            </PartialDiv>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>

                        </MRModalSummary>

                        <div style={{ clear: "both", marginTop: "25px" }}></div>
                        <PartialDiv style={{ width: "22%", paddingLeft: "32px" }}>
                            <MRModalLabelTitle>Action Taken</MRModalLabelTitle>

                        </PartialDiv>
                        <PartialDiv style={{ width: "78%" }}>
                            <textarea name="action_note_undertaken" rows="4" cols="80" value={this.state.action_note_undertaken} style={{ width: "calc(99% - 40px)", marginLeft: "30%", marginTop: "-36px", border: "1px solid #ffffff", borderRadius: "3px", boxShadow: "0 0 4px #c4c4c4", padding: "5px", color: "#000000", fontWeight: "bold", fontFamily: "Montserrat,sans-serif", resize: "none" }} onChange={this.handleChange}>
                            </textarea>
                            {
                            (() => {
                                if(this.state.IsNoteProvided){
                                    return(
                                        <div style={{marginLeft: "calc(45% - 100px)", marginTop: "5px"}}>
                                            <div style={{clear: "both"}}></div>
                                            <label style={{color:"#FF0000"}}>Please provide action note.</label>
                                        </div>
                                        )
                                    }  
                                })()
                            }


                        </PartialDiv>
                        <div style={{ clear: "both", marginTop: "25px" }}></div>

                        <div style={{ display: 'flex', marginTop: "25px" }}>
                            <Style.ModalNameLabelDiv style={{ width: "20%", marginRight: "50px", paddingTop: "3px", color: "#000000", fontWeight: "bold", paddingLeft: "32px" }}>Attachments</Style.ModalNameLabelDiv>
                            <Style.ModalNameInputDiv style={{ float: "right", marginRight: "50px" }}>
                            <Dropzone onFilesAdded={this.filesLoadedType2} initFiles={this.state.cur_files_json} onRemoveFile={this.filesRemovedType2} />

                            </Style.ModalNameInputDiv>
                        </div>
                        <div style={{ clear: "both" }} ></div>

                        <MRModalDivider />

                    </MRModalBody>
                    <MRModalFooter>
                        
                        <div>
                                        <CatBtn style={{ backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }} onClick={this.completeAction}>Complete</CatBtn>
                                        <CatBtn style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }} onClick={this.saveAction}>Save & Close</CatBtn>
                        </div>

                        <CatBtn style={{ backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black", float: "right" }} onClick={this.props.closeModal}>Cancel</CatBtn>
                        <div style={{ clear: "both" }}></div>
                    </MRModalFooter>
                </MRModalContainer>
            </div>
        );
    }
}

export default CompleteActionModal;