import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FaTimes, FaPlusCircle, FaStarOfLife, FaCalendarAlt, FaAngleDown } from 'react-icons/fa';
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';
import HtmlEditor from '../../Common/HtmlEditor';
import DatePicker from 'react-date-picker';
import Dropzone from '../../Common/Dropzone.js'
import * as Style from '../../Common/StyledComponents';
import EventBus from '../../../Common/EventBus.js';
import AlertBox from '../../Common/AlertBox.js';
import CSLLoader from '../../Common/CSLLoader.js';
import '../../Common/DateSelect.css';
import CSLAttachmentViewer from '../../Common/CSLAttachmentViewer.js';

const GridContainer = styled.div`
                     display: grid;
                     grid-template-columns: 100px auto;
                   
                    
                    `

const GridItem = styled.div`
                     margin-bottom: 20px;
                    `



const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;

const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
    height:calc(100vh - 260px);
    overflow-y:auto
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
    margin-top: -10px;
`;

const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 995;
    opacity: 0.8;
    `;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 320px);
    width: calc(100% - 650px);
    position: fixed;
    top: 38px;
    left: 300px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;

const CatBtn = styled.button`
    border-radius: 4px;
    padding: 13px 37px;
    color: #ffffff;
    cursor: pointer;
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-left : 17px;
`;

const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
	color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;
const MRModalDivider = styled.div`
    background-color: #EBEBEB;
    height:3px;
    margin-top: 15px;
    margin-bottom: 15px;
	width: 98%;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 84%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
	font-weight: bold;
    margin-left: 24px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;

const MRTestingLabel = styled.label`
    display: inline-block;
    box-sizing: border-box;
    width: calc(98% - 10px);
    background-color: #EEEEEE;
    padding: 14px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    /*margin-left: 75px;*/
	font-weight: bold;
`;
const TestingSelect = styled.select`
    margin-top: 5px;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 85.2%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    /*margin-left: 73px;*/
	font-weight: bold;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: calc(50% - 5px);
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
	font-weight: bold;
    `;

class TAModal extends React.Component {
    state = {
        ready: false, binFiles: [], curBinFiles: [], plan: null, risk: null, subcategory: null,
        ta: null, risk_owners: [], ta_id: '0', next_due_date: null, orig_next_due_date: null,
        initial_testing_freq: null, initial_first_schedule: null, due_date: null, alert_param: null, old_tact: null, due_date: null,
        isIncludeTaName: false, isIncludeDescription: false, IsActionAssignedToProvided: false, showmessage: false, is_loader: false, saveclicked: false
    }

    genKey(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    componentDidMount = () => {
        //alert('componentDidMount')

        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        //alert('componentDidUpdate')

        if (prev_props === this.props) return
        this.setComponentState()
    }

    setComponentState = () => {
        console.log("PROPS A:::", this.props)
        let plan = this.props.plan
        let subcategory = this.props.subcategory
        let risk = this.props.risk
        console.log("setComponentState", risk)
        let ta = {
            created_by: 0,
            testing_activity: "",
            testing_description: "",
            testing_objectives: "",
            testing_method: "",
            additional_guidance: "",
            testing_freq: "Monthly",
            first_schedule: new Date(),
            due_date: new Date(),
            next_due_date: new Date(),
            testing_owner: "0",
            attachments: [],
            gc_id: this.props.gc_id,
            is_deleted: false,
            delete_reason: {
                "frequency_change": null,
                "date_change_series": null,
                "date_scheduled_change_single": null
            },
            exclusions: {},
            first_schedule_dt_str: null,
            regenerate_years_count: 0,
            unique_subcat_id: this.props.subcategory === null ? "0" : this.props.subcategory.unique_subcat_id,
            unique_risk_id: this.props.risk.unique_risk_id,
            plan_ref_id: plan.unique_plan_id,
            unique_testing_act_id: this.genKey(20),
            __parent_id: plan.testing_activities_parent_id,
            __record_id: this.genKey(20),
        }

        ta = this.props.ta === null ? ta : JSON.parse(JSON.stringify(this.props.ta))
        let old_tact = this.props.ta === null ? ta : JSON.parse(JSON.stringify(this.props.ta))

        // alert('changeStringToAtob')

        ta.testing_description = JSON.parse(JSON.stringify(this.changeStringToAtob(ta.testing_description)))
        ta.testing_objectives = JSON.parse(JSON.stringify(this.changeStringToAtob(ta.testing_objectives)))
        ta.testing_method = JSON.parse(JSON.stringify(this.changeStringToAtob(ta.testing_method)))
        ta.additional_guidance = JSON.parse(JSON.stringify(this.changeStringToAtob(ta.additional_guidance)))





        ta.first_schedule = this.props.ta === null ? new Date() : new Date(this.props.ta.first_schedule)
        let fs_due_date_obj = new Date(ta.first_schedule);
        let fs_day = fs_due_date_obj.getDate();
        fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
        let fs_month = fs_due_date_obj.getMonth() + 1;
        fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
        let fs_year = fs_due_date_obj.getFullYear();
        ta.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`

        let ta_id = this.props.ta === null ? '0' : this.props.ta.unique_testing_act_id
        ta.attachments = 'attachments' in ta && ta.attachments !== null ? ta.attachments : []
        let gc_companies = Store.getStoreData('gc_companies')
        let user_info = Store.getStoreData('user_info')
        ta.created_by = user_info.ID;
        let user_id = user_info.ID.toString()
        let role = Store.getStoreData('role')
        let contacts = Store.getStoreData('contacts')
        let user_roles = Store.getStoreData('user_roles')
        // let gc_companies = Store.getStoreData('gc_companies')
        let company_users_list = {}
        for (let gc of gc_companies) {
            if (gc.is_gc) {
                company_users_list[gc.id.toString()] = gc.users
            } else {
                company_users_list['0'] = gc.users
            }
        }
        let company_users = company_users_list[plan.gc_id.toString()]
        let risk_owners = []
        let found = false
        for (let u_id in contacts) {
            if (user_roles[u_id] === 'admin_manager') {
                risk_owners.push({ id: u_id, name: contacts[u_id] })
                if (ta.testing_owner.toString() === u_id) found = true
            } else if (user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner' || user_roles[u_id] === 'subcat_owner' || user_roles[u_id] === 'team' || user_roles[u_id] === 'view_only_manager') {
                if (u_id in company_users) {
                    risk_owners.push({ id: u_id, name: contacts[u_id] })
                    if (ta.testing_owner.toString() === u_id) found = true
                }
            }
        }
        // console.log("testing_activities :::::", ta)

        let due_date = new Date(ta.due_date)
        ta.due_date = due_date
        old_tact.due_date = due_date

        if (!found) ta.testing_owner = '0'
        let ready = true
        let risk_owners_sorted =   risk_owners.sort((a, b) => a.name.localeCompare(b.name))

        this.setState({ ready, plan, subcategory, risk, ta, risk_owners:risk_owners_sorted, ta_id, binFiles: ta.attachments, initial_testing_freq: ta.testing_freq, initial_first_schedule: ta.first_schedule, old_tact, due_date: due_date })

    }


    isBase64(str) {
        console.log('isBase64::=>', str)
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    changeStringToAtob = (str_value) => {
        let str_text = str_value === null ? '' : str_value;
        console.log('str_text::', str_text, this.isBase64(str_text))
        if (this.isBase64(str_text)) {
            str_text = window.decodeURIComponent(atob(str_text))
        }
        return str_text;
    }

    handleChange = (e) => {
        let field_name = e.target.name
        let ta = this.state.ta
        ta[field_name] = e.target.value
        // this.setState({ta})

        if (field_name === "testing_description") {

            this.setState({ ta: ta, isIncludeDescription: false, showmessage: false })

        } else if (field_name === "testing_activity") {

            this.setState({ ta: ta, isIncludeTaName: false, showmessage: false })

        } else if (field_name === "testing_owner") {

            this.setState({ ta: ta, IsActionAssignedToProvided: false, showmessage: false })

        } else {
            this.setState({ ta: ta })
        }
    }
    onScheduleDateChange = (date) => {
        let ta = this.state.ta
        ta.first_schedule = date

        let fs_due_date_obj = new Date(ta.first_schedule);
        let fs_day = fs_due_date_obj.getDate();
        fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
        let fs_month = fs_due_date_obj.getMonth() + 1;
        fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
        let fs_year = fs_due_date_obj.getFullYear();
        ta.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`

        ta.due_date = date

        this.setState({ ta, due_date: date });
    }

    onFrequencyChange = (date) => {
        let ta = this.state.ta
        ta.first_schedule = date
        this.setState({ ta });
    }

    filesLoaded = (files) => {
        // console.log("TAMODAL files", files)
        for (let i = 0; i < files.length; i++) {
            console.log("curBinFiles_1", files[i])
            Object.assign(files[i], { user_id: Store.getStoreData('user_info').ID });
            Object.assign(files[i], { upload_date: Date.now() });
            Object.assign(files[i], { type: "complete_resource" });

            // if (typeof file_exists[jsonObj[i].name] === "undefined") {
            //     cur_files_json.push(jsonObj[i]);
            //     file_exists[jsonObj[i].name] = true;
            // }
            // curBinFiles_1 = cur_files_json.filter((cur_files_json, index, self) => index === self.findIndex((t) => (t.name === cur_files_json.name && t.uid === cur_files_json.uid)));

        }
        this.setState({ curBinFiles: files });
    }
    // saveTstingActivity = () => {
    //     let api = new APICall()
    //     let record = this.state.ta
    //     let plan = this.state.plan
    //     let sub_command = this.state.ta_id === '0' ? 'add_record' : 'update_record'
    //     record.ref_ids = ['testing_activities', record.unique_testing_act_id, record.testing_activity]
    //     record.testing_description = btoa(window.encodeURIComponent(Store.getStoreData('testing_description') === null ? "" : Store.getStoreData('testing_description')))
    //     record.testing_objectives = btoa(window.encodeURIComponent(Store.getStoreData('testing_objectives') === null ? "" : Store.getStoreData('testing_objectives')))
    //     record.testing_method = btoa(window.encodeURIComponent(Store.getStoreData('testing_method') === null ? "" : Store.getStoreData('testing_method')))
    //     record.additional_guidance = btoa(window.encodeURIComponent(Store.getStoreData('additional_guidance') === null ? "" : Store.getStoreData('additional_guidance')))


    //     let fs_due_date_obj = new Date(record.first_schedule);
    //     let fs_day = fs_due_date_obj.getDate();
    //     fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
    //     let fs_month = fs_due_date_obj.getMonth() + 1;
    //     fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
    //     let fs_year = fs_due_date_obj.getFullYear();
    //     record.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`



    //     for(let file in this.state.binFiles){
    //         record.attachments.push({file_id: this.state.binFiles[file].uid, name: this.state.binFiles[file].name})
    //     }
    //     // record.attachments = attachments;
    //     let postData = {command: 'plan', sub_command: sub_command, record: record, object_id: plan.unique_plan_id}
    //     if(this.state.binFiles.length > 0){
    //         postData['attachments'] = this.state.binFiles
    //     }
    //     api.command(postData, this.processTestingActivity)
    // }
    saveTstingActivity = () => {

        //console.log("This TAMODAL state::",this.state,Store.getStoreData('testing_description'))
        if ((this.state.ta.testing_description === '' && (Store.getStoreData('testing_description') === "" || Store.getStoreData('testing_description') === null)) || (Store.getStoreData('testing_description') === "" && Store.getStoreData('testing_description') !== null) || this.state.ta.testing_activity === '' || this.state.ta.testing_owner.toString() === "0") {
            // alert(0)
            if (this.state.ta.testing_description === '') {

                if (Store.getStoreData('testing_description') === "" || Store.getStoreData('testing_description') === null) {
                    //alert(1)
                    this.setState({ isIncludeDescription: true, showmessage: true })
                }


            }
            //alert(11)
            if (Store.getStoreData('testing_description') === "" && Store.getStoreData('testing_description') !== null) {
                alert(2)
                this.setState({ isIncludeDescription: true, showmessage: true })
            }
            //alert(3)
            if (this.state.ta.testing_activity === '') {

                this.setState({ isIncludeTaName: true, showmessage: true })
            }

            if (this.state.ta.testing_owner.toString() === "0") {

                this.setState({ IsActionAssignedToProvided: true, showmessage: true })
            }
        } else {

            let activity_flow = 'petty_change';

            if (this.state.ta_id !== '0') {
                if (new Date(this.state.ta.first_schedule).getTime() !== new Date(this.state.initial_first_schedule).getTime()) {
                    activity_flow = 'testing_scheduled_date_changed';
                } else {
                    if (this.state.initial_testing_freq !== this.state.ta.testing_freq) {
                        activity_flow = 'testing_frequency_changed';
                    }
                }

                if (activity_flow === 'petty_change') {
                    this.processSubmitTestingActivities(true, { activity_flow: activity_flow })
                } else {

                    if (activity_flow === 'testing_scheduled_date_changed') {
                        let alert_param = { title: 'Confirm', message: 'Scheduled activities for early in the year may not be complete and all remedial actions attached to this Testing Activity with a future date will be removed. Are you sure you want to change the scheduled date?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.processSubmitTestingActivities, stack: { activity_flow: activity_flow }, continue_to_next_screen_if_yes: true, next_messages: [{ value: 1, msg: 'Date of the entire series should be changed' }, { value: 2, msg: 'Only this particular testing activity' }], option_type: 'Radio' };
                        this.setState({ alert_param: alert_param });
                    } else {
                        let alert_param = { title: 'Confirm', message: 'Scheduled activities for early in the year may not be complete and all remedial actions attached to this Testing Activity with a future date will be removed. Are you sure you want to change the frequency?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.processSubmitTestingActivities, stack: { activity_flow: activity_flow } };
                        this.setState({ alert_param: alert_param });
                    }
                }
            } else {

                this.processSubmitTestingActivities(true, { activity_flow: activity_flow })
            }
        }
    }

    processSubmitTestingActivities = (result, stack) => {
        console.log("TAMODAL:: A", result, " B::", stack)
        // this.setState({alert_param: null}); 
        this.setState({is_loader:true})
        if (this.state.alert_param !== null) {
            this.setState({ alert_param: null });
        }
        if (result === false) return;

        let api = new APICall()
        let record = this.state.ta
        let record_old = null
        let plan = this.state.plan
        let sub_command = this.state.ta_id === '0' ? 'add_record' : 'update_record'

        let next_due_date = this.next_due_date(record.first_schedule,record.testing_freq)

        record.next_due_date = next_due_date


        record.ref_ids = ['testing_activities', record.unique_testing_act_id, record.testing_activity]

        console.log('testing_description Store::', Store.getStoreData('testing_description'))
        console.log('testing_description record::', record.testing_description)
        record.testing_description = Store.getStoreData('testing_description') === null ? btoa(window.encodeURIComponent(record.testing_description)) : btoa(window.encodeURIComponent(Store.getStoreData('testing_description')))
        record.testing_objectives = Store.getStoreData('testing_objectives') === null ? btoa(window.encodeURIComponent(record.testing_objectives)) : btoa(window.encodeURIComponent(Store.getStoreData('testing_objectives')))
        record.testing_method = Store.getStoreData('testing_method') === null ? btoa(window.encodeURIComponent(record.testing_method)) : btoa(window.encodeURIComponent(Store.getStoreData('testing_method')))
        record.additional_guidance = Store.getStoreData('additional_guidance') === null ? btoa(window.encodeURIComponent(record.additional_guidance)) : btoa(window.encodeURIComponent(Store.getStoreData('additional_guidance')))

        console.log("TAMODAL rec", record)

        let fs_due_date_obj = new Date(record.first_schedule);
        let fs_day = fs_due_date_obj.getDate();
        fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
        let fs_month = fs_due_date_obj.getMonth() + 1;
        fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
        let fs_year = fs_due_date_obj.getFullYear();
        // record.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`
        

        let selected_plan = Store.getStoreData('selected_plan')
        let gc_companies = Store.getStoreData('gc_companies')
        let parent_comp_id = gc_companies.find(d => d.is_gc === false).id
        let first_task_ref_id = selected_plan.id+'-'+selected_plan.gc_id+'-'+record.unique_risk_id+'-'+record.unique_subcat_id+'-'+record.unique_testing_act_id+'-'+`${fs_year}${fs_month}${fs_day}`+'-'+parent_comp_id;
        // console.log('ref_id',ref_id)
        // return;
        let change_reason = 0   
        let ref_to_delete = ''
        if (stack.activity_flow !== "petty_change") {
            record_old = this.state.old_tact
            record_old.ref_ids = ['testing_activities', record_old.unique_testing_act_id, record_old.testing_activity]
            // console.log("testing_activities old",record_old)
            // record_old.delete_reason.frequency_change = null
            // record_old.delete_reason.date_scheduled_change_single = null
            // record_old.delete_reason.date_change_series = null
            
                    let fs_due_date_obj_ol = new Date(record_old.due_date);
                    // console.log("TAMODAL::fs_due_date_obj",fs_due_date_obj_ol)
                    let fs_day_ol = fs_due_date_obj_ol.getDate();
                    fs_day_ol = (fs_day_ol > 9) ? fs_day_ol.toString() : `0${fs_day_ol}`;
                    let fs_month_ol = fs_due_date_obj_ol.getMonth() + 1;
                    fs_month_ol = (fs_month_ol > 9) ? fs_month_ol.toString() : `0${fs_month_ol}`;
                    let fs_year_ol = fs_due_date_obj_ol.getFullYear();

            if (stack.activity_flow === "testing_scheduled_date_changed") {


                if (stack.option_value.toString() === '1') {
                    // record_old.delete_reason.frequency_change = null
                    // record_old.delete_reason.date_scheduled_change_single = null
                    record_old.delete_reason.date_change_series = {
                        "reason": 'date_change_series',
                        // "starting_from": `${fs_year}${fs_month}${fs_day}`
                        "starting_from": `${fs_year_ol}${fs_month_ol}${fs_day_ol}`
                    }
                    change_reason = 1

                    record.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`
                    record.first_schedule = fs_due_date_obj
                } else {
                    let missing_at = []

                    //record.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`
                    // console.log("TAMODAL::fs_day",fs_day_ol)
                    // console.log("TAMODAL::fs_month",fs_month_ol)
                    // console.log("TAMODAL::fs_year",fs_year_ol)

                    missing_at.push({
                        'actual': `${fs_year_ol}${fs_month_ol}${fs_day_ol}`,
                        'tobeShown': `${fs_year}-${fs_month}-${fs_day}T00:00:00`,
                        'tobeShown_str': `${fs_day}/${fs_month}/${fs_year}`
                    })

                    //record_old.delete_reason.frequency_change = null
                    record_old.delete_reason.date_scheduled_change_single = {
                        'reason': 'date_scheduled_change_single',
                        'missing_at': missing_at
                    }
                    change_reason = 2
                    // record_old.delete_reason.date_change_series = null

                }

            }
            if (this.state.initial_testing_freq !== this.state.ta.testing_freq) {

                record_old.delete_reason.frequency_change = {
                    "reason": "frequency_change",
                    "starting_from": `${fs_year_ol}${fs_month_ol}${fs_day_ol}`
                }
                change_reason = 3
                // record_old.delete_reason.date_change_series = null
                // record_old.delete_reason.date_scheduled_change_single = null

                record.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`
                record.first_schedule = fs_due_date_obj
            }

            if(change_reason === 1 || change_reason === 3){
                

                    console.log('record_old',record_old)
                    record_old.is_deleted = true
                    record.is_deleted = false
                    record.delete_reason =  {
                        "frequency_change": null,
                        "date_change_series": null,
                        "date_scheduled_change_single": null
                    }
                    let plan_ref = plan.unique_plan_id
                    let current_gcid = Store.getStoreData('plan_gc_id')
                    let gc_plans = Store.getStoreData('gc_plans')[current_gcid]
                    console.log('gc_plans:',current_gcid,plan_ref,gc_plans)
                    let plan_details = Object.values(gc_plans).filter(function(dt){return dt.plan_ref_id === plan_ref })

                    ref_to_delete = plan_details[0].id+'-'+current_gcid+'-'+record_old.unique_risk_id+'-'+record_old.unique_subcat_id+'-'+record_old.unique_testing_act_id



            }

            record_old.due_date = this.state.initial_first_schedule
            record_old.testing_freq = this.state.initial_testing_freq
            record.__record_id = this.genKey(20)
            let new_unique_testing_act_id = this.genKey(20)
            record.unique_testing_act_id = new_unique_testing_act_id
            record.ref_ids = ['testing_activities', new_unique_testing_act_id, record.testing_activity]
            sub_command = 'update_del_record'
        }

        //var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
        //console.log("TAMODAL bins",bin_files)
        // for (let file in this.state.curBinFiles) {
        //     record.attachments.push({ file_id: this.state.curBinFiles[file].uid, name: this.state.curBinFiles[file].name,bin_file:this.state.binFiles[file].bin_file })
        // }
        var bin_files = [...this.state.binFiles, ...this.state.curBinFiles]
        record.attachments = bin_files
        // record.attachments = attachments;


        let postData = { command: 'plan', sub_command: sub_command, section: 'ta', ref_to_delete:ref_to_delete, record: record, record_old: record_old, object_id: plan.unique_plan_id, change_reason: change_reason,ref_id:first_task_ref_id }
        console.log("TAMODAL curBinFiles", this.state.curBinFiles)
        if (bin_files.length > 0) {
            postData['attachments'] = bin_files;
        }
        console.log("TAMODAL testing_activities postDATA", postData)
        api.command(postData, this.processTestingActivity)
        //this.setState({is_loader:true})
    }

    processTestingActivity = (result) => {
        this.setState({is_loader:false})
        if (Store.getStoreData('testing_description') !== null) {
            Store.updateStore('testing_description', null)
        }
        if (Store.getStoreData('testing_objectives') !== null) {
            Store.updateStore('testing_objectives', null)
        }
        if (Store.getStoreData('testing_method') !== null) {
            Store.updateStore('testing_method', null)
        }
        if (Store.getStoreData('additional_guidance') !== null) {
            Store.updateStore('additional_guidance', null)
        }

        //alert('Testing Activity successfully saved.')

        // this.state.is_loader = false;

        let plan = this.state.plan
        //EventBus.raiseEvent('is_loader', false)

        this.props.reloadTestingactivity(plan.unique_plan_id)

        // let alert_param = {
        //     title: 'Warning', message: 'Testing Activity successfully saved.', ok_text: 'Yes', cancel_text: 'No', confirm: true,
        //     alertHandler: this.AlertHandler, stack: { }
        // }
        // this.setState({ alert_param: alert_param })
    }

	   next_due_date = (due_date,frequency) => {
	            let freq = 0

	            switch(frequency) {
	                case 'Annually': freq = 365*24*60*60*1000; break;
	                case 'Semi-Annually': freq = 180*24*60*60*1000; break;
	                case 'Quarterly': freq = 120*24*60*60*1000; break;
	                case 'Every-Two-Months': freq = 60*24*60*60*1000; break;
	                case 'Monthly': freq = 30*24*60*60*1000; break;
	                case 'Bi-weekly': freq = 14*24*60*60*1000; break;
	                case 'Weekly': freq = 7*24*60*60*1000; break;
	                case 'Daily': freq = 1*24*60*60*1000; break;
	                case 'Once': freq = 0; break;

	            }

	            const selected_date = new Date(due_date);
	            let target_date = new Date(selected_date.getTime()+freq);
	            const yyyy = target_date.getFullYear();
	            let mm = target_date.getMonth() + 1; // Months start at 0!
	            let dd = target_date.getDate();

	            if (dd < 10) dd = '0' + dd;
	            if (mm < 10) mm = '0' + mm;

	            let next_due_date = yyyy + '/' + mm + '/' + dd;
	            // testing_activities[i].first_schedule = yyyy +'-'+ mm +'-'+ dd +'T00:00:00';

	            return next_due_date;
	}

    AlertHandler = (result, stack) => {
        this.setState({ alert_param: null })
        let plan = this.state.plan
        this.props.reloadTestingactivity(plan.unique_plan_id)

        //window.location = '/select/ServiceBasedLanding';
    }
    filesRemovedType2 = (jsonObj) => {
        console.log('filesRemovedType2::',jsonObj)
        let curBinFiles = this.state.curBinFiles.filter(item => item.uid !== jsonObj);
        this.setState({ curBinFiles });
    }
    updateAttachments = (uid) => {
        let binFiles = this.state.binFiles.filter(item => item.uid !== uid);
        this.setState({binFiles});
    }
    render() {
        console.log("TAMODAL State::", this.state)
        if (!this.state.ready) return <div />
            let testing_objectives = JSON.parse(JSON.stringify(this.state.ta.testing_objectives))
            if (this.isBase64(testing_objectives)) {
                testing_objectives = window.decodeURIComponent(atob(testing_objectives))
            }
            let testing_description = JSON.parse(JSON.stringify(this.state.ta.testing_description))
            if (this.isBase64(testing_description)) {
                testing_description = window.decodeURIComponent(atob(testing_description))
            }
            let testing_method = JSON.parse(JSON.stringify(this.state.ta.testing_method))
            if (this.isBase64(testing_method)) {
                testing_method = window.decodeURIComponent(atob(testing_method))
            }
            let additional_guidance = JSON.parse(JSON.stringify(this.state.ta.additional_guidance))
            if (this.isBase64(additional_guidance)) {
                additional_guidance = window.decodeURIComponent(atob(additional_guidance))
            } 
        return (<>
            {
                (() => {
                    if (this.state.is_loader) {
                        return <div><CSLLoader style={{ position: 'absolute' }} /></div>
                    }
                })()
            }

            <AlertBox alertParam={this.state.alert_param} />

            <InactiveOverlay style={{ height: "105%" }} />

            <MRModalContainer style={{ zIndex: "1050" }}>
                <MRModalHeader>
                    <MRModalHeaderText> Create Testing Activities  </MRModalHeaderText>
                    <div style={{ clear: "both" }}></div>
                    <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{ clear: "both" }}></div>
                </MRModalHeader>
                <MRModalBody>
                    {
                        (() => {
                            if (this.state.subcategory === null) {
                                return (
                                    <div>
                                        {/*<MRModalSummary style={{ marginLeft: "35px", width: "90%" }}>
                                            <MRModalLabel style={{ fontWeight: "600" }}>Risk Area</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "31px" }}>{this.state.risk.risk_area_name}</MRModalLabel>
                                            <div style={{ clear: "both", marginTop: "15px" }}></div>
                                            <MRModalLabel style={{ fontWeight: "600", marginTop: "10px" }}>Description</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "15px" }}><div style={{ paddingLeft: "116px", marginTop: "-15px" }}>{this.state.risk.description}</div></MRModalLabel>
                                            <div style={{ clear: "both", marginTop: "15px" }}></div>
                                            <MRModalLabel style={{ fontWeight: "600" }}>Risk Owner</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "19px" }}>{Store.getStoreData('contacts')[this.state.risk.risk_owner_id]}</MRModalLabel>
                                            <div style={{ clear: "both", marginTop: "15px" }}></div>
                                            <MRModalLabel style={{ fontWeight: "600" }}>Status</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "51px" }}>{this.state.risk.status}</MRModalLabel>
                                        </MRModalSummary>
                                */}
                                        <MRModalSummary style={{ marginLeft: "35px", width: "90%" }}>
                                            <GridContainer>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Risk Area</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{this.state.risk.risk_area_name}</div>
                                                </GridItem>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Description</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{this.state.risk.description}</div>
                                                </GridItem>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Risk Owner</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{Store.getStoreData('contacts')[this.state.risk.risk_owner_id]}</div>
                                                </GridItem>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Status</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{this.state.risk.status}</div>
                                                </GridItem>

                                            </GridContainer>


                                        </MRModalSummary>

                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        {/*<MRModalSummary style={{ marginLeft: "35px", width: "90%" }}>
                                            <MRModalLabel style={{ fontWeight: "600" }}>Subcategory</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "31px" }}>{this.state.subcategory.risk_subcategory_name}</MRModalLabel>
                                            <div style={{ clear: "both", marginTop: "15px" }}></div>
                                            <MRModalLabel style={{ fontWeight: "600", marginTop: "10px" }}>Description</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "39px" }}><div style={{ paddingLeft: "116px", marginTop: "-15px" }}>{this.state.subcategory.subcat_description}</div></MRModalLabel>
                                            <div style={{ clear: "both", marginTop: "15px" }}></div>
                                            <MRModalLabel style={{ fontWeight: "600" }}>Assign To</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "54px" }}>{Store.getStoreData('contacts')[this.state.subcategory.subcat_assignto]}</MRModalLabel>
                                            <div style={{ clear: "both", marginTop: "15px" }}></div>
                                            <MRModalLabel style={{ fontWeight: "600" }}>Status</MRModalLabel>
                                            <MRModalLabel style={{ fontWeight: "bold", marginLeft: "77px" }}>{this.state.subcategory.status}</MRModalLabel>
                                        </MRModalSummary>
                                */}

                                        <MRModalSummary style={{ marginLeft: "35px", width: "90%" }}>
                                        <GridContainer>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Subcategory</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{this.state.subcategory.risk_subcategory_name}</div>
                                                </GridItem>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Description</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{this.state.subcategory.subcat_description}</div>
                                                </GridItem>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Assign To</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{Store.getStoreData('contacts')[this.state.subcategory.subcat_assignto]}</div>
                                                </GridItem>
                                                <GridItem>
                                                    <MRModalLabel style={{ fontWeight: "600" }}>Status</MRModalLabel>
                                                </GridItem>
                                                <GridItem>
                                                    <div style={{ fontWeight: "bold" }}>{this.state.subcategory.subcat_status}</div>
                                                </GridItem>

                                            </GridContainer>

                                        </MRModalSummary>
                                    </div>
                                )
                            }

                        })()
                    }
                    <div>
                        <MRModalDivider />
                        <MRModalLabel style={{ marginLeft: "35px" }}>Activity<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                        <div style={{ clear: "both", marginTop: "8px" }}></div>

                        <MRModalInput name="testing_activity" onChange={this.handleChange} value={this.state.ta.testing_activity} style={{ width: "calc(98% - 95px)", marginLeft: "35px" }}></MRModalInput>
                        {
                            (() => {
                                if (this.state.isIncludeTaName === true && this.state.showmessage === true) return (<>
                                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                        <div style={{ clear: "both" }}></div>
                                        <label style={{ color: "#FF0000" }}>Please provide testing activity.</label>
                                    </div>
                                </>)
                            })()
                        }
                        <div style={{ clear: "both", marginTop: "10px" }}></div>
                        <div>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Description<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                        </div>
                        <div style={{ clear: "both", marginTop: "10px" }}></div>

                        <div style={{ width: "calc(98% - 80px)", marginLeft: "calc(2% + 10px)", marginTop: "-9px" }}>
                            <HtmlEditor content={JSON.parse(JSON.stringify(testing_description))} field_name={'testing_description'} />
                        </div>
                        {
                            (() => {
                                if (this.state.isIncludeDescription === true && this.state.showmessage === true) return (<>
                                    <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                        <div style={{ clear: "both" }}></div>
                                        <label style={{ color: "#FF0000" }}>Please provide description.</label>
                                    </div>
                                </>)
                            })()
                        }
                        <div style={{ clear: "both", marginTop: "10px" }}></div>
                        <div>
                            <div style={{ width: "300px", float: "left" }}>
                                <MRModalLabel style={{ marginLeft: "35px" }}>Risk Area<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                                <div style={{ clear: "both", marginTop: "10px" }}></div>
                                <MRTestingLabel style={{ marginLeft: "35px" }}>{this.state.risk.risk_area_name}<FaAngleDown style={{ float: "right" }} /></MRTestingLabel>
                            </div>
                            <div style={{ width: "300px", float: "right", marginRight: "50px" }}>
                                <MRModalLabel style={{ marginLeft: "10px" }}>Frequency<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                                <div style={{ clear: "both", marginTop: "5px" }}></div>
                                <TestingSelect style={{ marginLeft: "10px", width: "270px" }} name="testing_freq" onChange={this.handleChange} value={this.state.ta.testing_freq}  >
                                    <option key="Annually" value="Annually">Annually</option>
                                    <option key="Semi-Annually" value="Semi-Annually">Semi-Annually</option>
                                    <option key="Quarterly" value="Quarterly">Quarterly</option>
                                    <option key="Every-Two-Months" value="Every-Two-Months">Every-Two-Months</option>
                                    <option key="Monthly" value="Monthly">Monthly</option>
                                    <option key="Bi-weekly" value="Bi-weekly">Every-Two-Weeks</option>
                                    <option key="Weekly" value="Weekly">Weekly</option>
                                    <option key="Daily" value="Daily">Daily</option>
                                    <option key="Once" value="Once">Once</option>
                                </TestingSelect>
                            </div>
                        </div>
                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div>
                            <div style={{ width: "300px", float: "left", display: "inline-block", maxWidth: "20%" }}>
                                <MRModalLabel style={{ marginLeft: "35px" }}>Due Date<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                                <div style={{ clear: "both", paddingTop: "20px" }}></div>
                                <CSLDateCover style={{ display: "inline-block", marginLeft: "35px" }}>
                                    <DatePicker
                                        onChange={this.onScheduleDateChange}
                                        clearIcon={null}
                                        calendarIcon={null}
                                        locale={"en-GB"}
                                        value={this.state.due_date}
                                        style={{ border: "none", fontWeight: "bold" }}
                                        className="csldatecontainer"
                                    />
                                </CSLDateCover>
                            </div>
                        </div>

                        <div style={{ width: "300px", float: "right", marginRight: "80px", display: "inline-block", maxWidth: "20%" }}>

                            <MRModalLabel style={{ marginLeft: "10px" }}>Owner<label style={{ color: "#FF0000" }}>* </label></MRModalLabel>
                            <div style={{ clear: "both", paddingTop: "20px" }}></div>
                            <TestingSelect style={{ marginLeft: "10px", width: "100%" }} name="testing_owner" onChange={this.handleChange} value={this.state.ta.testing_owner}>
                                <option value="0">Select Testing owner...</option>
                                {
                                    this.state.risk_owners.map((r, i) => {
                                        return <option key={i} value={r.id}>{r.name}</option>
                                    })
                                }

                            </TestingSelect>
                            {
                                (() => {
                                    if (this.state.IsActionAssignedToProvided === true && this.state.showmessage === true) return (<>
                                        <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                            <div style={{ clear: "both" }}></div>
                                            <label style={{ color: "#FF0000" }}>Please select Testing Owner.</label>
                                        </div>
                                    </>)
                                })()
                            }
                        </div>

                        <div style={{ clear: "both", paddingTop: "20px" }}></div>
                        <div style={{ display: "inlineBlock" }}>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Testing Objectives</MRModalLabel>
                        </div>
                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div >
                            <div style={{ width: "calc(98% - 80px)", marginLeft: "calc(2% + 10px)", marginTop: "-9px" }}>
                                <HtmlEditor content={testing_objectives} field_name={'testing_objectives'} />
                            </div>
                        </div>

                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div style={{ display: "inlineBlock" }}>
                            <MRModalLabel style={{ marginLeft: "35px" }}>High Level Testing Method</MRModalLabel>
                        </div>
                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div >
                            <div style={{ width: "calc(98% - 80px)", marginLeft: "calc(2% + 10px)", marginTop: "-9px" }}>
                                <HtmlEditor content={JSON.parse(JSON.stringify(testing_method))} field_name={'testing_method'} />
                            </div>
                        </div>

                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div style={{ display: "inlineBlock" }}>
                            <MRModalLabel style={{ marginLeft: "35px" }}>Additional Guidance</MRModalLabel>
                        </div>
                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div >
                            <div style={{ width: "calc(98% - 80px)", marginLeft: "calc(2% + 10px)", marginTop: "-9px" }}>
                                <HtmlEditor content={JSON.parse(JSON.stringify(additional_guidance))} field_name={'additional_guidance'} />
                            </div>
                        </div>

                        <div style={{ clear: "both", paddingTop: "10px" }}></div>
                        <div >
                            <MRModalLabel style={{ marginLeft: "35px" }}>Attachments</MRModalLabel>

                        </div>
                        <div style={{ marginLeft: "35px", width: "50%" }}>
                            {
                                (() => {
                                  if (this.state.binFiles.length > 0) { 
                                    return <CSLAttachmentViewer files={this.state.binFiles} updateAttachments={this.updateAttachments}  downloadCallback = {'download_attachment'} edit_prop = {true}/>;
                                  }
                                })()
                            }
                            <Dropzone onFilesAdded={this.filesLoaded} attachmentViewerFiles={this.state.binFiles} initFiles={this.state.curBinFiles} height={"200px"} onRemoveFile={this.filesRemovedType2}/>
                        </div>
                        <div style={{ clear: "both", paddingTop: "10px" }}></div>

                    </div>
                </MRModalBody>
                <div style={{ clear: "both", marginTop: "10px" }}></div>
                <MRModalFooter>
                    <div style={{ marginRight: "35px", paddingTop: "10px" }}>
                        {/*<CatBtn onClick={this.saveTstingActivity} style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }}  >Submit & Close</CatBtn>*/}
                        <CatBtn onClick={this.saveTstingActivity} style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }}  >Submit</CatBtn>
                        <CatBtn onClick={this.props.closeModal} style={{ backgroundColor: "#ffffff", border: "1px solid #F0F1F1", color: "black", float: "right" }} >Cancel</CatBtn>
                    </div >
                    <div style={{ clear: "both" }}></div>
                </MRModalFooter>
            </MRModalContainer>
        </>)
    }
}

export default TAModal;
