import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FaEllipsisV, FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { FiEdit, FiChevronUp, FiChevronDown , FiChevronRight } from "react-icons/fi";
import { GoAlert } from "react-icons/go";
import ReactTooltip from 'react-tooltip';
import CSLLoader from '../Common/CSLLoader';
import Store from '../../Common/Store.js';
import EventBus from '../../Common/EventBus.js';
import APICall from '../../Common/APICall.js';
import GCPanel from '../Includes/GCPanel';
import TestingActivityList from '../Includes/TestingActivityList';
import FollowupActionsList from '../Includes/FollowupActionsList';
import RAModal from './Modals/RAModal.js'
import TAModal from './Modals/TAModal.js'
import SCModal from './Modals/SCModal.js'
import APModal from './Modals/APModal.js'
import CPModal from './Modals/CPModal.js'
import CellModal from './Modals/CellModal.js'
import AlertBox from '../Common/AlertBox.js';
import CMPUtils from '../Common/CMPUtils.js';
import filters from '../RDR/reportFilters.js';

const TABoxContainer = styled.div`
    box-sizing: border-box;
    /*border-right: 1px solid #dadbdc;*/
    /*padding: 5px;*/
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 100px);
    width: calc(100% - 650px);
    position: fixed;
    top: 38px;
    left: 300px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;

const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
    height:calc(100vh - 260px);
    overflow-y:auto
`;

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;

const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;

const MRModalBodyLabel = styled.div`
    position: absolute;
    top: 300px;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    text-align: center;
`

const RiskBtn = styled.button`
  background-color: #213E61;
  border: 1px solid #213E61;
  border-radius: 4px;
  padding: 9px 37px;
  color: #ffffff;
  cursor: pointer;
  width: calc(100%);
`;

const MRSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 22px;
    width: 250px;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #292929;
`;

const CalendarRow = styled.div`
    display: flex;
    width: 100%;
    height: 35px;
    background-color: white;
`

const DevDiv = styled.div`
    position: fixed;
    top: 60px;
    right: 10px;
    display: flex;
    width: 100%;
    height: 35px;
    background-color: white;
    justify-content: flex-end;
    border: 1px solid rgb(191, 187, 187);
`

const CalendarRowHeader = styled.div`
    width: 240px;
    border-bottom: 1px solid rgb(191, 187, 187);
    display: flex;
`

const CalendarYearCell = styled.div`
    background-color: rgb(244, 247, 251);
    width: 40px;
    font-weight: 800;
`
const CheckboxLabel = styled.div`
    background-color: white;
    color: #01B0A2,
    min-width: 40px;
    font-weight: 800;
    font-size: 14px;
    padding-left: 8px;
`

const RiskHeaderCell = styled.div`
    width: 200px;
    padding-left: 5px;
    padding-top: 7px;
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    margin-right: 10px;
`
const SubcatHeaderCell = styled.div`
    width: 200px;
    padding-left: 15px;
    font-size: 11px;
    padding-top: 7px;
    font-weight: bold;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    margin-right: 10px;
`

const HeaderIconCell = styled.div`
    width: 40px;
    padding: 5px;
    font-size: 14px;
    float: right;
    cursor: pointer;
	margin-right: 5px;
`

const CalendarRowMonthsHolder = styled.div`
    width: calc(100% - 280px);
    display: flex;
`
const CalendarHeaderHolder = styled.div`
    width: calc(100% - 280px);
    display: flex;
    justify-content: flex-end;
`
const CalendarRowMonthCell = styled.div`
    width: 8.33%;
    margin-top: 3px;
    text-align: center;
    padding-left: 5px;
`

const CellBox = styled.div`
    width: 80%;
    padding: 3px;
    text-align: center;
    height: 20px;
    border-radius: 4px;
`

const ClearFloat = styled.div`
    clear: both;
`

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


// "mrj6F01Z14jK-2023-01-07"
function includeTasks(visible_year, tas, months,risk_id,subcat_id,from) {
    let ret = {}
    
    let month_days = [31,28,31,30,31,30,31,31,30,31,30,31]
    if((0 == visible_year % 4) && (0 != visible_year % 100) || (0 == visible_year % 400)) month_days[1] = 29

    // console.log('includeTasks::tas',tas)
    let role = Store.getStoreData('role');
    let user_id = Store.getStoreData('user_info').ID;
    // console.log("includeTasks task::: ,risk,sub",risk_id,subcat_id)
    let risk_areas = Store.getStoreData('risk_areas')
    let risakarea_key = risk_areas.filter(function (risk) { return risk.unique_risk_id === risk_id });
    // console.log("risakarea_key::",risakarea_key)
    let subcat_key = subcat_id.toString() !== '0' && risakarea_key.length > 0 ? risakarea_key[0].subcategories.filter(function (sub) { return sub.unique_subcat_id === subcat_id }) : [];
    
    // console.log('includeTasks task::: month_days', month_days)

    let all_physical_tasks = Store.getStoreData('plan_tasks')
    for(let t of tas) {
        // console.log('includeTasks::t',t)
        if(subcat_id === "0" && role === 'subcat_owner' && t.testing_owner.toString() !== user_id.toString())continue;
        if(subcat_id !== "0" && role === 'subcat_owner' && (t.testing_owner.toString() !== user_id.toString() && subcat_key[0].subcat_assignto !== user_id.toString() && subcat_key[0].created_by !== user_id.toString()))continue;

        if(t.unique_risk_id !== risk_id && t.unique_subcat_id !== subcat_id)continue;

        let exclusions = 'exclusions' in t ? t.exclusions:{};
        // console.log("")
        // if(t.unique_risk_id === "k1E7qYEnTHyAOeJw1XNm")console.log('includeTasks task::: t', t)
        let stop_at = 100000000
        
        if('delete_reason' in t && t.delete_reason.reason === 'acitivity_by_series'){
        stop_at = ('delete_reason' in t && t.delete_reason.starting_from !== null) ? parseInt(t.delete_reason.starting_from) : stop_at

        }else{
        stop_at = ('delete_reason' in t && t.delete_reason.date_change_series !== null) ? parseInt(t.delete_reason.date_change_series.starting_from) : stop_at
        stop_at = ('delete_reason' in t && 
                    t.delete_reason.frequency_change !== null && 
                    parseInt(t.delete_reason.frequency_change.starting_from) < stop_at) ? parseInt(t.delete_reason.frequency_change.starting_from) : stop_at
        }
        let replaces = {}
        if('delete_reason' in t && typeof t.delete_reason.date_scheduled_change_single !== 'undefined' && t.delete_reason.date_scheduled_change_single !== null) {
            for(let ma of t.delete_reason.date_scheduled_change_single.missing_at) {
                let actual = ma.actual.toString()
                let tobeShown = ma.tobeShown.split('T')[0]
                // if(t.unique_risk_id === "k1E7qYEnTHyAOeJw1XNm")console.log('DELETERES::', tobeShown)

                let y_str = actual.substring(0, 4)
                // let m_str = actual.substring(4, 2)
                // let d_str = actual.substring(6, 2)
                let m_str = actual.substring(4, 6)
                let d_str = actual.substring(6, 8)
                let actual_id = `${t.unique_testing_act_id}_${y_str}-${m_str}-${d_str}`
                let replace_id = `${t.unique_testing_act_id}_${tobeShown}`
                let ts = tobeShown.split('-')
                let m = parseInt(ts[1]) - 1
                let y = parseInt(ts[0])
                let d = parseInt(ts[2])
                replaces[actual_id] = {y:y, m:m, d:d, id: replace_id}
            }
        }
        // if(t.unique_risk_id === "k1E7qYEnTHyAOeJw1XNm")console.log('DELETERES:: replaces', replaces)
        // console.log("HERER:::exclusions::",exclusions)
        // let first_date_parts = t.first_schedule_dt_str.split('/')
        // let first_day = parseInt(first_date_parts[0])
        // let first_month = parseInt(first_date_parts[1])
        // let first_year = parseInt(first_date_parts[2])
        let first_date_parts = ''
        let first_day = ''
        let first_month = ''
        let first_year = ''
        if('first_schedule_dt_str' in t){
            first_date_parts = t.first_schedule_dt_str.split('/')

            first_day = parseInt(first_date_parts[0])
            first_month = parseInt(first_date_parts[1])
            first_year = parseInt(first_date_parts[2])
        }else{
            first_date_parts = t.first_schedule.split('T')[0].split('-')

            first_day = parseInt(first_date_parts[2])
            first_month = parseInt(first_date_parts[1])
            first_year = parseInt(first_date_parts[0])
        }
        let start_at = first_year * 10000 + first_month * 100 + first_day
        let first_day_const = first_day
        // console.log('HERER:::first_month',first_month)
        if( t.unique_testing_act_id === 'kmGyZcjstZ3y' || t.unique_testing_act_id === '6f3rNiZWPKPQ')console.log('includeTasks task:::',t.unique_testing_act_id,' stop_at,start_at,first_day,first_month,first_year', stop_at,start_at,first_day,first_month,first_year)

        let by_days = false 
        let freq = 0

        switch(t.testing_freq) {
            case 'Annually': freq = 12; break;
            case 'Semi-Annually': freq = 6; break;
            case 'Quarterly': freq = 3; break;
            case 'Every-Two-Months': freq = 2; break;
            case 'Monthly': freq = 1; break;
            case 'Bi-weekly': by_days = true; freq = 14; break;
            case 'Weekly': by_days = true; freq = 7; break;
            case 'Daily': by_days = true; freq = 1; break;
        }
        //console.log("GGHHHH",t)
        let base_task = {
                        // due_date: first_year*10000 + first_month*100 + first_day,
                        testing_activity: t.testing_activity,
                        risk_id: t.unique_risk_id,
                        subcat_id: t.unique_subcat_id,
                        testing_act_id: t.unique_testing_act_id,
                        task_id: null,
                        freq: t.testing_freq,
                        cur_lane: 'COMP_CMP_ASSIGNMENT',
                        completed_date: null,
                        cur_assigned_to : t.cur_assigned_to,
                        plan_ref_id: t.plan_ref_id,
                        // testing_objectives: t.testing_objectives,
                        // additional_guidance: t.additional_guidance,
                        // testing_activity: t.testing_activity,
                        testing_owner: t.testing_owner,
                        //testing_owner: t.cur_assigned_to,                        
                        is_task: false
                   }
        // if(t.unique_risk_id === "k1E7qYEnTHyAOeJw1XNm")console.log('includeTasks task::: by_days', by_days)
        // console.log("HERER:::freq::",freq)
        if(by_days) {
            let fs_date =  moment(t.first_schedule)
            // console.log('includeTasks::fs_date',fs_date)
            let fv_date = fs_date
            // console.log('includeTasks::visible_year',visible_year)
            // console.log('includeTasks::first_year',first_year)
            if(visible_year >= first_year) {
            	let obj = CMPUtils.findNextDueDate(t.testing_freq, moment([visible_year]), t.first_schedule)
                let yyyy = obj.yy;
                let mm = obj.mm; // Months start at 0!
                let dd = obj.dd;

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                fv_date =moment( yyyy +'-'+ mm +'-'+ dd +'T00:00:00');
                // let fy_date = moment([visible_year])
                // //console.log("TSTACT fy_date::",fy_date)
                // fv_date = fy_date
                // if(freq > 1) {
                //     let diff = fy_date.diff(fs_date, 'days')
                //     //console.log("TSTACT diff::",diff)
                //     let fd = freq - (diff % freq)
                //     //console.log("TSTACT fd::",fd)
                //     fv_date = moment([visible_year, 0, fd])
                // }
                let d = fv_date
                // console.log('includeTasks::Years',d,new Date(fv_date),d.year(),visible_year)
                while(d.year() === visible_year) {
                    // console.log('includeTasks::Inside while')
                    let m = d.month()
                    let task_id = `${t.unique_testing_act_id}_${d.format('YYYY-MM-DD')}`
                    

                    let task = JSON.parse(JSON.stringify(base_task))


                    if(task_id in replaces) {
                        let rep = replaces[task_id]
                        
                        task.due_date = rep.y +'/'+ (rep.m + 1) +'/' + rep.d
                        
                        let due_date = rep.y * 10000 + (rep.m + 1) * 100 + rep.d
                        task.filter_due_date = due_date

                        let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                        let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                        let exclude_dt = rep.y.toString() + mm_str + this_day_str
                        // console.log('includeTasks::AAdue_date,start_at,stop_at',due_date,start_at,stop_at)

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                        }
                        // if(t.unique_risk_id === "k1E7qYEnTHyAOeJw1XNm")console.log('includeTasks task::: , start_at, stop_at', task, start_at, stop_at)
                        
                    } else {
                        task.due_date = d.year() + '/' + (d.month() + 1) + '/' + d.date()
                        let due_date = d.year() * 10000 + (d.month() + 1) * 100 + d.date()
                        task.filter_due_date = due_date

                        let mm_str = (d.month() + 1) < 10 ? '0' + (d.month() + 1) : (d.month() + 1).toString()
                        let this_day_str = d.date() < 10 ? '0' + d.date() : d.date().toString()
                        let exclude_dt = d.year().toString() + mm_str + this_day_str

                        // console.log('includeTasks::due_date,start_at,stop_at',due_date,start_at,stop_at)

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[m].tasks[task_id] = task                          
                        }
                        // if(t.unique_risk_id === "k1E7qYEnTHyAOeJw1XNm")console.log('includeTasks task::: task, start_at, stop_at', task, start_at, stop_at)
                    }

                    // task.due_date = d.year() +'/' + (d.month() + 1) +'/' + d.date()

                    // months[m].tasks[task_id] = task

                    d.add(freq, 'days')
                }
            }
        } else {
          
            if(freq === 0) {
                if(first_year === visible_year) {
                    let task = JSON.parse(JSON.stringify(base_task))
                    task.due_date = first_year +'/' + first_month +'/'+ first_day
                    let due_date = first_year*10000 + first_month*100 + first_day
                    task.filter_due_date = due_date

                    let task_id = `${t.unique_testing_act_id}_${first_date_parts[2]}-${first_date_parts[1]}-${first_date_parts[0]}`

                    let physical_task_for_this_hint = Object.keys(all_physical_tasks).find(x => t.unique_testing_act_id === all_physical_tasks[x].testing_act_id && first_month === all_physical_tasks[x].month && visible_year === all_physical_tasks[x].year);
                    // let physical_task_for_this_hint1 = all_physical_tasks.filter(function (dt) { t.unique_testing_act_id === dt.testing_act_id && mm === dt.month});
                    if( t.unique_testing_act_id === 'kmGyZcjstZ3y' || t.unique_testing_act_id === '6f3rNiZWPKPQ')console.log('includeTasks task:::',t.unique_testing_act_id,' physical_task_for_this_hint', physical_task_for_this_hint)
                    if( t.unique_testing_act_id === 'kmGyZcjstZ3y' || t.unique_testing_act_id === '6f3rNiZWPKPQ')console.log('includeTasks task:::',t.unique_testing_act_id,' replaces::',replaces)
                    if(typeof physical_task_for_this_hint !== 'undefined'){
                        task_id = physical_task_for_this_hint
                        // this_day = parseInt(physical_task_for_this_hint.split('-')[2])
                    }
                        
                    if(task_id in replaces) {
                        let rep = replaces[task_id]
                        task.due_date = rep.y +'/' + (rep.m + 1) +'/' + rep.d
                        let due_date = rep.y * 10000 + (rep.m + 1) * 100 + rep.d
                        task.filter_due_date = due_date

                        let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                        let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                        let exclude_dt = rep.y.toString() + mm_str + this_day_str
                        // console.log("HERER:::EXCL::",exclude_dt,typeof exclude_dt,exclusions)
                        if(exclude_dt in exclusions && exclusions[parseInt(exclude_dt)] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                        }

                        
                    } else {
                        let mm_str = (first_month) < 10 ? '0' + (first_month) : (first_month).toString()
                        let this_day_str = first_day < 10 ? '0' + first_day : first_day.toString()
                        let exclude_dt = first_year.toString() + mm_str + this_day_str
                        // console.log("HERER:::EXCL2::",exclude_dt,typeof exclude_dt,exclusions)

                        if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                          //exclude
                        }else{
                          if(due_date >= start_at && due_date < stop_at)months[first_month - 1].tasks[task_id] = task
                        }
                        
                    }
                    //months[first_month - 1].tasks[task_id] = task
                }
            } else {
                if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8')console.log('includeTasks task::: months', months)
                for(let m of months) {
                    //console.log("TAS:: m",m)
                    if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8' && m.m === 1)console.log('includeTasks task::: m', m)
                    let mm = m.m + 1
                    let month_diff = (m.y - first_year - 1) * 12 + 12 - first_month + mm
                    if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8')console.log('includeTasks task::: mm', mm)
                    if(month_diff >= 0 && month_diff % freq === 0) {
                        let task = JSON.parse(JSON.stringify(base_task))
                        // let this_day = first_day
                        // if(m.m === 1){
                        //     this_day = first_day > month_days[m.m] ? month_days[m.m] : first_day
                        // }

                        let this_day = first_day > month_days[m.m] ? month_days[m.m] : first_day

                        if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8' && m.m === 1)console.log('includeTasks task::: this_day', this_day,first_day,month_days)

                        let this_day_str = this_day < 10 ? '0' + this_day : this_day.toString()
                        let this_month_str = mm < 10 ? '0' + mm : mm.toString()
                        let task_id = `${t.unique_testing_act_id}_${m.y}-${this_month_str}-${this_day_str}`

                        let physical_task_for_this_hint = Object.keys(all_physical_tasks).find(x => t.unique_testing_act_id === all_physical_tasks[x].testing_act_id && mm === all_physical_tasks[x].month && visible_year === all_physical_tasks[x].year);
                        // let physical_task_for_this_hint1 = all_physical_tasks.filter(function (dt) { t.unique_testing_act_id === dt.testing_act_id && mm === dt.month});
                        if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8' && m.m === 1)console.log('includeTasks task::: physical_task_for_this_hint for month ',mm, physical_task_for_this_hint)
                        if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8' && m.m === 1)console.log('includeTasks task::: physical_task_for_this_hint', physical_task_for_this_hint)
                        if(typeof physical_task_for_this_hint !== 'undefined'){
                            task_id = physical_task_for_this_hint
                            this_day = parseInt(physical_task_for_this_hint.split('-')[2])
                            if( t.unique_testing_act_id === 'IqaKKirwiZuQclcOmxM8')console.log('includeTasks task::: this_day2', this_day)
                        }

                        if(task_id in replaces) {

                            let rep = replaces[task_id]
                            task.due_date = rep.y +'/'+ (rep.m + 1) +'/' + rep.d
                            let due_date = rep.y * 10000 +  (rep.m + 1) * 100 +  rep.d
                            task.filter_due_date = due_date
                            let mm_str = (rep.m + 1) < 10 ? '0' + (rep.m + 1) : (rep.m + 1).toString()
                            let this_day_str = rep.d < 10 ? '0' + rep.d : rep.d.toString()
                            let exclude_dt = rep.y.toString() + mm_str + this_day_str



                            if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                              //exclude
                            }else{
                              if(due_date >= start_at && due_date < stop_at)months[rep.m].tasks[rep.id] = task
                            }
                            
                        } else {
                            task.due_date = m.y +'/'+ mm +'/'+ this_day
                            let due_date = m.y*10000 + mm*100 + this_day
                            task.filter_due_date = due_date
                            let mm_str = mm < 10 ? '0' + mm : mm.toString()
                            let this_day_str = this_day < 10 ? '0' + this_day : this_day.toString()

                            let exclude_dt = m.y.toString()+ mm_str + this_day_str
                            
                            if(exclude_dt in exclusions && exclusions[exclude_dt] === 'single'){
                              //exclude
                            }else{
                                if(due_date >= start_at && due_date < stop_at)m.tasks[task_id] = task
                            }
                        }
                        //task.due_date = m.y+'/' + mm+'/' + this_day

                        //m.tasks[task_id] = task 
                    }
                }
            }
             //console.log("at false TSTACT months::",months)
        }
    }

    let plan_tasks = Store.getStoreData('plan_tasks')
    let followup_tasks = Store.getStoreData('followup_tasks')
    let f_tasks_byid = {}
    if(followup_tasks){
        for(let f of followup_tasks) {
            f_tasks_byid[f.task_id.toString()] = f
        }        
    }

//console.log("INCLUDE tas plan",plan_tasks)
    if(plan_tasks){
        for(let pid in plan_tasks) {
           //console.log("INCLUDE tas plan",plan_tasks[pid].risk_id,risk_id,plan_tasks[pid].subcat_id,subcat_id)
            if((plan_tasks[pid].risk_id.toString() === risk_id.toString() && from === 'risk_area') ||
                (plan_tasks[pid].risk_id.toString() === risk_id.toString() && plan_tasks[pid].subcat_id.toString() === subcat_id.toString() && from === 'subcat')){
                    
                    let t_dt = pid.split('_')[1]
                    let t_dts = t_dt.split('-')
                    let m_num = parseInt(t_dts[1]) - 1
                    let y_num = parseInt(t_dts[0]) 
                    // if(risk_id === 'n1T7p0Ei')console.log('INCLUDE tas pid, m_num, months', pid, m_num,  months[m_num].tasks)
                    if(pid in months[m_num].tasks) {
                        months[m_num].tasks[pid].completed_date = plan_tasks[pid].completed_date
                        months[m_num].tasks[pid].cur_lane = plan_tasks[pid].cur_lane
                        months[m_num].tasks[pid].task_id = plan_tasks[pid].task_id
                        months[m_num].tasks[pid].is_task = true 
                        months[m_num].tasks[pid].approver_id = "approver_id" in plan_tasks[pid] ? plan_tasks[pid].approver_id : 0

                    } else if(y_num === visible_year){
                        // console.log('includeTasks pid, plan_tasks[pid]', pid, plan_tasks[pid])
                        months[m_num].tasks[pid] = plan_tasks[pid]
                        months[m_num].tasks[pid].is_task = true
                    }
            }
        }        
    }


    for(let mm of months) {
        //console.log('includeTasks mm', mm)
        for(let t_id in mm.tasks) {
            if(mm.tasks[t_id].is_task) {
                if(mm.tasks[t_id].child_task_ids !== null && "child_task_ids" in mm.tasks[t_id]) {
                    let f_ids = JSON.parse(mm.tasks[t_id].child_task_ids)
                    for(let id of f_ids) {
                        mm.f_tasks.push(f_tasks_byid[id])
                    }
                }
            }
        }
    }

    //console.log('includeTasks months', months)
}

function GetSortOrder(valuePath, array){
  let path = valuePath.split('.')  

  return array.sort((a, b) => {
     return getValue(b,path) -  getValue(a,path)    
  });

  function getValue(obj, path){
    path.forEach(path => obj = obj[path])
    return obj;
  }
}
class ConversionAlert extends React.Component{
    state = {conversion: null}

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
        let conversion = this.props.conversion
        this.setState({conversion})
        setTimeout(this.conversionStatus, 2000)
    }

    conversionStatus = () => {
        let api = new APICall()
        let postData = {command: 'plan', sub_command: 'get_conversion_status'}
        api.command(postData, this.processConversionStatus)
    }

    processConversionStatus = (result) => {
        let conversion = result.result.conversion
        if(conversion.status === 'complete'){
            setTimeout(this.endConversion, 1000)
        } else {
            setTimeout(this.conversionStatus, 2000)
        }
        this.setState({conversion})
    }

    endConversion = () => {
        this.props.endConversion(this.state.conversion)
    }

    render() {
        if(this.state.conversion === null)return <div />
        return (<>
            <MRModalContainer>
                <MRModalHeader>
                <MRModalHeaderText> Data Conversion in progress  </MRModalHeaderText>
                </MRModalHeader>
                <MRModalBody>
                <MRModalBodyLabel>
                {
                    (() => {
                        if(this.state.conversion.status === 'incomplete') {
                            return (<>
                                Converted {this.state.conversion.total - this.state.conversion.old_count} of {this.state.conversion.total} Plans.
                                </>)
                        } else {
                            return (<>
                                All plans converted. Wrapping up.
                            </>)
                        }
                    })()
                }
                </MRModalBodyLabel>
                </MRModalBody>
            </MRModalContainer>
        </>)
    }
}

class CalendarCell extends React.Component{
    state = {data: null, current_date: null, color: 'none', cursor: 'none', show_modal: false,show_exclaim:false,tool_tip_id:"",processed_color_code_remedial:"white",complete_rem_count:0,incomplete_rem_count:0}

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
        // console.log('CELLDATA:: props',this.props)

        let data = this.props.tasks 

        // let key = Object.keys(data).filter(function (dt) { return dt.cur_lane === "COMP_CMP_ASSIGNMENT"||dt.cur_lane === "COMP_CMP_SAVED" });
        //console.log("CalendarCell key::",key)
        let current_date = this.props.current_date
        let current_month = current_date.this_year * 100 + current_date.this_month + 1 
        let current_date_num = current_date.this_year * 10000 + (current_date.this_month + 1) * 100 + current_date.this_day
        let this_month = data.y * 100 + data.m + 1
        let is_overdue = false 
        let all_complete = true 

       // console.log('CalendarCell this_month , current_month',this_month , current_month)

        let in_future = this_month > current_month ? true : false
        let in_month = (this_month === current_month)

        // let due = []
        // let overdue = []
        // let future = []
        // let complete = []

        for(let id in data.tasks) {
            let t = data.tasks[id]
            if(t.risk_id === '16bJam6NAt5D3rN9YIWP')console.log('CalendarCell details',t,t.filter_due_date,t.completed_date,current_date_num)

            //is_overdue = t.completed_date === null && t.filter_due_date < current_date_num ? true : is_overdue
            is_overdue = t.cur_lane !== 'COMP_CMP_COMPLETED' && t.filter_due_date < current_date_num ? true : is_overdue
            if(t.risk_id === '16bJam6NAt5D3rN9YIWP')console.log('CalendarCell is_overdue A',is_overdue)

            //all_complete = t.completed_date === null ? false : all_complete
            all_complete = t.cur_lane === 'COMP_CMP_COMPLETED' ? true : false
        }
        let color = 'white'
        // console.log('CalendarCell data',data)
        // console.log('CalendarCell is_overdue',is_overdue)
        let uncomplete_task =  Object.values(data.tasks).filter(function (dt) { return dt.cur_lane.toString() !== 'COMP_CMP_COMPLETED' });
        if(uncomplete_task.length > 0){all_complete = false;}
        // if(in_month)console.log('CalendarCell all_complete',all_complete,uncomplete_task)
        // console.log('CalendarCell in_month',in_month)
        // console.log('CalendarCell in_future',in_future)
        // console.log('CalendarCell is_overdue',is_overdue)

        if(Object.keys(data.tasks).length > 0) {
            // if(is_overdue)color = 'red'
            // if(all_complete) color = 'green'
            // if(in_month && !is_overdue) color = '#FFBF00'
            // if(in_future && !all_complete) color = 'blue'
            // console.log('data.m, in_month,is_overdue,in_future, all_complete', data.m, in_month,is_overdue,in_future, all_complete)
             if(is_overdue){

                    color = 'red'
                }else{
                    if(in_month && !is_overdue && !all_complete){ 

                        color = '#FFBF00'
                    }else{
                        if(in_future && !all_complete){
                            color = 'blue'
                        }else{
                            if(all_complete) color = '#01B0A2'

                        }

                    }
                }

                
        }

        let cursor = color === 'white' ? 'default' : 'pointer'

        
        // console.log('CalendarCell color',color)
        let show_exclaim = false
        let followup_tasks = Store.getStoreData('followup_tasks')
        let tool_tip_id = '';
        let complete_rem_count = this.state.complete_rem_count;
        let incomplete_rem_count  = this.state.incomplete_rem_count;
        let rem_colors = [];
		let comp_length = [];
		let incomp_length = [];
        let processed_color_code_remedial = this.state.processed_color_code_remedial
        if(Object.keys(data.tasks).length > 0){
	        // console.log("followup_tasks::",data.tasks,followup_tasks)
	    	for(let id in data.tasks) {
	    		let t = data.tasks[id]
                

	    		//if(t.is_task && t.cur_lane !== 'COMP_CMP_COMPLETED'){
	    		if(t.is_task ){
                     
                    let followup_key = followup_tasks.filter(function (dt) { return dt.parent_task_id.toString() === t.task_id.toString() });
                    if(followup_key.length > 0){
    	    			//console.log("followup_tasks::",t,followup_tasks)
    	    			// const followup_key =  Object.keys(followup_tasks).find(x => t.task_id === followup_tasks[x].parent_task_id);

    	    			//console.log("followup_tasks::",t,followup_tasks,followup_key)

                        let comp_length_l = followup_key.filter(element => element.cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE');
                        let incomp_length_l = followup_key.filter(element => element.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE');
                        //console.log("#04ADA8",comp_length_l,incomp_length_l)
						comp_length = [...comp_length,...comp_length_l];
						incomp_length = [...incomp_length,...incomp_length_l];

                        complete_rem_count = (comp_length.length >= 10) ? comp_length.length : `0${comp_length.length}`;

                        if(comp_length_l.length > 0){
                            let rem_color_singl = "#04ADA8"
                            rem_colors.push(rem_color_singl);
                        }

                        /*incomplete_rem_count = (incomp_length.length >= 10) ? incomp_length.length : `0${incomp_length.length}`;
                        for(let j = 0;j <incomp_length.length;j++){
                            let rem_color_singl = this.compareDateGetColorRemedial(incomp_length[j].due_date,incomp_length[j].cur_lane)
                            rem_colors.push(rem_color_singl);
                        }*/

    	    			// if(incomp_length.length > 0 && incomp_length[0].cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE'){
    	    				show_exclaim = true;
    						if( t.subcat_id !== "0"){
    							tool_tip_id = `${t.plan_ref_id}-${t.risk_id}-${t.subcat_id}-${data.m+1}`;
    						}else{
    							tool_tip_id = `${t.plan_ref_id}-${t.risk_id}-${data.m+1}`;
    						}
	    			}
	    		}


	    	}
			incomplete_rem_count = (incomp_length.length >= 10) ? incomp_length.length : `0${incomp_length.length}`;
			for(let j = 0;j <incomp_length.length;j++){
				let rem_color_singl = this.compareDateGetColorRemedial(incomp_length[j].due_date,incomp_length[j].cur_lane)
				rem_colors.push(rem_color_singl);
			}
            //console.log("rem_colors::",rem_colors)
            processed_color_code_remedial = this.get_color_code_basedon_priority(rem_colors)
	    }
		if(color === '#01B0A2')show_exclaim=false;
	    //console.log("followup_tasks::show_exclaim::",show_exclaim,tool_tip_id,incomp_length,comp_length)
        this.setState({data, current_date, color, cursor,show_exclaim,tool_tip_id,complete_rem_count,incomplete_rem_count,processed_color_code_remedial})
    }
    get_color_code_basedon_priority = (color_codes_array) => {
        let ret_color_code ;
        if(color_codes_array.includes('red')){ // red
            ret_color_code = "red";
        }else{
            if(color_codes_array.includes('#F39C12')){ // amber
                ret_color_code = "#F39C12";
            }else{
                if(color_codes_array.includes('blue')){ // blue
                    ret_color_code = "blue";
                }else{
                    ret_color_code = "#04ADA8";
                }
            }
        }
        return ret_color_code;
    }
    compareDateGetColorRemedial = (due_date,cur_lane) => {
    // console.log("color::==>"+cur_lane);
      let color_code = '#04ADA8'
      let scheduled_date = moment(due_date, 'YYYY/MM/DD');
      let month = scheduled_date.format('M');
      let year  = scheduled_date.format('YYYY');

      let curr_month = moment().month()+1;
      let curr_year  = moment().year();
      // console.log("monthmonth",month,curr_month,year,curr_year)
      // let current_date = moment().unix();
      // let due_date_unix =  moment(due_date).unix();
      let current_date = moment().format('YYYY-MM-DD');
      let due_date_unix =  moment(due_date).format('YYYY-MM-DD');

      if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
        color_code = "#04ADA8"; // green
      }else{
        if(due_date_unix >= current_date){
          if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED'){
            color_code = "blue"; // Scheduled
          }else{
            if(curr_month.toString() === month && curr_year.toString() === year){
              color_code = "#F39C12"; // due
            }else{
              color_code = "blue"; // Scheduled
            }
          }
        }else{
          color_code = "red"; // overdue
        }
      }

    //return color_code;
    //if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED' && color_code === "#F39C12")color_code='blue';
    return color_code;
  }

    closeModal = () => {
        this.setState({show_modal: false})
    }

    openCellModal = (c) => {
        // console.log('CalendarCell c', c)
        if(c === 'none' || c === 'white') return
        this.setState({show_modal: true})
    }
    render() {
        // console.log('CellBox this.state', this.state)
        if(this.state.data === null || this.state.color === 'none') return <div />

        return (<>
            {
                (() => {
                    if(this.state.show_modal) {
                        return <CellModal data={this.state.data} closeModal={this.closeModal} />
                    }
                })()
            }
            <CalendarRowMonthCell style={{padding: 3}}>
            <TABoxContainer>
                {
                    (()=>{
                        if(this.state.show_exclaim && this.state.tool_tip_id !== ""){
                            
                            return(<>
                                <ReactTooltip id={this.state.tool_tip_id} place="top" effect="solid" backgroundColor="rgb(232,240,247)" textColor="#000000">
                                    <GoAlert style={{fill: this.state.processed_color_code_remedial, marginTop: "1px", width: "63px", height: "20px"}}/><br/>
                                    Tasks Outstanding {this.state.incomplete_rem_count}<br/> 
                                    Tasks Completed&nbsp;&nbsp;&nbsp; {this.state.complete_rem_count}<br/>
                                </ReactTooltip>

                                <CellBox data-for={this.state.tool_tip_id} data-tip onClick={() => this.openCellModal(this.state.color)} style={{backgroundColor: this.state.color, cursor: this.state.cursor, position: "relative"}}>
                                <div style={{position:"absolute",left:"16%"}}><GoAlert style={{fill: "#FFFFFF", marginTop: "1px", width: "63px", height: "20px"}}/></div>
                                 </CellBox>

                                </>)
                        }else{
                            return(<CellBox onClick={() => this.openCellModal(this.state.color)} style={{backgroundColor: this.state.color, cursor: this.state.cursor, position: "relative"}}/>)
                        }
                    })()
                }
                
                </TABoxContainer>
            </CalendarRowMonthCell>
        </>)
    }
}

class CalendarSubcat extends React.Component {
    state = {risk: null, subcat: null, month_tasks: null, current_date: null}

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
        // console.log("SUBCAT PROPS::",this.props)
        let current_date = this.props.current_date
        let subcat = this.props.subcat
        let risk = this.props.risk
        let testing_activities = []
        let include_tasks = true 

        // console.log("SCMODAL cell",subcat)
        // if(this.props.show_inactives) {
        //     include_tasks = (risk.status === 'Active' && subcat.statue === 'Active')
        // } else {
        //     include_tasks = (risk.status !== 'Deleted' && subcat.statue !== 'Deleted')
        // }
        if(this.props.show_inactives) {
            if("subcat_status" in subcat){
                include_tasks = (risk.status === 'Active' && subcat.subcat_status === 'Active')
            }else{
                include_tasks = (risk.status === 'Active' && subcat.status === 'Active')
            }
        } else {
            if("subcat_status" in subcat){
                include_tasks = (risk.status !== 'Deleted' && subcat.subcat_status !== 'Deleted')
            }else{
                include_tasks = (risk.status !== 'Deleted' && subcat.status !== 'Deleted')
            }
            
        }
        //console.log("include_tasks subcat::",include_tasks)
        // if(include_tasks){
        //     let all_testing_activities = Store.getStoreData('testing_activities')
        //     testing_activities = all_testing_activities.filter((t, i) => {
        //         return (t.unique_risk_id === risk.unique_risk_id && 
        //                 t.unique_subcat_id.toString() === subcat.unique_subcat_id &&
        //                 !t.is_deleted)
        //     })
        // }
        let all_testing_activities = Store.getStoreData('testing_activities')
        if(this.props.show_inactives){
            testing_activities = all_testing_activities.filter((t, i) => {

                if("subcat_status" in subcat){
                        return (t.unique_risk_id === risk.unique_risk_id && risk.status === 'Active' && 
                        t.unique_subcat_id.toString() === subcat.unique_subcat_id && subcat.subcat_status === 'Active' &&
                        !t.is_deleted)
                }else{
                        return (t.unique_risk_id === risk.unique_risk_id && risk.status === 'Active' && 
                        t.unique_subcat_id.toString() === subcat.unique_subcat_id && subcat.status === 'Active' &&
                        !t.is_deleted)
                }


            })
        }else{

            testing_activities = all_testing_activities.filter((t, i) => {
                return (t.unique_risk_id === risk.unique_risk_id && 
                        t.unique_subcat_id.toString() === subcat.unique_subcat_id &&
                        !t.is_deleted )
            })
        }

        // console.log("include_tasks subcat::",testing_activities)

        let month_tasks = []
        for(let i=0; i < 12; i++) month_tasks.push({m: i, y: parseInt(this.props.visible_year), tasks: {}, f_tasks: [] })
        includeTasks(parseInt(this.props.visible_year), testing_activities, month_tasks,this.props.subcat.unique_risk_id,this.props.subcat.unique_subcat_id,'subcat')
        //console.log('includeTasks task::: ,risk,sub subcat month_tasks for',this.props.subcat.unique_risk_id,this.props.subcat.unique_subcat_id, month_tasks)
        // console.log('subcat month_tasks', month_tasks)

        this.setState({risk, subcat, current_date, month_tasks})
    }

    render() {
		// console.log("subcat_owner_present::Here",this.state);
        if(this.state.subcat === null)return <div />
        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID

        return (<>
            <CalendarRow>
                <CalendarRowHeader>
                    <SubcatHeaderCell data-for='risk_subcategory_name' data-tip={this.state.subcat.risk_subcategory_name}>{this.state.subcat.risk_subcategory_name}</SubcatHeaderCell>
                    <ReactTooltip
                        id="risk_subcategory_name"
                        place="bottom"
                        backgroundColor="#ffffff" textColor="#000000"
                      />

                    {
                        (() => {
                            if(( role === 'risk_owner' && (this.state.risk.risk_owner_id === user_id.toString() || this.state.risk.created_by.toString() === user_id.toString())) ||
                                 ( role === 'subcat_owner' && (this.state.subcat.subcat_assignto === user_id.toString() || this.state.subcat.created_by.toString() === user_id.toString())) 
                                 || role === 'admin_manager' || role ===  'plan_admin'){
                                return(<>
                                    <HeaderIconCell onClick={() => this.props.openModal('sc', this.state.subcat, this.state.risk)}><FiEdit style={{float: 'right', marginRight: '40px'}} /></HeaderIconCell>

                                    </>)
                            }
                        })()
                    }

                    
                    <ClearFloat />
                </CalendarRowHeader>
                <CalendarYearCell></CalendarYearCell>
                <CalendarRowMonthsHolder>
                {
                    this.state.month_tasks.map((m, i) => {
                         if(role === 'risk_owner' || role === 'admin_manager' || role ===  'plan_admin' || role ===  'view_only_manager' 
                            || ( role === 'subcat_owner' && (this.state.subcat.subcat_assignto === user_id.toString() || this.state.subcat.created_by.toString() === user_id.toString())) ){
                                return (<CalendarCell key={i} tasks={m} current_date={this.props.current_date} />)
                        }
                    })
                }
                </CalendarRowMonthsHolder>
            </CalendarRow>
        </>)
    }
}

class CalendarRisk extends React.Component {
    state = {plan: null, risk: null, subcategories: [], month_tasks: null, current_date: null, testing_activities: null, expanded: false,risk_area_name:''}

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }
	expandCollapse = () => {
		//e.preventDefault();
		let expanded = (this.state.expanded) ? false: true;
		this.setState({expanded})
	}
    setComponentState = () => {
        let current_date = this.props.current_date
        let risk = this.props.risk
        let risk_subcategories = risk.subcategories;
        // console.log("MgrIndex CalendarRisk", this.props)
        let subcategories = [];
        // console.log("SCMODAL cell",risk_subcategories)
        for(let r of risk_subcategories) {

            if(this.props.show_inactives) {
                if("subcat_status" in r && r.subcat_status === 'Active'){
                    subcategories.push(r)
                }else{
                    if(r.status === 'Active')subcategories.push(r)
                }
            } else {
                if("subcat_status" in r && r.subcat_status !== 'Deleted'){
                    subcategories.push(r)
                }else{
                    if(r.status !== 'Deleted')subcategories.push(r)
                }
                
            }
        }
        let plan = this.props.plan
        let testing_activities = []
        let testing_activities_risk_area = []
        let include_tasks = true 
        if(this.props.show_inactives) {
            include_tasks = (risk.status === 'Active')
        } else {
            include_tasks = (risk.status !== 'Deleted')
        }

        // console.log("include_tasks risk::",subcategories, this.props.show_inactives)
        if(include_tasks) {
            let all_testing_activities = Store.getStoreData('testing_activities')
            testing_activities = all_testing_activities.filter((t, i) => {
                // console.log("testing_activitiesALL",t.testing_activity,t.unique_risk_id,risk.unique_risk_id,t.unique_subcat_id.toString())

                // return (t.unique_risk_id === risk.unique_risk_id && t.unique_subcat_id.toString() === '0' )
                return (t.unique_risk_id === risk.unique_risk_id)
            })
            testing_activities_risk_area = all_testing_activities.filter((t, i) => {
                // console.log("testing_activitiesALL",t.testing_activity,t.unique_risk_id,risk.unique_risk_id,t.unique_subcat_id.toString())

                return (t.unique_risk_id === risk.unique_risk_id && t.unique_subcat_id.toString() === '0' )
            })
        }

        console.log("testing_activities_risk_area::",testing_activities_risk_area,testing_activities_risk_area.length)
        let expanded = testing_activities_risk_area.length === 0 ? true : false
        
        let month_tasks = []
        // console.log('risk month_tasks', month_tasks)
        for(let i=0; i < 12; i++) month_tasks.push({m: i, y: parseInt(this.props.visible_year), tasks: {}, f_tasks: []})
        // console.log('risk month_tasks', month_tasks)
        includeTasks(parseInt(this.props.visible_year), testing_activities, month_tasks,this.props.risk.unique_risk_id,'0','risk_area')
        console.log('INCLUDE tas task::: ,risk,sub risk month_tasks for',this.props.risk.unique_risk_id,'0', month_tasks)
        // console.log("ALLRISK::",risk)
        subcategories.sort(function(a, b) {
            return a.risk_subcategory_name.localeCompare(b.risk_subcategory_name)
        }); 
        let risk_area_name = JSON.parse(JSON.stringify(risk.risk_area_name))

        this.setState({risk, subcategories,plan, current_date, testing_activities, month_tasks,risk_area_name,expanded})
    }

    render() {
        //// console.log('RiskHeaderCell top this.state', this.state)
        if(this.state.risk === null) return <div />

        console.log('RiskHeaderCell this.state', this.state)
        // console.log('RiskHeaderCell this.props SUBCAT', this.props) 

        // let subcategories = []
        // if(this.state.plan !== null) {
        //     //for(let r of this.state.risk.subcategories) {
        //     for(let r of this.state.subcategories) {
        //         console.log("SUBCAT r",r)
        //         if(this.props.show_inactives) {
        //             if(r.status === 'Active')subcategories.push(r)
        //         } else {
        //             if(r.status !== 'Deleted')subcategories.push(r)
        //         }
        //     }
        // }
        //console.log("RiskCELL State ::",this.state)
        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID
        // console.log("openModalTA:: For ",this.state.risk.risk_area_name,role,user_id,this.state.risk,this.state.risk.risk_owner_id.toString())

        return (<>
            <CalendarRow>
                <CalendarRowHeader>
                    <RiskHeaderCell data-for='risk_header_name' data-tip={this.props.risk_area_name}  >{this.props.risk_area_name}</RiskHeaderCell>
                    <ReactTooltip
                        id="risk_header_name"
                        place="bottom"
                        backgroundColor="#ffffff" textColor="#000000"
                      />
                      
                    <>
                    {
                        (() => {
                            let subcat_owner_present = this.state.subcategories.filter(item => item.subcat_assignto === user_id.toString() );
                            {/*console.log("subcat_owner_present::",subcat_owner_present.length,subcat_owner_present,this.state.subcategories.length)*/}

                            if( (role === 'risk_owner' && (this.state.risk.risk_owner_id === user_id.toString() || this.state.risk.created_by.toString() === user_id.toString()) ) 
                                || role === 'admin_manager' || role ===  'plan_admin' || role ===  'view_only_manager' || (role === 'subcat_owner' && subcat_owner_present.length > 0) ){
                                return(<>

                                        <HeaderIconCell style={{position:'relative'}} >
                                    {
                                        (() => {
                                            if((role === 'risk_owner' && (this.state.risk.risk_owner_id === user_id.toString() || this.state.risk.created_by.toString() === user_id.toString()) ) 
                                            || role === 'admin_manager' || role ===  'plan_admin'){
                                            return(<FiEdit style={{float: 'right', marginRight: '20px' , position:'absolute' , right:'25px'}} onClick={() => this.props.openModal('ra', this.state.risk, this.state.plan)}/>)
									        }
                                        })()
                                    }
                                    {
										(() => {
												if(this.state.subcategories.length > 0){
													return this.state.expanded ? <FiChevronRight style={{float: 'right' , position:'absolute' , right:'10px'}} onClick={() => this.expandCollapse()} /> : <FiChevronDown style={{float: 'right' , position:'absolute' , right:'10px'}} onClick={() => this.expandCollapse()} />;
												}
											
										})()
									}
								    </HeaderIconCell>
                    
                                    </>)
                            }
                        })()
                    }</>
                    <ClearFloat />
                </CalendarRowHeader>
                <CalendarYearCell></CalendarYearCell>
                <CalendarRowMonthsHolder>
                {
                    this.state.month_tasks.map((m, i) => {
                        if(role === 'risk_owner' || role === 'admin_manager' || role ===  'plan_admin' || role === 'subcat_owner' || role === 'view_only_manager'){
                            return (<CalendarCell key={i} tasks={m} current_date={this.props.current_date} />)
                        }
                    })
                }
                </CalendarRowMonthsHolder>
            </CalendarRow>
			{
				(() => {
					if(this.state.expanded){
						let i =0;
						let subcats = [];
                        {/*console.log("subcat_owner_present::",this.state.expanded,this.state.subcategories)*/}
						for(let s of this.state.subcategories){
							if(!this.state.expanded)continue;
                            //let role = Store.getStoreData('role')
                            let show = true
                            let user_id = Store.getStoreData('user_info').ID
                            if(role === 'subcat_owner' && s.subcat_assignto.toString() !== user_id.toString()){
                                show = false
                            }
                            if(!show)continue;
							if(('status' in s && s.status !== 'Deleted') || ('subcat_status' in s && s.subcat_status !== 'Deleted')){
							{/*console.log("HH,",s);*/}
							i++;
							subcats.push(<CalendarSubcat openModal={() => this.props.openModal('sc', s, this.state.risk)} subcat={s} key={i} visible_year={this.props.visible_year}
													current_date={this.state.current_date} 
													risk={this.state.risk} 
													show_inactives={this.props.show_inactives}/>);
							}
						}
						return subcats;
					}
				})()
			}
            {/*
				//if(this.state.expanded){
					this.state.subcategories.map((s, i) => {
						if(s.status !== 'Deleted' || s.subcat_status !== 'Deleted'){
						return (<CalendarSubcat openModal={() => this.props.openModal('sc', s, this.state.risk)} subcat={s} key={i} visible_year={this.props.visible_year}
												current_date={this.state.current_date} 
												risk={this.state.risk} 
												show_inactives={this.props.show_inactives}/>)
						}
					})
				//}

            */}
        </>)
    }
}

class Calendar extends React.Component {
    state = {gc_plans: null, gc_id: null, plan_ref_id: '0' ,ta_tasks: null, 
            current_date: null, plan: null, visible_year: null, dev_modal: null, 
            edit_object: null, edit_object_sub:null,parent_object: null, show_inactives: true,
            visible_risk_count: 8, max_risk_count: 100000, min_risk_count: 8, alert_param: null}

    componentDidMount = () => {
        EventBus.registerEvent('gc_clicked', 'Calendar', this.changeGC)
        EventBus.registerEvent('plan_menu_clicked', 'Calendar', this.planMenuClicked)
        EventBus.registerEvent('plan_reload', 'Calendar', this.planReload)
        EventBus.registerEvent('data_reload', 'Calendar', this.dataReload)
        EventBus.registerEvent('data_risk_sub_reload', 'Calendar', this.dataReloadRisk)
        EventBus.registerEvent('subcat_saveclose_reload', 'Calendar', this.subcatSavecloseReload)

        this.setComponentState()
    }

    dataReload = (plan_ref_id) => {
         let api = new APICall()
         let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: plan_ref_id,year:this.state.visible_year}
         // console.log('changePlan postData', postData)
         api.command(postData, this.processChangePlanDataReload)
     }


    dataReloadRisk = (value) => {
        // console.log('processChangePlanRisk dataReloadRisk::',value)
         let api = new APICall()
         let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: value.plan_ref_id,year:this.state.visible_year}
         // console.log('changePlan postData', postData)
         let that = this;
         api.command(postData, function callback(result){that.processChangePlanRisk(result,value)});    
         // api.command(postData, this.processChangePlanRisk)
     }
    subcatSavecloseReload = (plan_ref_id) => {
        // console.log('processChangePlanRisk subcatSavecloseReload::',plan_ref_id)
         let api = new APICall()
         let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: plan_ref_id,year:this.state.visible_year}
         // console.log('changePlan postData', postData)
         let that = this;
         api.command(postData, function callback(result){that.processSubcatSavecloseReload(result,plan_ref_id)});    
         // api.command(postData, this.processChangePlanRisk)
     }

     planReload = (plan_ref_id) => {
         let api = new APICall()
         let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: plan_ref_id,year:this.state.visible_year}
         // console.log('changePlan postData', postData)
         api.command(postData, this.processReloadPlan)
     }
     processSubcatSavecloseReload = (result,callback_value) => {
         console.log('processSubcatSavecloseReload::',result,callback_value)
         let plan = result.result.plan 
         let plan_tasks = result.result.plan_tasks 
         let plan_ref_id = plan.unique_plan_id
         let testing_activities = plan.testing_activities
         // Store.updateStore('testing_activities', testing_activities)
         // Store.updateStore('plan_tasks', plan_tasks)
         Store.updateStore('selected_plan', plan)
         Store.updateStore('risk_areas', plan.risk_areas)

         // EventBus.raiseEvent('plan_selected', plan)
         // this.setState({plan, plan_ref_id})
     }
     processReloadPlan = (result) => {
        // console.log("processReloadPlan", result)
         let plan = result.result.plan 
         let plan_tasks = result.result.plan_tasks 
         let plan_ref_id = plan.unique_plan_id
         let testing_activities = plan.testing_activities
         Store.updateStore('testing_activities', testing_activities)
         Store.updateStore('plan_tasks', plan_tasks)
         Store.updateStore('selected_plan', plan)
         Store.updateStore('risk_areas', plan.risk_areas)

         EventBus.raiseEvent('plan_selected', plan)
         this.setState({plan, plan_ref_id, dev_modal: null, edit_object: null, parent_object: null})
     }

    planMenuClicked = (dev_modal) => {
        this.setState({dev_modal})
    }

    changeVisibleCount = () => {
        let visible_risk_count = this.state.visible_risk_count < this.state.max_risk_count ? this.state.max_risk_count : this.state.min_risk_count
        this.setState({visible_risk_count})
    }

    changeGC = (gc_id) => {
        console.log('gc_clicked,e, gc_id',  gc_id)
        let current_date = this.props.current_date
        let visible_year = current_date.this_year
        let plan = null 
        let plan_ref_id = '0'
        let ta_tasks = null
        Store.updateStore('ta_toggle_state',false)
        Store.updateStore('ta_advancedFilterData',null)
        // Store.updateStore('ta_advancedFilterData',JSON.parse(JSON.stringify(filters)))
        EventBus.raiseEvent('plan_selected', plan)
        let gc_plans = Store.getStoreData('gc_plans')
        if(typeof gc_plans[gc_id] !== 'undefined' && Object.keys(gc_plans[gc_id]).length === 1 ){
        	this.changePlan_single(Object.keys(gc_plans[gc_id])[0],this.state.visible_year,gc_plans,gc_id)
        }
        this.setState({gc_id, current_date, visible_year, plan, plan_ref_id, ta_tasks})
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
        let gc_plans = Store.getStoreData('gc_plans') 
        //console.log('changePlan_single :: gc_plans',gc_plans)
        let current_date = this.props.current_date
        let visible_year = current_date.this_year
        let gc_id = this.state.gc_id === null ? '0' :  this.state.gc_id;
		let plan_ref = '';
        // if(Object.keys(gc_plans).length > 0 ){
        //     gc_id = Object.keys(gc_plans)[0]
        // }
        // console.log("setComponentState::",Object.keys(gc_plans),gc_id)
        // console.log("selected_plan::",Store.getStoreData('selected_plan'))
         //console.log('current_gcid:: length::',Object.keys(gc_plans[gc_id]).length,Object.keys(gc_plans[gc_id]))
        
        let last_working_plan_info = CMPUtils.getPlanCookie();
        console.log('last_working_plan_info::',last_working_plan_info)
        if(last_working_plan_info !== null){
			gc_id = last_working_plan_info.split('-')[2];
			visible_year = last_working_plan_info.split('-')[0];
			plan_ref = last_working_plan_info.split('-')[1];
			this.changePlan_single(plan_ref,visible_year,gc_plans,gc_id)
		}


      //  console.log('changePlan_single :: ',gc_plans[gc_id],gc_id,last_working_plan_info)
      //   if(typeof gc_plans[gc_id] !== 'undefined' && Object.keys(gc_plans[gc_id]).length === 1 ){
		    // plan_ref = Object.keys(gc_plans[gc_id])[0];
      //   	this.changePlan_single(plan_ref,visible_year,gc_plans,gc_id)
      //   }
        this.setState({gc_plans, current_date, visible_year, gc_id})
    }

    changePlan_single = (plan_ref,visible_year,gc_plans,gc_id) => {

        let plan_ref_id = plan_ref
        //console.log("plan_ref_id AAA", plan_ref_id,this.state)
        if(plan_ref_id === '0') {
            let current_date = this.props.current_date
            let visible_year = current_date.this_year
            let plan = null 
            let plan_ref_id = '0'
            let ta_tasks = null
            this.setState({current_date, visible_year, plan, plan_ref_id, ta_tasks})
            EventBus.raiseEvent('plan_selected', plan)
            return
        }
        this.setState({plan_ref_id})
        let api = new APICall()
        let postData = {command: 'plan', sub_command: 'get_plan', gc_id: gc_id, plan_ref_id: plan_ref_id,year:visible_year}
        // console.log('changePlan_single postData', postData)
        api.command(postData, this.processChangePlan)
    }

    changePlan = (e) => {
        // alert(1)
        e.preventDefault()
        let plan_ref_id = e.target.value
        if(plan_ref_id === '0') {
            let current_date = this.props.current_date
            let visible_year = current_date.this_year
            let plan = null 
            let plan_ref_id = '0'
            let ta_tasks = null
            this.setState({current_date, visible_year, plan, plan_ref_id, ta_tasks})
            EventBus.raiseEvent('plan_selected', plan)
            return
        }
        this.setState({plan_ref_id});
		//set plan cookie
		CMPUtils.setPlanCookie(plan_ref_id,this.state.gc_id,this.state.visible_year);
        let api = new APICall()
        let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: plan_ref_id,year:this.state.visible_year}
        // console.log('changePlan postData', postData)
        api.command(postData, this.processChangePlan)
    }
    processChangePlanRisk = (result,callback_value) => {
        // console.log('processChangePlanRisk result', result,callback_value)
        let dev_modal = callback_value.dev_modal;
        let plan = result.result.plan 
        let plan_ref_id = plan.unique_plan_id
        let edit_object = null;
        let edit_object_sub = null;
        let parent_object = null;

        switch(dev_modal) {
            case 'ra': 
                 let riskarea_key = plan.risk_areas.filter(function (risk) { return risk.unique_risk_id === callback_value.unique_risk_id });
                 edit_object = riskarea_key[0];
                 break;
            case 'sc': 
                 let risk_key = plan.risk_areas.filter(function (risk) { return risk.unique_risk_id === callback_value.unique_risk_id });
                 let subcategory =  risk_key[0].subcategories.filter(function (sub) { return sub.unique_subcat_id === callback_value.unique_subcat_id });
                 
                 if(callback_value.from === 'ra'){
                    dev_modal = 'ra'
                    edit_object_sub = subcategory[0];
                    edit_object = risk_key[0];
                 }else{
                    edit_object = subcategory[0];
                    parent_object = risk_key[0];
                 }

                 
                 break;
        }

         let plan_tasks = result.result.plan_tasks 

         let testing_activities = plan.testing_activities
         Store.updateStore('testing_activities', testing_activities)
         Store.updateStore('plan_tasks', plan_tasks)
         Store.updateStore('selected_plan', plan)
         EventBus.raiseEvent('plan_selected', plan)
         Store.updateStore('risk_areas', plan.risk_areas)
        this.setState({plan, plan_ref_id,dev_modal,edit_object,edit_object_sub,parent_object})
    }
    processChangePlanDataReload = (result) => {
        //console.log('processChangePlan result', result)
        if('__type' in result.result.plan.risk_areas){
            let api = new APICall()
            let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: this.state.plan_ref_id,year:this.state.visible_year}
            // // console.log('changePlan postData', postData)
            api.command(postData, this.processChangePlan)
        } else {
            let plan = result.result.plan 
            let plan_tasks = result.result.plan_tasks 
            let followup_tasks = result.result.followup_tasks 
            
            let plan_ref_id = plan.unique_plan_id
            let testing_activities = plan.testing_activities
            let risk_areas = plan.risk_areas
            let tasks_by_id = {}
            for(let tid in plan_tasks) {
                let t = plan_tasks[tid]
                tasks_by_id[t.task_id.toString()] = t
            }


            Store.updateStore('testing_activities', testing_activities)
            Store.updateStore('tasks_by_id', tasks_by_id)
            Store.updateStore('risk_areas', risk_areas)
            Store.updateStore('plan_tasks', plan_tasks)
            Store.updateStore('followup_tasks', followup_tasks)
            Store.updateStore('plan_gc_id', this.state.gc_id)
            Store.updateStore('selected_plan', plan)
            // alert(4)

            EventBus.raiseEvent('plan_selected', plan)
            this.setState({plan, plan_ref_id})
        }
    }
    processChangePlan = (result) => {
        // console.log('processChangePlan result', result)
        if('__type' in result.result.plan.risk_areas){
            let api = new APICall()
            let postData = {command: 'plan', sub_command: 'get_plan', gc_id: this.state.gc_id, plan_ref_id: this.state.plan_ref_id,year:this.state.visible_year}
            // // console.log('changePlan postData', postData)
            api.command(postData, this.processChangePlan)
        } else {
            let plan = result.result.plan 
            let plan_tasks = result.result.plan_tasks 
            let followup_tasks = result.result.followup_tasks 
            
            let plan_ref_id = plan.unique_plan_id
            let testing_activities = plan.testing_activities
            let risk_areas = plan.risk_areas
            let tasks_by_id = {}
            for(let tid in plan_tasks) {
                let t = plan_tasks[tid]
                tasks_by_id[t.task_id.toString()] = t
            }


            Store.updateStore('testing_activities', testing_activities)
            Store.updateStore('tasks_by_id', tasks_by_id)
            Store.updateStore('risk_areas', risk_areas)
            Store.updateStore('plan_tasks', plan_tasks)
            Store.updateStore('followup_tasks', followup_tasks)
            Store.updateStore('plan_gc_id', this.state.gc_id)
            Store.updateStore('selected_plan', plan)
            // alert(3)
            Store.updateStore('ta_toggle_state',false)
            Store.updateStore('ta_advancedFilterData',null)
            // Store.updateStore('ta_advancedFilterData',JSON.parse(JSON.stringify(filters)))

            EventBus.raiseEvent('plan_selected', plan)
            this.setState({plan, plan_ref_id})
        }
    }

    processAddPlan = (plan) => {
        // alert('Plan successfully added.')
        let gc_plans = Store.getStoreData('gc_plans')
        let gc_id = this.state.gc_id.toString()
        if(!(gc_id in gc_plans))gc_plans[gc_id] = {}
        gc_plans[gc_id][plan.unique_plan_id] = {name: plan.plan_name, gc_id: gc_id, plan_ref_id: plan.unique_plan_id , sort_field:plan.plan_name}
        Store.updateStore('gc_plans', gc_plans)
        Store.updateStore('testing_activities', [])
        Store.updateStore('risk_areas', [])
        Store.updateStore('selected_plan', plan)
        Store.updateStore('plan_gc_id', gc_id)

        Store.updateStore('plan_tasks', [])
        EventBus.raiseEvent('plan_selected', plan)

        CMPUtils.setPlanCookie(plan.unique_plan_id,gc_id,this.state.visible_year);

        let alert_param = {title: 'Success', message: 'Plan successfully added.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} };

        this.setState({plan, plan_ref_id: plan.unique_plan_id, gc_plans,dev_modal: null,alert_param:alert_param})
    }

    devModal = (dev_modal) => {
        this.setState({dev_modal})
    } 

    closeDevModal = () => {
        this.setState({dev_modal: null})
    }

    openModal = (dev_modal, edit_object, parent_object) => {
        // console.log("plan", parent_object)
        this.setState({dev_modal, edit_object, parent_object})
    } 

    closeModal = () => {
        this.setState({dev_modal: null, edit_object: null, parent_object: null})
    }
    AlertHandler = (result, stack) => {
        this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
    }
    planCopied = (r) => {
        // console.log("planCopied::",r)
        let gc_plans = this.state.gc_plans
        let gc_id = r.gc_id
        let plan_ref_id = r.plan_ref_id
        let plan = r.plan.result.plan
        let name = r.plan.result.plan.plan_name
        if(!(gc_id in gc_plans))gc_plans[gc_id] = {}
        gc_plans[gc_id][plan_ref_id] = {gc_id: gc_id, name: name, plan_ref_id: plan_ref_id, sort_field: name}
        // alert('Plan copied successfully.')
        let alert_param = {title: 'Success', message: 'Plan copied successfully.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} };

        this.setState({gc_plans, dev_modal: null,alert_param: alert_param})
    }
    planModified = (plan, plan_tasks) => {
        if(plan === null) {
            this.setState({dev_modal: null})
            return
        }
        
        let testing_activities = plan.testing_activities
        let risk_areas = plan.risk_areas
        Store.updateStore('testing_activities', testing_activities)
        Store.updateStore('risk_areas', risk_areas)
        Store.updateStore('selected_plan', plan)
        let alert_param = {title: 'Success', message: 'Successfully modified the plan.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.AlertHandler, stack: {} };
        EventBus.raiseEvent('plan_selected', plan)
        this.setState({plan, plan_tasks, dev_modal: null,alert_param: alert_param})
    }

    processChangeYear = (result) => {
        // console.log('processChangeYear result', result)
        let plan_tasks = result.result.plan_tasks 
        let followup_tasks = result.result.followup_tasks 
        let tasks_by_id = {}
        for(let tid in plan_tasks) {
            let t = plan_tasks[tid]
            tasks_by_id[t.task_id.toString()] = t
        }
        Store.updateStore('tasks_by_id', tasks_by_id)
        Store.updateStore('plan_tasks', plan_tasks)
        Store.updateStore('followup_tasks', followup_tasks)
        this.setState({visible_year: this.visible_year})
    }

    changeYear = (diff) => {
        // this.visible_year = this.state.visible_year + diff 
        this.visible_year = parseInt(this.state.visible_year) + parseInt(diff)
        let api = new APICall()
        let postData = { command: 'plan', 
                            sub_command: "list_year_tasks", 
                            year: this.visible_year , plan_gc_id: this.state.gc_id,
                            plan_ref : this.state.plan_ref_id}
        api.command(postData, this.processChangeYear)
        // this.processChangeYear(1)
    }

    showInactives = () => {
        let show_inactives = !this.state.show_inactives
        // console.log("include_tasks show_inactives::",show_inactives)
        // console.log("TATESTACT  this.state act",show_inactives)

        EventBus.raiseEvent('show_inactive', show_inactives)
        this.setState({show_inactives})
    }

    render() {
        // console.log('Calendar state', this.state, Store.getStoreData('selected_plan'))
        let role = Store.getStoreData('role')
        if(this.state.gc_plans === null || this.state.gc_id === null)return <div />

        let plans = (this.state.gc_id in this.state.gc_plans) ? this.state.gc_plans[this.state.gc_id] : {};
		let p_array = CMPUtils.sortAnObject(plans);
        // this.state.gc_plans.forEach(element => console.log("element::",element));
        let risk_areas = []
        let plan = Store.getStoreData('selected_plan')
        if(plan !== null) {
            for(let r of plan.risk_areas) {
                if(this.state.show_inactives) {
                    if(r.status === 'Active')risk_areas.push(r)
                } else {
                    if(r.status !== 'Deleted')risk_areas.push(r)
                }
            }
        }
        // console.log("ALLRISK A",risk_areas)

        risk_areas.sort(function(a, b) {
            return a.risk_area_name.localeCompare(b.risk_area_name)
        }); 
        // console.log("ALLRISK B",risk_areas)

        let total = risk_areas.length < this.state.visible_risk_count ? risk_areas.length : this.state.visible_risk_count
        total = total === this.state.max_risk_count ? risk_areas.length : this.state.visible_risk_count

        let label = risk_areas.length < this.state.visible_risk_count ? `Showing ${risk_areas.length} of ${risk_areas.length} Risks` : `Showing ${this.state.visible_risk_count} of ${risk_areas.length} Risks`
        let count_label = risk_areas.length > this.state.visible_risk_count ? 'Show more' : 'Show less'
        if(risk_areas.length < this.state.min_risk_count)count_label = ''

        let CountChange = <div onClick={this.changeVisibleCount} style={{cursor: 'pointer', marginLeft: 5, maxWidth: 200, float: 'left'}}>{count_label}</div>
        return (<>
            <AlertBox alertParam = {this.state.alert_param}/>
            <div style={{width: "100%", float: "left", minHeight: "300px", backgroundColor: "#ffffff", marginBottom: "5px"}}>

            {
                (() => {
                    if(this.state.dev_modal !== null) {
                        //console.log("MGR edit_object",this.state.edit_object)
                        //console.log("MGR dev_modal",this.state.dev_modal)

                        switch(this.state.dev_modal) {
                            case 'ra' : return <RAModal 
                                                    risk_area={this.state.edit_object} 
                                                    from_sub={this.state.edit_object_sub}
                                                    plan={this.state.plan} 
                                                    closeModal={this.closeModal} 
                                                    create_new={this.state.edit_object === null ? true : false}
                                                    gc_id={this.state.gc_id}/>; break;
                            case 'ta' : return <TAModal 
                                                    ta={this.state.edit_object} 
                                                    risk={this.state.parent_object} 
                                                    plan={this.state.plan}
                                                    closeModal={this.closeModal} 
                                                    gc_id={this.state.gc_id}/>; break;
                            case 'sc' : return <SCModal 
                                                    subcategory={this.state.edit_object} 
                                                    risk={this.state.parent_object} 
                                                    plan={this.state.plan}
                                                    closeModal={this.closeModal} 
                                                    gc_id={this.state.gc_id}
                                                    create_new={false}
                                                    from="index"
                                                    />; break;
                            case 'ap' : return <APModal 
                                gc_id={this.state.gc_id} 
                                closeModal={this.closeModal} 
                                processAddPlan={this.processAddPlan}/>; break;
                            case 'cp' : return <CPModal 
                                                gc_id={this.state.gc_id} 
                                                plan={plan} 
                                                mode="copy"
                                                planCopied={this.planCopied}
                                                closeModal={this.closeModal} />; break;
                            case 'ep' : return <CPModal 
                                                gc_id={this.state.gc_id} 
                                                plan={plan} 
                                                planModified={this.planModified}
                                                mode="edit"
                                                closeModal={this.closeModal} />; break;
                            default: return <div />
                        }
                    }
                })()
            }
             <CalendarRow style={{backgroundColor: 'rgb(244, 247, 251)'}}>
                <CalendarRowHeader>
                {
                    (() => {
                        if(this.state.plan !== null && ( role === 'risk_owner' || role === 'admin_manager' || role ===  'plan_admin') ) return (

                            <RiskBtn onClick={() => this.openModal('ra', null, this.state.plan)} >Create Risk Area</RiskBtn>)
                    })()
                }
                </CalendarRowHeader>
                <CalendarYearCell style={{marginLeft:'3px'}}>{this.state.visible_year}</CalendarYearCell>
                <CalendarRowMonthsHolder>
                {
                    months.map((m, i) => {
                        return (<CalendarRowMonthCell key={i} style={{marginTop: 5}}>{m}</CalendarRowMonthCell>)
                    })
                }
                </CalendarRowMonthsHolder>
            </CalendarRow>
            <CalendarRow>
                <CalendarRowHeader>
                    <MRSelect onChange={this.changePlan} value={this.state.plan_ref_id} style={{width: 220, marginTop: 7}}>
                        <option value="0">Select a plan...</option>
                        {
                            p_array.map((p, i) => {
                                return <option value={p.plan_ref_id}>{p.name}</option>;
                            })
                        }
                    </MRSelect>
                </CalendarRowHeader>
                <CalendarYearCell style={{backgroundColor: 'white'}} />
                <CalendarHeaderHolder> 
                {
                    (() => {
                        if(this.state.plan !== null) {
                            if(this.state.show_inactives) return (<div style={{ marginLeft: 10, marginRight: 10,marginTop: 8, display: 'flex'}}>
                                                        <FaCheckSquare style={{color: 'blue',cursor: 'pointer'}} onClick={this.showInactives} /><CheckboxLabel>Hide inactive risk areas</CheckboxLabel>
                                                    </div>)
                            else return (<div style={{ marginLeft: 10, marginRight: 10,marginTop: 8, display: 'flex'}}>
                                            <FaRegSquare style={{color: 'blue',cursor: 'pointer'}} onClick={this.showInactives} /><CheckboxLabel>Hide inactive risk areas</CheckboxLabel>
                                        </div>)
                        }
                    })()
                }
                
                {
                    (() => {
                        if(this.state.plan !== null)return (<>
                                <HeaderIconCell onClick={() => this.changeYear(-1)} style={{backgroundColor: '#01B0A2', 
                                            borderRadius: 4, 
                                            color: 'white', 
                                            fontSize: 18, fontWeight: 500,
                                            textAlign: 'center', 
                                            paddingTop: 2, width: 20, margin: 4}}> &lt; </HeaderIconCell>
                                <HeaderIconCell onClick={() => this.changeYear(1)} style={{backgroundColor: '#01B0A2', 
                                            borderRadius: 4, 
                                            color: 'white', 
                                            fontSize: 18, fontWeight: 500,
                                            textAlign: 'center', 
                                            paddingTop: 2, width: 20, margin: 4}}> &gt; </HeaderIconCell>
                            </>)
                    })()
                }
                </CalendarHeaderHolder>
            </CalendarRow>
            {/*<CalendarRow style={{backgroundColor: 'rgb(244, 247, 251)'}}>
                <CalendarRowHeader>
                {
                    (() => {
                        if(this.state.plan !== null && ( role === 'risk_owner' || role === 'admin_manager' || role ===  'plan_admin') ) return (

                            <RiskBtn onClick={() => this.openModal('ra', null, this.state.plan)} >Create Risk Area</RiskBtn>)
                    })()
                }
                </CalendarRowHeader>
                <CalendarYearCell>{this.state.visible_year}</CalendarYearCell>
                <CalendarRowMonthsHolder>
                {
                    months.map((m, i) => {
                        return (<CalendarRowMonthCell key={i} style={{marginTop: 5}}>{m}</CalendarRowMonthCell>)
                    })
                }
                </CalendarRowMonthsHolder>
            </CalendarRow>*/}
            {
                (() => {
                    if(this.state.plan !== null) {
                        return (<>
                                {
                                    risk_areas.map((r, i) => {
                                        if(i < total) return (<CalendarRisk key={i} current_date={this.state.current_date} risk={r} 
                                                                    risk_area_name ={r.risk_area_name}
                                                                    openModal={this.openModal}
                                                                    visible_year={this.state.visible_year} 
                                                                    plan={this.state.plan} 
                                                                    show_inactives={this.state.show_inactives} />)
                                    }) 
                                }
                            </>)
                    } else {
                        return (<div />)
                    }
                })()
            }
            <div style={{clear: 'both'}}></div>
            
            </div>
            <div style={{marginBottom: 20}}>
                <div style={{maxWidth: '50%', float: 'left'}}>{label}</div>{CountChange}
            </div>
            <div style={{clear: 'both'}}></div>
            </>)
    }
}

class PlanMenu extends React.Component {
    state = {show_menu: false, plan: null}

    componentDidMount = () => {
        EventBus.registerEvent('plan_selected', 'PlanMenu', this.changePlan)
        this.setComponentState()
    }

    changePlan = (plan) => {
        console.log("qqq,",plan);
        this.setState({plan})
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {

    }

    showMenu = () => {
        this.setState({show_menu: true})
    }

    hideMenu = () => {
        this.setState({show_menu: false})
    }

    openModal = (modal_id) => {
        EventBus.raiseEvent('plan_menu_clicked', modal_id)
    }

    render() {
        return (<>
                    <div style={{float: "right"}}>
                    {
                        (() => {
                            if(Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'plan_admin'){
                                return (
                                    <div style={{position: 'relative'}}>
                                        <FaEllipsisV onClick={this.showMenu} style={{cursor: 'pointer', fontSize: "20px", color: "#737272", marginTop: "7px"}} />
                                    </div>
                                );
                            }
                        })()
                    }
                    {
                        (() => {
                            if (this.state.show_menu) {
                                return (
                                    <div onMouseLeave={this.hideMenu} style={{padding: "10px 0px", backgroundColor: "#ffffff", position: "absolute", right: "2%", boxShadow: "rgb(167,166,166) 0px 0px 5px" , zIndex: 2000}}>
                                        <div style={{padding: "0px 5px 10px 5px", fontWeight: "bold", cursor: "pointer"}} 
                                            onClick={() => this.openModal('ap')}>New Compliance Plan</div>
                                        {
                                            (() => {
                                                if(this.state.plan !== null) return (<>
                                                    <div style={{padding: "0px 5px 10px 5px", fontWeight: "bold", cursor: "pointer"}} 
                                                        onClick={() => this.openModal('cp')}>Copy Plan</div>
                                                    <div style={{padding: "0px 5px 10px 5px", fontWeight: "bold", cursor: "pointer" }} 
                                                        onClick={() => this.openModal('ep')}>Edit Plan</div>
                                                </>)
                                            })()
                                        }
                                        
                                    </div>
                                );
                            }
                        })()
                    }
                    </div>
                    <div style={{clear: "both"}}></div>
        </>)
    }
}

class MGRIndex extends React.Component
{
    state = {ready: false ,cur_view: null, show_menu: false, 
             modal_view: null, current_date: null};

    constructor(props) {
        super(props);
        this.list_plans_called = false
    }

    componentDidMount = () => {
        EventBus.registerEvent('plan_deleted', 'MGRIndex', this.ReloadAllPlan)

        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return
            
        this.setComponentState()
    }

    setComponentState = () => {
        let api = new APICall()
        let postData = {command: 'plan', sub_command: 'list_plans'}
        // console.log('MGRIndex postData', postData)
        if(!this.list_plans_called)api.command(postData, this.processListPlans)
        this.list_plans_called = true
    }

    processListPlans = (result) => {
        console.log('processListPlans result', result)
        let gc_companies = Store.getStoreData('gc_companies')
        let user_info = Store.getStoreData('user_info')
        let user_id = user_info.ID.toString()
        let role = Store.getStoreData('role')
        let gc_plans = {};
        let plans_pre = {};
		for(let p_id in result.result.plans) {
			let p = result.result.plans[p_id];
			p.sort_field = p.name;
			plans_pre[p_id] = p;
		}
		//let obj = CMPUtils.sortAnObject(plans_pre);
        for(let p_id in plans_pre) {
            let p = result.result.plans[p_id]
            if(!(p.gc_id in gc_plans))gc_plans[p.gc_id] = {}
            gc_plans[p.gc_id][p.plan_ref_id] = p
        }
        console.log('processListPlans gc_plans', gc_plans)
        let current_date = result.result.current_date
        let conversion = result.result.conversion
        let module_config = result.result.module_config
        Store.updateStore('gc_plans', gc_plans)
        Store.updateStore('module_config', module_config)

        this.setState({ready: true, current_date, conversion});


    }

    ReloadAllPlan = () => {
        let api = new APICall()
        let postData = {command: 'plan', sub_command: 'list_plans'}
        // console.log('MGRIndex postData', postData)
        if(!this.list_plans_called)api.command(postData, this.processListPlans)
        this.list_plans_called = true
    }
    showMenu = () => {
        this.setState({show_menu: true});
    }

    hideMenu = () => {
      this.setState({show_menu: false});
    }

    createPlanModal = (event) => {
        event.preventDefault()
        this.setState({modal_view: "createplan"})
    }

    copyPlanModal = (event) => {
        event.preventDefault()
        this.setState({modal_view: "copyplan"})
    }

    editPlanModal = (event) => {
        event.preventDefault()
        this.setState({modal_view: "editplan"})
    }

    endConversion = (conversion) => {
        this.setState({conversion})
    }

    render()
    {
        if (!this.state.ready) {
          return (<div><CSLLoader /></div>
          );
        }
        // console.log('MGRIndex this.state', this.state)
        if(this.state.conversion.status === 'incomplete') {
           //return <ConversionAlert conversion={this.state.conversion} endConversion={this.endConversion} />
        }

        return (
            <div>
                <div style={{width: "100%", height: "46px", backgroundColor: "#E8F0F7", padding: "5px 15px", boxSizing: "border-box", marginBottom: "20px"}}>
                    <GCPanel />
                    <PlanMenu />
                </div>
                <div style={{clear: "both"}}></div>
                
                    <Calendar current_date={this.state.current_date} />

                <div style={{clear: "both"}}></div>
                <TestingActivityList />
                <FollowupActionsList />

            </div>
        );
    }
}

export default MGRIndex;