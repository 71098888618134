import React from 'react';
import AlertBox from './AlertBox.js';
import {FiDownload} from "react-icons/fi";
import {saveAs} from 'file-saver';
import {MdClear} from "react-icons/md";
import APICall from '../../Common/APICall.js';

class CSLAttachmentViewer extends React.Component {
  state = { ready: false, files: null, task_id: null, registering: false, alert_param:null };

  setComponentState = () => {
    if (this.props?.task_id) {
      this.setState({ ready: true, files: this.props.files, task_id: this.props.task_id });
    }
    this.setState({ ready: true, files: this.props.files });
  };
  componentDidMount = () => {
    this.setComponentState();
  };
  componentDidUpdate = (prevprops) => {
    if (this.props === prevprops){
      return;
    }
    this.setComponentState();
  };

  handleDownload(image_id, task_id) {
    let api = new APICall();
    let postData = { command: this.props.downloadCallback, image_id: image_id, task_id: task_id };
    api.command(postData, this.processDownloadFile);
  }
  confirmRemove(image_id) {
    let alert_param = {title: 'Alert', message: 'Are you sure you want to remove this attachment?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.handleRemove, stack: {image_id:image_id} };
    this.setState({alert_param: alert_param});
    return false;
  }
  handleRemove = (result, stack) => {
    if(result){
        let image_id = stack.image_id;
        let bin_files = this.state.files;
        let fls = bin_files.filter( x => x.uid !== image_id );
        this.setState({files: fls,alert_param:null})
        this.props.updateAttachments(image_id)
    }else{
        this.setState({alert_param:null})
    }
    
  }

  processDownloadFile = (result) => {
    let dbinfile = result.result;
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    let blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  };



  imageUrl(file) {
    const fileParts = file.split(/\.(?=[^.]+$)/);
    let ext;
    let extension = fileParts[fileParts.length - 1];
    switch (extension) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOC";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      default:
        ext = "PDF";
        break;
    }

    ext = ext === "DOCX" ? "DOC" : ext;
    ext = ext === "XLSX" ? "XLS" : ext;
    return `/dropzoneImages/${ext}.png`;
  }

  render() {
    if (!this.state.ready){
      return <div></div>; 
    }

    return (<>
      <AlertBox alertParam={this.state.alert_param} />
      <div style={{ width: "100%" }}>
            {
                this.state.files.map((f, index) => {
                    return (
                      <div key={index} style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
                        <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                          <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                            <img src={this.imageUrl(f.name)} style={{ height: "100px", width: "auto" }} />
                          </div>
                          <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                            <div>
                              <strong title={f.name} style={{ color: "#000000" }}>
                                {f.name.length > 20 ? f.name.substring(0, 20) : f.name}
                              </strong>
                              <br />
                              <br />
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(f.uid, this.state.task_id)}>
                              <FiDownload /> Download
                            </div>
                            {
                                (() => {
                                    if(this.props?.cur_lane !== 'COMP_CMP_COMPLETED' || this.props?.edit_prop){
                                        return(
                                             <div style={{ cursor: "pointer", marginTop: "10px" }} onClick={() => this.confirmRemove(f.uid)}>
                                              <MdClear /> Remove
                                            </div>                                           
                                        )
                                    }
                                })()
                            }    

                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </div>
                    );
                })
            }
      </div>
    </>);
  }
}

export default CSLAttachmentViewer;