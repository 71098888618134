import React from 'react';
import styled from 'styled-components';
//import moment from 'moment';
import moment from 'moment';
import {FaAngleDown , FaEye} from 'react-icons/fa';
import { MdEdit , MdDelete } from 'react-icons/md';
import { FaTimes, FaPlusCircle } from 'react-icons/fa';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall.js';
import HtmlEditor from '../Common/HtmlEditor';
import DatePicker from 'react-date-picker';
import Dropzone from '../Common/Dropzone.js'
import * as Style from '../Common/StyledComponents';
import EventBus from '../../Common/EventBus.js';
import AlertBox from '../Common/AlertBox.js';
import CSLLoader from '../Common/CSLLoader.js';

import CSLTable from '../Common/CSLTable.js';
import AddActions from './AddActionHOC.js';
import CMPUtils from '../Common/CMPUtils.js';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1007;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 75%;
    left: 11%;
    top : 60px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    /*color: #a0a0a0;*/
    color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBanner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #F2EBD9;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 25px;
    color: #E39E00;
    text-align: center;
    
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 98%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin:10px;

`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
    /*color: #a0a0a0;*/
    color: #000000;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 77%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-left: 89px;
    margin-top: 10px;
    font-family: Montserrat,sans-serif
`;
const MRModalDivider = styled.div`
    background-color: #EBEBEB;
    height:3px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: calc(99% - 10px);
    margin-left: 12px;
`;
const MRModalLabelTitle = styled.label`
    /*color: #a0a0a0;*/
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
`;

const TestingSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 78%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 73px;
`;
const CatBtn = styled.button`
    border-radius: 4px;
    padding: 10px 32px;
    color: #ffffff;
    cursor: pointer;
`;

const MRSubcatHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #26ADA7;
    padding: 13px;
    padding-bottom:27px;
    border: 1px solid #EBEBEB;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left : 17px;
    margin-top:10px;
`;
const MRSubcatList = styled.div`
    display: block;
    box-sizing: border-box;
    width: 95%;
    background-color: #FFFFFF;
    padding: 13px;    
    border: 1px solid #EBEBEB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left : 17px;
`;
const MRModalCircle = styled.label`
    height: 22px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    float: right;
    padding-bottom: 9px;
    padding-left: 5px;
    margin-bottom:2px;
    cursor:pointer;
`;
const MRTestingLabel = styled.label`
    display: inline-block;
    box-sizing: border-box;
    width: 78%;
    background-color: #EEEEEE;
    padding: 14px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-left: 71px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 210px;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;
const TestingTextArea = styled.textarea`
    width:70%;
    margin-left:71px;
    margin-top: 15px;
    border: 1px solid #ffffff; 
    borderRadius: 3px; 
    boxShadow: 0 0 4px #c4c4c4;
    padding: 5px; 
    color: #979797; 
    fontFamily: Montserrat,sans-serif;
`;
const PartialDiv = styled.div`
    display:inline-block;
`;
const CompleteModalSelect = styled.select`
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 94%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    margin-left: 20%;
    font-weight: bold;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 1006;
    opacity: 0.8;
    `;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    margin-right:3px;
`;

const MrModalSelect = styled.select`
margin-top: 15px;
display: inline-block;
box-sizing: border-box;
height: 34px;
width: calc(98% - 147px);
background-color: #fff;
border: 1px solid #ffffff;
border-radius: 3px;
box-shadow: 0 0 4px #c4c4c4;
font-family: Montserrat,sans-serif;
margin-left: 73px;
font-weight: bold;
`;

const OverviewInfoHeader = styled.div`
padding: 12px 15px;
background-color: #04ada8;
border-top-left-radius: 5px;
border-top-right-radius: 5px;    
width: 96%;
margin: 0 auto;
margin-top: 30px;    
`;
const capitalize = (s) => {
  //alert(typeof s)
  if (typeof s !== 'string') return ''
  //console.log(s.charAt(0).toUpperCase() + s.slice(1))
  return s.charAt(0).toUpperCase() + s.slice(1)
}
class CompleteModal extends React.Component {
    state = {
        task_data: {},
        risk_area_name: '',
        risk_owner_id: '',
        testing_activity: null,
        risk_area:null,
        testing_description: '',
        testing_freq: '',
        first_schedule: new Date(),
        testing_owner: '',
        testing_objectives: '',
        testing_method: '',
        additional_guidance: '',
        testing_activity_undertaken: '',
        testing_activity_undertaken_values_arr: [],
        review_attachment: [],
        rag: '0',
        require_remediation: 'No',
        submit_msg: '',
        unique_testing_act_id: null,
        task_id: 0,
        binFiles: [],
        curBinFiles: [],
        actions: [],
        cur_files_json: [],
        actionModalOpen: false,
        is_form_modified: false,
        alert_param: null,
        action_extra_json: {},
        cur_lane: 'COMP_CMP_ASSIGNMENT',
        model_bin_file_id: 0,
        ready: false,
        show_initiate_approval: false,
        approval_contact: 0,
        IsApprovalContactProvided: false,
        custom_rags: [],
        enable_approval_assigned_to: 0,
        req_rem: null,
        showAddActionModal: false,
        ready: false,
        tact_task_id:null,
        unique_plan_id:null,
        testing_activity_obj:null,
        parent_comp_id:0,
        ref_id:null,
        useful_resource:null,
        is_loader:false,
        show_initiate_approval:false,
        approval_contact : 0,
        approver_id:0,
        all_risk_area:[]
    }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prev_props) {
        if(prev_props === this.props) return
        this.setComponentState()

    }

    isBase64(str) {
        if (str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }
    changeStringToAtob = (str_value) => {
        let str_text = str_value === null || str_value === "" ? "" : str_value;
        if(this.isBase64(str_text)){
          str_text = window.decodeURIComponent(atob(str_text))
        }
        return str_text;
    }
    setComponentState = () => {
        console.log("COMP:: Props::",this.props)

        let refId = this.props.refId;

        //this.setState({ready:false})

        let postData = {"command": "check_task_type", "ref_id": refId};
        let api = new APICall();
        //let that = this;
        api.command(postData, this.setComponentState_task)

    }
    setComponentState_task = (result) => {    
        console.log("COMP props,",result)


        let refId = this.props.refId;

        if(result.is_task){
            ///for physical tasks
            this.setPhysicalTask(result)
            //let postData = {"command": "get_task", "task_id": tact_task_id};
            //let api = new APICall();
            //let that = this;
            //api.command(postData, this.setPhysicalTask)
        }else{
            let tact_task_id = 0
            // console.log("ACT:: Props::",this.props)
            let refId = this.props.refId;
            // let role = Store.getStoreData('role')
            let testing_activity = result.testing_activity
            let risk_area = result.risk_area;
            // let rag = 'rag' in testing_activity ? testing_activity.rag : 0
            let rag = 'rag' in testing_activity ? this.customRagDecider(testing_activity.rag) : 0
            let testing_description = this.changeStringToAtob(testing_activity.testing_description);
            let testing_objectives  = this.changeStringToAtob(testing_activity.testing_objectives);
            let additional_guidance = this.changeStringToAtob(testing_activity.additional_guidance);
            let testing_method = this.changeStringToAtob(testing_activity.testing_method);
            let unique_plan_id = testing_activity.plan_ref_id 
            let unique_testing_act_id = testing_activity.unique_testing_act_id
            let useful_resource = testing_activity.attachments;

            var actual = refId.split('-')[5]

            let y_str = actual.substring(0, 4)
            let m_str = actual.substring(4, 6)
            let d_str = actual.substring(6, 8)

            console.log(y_str,m_str,d_str)
            

            let fs_due_date_obj = new Date(y_str+'/'+m_str+"/"+d_str);
            let fs_day = fs_due_date_obj.getDate();
            fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
            let fs_month = fs_due_date_obj.getMonth() + 1;
            fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
            let fs_year = fs_due_date_obj.getFullYear();

            testing_activity.due_date = `${fs_year}-${fs_month}-${fs_day}T00:00:00z`
            let testing_activity_obj = {};
            testing_activity_obj.testing_activity = testing_activity.testing_activity;
            testing_activity_obj.testing_description = testing_activity.testing_description;
            testing_activity_obj.testing_freq = testing_activity.testing_freq;
            testing_activity_obj.first_schedule = testing_activity.due_date;
            testing_activity_obj.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`;
            testing_activity_obj.testing_owner = testing_activity.testing_owner;
            testing_activity_obj.testing_objectives = testing_activity.testing_objectives;
            testing_activity_obj.testing_method = testing_activity.testing_method;
            testing_activity_obj.additional_guidance = testing_activity.additional_guidance;
            testing_activity_obj.unique_testing_act_id = testing_activity.unique_testing_act_id; 
            testing_activity_obj.unique_risk_id = testing_activity.unique_risk_id;   
            testing_activity_obj.unique_subcat_id = testing_activity.unique_subcat_id;

            let enable_approval_assigned_to = ('enable_approval_assigned_to' in testing_activity) ? testing_activity.risk_json.enable_approval_assigned_to : 0;

            let gc_companies = Store.getStoreData('gc_companies')
            let parent_comp_id = refId.split('-')[6]

            

            let gc_id = refId.split('-')[1]

            //let plan_id = role === "team" ? Store.getStoreData('team_plans')[unique_plan_id] : this.props.plan.id

            //console.log("paren_comp_id::",parent_comp_id)
            let ref_id = refId
            //plan_id+'-'+gc_id+'-'+testing_activity.unique_risk_id+'-'+testing_activity.unique_subcat_id+'-'+testing_activity.unique_testing_act_id+'-'+`${fs_year}${fs_month}${fs_day}`+'-'+parent_comp_id;
            let module_configs = Store.getStoreData('module_config')

            let role = Store.getStoreData('role')
            let user_id = Store.getStoreData('user_info').ID.toString()
            let testing_owner = testing_activity.testing_owner 
            let alert_param = null
            let created_by = testing_activity.created_by
            if((role === 'risk_owner' && (testing_owner.toString() !== user_id.toString() && (created_by.toString() !==  user_id.toString()) )) || 
            (role === 'subcat_owner' && (testing_owner.toString() !== user_id.toString() && (created_by.toString() !==  user_id.toString()) ))  ||
            (role === 'team' && (testing_owner !== user_id.toString())) ){
            
                alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
            }
            let ready = true
            this.setState({gc_id,ready,parent_comp_id,ref_id,unique_plan_id,testing_activity,useful_resource,first_schedule : testing_activity_obj.first_schedule,testing_owner: testing_activity_obj.testing_owner,testing_activity_obj,unique_testing_act_id,risk_area,tact_task_id,rag,testing_description,testing_objectives,additional_guidance,testing_method}) 

        }

    }
    setPhysicalTask = (result) => {
        console.log(result)
        let task_data = result.task_data.result.task.parenttask;
        let mainTask = JSON.parse(result.task_data.result.task.parenttask.task_json)
        let child_tasks = result.task_data.result.task.childtask.result
        let actions=[]
        let all_risk_area = result.all_risk_area


        for(let i =0; i<child_tasks.length;i++){
            if(child_tasks[i].cur_lane !== 'COMP_CMP_FOLLOWUP_DELETE'){
                let singleaction = {}
                let followup_task_json = JSON.parse(child_tasks[i].task_json)
                singleaction.due_date = child_tasks[i].due_date
                singleaction.cur_lane = child_tasks[i].cur_lane
                singleaction.created_by = child_tasks[i].contact_id
                singleaction.action_title = followup_task_json.object_data.headerinfo.risk_json.actions.action_title
                singleaction.action_description = followup_task_json.object_data.headerinfo.risk_json.actions.action_description
                singleaction.action_assignto = followup_task_json.object_data.headerinfo.risk_json.actions.action_assignto
                singleaction.action_status = child_tasks[i].cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE' ? 'Complete' : 'Incomplete'
                singleaction.followupBinFiles = []
                singleaction.task_id = child_tasks[i].id

                actions.push(singleaction)
            }
        }

        // console.log("MYACTIONS",actions)
        // let module_configs = Store.getStoreData('module_config')
        let module_configs = Store.getStoreData('module_config')

        // let rag = mainTask.object_data.result[0].rag
        //let rag = this.customRagDecider(mainTask.object_data.result[0].rag)
        let rag = mainTask.object_data.result[0].rag.toString() === '0' ? '0' : this.customRagDecider(mainTask.object_data.result[0].rag)
        let curBinFiles = mainTask.object_data.bin_files;

        let testing_activity_undertaken = this.changeStringToAtob(mainTask.object_data.result[0].testing_activity_undertaken);
        let testing_activity_undertaken_values_arr = [];
            if(module_configs.general.allow_rolling_comments_for_testing_activities){
                testing_activity_undertaken = '';
            }
            if('testing_activity_undertaken_values_arr' in mainTask.object_data.result[0]){
                testing_activity_undertaken_values_arr =  mainTask.object_data.result[0].testing_activity_undertaken_values_arr;
            }   
        //let testing_activity_undertaken_values_arr = mainTask.object_data.result[0].testing_activity_undertaken_values_arr
        let require_remediation = mainTask.object_data.result[0].require_remediation


            console.log("ACT:: Props::",this.props)
            const risk_json = mainTask?.object_data?.headerinfo?.risk_json;
            const subcategories = risk_json.subcategories;
            let testing_activity = "testing_activities" in subcategories ? subcategories.testing_activities[0] : risk_json.testing_activities[0];
            //console.log("ACT:: Props::",this.props)
            //let testing_activity = this.props.testing_activity
            let risk_area = result.risk_area;
            let tact_task_id = result.task_id;
            //let tact_task_id = tact_task_id;
            //let rag = 'rag' in testing_activity ? testing_activity.rag : 0
            let testing_activity_props = result.testing_activity

            let testing_description = this.changeStringToAtob(testing_activity_props.testing_description);
            let testing_objectives  = this.changeStringToAtob(testing_activity_props.testing_objectives);
            let additional_guidance = this.changeStringToAtob(testing_activity_props.additional_guidance);
            let testing_method = this.changeStringToAtob(testing_activity_props.testing_method);

            if(task_data.cur_lane === 'COMP_CMP_COMPLETED'){
                testing_description = this.changeStringToAtob(testing_activity.testing_description);
                testing_objectives  = this.changeStringToAtob(testing_activity.testing_objectives);
                additional_guidance = this.changeStringToAtob(testing_activity.additional_guidance);
                testing_method = this.changeStringToAtob(testing_activity.testing_method);
            }
            // let role = Store.getStoreData('role')
            let unique_plan_id = result.plan_ref;
            let unique_testing_act_id = testing_activity.unique_testing_act_id
            let useful_resource = result.testing_activity.attachments
            


            let fs_due_date_obj = new Date(testing_activity.first_schedule);
            let fs_day = fs_due_date_obj.getDate();
            fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
            let fs_month = fs_due_date_obj.getMonth() + 1;
            fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
            let fs_year = fs_due_date_obj.getFullYear();

            let testing_activity_obj = {};
            // testing_activity_obj.testing_activity = testing_activity.testing_activity;
            // testing_activity_obj.testing_description = testing_activity.testing_description;
            // testing_activity_obj.testing_freq = testing_activity.testing_freq;
            // testing_activity_obj.first_schedule = testing_activity.first_schedule;
            // testing_activity_obj.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`;
            // testing_activity_obj.testing_owner = testing_activity.testing_owner;
            // testing_activity_obj.testing_objectives = testing_activity.testing_objectives;
            // testing_activity_obj.testing_method = testing_activity.testing_method;
            // testing_activity_obj.additional_guidance = testing_activity.additional_guidance;
            // testing_activity_obj.unique_testing_act_id = testing_activity.unique_testing_act_id; 
            // testing_activity_obj.unique_risk_id = testing_activity.unique_risk_id;   
            // testing_activity_obj.unique_subcat_id = testing_activity.unique_subcat_id;

            testing_activity_obj.testing_activity = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.testing_activity : testing_activity_props.testing_activity;
            testing_activity_obj.testing_description = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.testing_description : testing_activity_props.testing_description;
            testing_activity_obj.testing_freq = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.testing_freq : testing_activity_props.testing_freq;
            testing_activity_obj.first_schedule = testing_activity.first_schedule;
            testing_activity_obj.first_schedule_dt_str = `${fs_day}/${fs_month}/${fs_year}`;
            testing_activity_obj.testing_owner = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.testing_owner : testing_activity_props.testing_owner;
            testing_activity_obj.testing_objectives = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.testing_objectives : testing_activity_props.testing_objectives;
            testing_activity_obj.testing_method = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.testing_method : testing_activity_props.testing_method;
            testing_activity_obj.additional_guidance = task_data.cur_lane === 'COMP_CMP_COMPLETED' ? testing_activity.additional_guidance : testing_activity_props.additional_guidance;
            testing_activity_obj.unique_testing_act_id = testing_activity.unique_testing_act_id; 
            testing_activity_obj.unique_risk_id = testing_activity.unique_risk_id;   
            testing_activity_obj.unique_subcat_id = testing_activity.unique_subcat_id;

            let enable_approval_assigned_to = ('enable_approval_assigned_to' in testing_activity) ? testing_activity.risk_json.enable_approval_assigned_to : 0;

            let gc_companies = Store.getStoreData('gc_companies')
            let parent_comp_id = gc_companies.find(d => d.is_gc === false).id

            // let gc_id = this.props.gc_id;
            let refId = this.props.refId;
            let gc_id = refId.split('-')[1]

            
            // let plan_id = role === "team" ? Store.getStoreData('team_plans')[unique_plan_id] : this.props.plan.id

            //console.log("paren_comp_id::",parent_comp_id)
            let ref_id = this.props.refId

            let cur_lane = task_data.cur_lane

            let approver_id = this.state.approver_id
            if(cur_lane === "COMP_CMP_APPROVAL_REQUEST"){
                if("approver_id" in mainTask.object_data){
                    approver_id = mainTask.object_data.approver_id
                }else{
                    approver_id = task_data.cur_assigned_to
                }
            }
            console.log('VALIDATION::',task_data)
            let role = Store.getStoreData('role')
            let user_id = Store.getStoreData('user_info').ID.toString()
            let testing_owner = task_data.cur_assigned_to.toString()
            let alert_param = null
            let created_by = result.testing_activity.created_by
            if((role === 'risk_owner' && (testing_owner.toString() !== user_id.toString() && (created_by.toString() !==  user_id.toString()) && approver_id.toString() !== user_id.toString())) || 
            (role === 'subcat_owner' && (testing_owner.toString() !== user_id.toString() && (created_by.toString() !==  user_id.toString()) && approver_id.toString() !== user_id.toString()))  ||
            (role === 'team' && (testing_owner !== user_id.toString())) ){
            
                alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
            }


            let ready = true
            this.setState({ready,gc_id,alert_param,require_remediation,curBinFiles,approver_id,useful_resource,actions,task_data,cur_lane,parent_comp_id,testing_activity_undertaken,testing_activity_undertaken_values_arr,ref_id,unique_plan_id,testing_activity,first_schedule : testing_activity_obj.first_schedule,testing_owner: testing_activity_obj.testing_owner,testing_activity_obj,unique_testing_act_id,risk_area,tact_task_id,rag,testing_description,testing_objectives,additional_guidance,testing_method}) 

    }
    AlertHandler = (result, stack) => {
        //this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
        this.props.reloadTestingactivity();
    }
    customRagDecider = (current_rag) => {
        let module_configs = Store.getStoreData('module_config')
        //console.log("module_configs,",module_configs.custom_rags);
        let id_array = [];
        let rag_id = '';
        for (let item of module_configs.custom_rags.additional_rags) {
            id_array.push(item.id);
            if (item.nickname === current_rag) { rag_id = item.id; break; }
            if (item.rag_value === current_rag) { rag_id = item.id; break; }
        }
        if (rag_id !== '') return rag_id;
        if (id_array.includes(current_rag)) return current_rag;
    }
    filesLoadedType2 = (jsonObj) => {
        // let me = Store.getStoreData('cmp-loggedin_user_info');
        let cur_files_json = this.state.curBinFiles;
        //console.log('files in Modal size 1==>', curBinFiles.length);
        let file_exists = {};
        let curBinFiles_1 = [];
        
            for (let i = 0; i < jsonObj.length; i++) {
                console.log("curBinFiles_1",jsonObj[i])
                Object.assign(jsonObj[i], {user_id: Store.getStoreData('user_info').ID});
                Object.assign(jsonObj[i], {upload_date:  Date.now()});
                Object.assign(jsonObj[i], {type: "complete_resource"});

                if (typeof file_exists[jsonObj[i].name] === "undefined") {
                    cur_files_json.push(jsonObj[i]);
                    file_exists[jsonObj[i].name] = true;
                }
                curBinFiles_1 = cur_files_json.filter((cur_files_json, index, self) => index === self.findIndex((t) => (t.name === cur_files_json.name && t.uid === cur_files_json.uid)));
                
            }
        
             this.setState({ curBinFiles: curBinFiles_1, is_form_modified: true });
    }


    filesRemovedType2 = (jsonObj) => {
        // if (jsonObj.files_to_show_size === 0) {
        //     this.setState({ is_form_modified: false });
        // }
        console.log('filesRemovedType2::',jsonObj)
        let curBinFiles = this.state.curBinFiles.filter(item => item.uid !== jsonObj);


        if (jsonObj.files_to_show_size === 0) {
            this.setState({ is_form_modified: false,curBinFiles });
        }else{
            this.setState({ curBinFiles });
        }
    }
    changeDropdown = (key, e) => {
        this.setState({ [key]: e.target.value })
    }

    openTestingActivities = () => {
        this.setState({ showAddActionModal: true })
    }
     genKey = (length) => {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  } 

    refreshAddActionValueTask= (task_id,action_title,actionDescription,actionAssignedTo, due_date) => {
            let actions = this.state.actions;
            // console.log('refreshAddActionValueTask actions',task_id,action_title,actionDescription,actionAssignedTo, due_date)
            // console.log('refreshAddActionValueTask actions',actions)

            actions.forEach((item) => {
            // console.log('refreshAddActionValueTask actions',item)

                if(typeof item.task_id !== 'undefined' && typeof task_id !== null && item.task_id === task_id){
                    // console.log('refreshAddActionValueTask actions 123456798')

                    item.action_description = actionDescription
                    item.action_title = action_title
                    item.action_assignto = actionAssignedTo
                    item.due_date = due_date
                }
            });
            // console.log('refreshAddActionValueTask actions',actions)
            EventBus.raiseEvent('Followup_updated', actions)


            this.setState({actions: actions,showAddActionModal: false, action_extra_json: {},tact_risk_area:null});
            
    }

    refreshAddActionValue = (action_title,actionDescription,actionAssignedTo,actionBinFiles,action_status , due_date , temp_id) => {
        //console.log("refreshAddActionValue actions,", this.state.actions , temp_id);
        let loggedin_user_info = Store.getStoreData('user_info');
        //let utils = Utils();
        if(action_title != '' && actionAssignedTo != 0) {
            
            let fs_due_date_obj = new Date(due_date);
            let fs_day = fs_due_date_obj.getDate();
            fs_day = (fs_day > 9) ? fs_day.toString() : `0${fs_day}`;
            let fs_month = fs_due_date_obj.getMonth() + 1;
            fs_month = (fs_month > 9) ? fs_month.toString() : `0${fs_month}`;
            let fs_year = fs_due_date_obj.getFullYear();            

            let actionsObject = {};
            actionsObject.action_title = action_title;
            actionsObject.action_description = actionDescription;
            actionsObject.action_assignto = actionAssignedTo;
            actionsObject.actionBinFiles = actionBinFiles;
            actionsObject.followupBinFiles = actionBinFiles;
            actionsObject.action_status = 'New';
            actionsObject.due_date = due_date;
            actionsObject.due_date_str = `${fs_day}/${fs_month}/${fs_year}`;
            actionsObject.temp_id = (temp_id !== null) ? temp_id : this.genKey(8);
            actionsObject.created_by = loggedin_user_info.ID;
            

            let actions = this.state.actions;

            // console.log("refreshAddActionValue Action::",actions)
            // console.log("refreshAddActionValue actionsObject::",actionsObject)
            // console.log("refreshAddActionValue temp_id::",temp_id)
            
            let updated_actions = [];
            
            actions.forEach((item) => {
                if(typeof item.temp_id !== 'undefined' && typeof temp_id !== null && item.temp_id === temp_id)item = actionsObject; // 2nd,3rd onward
                updated_actions.push(item);
            });
            //console.log("refreshAddActionValue actions,", updated_actions , temp_id);
            if(temp_id === null)updated_actions.push(actionsObject); // first time
            this.setState({actions: updated_actions,showAddActionModal: false});
        }
        
    }

    viewAction = (jsonObj) => {
        // console.log("task_id in viewAction ==>",jsonObj,jsonObj.task_id);
        this.setState({action_extra_json : jsonObj});
        if(typeof jsonObj.temp_id === 'undefined'){
            let postData = {"command": "get_task", "task_id": jsonObj.task_id};
            let api = new APICall();
            let that = this;
            //api.command(postData, function callback(result){this.processViewAction(jsonObj,result)});    
            api.command(postData, this.processViewAction);           

        }else{
            // console.log("task_id in viewAction with temp==>",jsonObj,jsonObj.task_id);
            this.setState({showAddActionModal: true , view_mode:true, action_extra_json : jsonObj});
        }
        
        //
    }
    processViewAction = (task_info) => {
        let jsonObj = this.state.action_extra_json
        // console.log("task_info==>",task_info);
        //console.log("task_json of current followup task ==>"+task_info.result.task.parenttask.task_json);
        let followupBinFiles = [];
        let child_task_json = JSON.parse(task_info.result.task.parenttask.task_json );  
        // console.log("child_task_json::",child_task_json)
        if('bin_files' in  child_task_json.object_data) {
            child_task_json.object_data.bin_files.forEach((item) => {
                item.uid = "uid" in item ? item.uid : Utils.genKey(10);
                followupBinFiles.push(item);
            });                 
            //actionBinFiles = current_task.object_data.bin_files;
        }
        let action_note_undertaken ="";
        if('result' in  child_task_json.object_data && 'action_note_undertaken' in child_task_json.object_data.result[0]) {
            action_note_undertaken = child_task_json.object_data.result[0].action_note_undertaken;                  
            //actionBinFiles = current_task.object_data.bin_files;
        }       
        jsonObj.followupBinFiles = followupBinFiles;
        jsonObj.action_note_undertaken = action_note_undertaken;
        jsonObj.cur_lane = task_info.result.task.parenttask.cur_lane;
        jsonObj.result = child_task_json.object_data.result;


        this.setState({showAddActionModal: true , action_extra_json : jsonObj});       
    }   

    processMyActionData = () => {
        //console.log("TT=>",Store.getStoreData('contacts'));
        let contact_list = Store.getStoreData('contacts');
        let loggedin_user_info = Store.getStoreData('user_info');
        let role = Store.getStoreData('role');
        let original_risk_areas_pre = this.state.all_risk_area;
        let risk_owners_assoc = {};
        let subcat_owners_assoc = {};
        original_risk_areas_pre.forEach((item) => {
            if(item.status === 'Active'){
                risk_owners_assoc[`${item.plan_ref_id}-${item.gc_id}-${item.unique_risk_id}`] = item.risk_owner_id;
                item.subcategories.forEach((subcat_item) => {
                    if(subcat_item.subcat_status === 'Active'){
                        subcat_owners_assoc[`${item.plan_ref_id}-${item.gc_id}-${item.unique_risk_id}-${subcat_item.unique_subcat_id}`] = subcat_item.subcat_assignto;
                    }
                });
            }
        });

        
        
        
        var keysdata = this.state.actions;
        console.log("processMyActionData keysdata,",keysdata);
        let ret = {data: [], columns: []};
        let i = 1;
        keysdata.sort(function(a, b){return new Date(a.due_date).getTime() - new Date(b.due_date).getTime()});
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        {Header: 'Title', accessor: 'action_title', minWidth: 90, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'action_description', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {Header: 'Assign to', accessor: 'action_assignto', minWidth: 70, headerStyle: {textAlign: 'left'}},
        {Header: 'Created by', accessor: 'created_by', minWidth: 70, headerStyle: {textAlign: 'left'}},
        //{Header: 'Due Date', id: 'due_date' ,accessor: c => c.due_date , Cell: c => <span>{c.original.due_date && moment(c.original.due_date).format("DD/MM/YYYY")}</span> , minWidth: 70, headerStyle: {textAlign: 'left'}},
        {Header: 'Due Date', accessor: 'due_date' , minWidth: 120, headerStyle: {textAlign: 'left'} ,sortMethod: (a, b) => {
        if (a === b) {
            return 0;
        }
        let aReverse = 0;
        let bReverse = 0;
        let adue = a.split("/");
        aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let bbdue = b.split("/");
        bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
        }},     
                      {'Header' : 'Status', Cell: row => (
                        <div>
                            {
                                (() => {
                                    //need to restrict by role
                                    if(row.original.action_status === "Incomplete"){
                                        if(row.original.cur_lane === "COMP_CMP_FOLLOWUP_ASSIGNMENT" || row.original.cur_lane === "COMP_CMP_FOLLOWUP_APPROVAL" ){
                                            return (<MRButton  style={{float: "left",backgroundColor: "#F39C12"}}>Pending</MRButton>);
                                        }else if(row.original.cur_lane === "COMP_CMP_FOLLOWUP_SAVED"){
                                            return (<MRButton  style={{float: "left",backgroundColor: "blue"}}>In Progress</MRButton>);
                                        }else if(row.original.cur_lane === "OVERDUE"){
                                            return (<MRButton  style={{float: "left",backgroundColor: "red"}}>Overdue</MRButton>);
                                        }
                                        
                                    }else if(row.original.action_status === "New" ){
                                        if(row.original.cur_lane === "OVERDUE"){
                                            return (<MRButton  style={{float: "left",backgroundColor: "red"}}>Overdue</MRButton>);
                                        }else{
                                            return (<MRButton  style={{float: "left",backgroundColor: "#F39C12"}}>Pending</MRButton>);
                                        }                                       
                                    }else{
                                        return (<MRButton style={{float: "left"}}>Complete</MRButton>);
                                    }
                                })()
                            }


                            {
                                (() => {
                                    //need to restrict by role
                                        return (
                                            <div style={{float: "right"}}>


                                                {
                                                    (() => {
                                                        if(row.original.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE'){
                                                            if(row.original.created_by_id.toString() === loggedin_user_info.ID.toString() || role === 'admin_manager' || ((row.original.risk_owner_key in risk_owners_assoc && risk_owners_assoc[row.original.risk_owner_key].toString() === loggedin_user_info.ID.toString()) || (row.original.subcat_owner_key in subcat_owners_assoc && subcat_owners_assoc[row.original.subcat_owner_key].toString() === loggedin_user_info.ID.toString()))){
                                                                return(
                                                                    <div style={{float: "right", marginRight : "10px"}}>
                                                                        <ViewDiv style={{ curser: "pointer" }} onClick={() => this.viewAction({testing_act_id: row.original.testing_act_id,  name : row.original.action_title,description : row.original.action_description,cur_assigned_to : row.original.action_assignto_id, due_date : new Date(row.original.unformatted_due_date), followupBinFiles : row.original.followupBinFiles , showModal : true , view_mode : false , task_id : row.original.task_id , temp_id : row.original.temp_id })}   title="edit this remedial action" style={{color : "#1A3552"}}><MdEdit /></ViewDiv>
                                                                    </div>
                                                                    );                                                              
                                                            }

                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(row.original.cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE'){
                                                            //if(role === 'admin_manager'){
                                                                return(
                                                                    <div style={{float: "right", marginRight : "10px"}}>
                                                                        <ViewDiv style={{ curser: "pointer" }} onClick={() => this.viewAction({testing_act_id: row.original.testing_act_id, name : row.original.action_title,description : row.original.action_description,cur_assigned_to : row.original.action_assignto_id, due_date : new Date(row.original.unformatted_due_date), followupBinFiles : row.original.followupBinFiles , showModal : true , view_mode : true , task_id : row.original.task_id})}   title="view this remedial action" style={{color : "#1A3552"}}><FaEye /></ViewDiv>
                                                                    </div>
                                                                    );                                                              
                                                            //}

                                                        }
                                                    })()
                                                }

                                            </div>);
                                })()
                            }                           

                        </div>
                      ), width: 200, headerStyle: {textAlign: 'center'}},       
        ];
        for(let d of keysdata) {           
            i++;
            // let parent_task_ref_id_splitted = this.props.ref_id.split('-');
            // let risk_owner_key = `${parent_task_ref_id_splitted[0]}-${parent_task_ref_id_splitted[1]}-${parent_task_ref_id_splitted[2]}`;
            // let subcat_owner_key = `${parent_task_ref_id_splitted[0]}-${parent_task_ref_id_splitted[1]}-${parent_task_ref_id_splitted[2]}-${parent_task_ref_id_splitted[3]}`;

            let due_date_obj = new Date(d.due_date);
            const yyyy = due_date_obj.getFullYear();
            let mm = due_date_obj.getMonth() + 1; // Months start at 0!
            let dd = due_date_obj.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            const due_date_string = dd + '/' + mm + '/' + yyyy;

            // let due_day = due_date_obj.getDate() < 10 ? "0"+due_date_obj.getDate().toString() : due_date_obj.getDate();
            // let due_month = due_date_obj.getMonth()+1 < 10 ? `0${(due_date_obj.getMonth() + 1).toString()}` : due_date_obj.getMonth()+1;
            // let due_year = due_date_obj.getFullYear();
            // let due_date_string = `${due_day}/${due_month}/${due_year}`;            
            
            
            let g1 = new Date();
            let g2 = new Date(d.due_date);
            let cur_lane = d.cur_lane;
            if(g1.withoutTime() > g2.withoutTime()){
                if(cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE')cur_lane = 'OVERDUE';
            }
            if(typeof d.task_id === 'undefined'){d.action_status = 'New'}
            let created_by = ('created_by' in d) ? contact_list[d.created_by] : contact_list[loggedin_user_info.ID];

            let include = true;
            if(role === 'view_only_manager'){
                if(parseInt(this.state.testing_activity.testing_owner) !== parseInt(loggedin_user_info.ID) && parseInt(d.action_assignto) !== parseInt(loggedin_user_info.ID)){
                    include = false;
                }
            }

            if(include){        
	            let elem = {'index' : i, 
	                    'action_title' : d.action_title,
	                    'action_description' : d.action_description,
	                    'action_assignto': contact_list[d.action_assignto] ,
	                    'created_by': created_by,
	                    'action_status' : d.action_status,
	                    'due_date'   :    due_date_string,
	                    'cur_lane' : cur_lane,
	                    'followupBinFiles' : d.followupBinFiles ,
	                    'task_id' : d.task_id,
	                    'action_assignto_id': d.action_assignto ,
	                    'unformatted_due_date'   :    d.due_date ,
	                    'created_by_id' : ('created_by' in d) ? d.created_by : loggedin_user_info.ID,
	                    //'risk_owner_key' : risk_owner_key,
	                    //'subcat_owner_key' : subcat_owner_key,
	                    'temp_id' : d.temp_id,
	                    'testing_act_id' : this.state.testing_activity.unique_testing_act_id
	                    
	                   };
	            ret.data.push(elem);
	            i++;

            }
        }
        // console.log("processMyActionData keysdata,",ret);
        return ret;
    }
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value, is_form_modified : true});
    }
    getCustomRagAssoc = (module_configs) =>{
        let addtional_rags_assoc = {};
        for(let item of module_configs.custom_rags.additional_rags){
            addtional_rags_assoc[item.id] = item;
        }
        return addtional_rags_assoc;
    }
    handleChangeRag = (event) => {
        // event.preventDefault();
        //let cmputils = new CMPUtils();
        let module_configs = Store.getStoreData('module_config');
        // let module_configs = Store.getStoreData('module_config');
        let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);
        console.log("handleChangeRag::",event.target.value)
        console.log("handleChangeRag addtional_rags_assoc::",addtional_rags_assoc)
        // let require_remediation = this.state.require_remediation;
        // if((event.target.value !== '0' && addtional_rags_assoc[event.target.value].required_remedial_action) || this.state.actions.length > 0){
        // // if(event.target.value !== '0' && addtional_rags_assoc[event.target.value].required_remedial_action){
        //     require_remediation='Yes';
        // }else{
        //     require_remediation='No';
        // }
        this.setState({[event.target.name]: event.target.value, is_form_modified : true});
    }
    saveTestingActivities = (event) =>{
        event.preventDefault();
        
        if(this.state.rag.toString() === '0' && this.state.require_remediation === 'Yes'){
            let alert_param = {title: 'Alert', message: 'A Risk Rating has to be selected.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorSaveTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
            return false;           
        }
        if((this.state.require_remediation === 'Yes' && this.state.actions.length === 0)){
            let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to save this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorSaveTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
            return false;           
        } 
        // console.log('saveTestingActivities state::',this.state)
        // console.log('saveTestingActivities props::',this.props)
        this.setState({is_loader : true});
        let role = Store.getStoreData('role');
        let bin_files = this.state.curBinFiles;
        let action_tasks = this.state.actions.length > 0 ? this.state.actions : [] ;
        let action_bin_files = this.state.cur_files_json;
        let module_configs = Store.getStoreData('module_config')
        //Store.getStoreData('module_config'); 
        let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);   

        //return;
        let cur_lane_parent = 'COMP_CMP_SAVED';
        if(role === 'team' && module_configs.general.enable_approval)cur_lane_parent = 'COMP_CMP_APPROVAL';
        console.log('actiondate::',moment().unix())
        console.log('actiondate::',Date.now())
        var action_data = {action: cur_lane_parent,actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : cur_lane_parent}
        var task_data = this.state.task_data;
        let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
        if(testing_activity_undertaken_text === null){
            testing_activity_undertaken_text = this.state.testing_activity_undertaken;
        }
        let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
        let testing_activity_undertaken_value_array = this.state.testing_activity_undertaken_values_arr;
        testing_activity_undertaken_value_array.push({
            tau_text: testing_activity_undertaken_value,
            created_by: Store.getStoreData('user_info').ID,
            created_on: moment().format("DD/MM/YYYY") 
        }); 

        var result = [{
                    unique_testing_act_id:this.state.unique_testing_act_id,
                    testing_activity_undertaken: testing_activity_undertaken_value,
                    testing_activity_undertaken_values_arr: testing_activity_undertaken_value_array,
                    reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation
                    }]

        let task_json = {};
        let ref_id = this.state.ref_id;

        let followup_tasks_to_be_created = this.getNewFollowupTasks();

        if((this.state.require_remediation === 'Yes' && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0) && this.state.rag.toString() === '0') || (this.state.rag.toString() !== '0' && addtional_rags_assoc[this.state.rag].required_remedial_action && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0))){
            let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to save this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorSaveTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
            return false;           
        } 
        //console.log(ref_id);      
        if(this.state.tact_task_id === 0){
            var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_undertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
            if(this.state.testing_activity.unique_subcat_id.toString() !== '0' ){ // subcat checking
                res_data.object_data.headerinfo.risk_json = {
                    unique_risk_id : this.state.testing_activity.unique_risk_id,
                    testing_activities : [],
                    subcategories : {
                        unique_subcat_id : this.state.testing_activity.unique_subcat_id,
                        testing_activities : [this.state.testing_activity_obj]
                    }
                }           
            }else{
                res_data.object_data.headerinfo.risk_json = {
                    unique_risk_id : this.state.testing_activity.unique_risk_id ,
                    testing_activities : [this.state.testing_activity_obj],
                    subcategories : {

                    }
                }               
            }
            task_json = {
                "task_data": { "module_id": "cmp", "last_action": "COMP_CMP_ASSIGNMENT", "actiondate": Date.now(), 
                'system_id' : '1001', 'cur_lane' : cur_lane_parent,
                "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, 
                 due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
                "object_data": res_data.object_data,
                "action_data": { "action": cur_lane_parent, "actiondate": Date.now(),"performed_by":Store.getStoreData('user_info').ID, "assigned_to": this.state.testing_owner, "cur_lane":cur_lane_parent }
            };
        }else{
            task_json = JSON.parse(task_data.task_json);
        }   

        task_json.object_data.result = result;
        task_json.object_data.plan_ref = this.state.unique_plan_id
        task_json.object_data.action_bin_files = bin_files;
        task_json.object_data.approver_id = 0
        //task_json.object_data.action_bin_files = action_bin_files;
        task_json.action_data = action_data;
        task_data.task_json = task_json;

        // console.log("saveTestingActivities A task_json::",task_json)



        // console.log("saveTestingActivities A followup_tasks_to_be_created::",followup_tasks_to_be_created)

        let extra_json = {}
        if(this.state.rag === 'Red'){
            extra_json = {'risk_area_name' : this.state.risk_area.risk_area_name , 'risk_owner_id' : this.state.risk_area.risk_owner_id , 'send_rag_alert_email' : true , 'task_id' : this.state.tact_task_id };
        }

        let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
        let that = this;
        let postData = {"command": "updateTask", "task_id" : ('id' in task_data) ? task_data.id : 0, "task_json" :task_data.task_json , "extra_json" : extra_json , ref_id : this.state.ref_id , followup_tasks_to_be_created : followup_tasks_to_be_created , model_bin_file_id : this.state.model_bin_file_id , last_action_time : last_action_time};
        if(bin_files.length > 0){
            postData['attachments'] = bin_files;
        }

        let api = new APICall();
        // console.log("saveTestingActivities postData",postData);
        api.command(postData, this.processSaveTestingActivities);
        let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processSaveTestingActivities, stack: {} };
        this.setState({alert_param: alert_param});
    }

   processSaveTestingActivities = (result) => {
    //alert("saved")
    this.props.reloadTestingactivity(this.state.unique_plan_id)

   }
    processErrorSaveTestingActivities = (result , stack) => {
        this.setState({alert_param: null} );
    }  
    getNewFollowupTasks = () =>{
        let followup_tasks_to_be_created = [];
        let userid = Store.getStoreData('user_info').ID;
        let role = Store.getStoreData('role');
        let module_configs = Store.getStoreData('module_config')
        //Store.getStoreData('module_config');
        let cur_lane_followup = 'COMP_CMP_FOLLOWUP_ASSIGNMENT';
        if(role === 'team' && module_configs.general.enable_approval)cur_lane_followup = 'COMP_CMP_FOLLOWUP_APPROVAL';      
        if(this.state.actions.length !== 0){
            for(let i=0;i<this.state.actions.length;i++){
                if(this.state.actions[i].action_status === 'New'){
                    let risk_json = {}; 
                    let action_binfiles = this.state.actions[i].actionBinFiles;
                    let action_single = this.state.actions[i];
                    delete action_single.actionBinFiles;
                    delete action_single.action_status;
                    delete action_single.temp_id;
                    delete action_single.created_by;
                    risk_json.actions = action_single;                
                    let headerinfo = {}
                    headerinfo.risk_json = risk_json;
                    headerinfo.unique_testing_act_id = this.state.unique_testing_act_id ;
                    //headerinfo.bin_files = action_binfiles;           
                    let task = {
                        "task_data": { "module_id": "cmp", "last_action": "COMP_CMP_FOLLOWUP_CREATE", "ref_id" : this.state.unique_testing_act_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.actions[i].action_assignto, 'task_type' : 'COMP_CMP_FOLLOWUP', 'parent_task_id' : this.state.taskId, 'cur_lane': cur_lane_followup, due_date: this.state.actions[i].due_date},
                        "object_data": {headerinfo},
                        "action_data": { "action": "COMP_CMP_FOLLOWUP_CREATE", "actiondate": Date.now(), "assigned_to": this.state.actions[i].action_assignto, "cur_lane":cur_lane_followup, performed_by: userid}
                    };
                    task.object_data.bin_files = action_binfiles;   
                    task.object_data.plan_ref = this.state.unique_plan_id;    
                    //console.log("task follow up",JSON.stringify(task));
                    followup_tasks_to_be_created.push(task);
                
                }

            }           
        }
        return  followup_tasks_to_be_created;   
    }
    completeTestingActivitiesAlert = (event) =>{
        event.preventDefault();
        // console.log(this.state)
        if(this.state.rag.toString() === '0'){
            let alert_param = {title: 'Alert', message: 'A Risk Rating has to be selected.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
            return false;           
        }
        
        let alert_param = {title: 'Confirm', message: 'This will complete the testing activity and further updates cannot be made. Are you sure you want to continue?', ok_text: 'Yes',cancel_text: 'No', confirm: true, alertHandler: this.completeTestingActivities, stack: {} };
        this.setState({alert_param: alert_param});
        return false;       
    }
    processErrorCompleteTestingActivities = (result , stack) => {
        this.setState({alert_param: null} );
    } 
    completeTestingActivities = (result , stack) =>{
        // console.log("CATCOMP state",this.state)
        if(result === false) return;

        let module_configs = Store.getStoreData('module_config')
        //Store.getStoreData('module_config');
        let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);

        // console.log("CATCOMP addtional_rags_assoc",addtional_rags_assoc)


        let action_incomplete_counter = 0;

        if(this.state.require_remediation === "Yes"){
            if(this.state.actions.length === 0){
                //alert("There are currently no actions associated with this Testing Activity.");
                let alert_param = {title: 'Alert', message: 'There are currently no remedial actions associated with this Testing Activity, at least one remedial action must be added to progress.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
                this.setState({alert_param: alert_param});
                return false;
            }else{
                for(let i=0;i<this.state.actions.length;i++){
                    //console.log("actions status==>", this.state.actions[i].action_status);
                    if(this.state.actions[i].action_status === 'Incomplete' || this.state.actions[i].action_status === 'New'){
                        action_incomplete_counter++;
                    }
                }               
            }
        }

        if(addtional_rags_assoc[this.state.rag].required_remedial_action && this.state.actions.length === 0){
            let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to complete this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
            return false;           
        }

        if(!module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding && action_incomplete_counter > 0){
            let alert_param = {title: 'Alert', message: 'Remedial actions are outstanding, please ensure all remedial actions are completed prior to completing this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorCompleteTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
            return false;
        }

        if(module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding && action_incomplete_counter > 0){
            
            let alert_param = {title: 'Confirm', message: 'There are one or more Remedial Actions outstanding. These will not be completed on submission and can be accessed from your Remedial Actions table.', ok_text: 'OK',cancel_text: 'Cancel', confirm: true, alertHandler: this.processErrorCompleteTestingActivitiesWithRemedialActions, stack: {allow_outstanding_remedial_actions_to_complete : true} };
            this.setState({alert_param: alert_param});
            return false;           
        }
        let input_json = {allow_outstanding_remedial_actions_to_complete : module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding};
        this.completeTestingActivitiesBrokenPart(input_json);

    }
    processErrorCompleteTestingActivitiesWithRemedialActions = (result , stack) => {
        this.setState({alert_param: null});
        if(result === false) return;
        
        let followup_tasks_to_be_created = this.getNewFollowupTasks();
        // console.log(followup_tasks_to_be_created)
        let input_json = {allow_outstanding_remedial_actions_to_complete : stack.allow_outstanding_remedial_actions_to_complete , followup_tasks_to_be_created: followup_tasks_to_be_created}
        this.completeTestingActivitiesBrokenPart(input_json);
        
    }
    completeTestingActivitiesBrokenPart = (input_json) =>{
        // console.log("CATCOMP input_json",input_json)
        this.setState({is_loader: true});
        let ref_id = this.state.ref_id;
        
        var bin_files = this.state.curBinFiles;
        var action_bin_files = this.state.cur_files_json;
        
        let task_json = {};
        
        let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
        if(testing_activity_undertaken_text === null){
            testing_activity_undertaken_text = this.state.testing_activity_undertaken;
        }
        let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
        let testing_activity_undertaken_value_array = this.state.testing_activity_undertaken_values_arr;
        testing_activity_undertaken_value_array.push({
            tau_text: testing_activity_undertaken_value,
            created_by: Store.getStoreData('user_info').ID,
            created_on: moment().format("DD/MM/YYYY") 
        });
        
        var result = [{unique_testing_act_id:this.state.unique_testing_act_id,
                    testing_activity_undertaken: testing_activity_undertaken_value,
                    testing_activity_undertaken_values_arr: testing_activity_undertaken_value_array,
                    reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation}]; 
        var task_data = this.state.task_data;
        // console.log("CATCOMP task data::",task_data)

        let risk_id = ref_id.split('-')[2]
        let subcat_id = ref_id.split('-')[3]
        let selected_risk_area = this.state.risk_area;
        
        // selected_risk_area = Store.getStoreData('risk_areas').find(d => d.unique_risk_id === risk_id)
        let selected_sub_cat = {}
        if(subcat_id !== '0'){
            selected_sub_cat = selected_risk_area.subcategories.find(d => d.unique_subcat_id === subcat_id)
        }
        console.log('selected_risk_area ' , selected_risk_area , selected_sub_cat)

        if(this.state.tact_task_id === 0){
            var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_undertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
            if(ref_id.split('-')[3].toString() !== '0' ){ // subcat checking
                res_data.object_data.headerinfo.risk_json = {
                    unique_risk_id : ref_id.split('-')[2],
                    risk_area_name : selected_risk_area.risk_area_name,
                    risk_area_description : selected_risk_area.description,
                    risk_area_risk_impact : selected_risk_area.risk_impact,
                    risk_owner_id : selected_risk_area.risk_owner_id,
                    testing_activities : [],
                    subcategories : {
                        unique_subcat_id : ref_id.split('-')[3],
                        subcategory_name: selected_sub_cat.risk_subcategory_name,
                        subcat_description: selected_sub_cat.subcat_description,
                        subcat_risk_impact: selected_sub_cat.risk_impact,
                        subcat_assignto: selected_sub_cat.subcat_assignto,
                        testing_activities : [this.state.testing_activity_obj]
                    }
                }           
            }else{
                res_data.object_data.headerinfo.risk_json = {
                    unique_risk_id : ref_id.split('-')[2] ,
                    risk_area_name : selected_risk_area.risk_area_name,
                    risk_area_description : selected_risk_area.description,
                    risk_area_risk_impact : selected_risk_area.risk_impact,
                    risk_owner_id : selected_risk_area.risk_owner_id,
                    testing_activities : [this.state.testing_activity_obj],
                    subcategories : {

                    }
                }               
            }
            task_json = {
                "task_data": { "module_id": "cmp", "last_action": "COMP_CMP_ASSIGNMENT", "actiondate": Date.now(), 
                'system_id' : '1001', 'cur_lane' : 'COMP_CMP_COMPLETED',
                "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
                "object_data": res_data.object_data,
                "action_data": { "action": "COMP_CMP_COMPLETED", "actiondate": Date.now(),"performed_by":Store.getStoreData('user_info').ID, "assigned_to": this.state.testing_owner, "cur_lane":"COMP_CMP_COMPLETED" }
            };

        }else{
            task_json = JSON.parse(this.state.task_data.task_json);
        }

        task_json.object_data.result = result;
        task_json.object_data.plan_ref = this.state.unique_plan_id
        task_json.object_data.approver_id = 0
        task_json.object_data.action_bin_files = bin_files;
        // task_json.object_data.action_bin_files = action_bin_files;      
        //current_task.task_data = { "module_id": "cmp", "last_action": "COMP_CMP_COMPLETE", "actiondate": Date.now(), "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'cur_lane': 'COMP_CMP_COMPLETE', due_date: ""};
        var action_data = {action: 'COMP_CMP_COMPLETED',actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : 'COMP_CMP_COMPLETED' , performed_by: Store.getStoreData('user_info').ID ,completed_date: moment().format('YYYY-MM_DD')};
        task_json.action_data = action_data;
        task_data.task_json = task_json;

        //console.log("task_data==>", JSON.stringify(task_data));
        let extra_json = {is_series : this.state.is_series , same_series_tasks : this.state.same_series_tasks , task_status : 'Completed' , allow_outstanding_remedial_actions_to_complete : input_json.allow_outstanding_remedial_actions_to_complete};
        
        let followup_tasks_to_be_created = ('followup_tasks_to_be_created' in input_json) ? input_json.followup_tasks_to_be_created : [];

        // console.log("CATCOMP task_data",task_data)

        // console.log("CATCOMP followup_tasks_to_be_created",followup_tasks_to_be_created)

        let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
        let that = this;
        let postData = {"command": "updateTask","flag":"complete_testingactivity", "task_id" : ('id' in task_data) ? task_data.id : 0, "task_json" :task_data.task_json , "extra_json" : extra_json , ref_id : this.state.ref_id , followup_tasks_to_be_created : followup_tasks_to_be_created , model_bin_file_id : this.state.model_bin_file_id , last_action_time : last_action_time};
        if(bin_files.length > 0){
            postData['attachments'] = bin_files;
        }
        let api = new APICall();
        console.log("saveTestingActivities postData",postData);
        api.command(postData, this.processSaveTestingActivities);
        let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processSaveTestingActivities, stack: {} };
        this.setState({alert_param: alert_param});
    }
    followupcloseModal = () => {
        this.setState({showAddActionModal: false, action_extra_json: {},tact_risk_area:null})
    }
    initiateSendForApproval = (event)=>{
        event.preventDefault();
        this.setState({show_initiate_approval : true}) ;            
    }
    requestSendForApproval = (event)=>{
        if(this.state.approval_contact == 0){
            this.setState({IsApprovalContactProvided: true});
                    
        }else{

            event.preventDefault();
            this.setState({is_loader: true});
            let module_configs = Store.getStoreData('module_config')
            //Store.getStoreData('module_config')
            let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);
            let ref_id = this.state.ref_id;
            let bin_files = this.state.curBinFiles;
            let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
            if(testing_activity_undertaken_text === null){
                testing_activity_undertaken_text = this.state.testing_activity_undertaken;
            }
            let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
            let testing_activity_undertaken_value_array = this.state.testing_activity_undertaken_values_arr;
            testing_activity_undertaken_value_array.push({
                tau_text: testing_activity_undertaken_value,
                created_by: Store.getStoreData('user_info').ID,
                created_on: moment().format("DD/MM/YYYY") 
            }); 

            var result = [{
                        unique_testing_act_id:this.state.unique_testing_act_id,
                        testing_activity_undertaken: testing_activity_undertaken_value,
                        testing_activity_undertaken_values_arr: testing_activity_undertaken_value_array,
                        reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation
                        }]

            let task_json = {};

            var task_data = this.state.task_data;

            let followup_tasks_to_be_created = this.getNewFollowupTasks();

            if((this.state.require_remediation === 'Yes' && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0) && this.state.rag.toString() === '0') || (this.state.rag.toString() !== '0' && addtional_rags_assoc[this.state.rag].required_remedial_action && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0))){
                let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to save this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorSaveTestingActivities, stack: {} };
                this.setState({alert_param: alert_param});
                return false;           
            } 
            //console.log(ref_id);      
            if(this.state.tact_task_id === 0){
                var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_undertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
                if(this.state.testing_activity.unique_subcat_id.toString() !== '0' ){ // subcat checking
                    res_data.object_data.headerinfo.risk_json = {
                        unique_risk_id : this.state.testing_activity.unique_risk_id,
                        testing_activities : [],
                        subcategories : {
                            unique_subcat_id : this.state.testing_activity.unique_subcat_id,
                            testing_activities : [this.state.testing_activity_obj]
                        }
                    }           
                }else{
                    res_data.object_data.headerinfo.risk_json = {
                        unique_risk_id : this.state.testing_activity.unique_risk_id ,
                        testing_activities : [this.state.testing_activity_obj],
                        subcategories : {

                        }
                    }               
                }
                task_json = {
                    "task_data": { "module_id": "cmp", "last_action": "COMP_CMP_ASSIGNMENT", "actiondate": Date.now(), 
                    'system_id' : '1001', 'cur_lane' : 'COMP_CMP_APPROVAL_REQUEST',
                    "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, 
                     due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
                    "object_data": res_data.object_data,
                    "action_data": { "action": "COMP_CMP_APPROVAL_REQUEST", "actiondate": Date.now(),"performed_by":Store.getStoreData('user_info').ID, "assigned_to": this.state.testing_owner, "cur_lane":"COMP_CMP_APPROVAL_REQUEST" }
                };
            }else{
                task_json = JSON.parse(task_data.task_json);
                task_json.task_data.cur_assigned_to = this.state.testing_owner;
            }   

            task_json.object_data.result = result;
            task_json.object_data.plan_ref = this.state.unique_plan_id
            task_json.object_data.approver_id = this.state.approval_contact
            task_json.object_data.action_bin_files = bin_files;
            //task_json.object_data.action_bin_files = action_bin_files;
            var action_data = {action: 'COMP_CMP_APPROVAL_REQUEST',actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : 'COMP_CMP_APPROVAL_REQUEST' , performed_by: Store.getStoreData('user_info').ID ,completed_date: Date.now()};

            task_json.action_data = action_data;
            task_data.task_json = task_json;

            // console.log("saveTestingActivities A task_json::",task_json)



            // console.log("saveTestingActivities A followup_tasks_to_be_created::",followup_tasks_to_be_created)

            let extra_json = {}
            if(this.state.rag === 'Red'){
                extra_json = {'risk_area_name' : this.state.risk_area.risk_area_name , 'risk_owner_id' : this.state.risk_area.risk_owner_id , 'send_rag_alert_email' : true , 'task_id' : this.state.tact_task_id };
            }

            let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
            let that = this;
            let postData = {"command": "updateTask","flag":"approval", "task_id" : ('id' in task_data) ? task_data.id : 0, "task_json" :task_data.task_json , "extra_json" : extra_json , ref_id : this.state.ref_id , followup_tasks_to_be_created : followup_tasks_to_be_created , model_bin_file_id : this.state.model_bin_file_id , last_action_time : last_action_time};
            if(bin_files.length > 0){
                postData['attachments'] = bin_files;
            }

            let api = new APICall();
            // console.log("saveTestingActivities postData",postData);
            api.command(postData, this.processSaveTestingActivities);
            let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processSaveTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
        }
    }    
    doApprove = (event)=>{
        event.preventDefault();
            this.setState({is_loader: true});
            let module_configs = Store.getStoreData('module_config')
            let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);
            let ref_id = this.state.ref_id;
            let bin_files = this.state.curBinFiles;
            let testing_activity_undertaken_text = Store.getStoreData('testing_activity_undertaken');
            if(testing_activity_undertaken_text === null){
                testing_activity_undertaken_text = this.state.testing_activity_undertaken;
            }
            let testing_activity_undertaken_value = btoa(window.encodeURIComponent(testing_activity_undertaken_text));
            let testing_activity_undertaken_value_array = this.state.testing_activity_undertaken_values_arr;
            testing_activity_undertaken_value_array.push({
                tau_text: testing_activity_undertaken_value,
                created_by: Store.getStoreData('user_info').ID,
                created_on: moment().format("DD/MM/YYYY") 
            }); 

            var result = [{
                        unique_testing_act_id:this.state.unique_testing_act_id,
                        testing_activity_undertaken: testing_activity_undertaken_value,
                        testing_activity_undertaken_values_arr: testing_activity_undertaken_value_array,
                        reviewer_attachment:[],rag:this.state.rag,require_remediation:this.state.require_remediation
                        }]

            let task_json = {};

            var task_data = this.state.task_data;

            // let followup_tasks_to_be_created = this.getNewFollowupTasks();

            // if((this.state.require_remediation === 'Yes' && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0) && this.state.rag.toString() === '0') || (this.state.rag.toString() !== '0' && addtional_rags_assoc[this.state.rag].required_remedial_action && !(this.state.actions.length !== 0 || followup_tasks_to_be_created.length !== 0))){
            //     let alert_param = {title: 'Alert', message: 'At least one remedial action must be added to save this testing activity.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processErrorSaveTestingActivities, stack: {} };
            //     this.setState({alert_param: alert_param});
            //     return false;           
            // } 
            //console.log(ref_id);      
            if(this.state.tact_task_id === 0){
                var res_data = {object_data : {headerinfo:{risk_json:{}}, result:[{unique_testing_act_id:'', testing_activity_undertaken:'', reviewer_attachment:[], rag:'', require_remediation:''}]}};
                if(this.state.testing_activity.unique_subcat_id.toString() !== '0' ){ // subcat checking
                    res_data.object_data.headerinfo.risk_json = {
                        unique_risk_id : this.state.testing_activity.unique_risk_id,
                        testing_activities : [],
                        subcategories : {
                            unique_subcat_id : this.state.testing_activity.unique_subcat_id,
                            testing_activities : [this.state.testing_activity_obj]
                        }
                    }           
                }else{
                    res_data.object_data.headerinfo.risk_json = {
                        unique_risk_id : this.state.testing_activity.unique_risk_id ,
                        testing_activities : [this.state.testing_activity_obj],
                        subcategories : {

                        }
                    }               
                }
                task_json = {
                    "task_data": { "module_id": "cmp", "last_action": "COMP_CMP_APPROVAL_REQUEST","cur_lane":"COMP_CMP_SAVED", "actiondate": Date.now(), 
                    'system_id' : '1001', 'cur_lane' : 'COMP_CMP_SAVED',
                    "cur_assigned_to" : this.state.testing_owner, 'task_type' : 'COMP_CMP', 'parent_task_id' : 0, 
                     due_date: new Date(this.state.first_schedule).toISOString() , ref_id : ref_id },
                    "object_data": res_data.object_data,
                    "action_data": { "action": "COMP_CMP_SAVED", "actiondate": Date.now(),"performed_by":Store.getStoreData('user_info').ID, "assigned_to": this.state.testing_owner, "cur_lane":"COMP_CMP_SAVED" }
                };
            }else{
                task_json = JSON.parse(task_data.task_json);
                task_json.task_data.cur_assigned_to = this.state.testing_owner;
            }   

            task_json.object_data.result = result;
            task_json.object_data.plan_ref = this.state.unique_plan_id
            task_json.object_data.action_bin_files = bin_files;
            task_json.object_data.approver_id = 0
            //task_json.object_data.action_bin_files = action_bin_files;
            var action_data = {action: 'COMP_CMP_SAVED',actiondate :Date.now(), assigned_to: this.state.testing_owner, cur_lane : 'COMP_CMP_SAVED' , performed_by: Store.getStoreData('user_info').ID ,completed_date: Date.now()};

            task_json.action_data = action_data;
            task_data.task_json = task_json;

            // console.log("saveTestingActivities A task_json::",task_json)



            // console.log("saveTestingActivities A followup_tasks_to_be_created::",followup_tasks_to_be_created)

            let extra_json = {}
            if(this.state.rag === 'Red'){
                extra_json = {'risk_area_name' : this.state.risk_area.risk_area_name , 'risk_owner_id' : this.state.risk_area.risk_owner_id , 'send_rag_alert_email' : true , 'task_id' : this.state.tact_task_id };
            }

            let last_action_time = (Store.getStoreData('cmp-last_action_time') === null) ? 0 : Store.getStoreData('cmp-last_action_time');
            let that = this;
            let postData = {"command": "updateTask", "flag":"approved", "task_id" : ('id' in task_data) ? task_data.id : 0, "task_json" :task_data.task_json , "extra_json" : extra_json , ref_id : this.state.ref_id , model_bin_file_id : this.state.model_bin_file_id , last_action_time : last_action_time};
            // , followup_tasks_to_be_created : followup_tasks_to_be_created
            if(bin_files.length > 0){
                postData['attachments'] = bin_files;
            }

            let api = new APICall();
            // console.log("saveTestingActivities postData",postData);
            api.command(postData, this.processSaveTestingActivities);
            let alert_param = {title: 'Alert', message: 'The changes have been saved.', ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processSaveTestingActivities, stack: {} };
            this.setState({alert_param: alert_param});
    }
    render() {


        console.log("ACT:: State::",this.state)

        let role = Store.getStoreData('role');
        let module_configs = Store.getStoreData('module_config')
        let contacts = Store.getStoreData('contacts')
        let addtional_rags_assoc = this.getCustomRagAssoc(module_configs);
        console.log("ACT:: addtional_rags_assoc",addtional_rags_assoc)
        let user_id = Store.getStoreData('user_info').ID
        let view_manage_permission = true;
        if(role === 'view_only_manager' && parseInt(user_id) !== parseInt(this.state.testing_owner)){
            view_manage_permission = false
        }
        if (!this.state.ready) {
          return (<div><CSLLoader /></div>
          );
        }
        if(this.state.alert_param !== null)return(<AlertBox alertParam={this.state.alert_param} />)
        return (
            <div>
            
            <AlertBox alertParam={this.state.alert_param} />
            {
                (() => {
                    if(this.state.is_loader){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
                <InactiveOverlay style={{ height: "105%" }} />

                <MRModalContainer style={{ zIndex: 1007 }}>
                    <MRModalHeader>
                        <MRModalHeaderText>{this.state.testing_activity_obj.testing_activity}</MRModalHeaderText>
                        <div style={{ clear: "both" }}></div>
                        <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>
                    {
                        (() => {
                            if(this.state.cur_lane.toString() === "COMP_CMP_APPROVAL_REQUEST" && this.state.approver_id.toString() !== "0" && this.state.approver_id.toString() === user_id.toString()){
                            
                                let testing_owner_name = contacts[this.state.testing_owner];
                                let approval_manager_name = contacts[this.state.approver_id];
                                return(
                                    <MRModalBanner style={{height: "40px"}}><br/>
                                      &nbsp;<b><i>{testing_owner_name}</i> has sent this testing activity to <i>{approval_manager_name}</i> for approval</b> 
                                    </MRModalBanner>                                        
                                );
                            }
                        })()
                    }   
                    {/*<MRModalBanner style={{ height: "40px" }}><br />
                       
                    </MRModalBanner>*/}
                    <MRModalBody>
                        <MRModalSummary>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Testing Activity</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.testing_activity_obj.testing_activity}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle >Description</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                             <div dangerouslySetInnerHTML={{__html: this.state.testing_description}} />
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Risk Area</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.risk_area.risk_area_name}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Frequency</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{this.state.testing_activity_obj.testing_freq}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Owner </MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <MRModalLabel>{Store.getStoreData('contacts')[this.state.testing_activity_obj.testing_owner]}</MRModalLabel>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Testing </MRModalLabelTitle>
                                <div style={{ clear: "both" }}></div>
                                <MRModalLabelTitle>Objectives</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{__html: this.state.testing_objectives}}/>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>High Level </MRModalLabelTitle>
                                <div style={{ clear: "both" }}></div>
                                <MRModalLabelTitle>Testing Method</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{__html: this.state.testing_method}}/>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "22%" }}>
                                <MRModalLabelTitle>Additional</MRModalLabelTitle>
                                <div style={{ clear: "both" }}></div>
                                <MRModalLabelTitle>Guidance</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "78%" }}>
                                <div dangerouslySetInnerHTML={{__html: this.state.additional_guidance}}/>
                            </PartialDiv>

                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            <PartialDiv style={{ width: "15%" }}>
                                <MRModalLabelTitle>Useful Resources</MRModalLabelTitle>
                            </PartialDiv>
                            <PartialDiv style={{ width: "85%", float: "right" }}>
                                <div style={{ padding: "10px" }}>
                                    <Style.ModalNameLabelDiv>&nbsp;</Style.ModalNameLabelDiv>
                                    <Style.ModalNameInputDiv>
                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.useful_resource} onRemoveFile={this.filesRemoved} showFileButton={false}  /> 

                                    </Style.ModalNameInputDiv>
                                </div>
                                <div style={{ clear: "both", marginTop: "10px" }}></div>

                            </PartialDiv>
                            <div style={{ clear: "both", marginTop: "25px" }}></div>
                            {
                                (() => {
	                                if(view_manage_permission){
	                                    if(!module_configs.general.allow_rolling_comments_for_testing_activities){
	                                        return(
	                                            <div>
	                                                <PartialDiv style={{width:"22%"}}>
	                                                {
	                                                    (() => {
	                                                        if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
	                                                            return <MRModalLabelTitle >Testing Activities Undertaken</MRModalLabelTitle>;
	                                                        }
	                                                    })()
	                                                }                               
	                                                </PartialDiv>
	                                                <div style={{marginLeft : "250px" , marginTop : "-100px", width:"78%"}}>
	                                                {
	                                                    (() => {
	                                                        if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
	                                                            return  <div dangerouslySetInnerHTML={{__html: this.state.testing_activity_undertaken}} />;
	                                                        }
	                                                    })()
	                                                }   
	                                                </div>
	                                            </div>
	                                        );
	                                    }else{
	                                        if(this.state.testing_activity_undertaken_values_arr.length > 0){
	                                            return(
	                                                    <div >
	                                                        <div style={{clear: "both",marginTop: "10px",height: "10px"}}></div>
	                                                        <div style={{ width:"100%" ,overflowY: "scroll",height: "170px"}}>
	                                                        {
	                                                            (() => {
	                                                                let tau_vals_div = [];
	                                                                let contact_list = Store.getStoreData('contacts');
	                                                                for(let r of this.state.testing_activity_undertaken_values_arr){
	                                                                    let tau = this.changeStringToAtob(r.tau_text);
	                                                                    let text_created_by = contact_list[r.created_by.toString()];
	                                                                    tau_vals_div.push(
	                                                                        <div style={{backgroundColor: "rgb(232, 240, 247)" }}>
	                                                                            <div style={{height: "10px"}}>&nbsp;</div>
	                                                                            <div style={{marginLeft: "20px"}}><b>Testing Activity Undertaken</b></div>
	                                                                            <div style={{marginLeft: "20px"}} dangerouslySetInnerHTML={{__html: tau}} />
	                                                                            <div style={{marginLeft: "20px"}}><b><i>Testing Activity Updated by {text_created_by} - {r.created_on}</i></b></div>
	                                                                            <div style={{height: "10px"}}>&nbsp;</div>
	                                                                            <div style={{clear: "both",marginTop: "20px"}}></div>
	                                                                        </div>                                                              
	                                                                    );
	                                                                }
	                                                                return  <div > {tau_vals_div} </div>;
	                                                            })()
	                                                        }   
	                                                        </div>
	                                                        <div style={{clear: "both",marginTop: "10px"}}></div>
	                                                    </div>
	                                                );      
	                                        }
	                                    }
	                                }
                                })()
                            }
                            <div >

                                <div style={{ clear: "both", marginTop: "10px", height: "10px" }}></div>

                                <div style={{ clear: "both", marginTop: "10px" }}></div>
                            </div>
                            <div style={{ clear: "both", marginTop: "85px" }}></div>
                        </MRModalSummary>
                        {
                            (() => {
                                if(view_manage_permission){
                                    return(
                                    	<div>
					                        <div style={{ clear: "both", marginTop: "25px" }}></div>

					                        <div style={{ clear: "both", marginTop: "25px" }}></div>

					                        <PartialDiv style={{width:"22%", paddingLeft:"32px"}}>
					                            {
					                                (() => {
					                                    if(this.state.ready){
					                                        if('cur_lane' in this.state.task_data){
					                                            if(this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED"){
					                                                return <MRModalLabelTitle>Testing Activities Undertaken</MRModalLabelTitle>;
					                                            }
					                                        }else{
					                                            return <MRModalLabelTitle>Testing Activities Undertaken</MRModalLabelTitle>;
					                                        }                                       
					                                    }

					                                })()
					                            }   

					                        </PartialDiv>
					                        <PartialDiv style={{ width: "78%",display:"block" }}>
					                         {
					                                (() => {
					                                    if(this.state.ready){
					                                        if('cur_lane' in this.state.task_data){
					                                            if(this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED"){

					                                                return (
					                                                <div style={{ width: "calc(98% - 10px)", marginLeft: "calc(28% + 10px)", marginTop: "-9px" }}>
					                                                    <HtmlEditor content={this.state.testing_activity_undertaken} field_name={'testing_activity_undertaken'}/>
					                                                </div>

					                                                )
					                                            }
					                                        }else{

					                                                return (
					                                                <div style={{ width: "calc(98% - 10px)", marginLeft: "calc(28% + 10px)", marginTop: "-9px" }}>
					                                                    <HtmlEditor content={this.state.testing_activity_undertaken} field_name={'testing_activity_undertaken'}/>
					                                                </div>

					                                                )                                      
					                                        }                                       
					                                    }

					                                })()
					                            }
					                            

					                        </PartialDiv>


					                        <div style={{ clear: "both", marginTop: "25px" }}></div>
					                        <PartialDiv style={{ width: "15%" }}>
					                            <MRModalLabelTitle style={{ marginLeft: "32px" }}>Attachments</MRModalLabelTitle>
					                        </PartialDiv>
					                        <PartialDiv style={{ width: "78%" }}>
					                            <div style={{ width: "calc(98% - 10px)", marginLeft: "90px" }}>

					                                <Style.ModalNameInputDiv>
					                                    <Dropzone onFilesAdded={this.filesLoadedType2} initFiles={this.state.curBinFiles} onRemoveFile={this.filesRemovedType2} />

					                                </Style.ModalNameInputDiv>
					                            </div>
					                            <div style={{ clear: "both", marginTop: "10px" }}></div>
					                        </PartialDiv>
					                    </div>
									)
                                }
                            })()
                        }
                        {
                            (() => {
                            	if(view_manage_permission){
	                                if(module_configs.custom_rags.enabled){
		                                return(
		                                    <div>
		                                        <div style={{ clear: "both", marginTop: "100px" }}></div>
		                                        <PartialDiv style={{ width: "15%", paddingLeft: "32px" }}>
		                                            <MRModalLabelTitle>Risk Rating</MRModalLabelTitle>
		                                        </PartialDiv>
		                                        <PartialDiv style={{ width: "25%" }}>
		                                        {
		                                            (() => {
		                                                if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
		                                                    return <MRModalLabel style={{whiteSpace: "pre-line"}}>{addtional_rags_assoc[this.state.rag].nickname}</MRModalLabel>;
		                                                }else{
		                                                    return (
		                                                    <MrModalSelect onChange={this.handleChangeRag} name="rag" value={this.state.rag}
		                                                        style={{ width: "80%", marginLeft: "30px" }}>
		                                                        {
		                                                            (() => {
		                                                                let customragsdropdown = [];
		                                                                let custom_rags = module_configs.custom_rags.additional_rags;
		                                                                //console.log("additional_rags==>", custom_rags)
		                                                                customragsdropdown.push(<option key={'0'} value={'0'}>Select</option>);
		                                                                custom_rags.forEach((item) => {
		                                                                    if(item.isActive === 1){
		                                                                        customragsdropdown.push(<option key={item.id} value={item.id}>{item.nickname}</option>);
		                                                                    }
		                                                                    if(item.isActive === 0 && this.state.rag === item.id) {
		                                                                        customragsdropdown.push(<option key={item.id} value={item.id}>{item.nickname}</option>);
		                                                                    }
		                                                                });
		                                                                return customragsdropdown;
		                                                            })()
		                                                        }
		                                                    </MrModalSelect>
		                                                    )
		                                                }
		                                            })()
		                                        }
		                                        </PartialDiv>

		                                        <PartialDiv style={{ width: "15%", paddingLeft: "32px" }}>
		                                            <MRModalLabelTitle>Requires Remediation</MRModalLabelTitle>
		                                        </PartialDiv>
		                                        <PartialDiv style={{ width: "25%" }}>
		                                        {
		                                            (() => {
		                                                if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
		                                                    return <MRModalLabel style={{whiteSpace: "pre-line"}}>{this.state.require_remediation}</MRModalLabel>;
		                                                }else{
		                                                    return (
		                                                        <CompleteModalSelect onChange={(e) => this.changeDropdown('require_remediation', e)} name="require_remediation" value={this.state.require_remediation}>
		                                                        {
		                                                            (() => {
		                                                                let require_remedy_dropdown = [];
		                                                                require_remedy_dropdown.push(<option key={'No'} value={'No'}>No</option>)
		                                                                require_remedy_dropdown.push(<option key={'Yes'} value={'Yes'}>Yes</option>)
		                                                                return require_remedy_dropdown;        
		                                                            })()
		                                                        }
		                                                        </CompleteModalSelect>
		                                                    );
		                                                }
		                                            })()
		                                        }
		                                            {/*<MrModalSelect name="subcat_assignto"
		                                                style={{ width: "80%", marginLeft: "30px" }} onChange={(e) => this.changeDropdown('require_remediation', e)}>

		                                              
		                                                <option key="Active" value="Yes" selected="select">Yes</option>
		                                                <option key="Active" value="No" selected="select">No</option>
		                                            </MrModalSelect>*/}

		                                        </PartialDiv>


		                                    </div>
		                                );
	                                }
                                }
                            })()
                        } 
                        {
                            (() => {
                            	if(view_manage_permission){
	                                if(!module_configs.custom_rags.enabled && 'cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
		                                return(
		                                    <div>                               
		                                    <div style={{clear: "both",marginTop: "100px"}}></div>
		                                    <PartialDiv style={{width:"15%", paddingLeft:"32px"}}>
		                                        <MRModalLabelTitle>Risk Rating</MRModalLabelTitle>
		                                    </PartialDiv>
		                                    <PartialDiv style={{width:"25%"}}>
		                                        <MRModalLabel style={{whiteSpace: "pre-line"}}>{addtional_rags_assoc[this.state.rag].nickname}</MRModalLabel>                       
		             
		                                    </PartialDiv>
		                                    <PartialDiv style={{width:"25%", paddingLeft:"32px"}}>
		                                        <MRModalLabelTitle>Requires Remediation</MRModalLabelTitle>
		                                    </PartialDiv>
		                                    <PartialDiv style={{width:"calc(25% - 30px)"}}>
		                                        <MRModalLabel style={{whiteSpace: "pre-line"}}>{this.state.require_remediation}</MRModalLabel>                      

		                                    </PartialDiv>
		                                    </div>                              
		                                );
	                                }
                                }
                            })()
                        }                                             
                        <div style={{ clear: "both", marginTop: "85px" }}></div>
                        <MRModalDivider />
                        <div>

                        </div>
                        <div>
                            {this.state.require_remediation === 'Yes' ? (
                                <>
                                {
                                (() => {
                                    if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() === "COMP_CMP_COMPLETED"){
                                        return(
                                            <OverviewInfoHeader>
                                                <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Actions</div>
                                                
                                                <div style={{ clear: "both" }}></div>
                                            </OverviewInfoHeader>
                                    )

                                    }else{
                                        return (
                                            <OverviewInfoHeader>
                                                <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Actions</div>
                                                {
                                                    (() => {
                                                        if(view_manage_permission){
                                                            return <div style={{ float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer" }} onClick={this.openTestingActivities}><FaPlusCircle /></div>
                                                		}
                                                    })()
                                                }
                                                <div style={{ clear: "both" }}></div>
                                            </OverviewInfoHeader>
                                        );
                                    }
                                })()
                            }
                                    
                                   
                             {
                                (() => {
                                   
                                        //console.log("actions length in remedian==>"+this.state.actions.length);
                                        if(this.state.actions.length === 0)
                                        {
                                            return(<div>
                                                <OverviewInfoBody>
                                                <div style={{textAlign: "center",fontStyle: "italic", color: "#000000"}}>There are currently no Actions Associated with this Testing Activity.</div>
                                            </OverviewInfoBody>
                                            </div>)
                                        }else {
                                            return(<div>
                                                 <OverviewInfoBody>                                    
                                                    <CSLTable add={false} processData={this.processMyActionData} headerText={'Actions List'} tableRows="5" refreshCallback={this.refreshCallback} />                                                
                                                </OverviewInfoBody>
                                            </div>)
                                        }                                    
                                    
                                })()
                            }   

                                </>
                            )
                            : ''}

                        </div>
                        {/*<div>
                            <OverviewInfoBody>
                                <CSLTable add={false} processData={this.processMyActionData} headerText={'Actions List'} tableRows="5" refreshCallback={this.refreshCallback} />
                            </OverviewInfoBody>
                        </div>*/}


                    </MRModalBody>
                    <MRModalFooter>
                        {/*<div>
                            <CatBtn style={{ backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }} onClick={this.initiateSendForApproval}>Send for Approval</CatBtn>
                            <CatBtn style={{ backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px" }} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
                        </div>*/}
                         {
                            (() => {
                                if(this.state.tact_task_id > 0){
                                    if('cur_lane' in this.state.task_data && this.state.task_data.cur_lane.toString() !== "COMP_CMP_COMPLETED"){

                                    return (
                                        <div>
                                        {
                                            (() => {
                                                 if(role === 'team' && module_configs.general.enable_approval){
                                                    if(this.state.cur_lane.toString() !== "COMP_CMP_APPROVAL_REQUEST"){
                                                         if(this.state.cur_lane.toString() === "COMP_CMP_SAVED"){
                                                             return (
                                                             <div>
                                                             <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>
                                                             </div>
                                                             );                                                          
                                                         }else{
                                                             return (
                                                             <div>
                                                             <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.initiateSendForApproval}>Send for Approval</CatBtn>
                                                             <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
                                                             </div>
                                                             ); 
                                                         }                                                       
                                                     }
                                                 }else{
                                                    if(this.state.cur_lane.toString() !== "COMP_CMP_APPROVAL_REQUEST"){
                                                    	if(view_manage_permission){
                                                         	return (
		                                                         <div>
		                                                         <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>
		                                                         <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
		                                                         </div>
	                                                         ); 
	                                                     }
                                                    }else{
                                                         return (
                                                         <div>
                                                        {
                                                            (() => {
                                                                if(module_configs.general.allow_completetion_of_testing_activities_where_remedial_actions_outstanding){
                                                                    if(view_manage_permission){
                                                                    	return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>
                                                               		}
                                                                }else{
                                                                    if(parseInt(this.state.approver_id) === parseInt(Store.getStoreData('user_info').ID)){
                                                                        return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.doApprove}>Approve</CatBtn>;
                                                                    }
                                                                    
                                                                }
                                                            })()
                                                        }                                               
                                                
                                                         
                                                         <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
                                                         </div>
                                                         );     

                                                    }
                                                 }
                                             })()
                                        }
                                            
                                        </div>
                                        )
                                    }
                                }else{return (
                                    <div>
                                        {
                                            (() => {
                                                 if(role === 'team' && module_configs.general.enable_approval){
                                                     return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.initiateSendForApproval}>Send for Approval</CatBtn>;
                                                 }else{
                                                    if(view_manage_permission){
                                                     return <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.completeTestingActivitiesAlert}>Complete</CatBtn>;                                                   
                                                 	}                                                   
                                                 }                                                   
                                            })()
                                        }       
                                        {
                                            (() => {     
                                                 if(view_manage_permission){                            
                                                    return  <CatBtn style={{backgroundColor: "#26ADA7", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.saveTestingActivities}>Save & Close</CatBtn>
                                                 }
                                            })()
                                        }                               
                                   
                                    </div>
                                    )   

                                }

                            })()
                        }

                        <CatBtn style={{ backgroundColor: "#ffffff", border: "1px solid #ffffff", color: "black", float: "right" }} onClick={this.props.closeModal}>Cancel</CatBtn>
                        <div style={{ clear: "both" }}></div>
                        {
                            (() => {
                                if(Store.getStoreData('role') === 'team' && this.state.show_initiate_approval){
                                    return(
                                    <div>
                                        <MRModalDivider />
                                        <div style={{clear: "both", marginTop: "20px"}}></div>
                                        <div style={{float : "right" , width : "calc(25% - 30px)" , marginRight : "100px"}}>
                                            <div style={{fontWeight: "600"}}> Select a Manager : </div>
                                            <div style={{marginLeft : "50%" , marginTop: "-38px"}}>
                                                <CompleteModalSelect onChange={this.handleChange} name="approval_contact" value={this.state.approval_contact} style={{ width : "200px" }}>
                                                {
                                                    (() => {
                                                        let contactsdropdown = [];
                                                        contactsdropdown.push(<option key={0} value={0}>Select User</option>)
                                                        let gc_companies = Store.getStoreData('gc_companies')
                                                        let user_info = Store.getStoreData('user_info')
                                                        let role = Store.getStoreData('role')
                                                        let contacts = Store.getStoreData('contacts')
                                                        let user_roles = Store.getStoreData('user_roles')

                                                        let company_users_list = {}
                                                        for(let gc of gc_companies) {
                                                            if(gc.is_gc) {
                                                                company_users_list[gc.id.toString()] = gc.users
                                                            } else {
                                                                company_users_list['0'] = gc.users
                                                            }
                                                        }
                                                        let company_users = company_users_list[this.state.gc_id.toString()] 
                                                        {/*console.log("company_users::",company_users)*/}
                                                        //let risk_owners = []
                                                        let found  = false
                                                        for(let u_id in contacts) {
                                                            if(user_roles[u_id] === 'admin_manager'){
                                                                contactsdropdown.push(<option key={u_id} value={u_id}>{contacts[u_id]}</option>)
                                                                //if(followup_task.testing_owner.toString() === u_id)found = true
                                                            } else if(user_roles[u_id] === 'plan_admin' || user_roles[u_id] === 'risk_owner' || user_roles[u_id] === 'subcat_owner') {
                                                                if(u_id in company_users){
                                                                    contactsdropdown.push(<option key={u_id} value={u_id}>{contacts[u_id]}</option>)
                                                                    //if(followup_task.testing_owner.toString() === u_id)found = true
                                                                }
                                                            }
                                                        }
                                                        return contactsdropdown;        
                                                    })()
                                                }                                               
                                                </CompleteModalSelect>
                                                {
                                                    (() => {
                                                        if(this.state.IsApprovalContactProvided){
                                                            return(
                                                                <div style={{width: "200px" ,marginLeft: "50%" }}>
                                                                    <div style={{clear: "both"}}></div>
                                                                    <label style={{color:"#FF0000"}}>Please select a manager.</label>
                                                                </div>
                                                                )
                                                        }  
                                                    })()
                                                }                                               
                                            </div>
                                        </div>
                                        <div style={{clear: "both", marginTop: "60px"}}></div>
                                        <MRModalDivider />
                                        <div style={{clear: "both", marginTop: "20px"}}></div>
                                        <div style={{float : "right" , width : "calc(25% - 30px)" , marginRight : "2px"}}>
                                            <CatBtn style={{backgroundColor: "#213E61", border: "1px solid #26ADA7", float: "right", marginLeft: "10px"}} onClick={this.requestSendForApproval}>Submit</CatBtn>
                                        </div>
                                        <div style={{clear: "both", marginTop: "20px"}}></div>
                                    </div>

                                    
                                    );
                                }
                            })()
                        }           
                    </MRModalFooter>

                    {this.state.showAddActionModal ? <AddActions closeModal={this.followupcloseModal} followup_task={this.state.action_extra_json} plan_ref_id={this.state.unique_plan_id} task_id={'task_id' in this.state.action_extra_json  ? this.state.action_extra_json.task_id : 0} parent_task_id={this.state.tact_task_id} refreshAddActionValue={this.refreshAddActionValue} refreshAddActionValueTask={this.refreshAddActionValueTask} gc_id={this.state.gc_id}/> : ''}
                </MRModalContainer>
                {/*
                                                    //followup_task={this.state.edit_object}
                                                    //closeModal={this.closeModal} 
                                                    //processSaveAction={this.processSaveAction}*/}
                


            </div>
        );
    }
}

export default CompleteModal;