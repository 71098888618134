import React from 'react';
import styled from 'styled-components';

//import CSLPDFLoader from '../Common/CSLPDFLoader';
import ReactHTMLTableToExcel from './ReactHTMLTableToExcel'


import { FaTimes,FaAngleDown,FaRegCheckSquare,FaRegSquare,FaAngleUp } from 'react-icons/fa';


const ExcelContainer = styled.div`
	position: absolute;
	width: calc(100vw - 325px);

	z-index: 1011;
    background-color: white;
`;
const FootButton = styled.div`
	padding: 10px 15px;
    float: right;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;

`;

class ExcelGenerator extends React.Component
{
	state = {pdfURL: null, ready: true, gstruct: null, table_headers: null, view_count: 0};

	month_hash = {'1' : 'Jan' , '2' : 'Feb' , '3' : 'Mar' , '4' : 'Apr' , '5' : 'May' , '6' : 'Jun' , '7' : 'Jul' , '8' : 'Aug' , '9' : 'Sep' , '10' : 'Oct' , '11' : 'Nov','12' : 'Dec' };


	

	render()
	{
		console.log('CSLExcelLoader this.props', this.props)
		//if(!this.state.ready)return(<CSLPDFLoader />)
        let columns = [];
        let rows = [];
	{/*	if(!('xls_data' in this.props))return(<CSLPDFLoader />) */}

		return (
			<ExcelContainer style={{position: 'fixed', top: 100, zIndex: 15000, right: 10, paddingBottom: 30 , maxHeight: "750px"}}>
				<div style={{backgroundColor: '#213E61', height: 30}}>
					<span style={{position: 'absolute' , fontSize: 16, fontWeight: 700, marginLeft: 7 , marginTop: 5 , color: "#FFFFFF"}}>Excel Preview</span><FaTimes style={{position: 'absolute',  marginLeft: "calc(100vw - 345px)", marginTop: 5, cursor: 'pointer' , color: "#FFFFFF"}} onClick={() => this.props.toggleExcel()} />
				</div>
                <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>

		        <div style={{overflowY: "scroll" , height : "675px"}}>
					<div >
						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="download-table-xls-button"
							table="table-to-xls"
							filename={this.props.xls_data.file_name}
							sheet="tablexls"
							buttonText="Download as XLS"/>
					</div>
					<div>
					<table id="table-to-xls" style={{width : "100%"}}>
					<tr >
					{
						(()=>{
							
							this.props.xls_data.cols.forEach((item) => {
								columns.push(<th align="left">{item}</th>);
							});
						})()
					}
						{columns}
					</tr>
					{
						(()=>{
							
							this.props.xls_data.data.forEach((item) => {
								let vals = [];
								item.forEach((val) => {
									vals.push(<td valign="top"><div dangerouslySetInnerHTML={{__html: val}} /></td>);
								});
								rows.push(<tr>{vals}</tr>);
							});
						})()
					}
						{rows}
					</table>
					</div>
		        </div>
			</ExcelContainer>
		);
	}
}

export default ExcelGenerator;

