import Store from "./Store.js";
import Utils from "./Utils.js";
import moment from "moment";
import http from 'mcc-front-aux/dist/httpCommon'
/* global fetch */
// import { trackPromise } from "react-promise-tracker";

const cache_commands = { index: 1};
const cache_renew_time = 2 * 60;

class APICall {
  constructor() {
    this.url = Store.getStoreData("api_url");
    this.is_admin = Store.getStoreData("is_admin");
    this.module_name = Store.getStoreData("module_name");
  }

  strRawToWeb(jsonDataString) {
    jsonDataString = jsonDataString.replace(/&/g, "--and--");
    jsonDataString = jsonDataString.replace(/\+/g, "--plus--");
    jsonDataString = jsonDataString.replace(/'+/g, "--quote--");
    jsonDataString = jsonDataString.replace(/\n/g, "--newline--");
    jsonDataString = jsonDataString.replace(/\r/g, "--creturn--");

    return jsonDataString
  }

  strWebToRaw(result) {
    result = result.replace(/(--plus--)/g, "+");
    result = result.replace(/(--quote--)/g, "'");
    result = result.replace(/(--and--)/g, "&");
    result = result.replace(/(--newline--)/g, "\n");
    result = result.replace(/(--creturn--)/g, "\r");
    result = result.replace(/'+/g, "'");

    return result
  }


  command(postData, callback, incremental_callback) {


    let now_time = moment().unix();
    let module_name = Store.getStoreData("module_name");
    let command = postData.command;
    let env = this.is_admin ? "csl" : "select";
    let command_id = `${module_name}-${command}-${env}`;
    // console.log("command_id", command_id);

    let jsonDataString = this.strRawToWeb(JSON.stringify(postData))


    let incremental = typeof incremental_callback !== "undefined" ? true : false;
    if (command in cache_commands) {
      let cached_result = Store.getStoreData(command_id);
      let cached_time = Store.getStoreData(command_id + "-last_cached");
      // console.log("cached_time, now_time, now_time - cached_time, cache_renew_time",cached_time,now_time,now_time - cached_time,cache_renew_time);
      if (cached_result !== null) {
        // console.log("cached_result", cached_result);
        postData.incremental = "incremental" in postData ? postData.incremental : incremental;
        try {
          let str_cached_result = JSON.stringify(cached_result);
          if (cached_result.error_code === 0) callback(cached_result);
        } catch (err) {
          console.log(err);
        }
      }
    }


      // Add in the json command if it exists
      let postURL = this.url
      if('command' in postData) {
          postURL += `/${postData.command}`
      }



      // let httpVerb = {method: "POST",mode: "cors",headers: { "Content-Type": "application/x-www-form-urlencoded" },body: "jsondata=" + jsonDataString};
    http.post(postURL, {jsondata: jsonDataString}).then(result => {
        result = result.data.body

        // console.log(jsonDataString + "///" + result);
        // result = this.strWebToRaw(JSON.stringify(result));

        // console.log('jsonparse before ---->' , result)
        result = JSON.parse(result);
        // console.log('jsonparse---->' , result)
        if (command in cache_commands) {
            Store.updateStore(command_id, result);
            Store.updateStore(command_id + "-last_cached", now_time);
        }
        if (incremental) {
            // console.log('if(incremental)--->' , result )
            incremental_callback(result);
        } else {
            // console.log('else(incremental)--->' , result )
            callback(result);
        }
    }).catch((error) => {
        console.log('Error data',error);
        return null;
    })

      // console.log('httpVerb', httpVerb)
     // fetch(url, httpVerb)
     //  .then((res) => res.json())
     //  .then((result) => {
     //    // console.log(jsonDataString + "///" + result);
     //    result = this.strWebToRaw(JSON.stringify(result));
     //
     //    //console.log('jsonparse before ---->' , result)
     //    result = JSON.parse(result);
     //    //console.log('jsonparse---->' , result)
     //    if (command in cache_commands) {
     //      Store.updateStore(command_id, result);
     //      Store.updateStore(command_id + "-last_cached", now_time);
     //    }
     //    if (incremental) {
     //      // console.log('if(incremental)--->' , result )
     //      incremental_callback(result);
     //    } else {
     //      // console.log('else(incremental)--->' , result )
     //      callback(result);
     //    }
     //  })
     //  .catch((error) => {
     //    console.log('Error data',error);
     //    return null;
     //  })

  }


  async commandDocsCall(postData, callback) {
    let jsonDataString = JSON.stringify(postData).replace(/&/g, "--and--");
    jsonDataString = jsonDataString.replace(/\+/g, "--plus--");
    jsonDataString = jsonDataString.replace(/'+/g, "--quote--");
    jsonDataString = jsonDataString.replace(/\n/g, "");
    jsonDataString = jsonDataString.replace(/\r/g, "");
    let httpVerb = {method: "POST",mode: "cors",headers: { "Content-Type": "application/x-www-form-urlencoded" },body: "jsondata=" + jsonDataString};

    fetch(this.url, httpVerb)
      .then((res) => res.json())
      .then((result) => {
        result = JSON.stringify(result);
        result = result.replace(/(--plus--)/g, "+");
        result = result.replace(/(--quote--)/g, "'");
        result = result.replace(/(--and--)/g, "&");
        result = result.replace(/'+/g, "'");
        result = JSON.parse(result);
        // console.log("stats", result);
        callback(result);
      })
      .catch((error) => {
        console.log(error);
        return null;
      })
  }

  async commandWithoutCallback(postData) {
    let jsonDataString = JSON.stringify(postData).replace(/&/g, "--and--");
    jsonDataString = jsonDataString.replace(/\+/g, "--plus--");
    jsonDataString = jsonDataString.replace(/'+/g, "--quote--");
    jsonDataString = jsonDataString.replace(/\n/g, "");
    jsonDataString = jsonDataString.replace(/\r/g, "");
    let httpVerb = {method: "POST",mode: "cors",headers: { "Content-Type": "application/x-www-form-urlencoded" },body: "jsondata=" + jsonDataString};

    fetch(this.url, httpVerb)
      .then((res) => res.json())
      .then((result) => {
        result = JSON.stringify(result);
        result = result.replace(/(--plus--)/g, "+");
        result = result.replace(/(--quote--)/g, "'");
        result = result.replace(/(--and--)/g, "&");
        result = result.replace(/'+/g, "'");
        result = JSON.parse(result);
        // console.log("stats", result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        return null;
      })
  }
}

export default APICall;
