import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {FaTimes ,FaPlusCircle , FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';
import CSLTable from '../../Common/CSLTable.js';
import CompleteModal from './CompleteModal.js';
import EventBus from '../../../Common/EventBus.js';
import AlertBox from '../../Common/AlertBox.js';
import { GoAlert } from "react-icons/go";
import ReactTooltip from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css'

const colorwiseTextStatus = { '#04ADA8' : 'Completed' , 'blue' : 'Scheduled' , '#F39C12' : 'Due' , 'red' : 'Overdue' };

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 80px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1800px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 11px;
    top: -5px;
    z-index: 995;
    opacity: 0.8;
    `;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;

const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 670px);
    width: 80%;
    position: absolute;
    top: 4%;
    left: 62px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const StatusButton = styled.div`
  box-sizing: border-box;
  background-color: #37ada7;
  border-radius: 4px;
  padding: 4px;
  height : 25px;
  width : 90%;
  text-align: center; 
  color : #FFFFFF;
`;
class CellModal extends React.Component{
    state = {
        task_data: {},
        header_text:'',
        dev_modal: null,
        edit_object: null,
        plan: null ,
        tact_risk_area:null,
        tact_task_id:null
    }
    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prev_props) {
        if(prev_props === this.props) return
        this.setComponentState()

    }

    setComponentState = () =>{
        console.log('CELLMODAL Props::',this.props)
        let header_text = 'List of Testing Activities for '+ (this.props.data.m+1)+'/'+this.props.data.y;

        this.setState({task_data:this.props.data.tasks,header_text}) 

    }
    get_color_code_basedon_priority = (color_codes_array) => {
    let ret_color_code ;
    if(color_codes_array.includes('red')){ // red
      ret_color_code = "red";
    }else{
      if(color_codes_array.includes('#F39C12')){ // amber
          ret_color_code = "#F39C12";
      }else{
        if(color_codes_array.includes('blue')){ // blue
            ret_color_code = "blue";
        }else{
          ret_color_code = "#04ADA8";
        }
      }
    }
    return ret_color_code;
  }
    processTableData = () => {
      let tasks = this.state.task_data;
      let all_companies = Store.getStoreData('gc_companies')
      let all_contacts = Store.getStoreData('contacts')
      let risk_areas = Store.getStoreData('risk_areas')
      let role = Store.getStoreData('role');
      let userId = Store.getStoreData('user_info')?.ID

      let ret = {data: [], columns: []};
      let i = 1;
      if(risk_areas.length > 0){
          ret.columns =[
                          {Header: 'Activity', id: 'activity' , accessor: 'activity' , Cell: c => <div >{c.original.activity}&nbsp;<div style={{fontWeight : "20"}}><b><i>{c.original.approval_string}</i></b></div></div> , minWidth: 270, headerStyle: {textAlign: 'left'}},    
                          {Header: 'Risk Areas', accessor: 'risk_area', minWidth: 170, headerStyle: {textAlign: 'left'}},      
                          {Header: 'Subcategory', accessor: 'subcat_name', minWidth: 140, headerStyle: {textAlign: 'left'}},  
                          {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 140, headerStyle: {textAlign: 'left'}},
                          {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left'}},
                          {Header: 'Status', id: 'colorwise_text' , accessor: 'colorwise_text' , 
                           Cell: row => (
                            <div>
                              {
                                (() => {

                                  if(row.original.show_exclaim){
                                    return(<>
                                      <ReactTooltip id={row.original.tool_tip_id} place="top" effect="solid" backgroundColor="rgb(232,240,247)" textColor="#000000">
                                          <GoAlert style={{fill: row.original.processed_color_code_remedial, marginTop: "1px", width: "63px", height: "20px"}}/><br/>
                                          Tasks Outstanding {row.original.incomplete_rem_count}<br/> 
                                          Tasks Completed&nbsp;&nbsp;&nbsp; {row.original.complete_rem_count}<br/>
                                      </ReactTooltip>
                                      <StatusButton data-for={row.original.tool_tip_id} data-tip style={{ cursor: "default", backgroundColor: `${row.original.colorwise_text.color}`, borderColor: `${row.original.colorwise_text.color}` }}><div style={{position:"relative",top:"-14%"}}><GoAlert style={{fill: "#FFFFFF", marginTop: "1px", width: "63px", height: "20px"}}/></div></StatusButton>
                                      </>)
                                  }
                                  return(<StatusButton style={{ cursor: "default", backgroundColor: `${row.original.colorwise_text.color}`, borderColor: `${row.original.colorwise_text.color}` }}>{row.original.colorwise_text.text}</StatusButton>)
                                  
                                })()
                              }
                              
                            </div>
                          ),
                          minWidth: 120, headerStyle: {textAlign: 'left'}},
                          {Header : 'Action', Cell: row => (
                            <div>
                              {
                                (() => {
                                  if(row.original?.cur_lane === 'COMP_CMP_COMPLETED'){
                                    return (<MRButton onClick={() => this.openTestingActivities('ta_action', row.original?.plan_ref_id, row.original?.due_date, row.original?.testing_act_id,row.original?.unique_risk_id,row.original?.task_id,row.original?.approver_id)} style={{cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>View</MRButton>);
                                  }
                                  
                                  if(row.original?.risk_status !== 'Active'){
                                    if(role === 'view_only_manager' && parseInt(row.original?.testing_owner) !== userId){
                                      return (<MRButton style={{ backgroundColor: "grey", cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>View</MRButton>);
                                    }
                                    return (<MRButton style={{ backgroundColor: "grey", cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>Action</MRButton>);
                                  }
                                  
                                  if(role === 'view_only_manager' && parseInt(row.original?.testing_owner) !== userId){
                                    return (<MRButton onClick={() => this.openTestingActivities('ta_action', row.original?.plan_ref_id, row.original?.due_date, row.original?.testing_act_id,row.original?.unique_risk_id,row.original?.task_id,row.original?.approver_id)} style={{cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>View</MRButton>);
                                  }
                                  return (<MRButton onClick={() => this.openTestingActivities('ta_action', row.original?.plan_ref_id, row.original?.due_date, row.original?.testing_act_id,row.original?.unique_risk_id,row.original?.task_id,row.original?.approver_id)} style={{cursor: 'pointer' , float: "left" , marginLeft: "15px"}}>Action</MRButton>);
                                })()
                              }
                              
                            </div>
                            ), width: 180, headerStyle: {textAlign: 'center'}},
                        ];

        let followup_tasks = Store.getStoreData('followup_tasks')
        for (const key in tasks) {
            let m = 0;
            console.log(key,tasks[key],"A");
            //let due_date
            let color =  this.compareDateGetColor(key.split('_')[1],tasks[key].cur_lane) ;

            //console.log("CELLMODAL color",color)
                  //const comp_key = Object.keys(all_companies).find(x => all_companies[x].id === company);
                  let activity_status = (tasks[key].cur_lane === 'COMP_CMP_COMPLETED') ? 'Complete' : 'Incomplete';

                  let risakarea_key = risk_areas.filter(function (risk) { return risk.unique_risk_id === tasks[key].risk_id });
            
            //console.log("CELLMODAL risakarea_key",risakarea_key)

                  if(risakarea_key.length !== 0){

                      let subcat_name = tasks[key].subcat_id.toString() === '0' ? '' : risakarea_key[0].subcategories.filter(function (sub) { return sub.unique_subcat_id === tasks[key].subcat_id })[0].risk_subcategory_name;

            //console.log("CELLMODAL subcat_name",subcat_name)

                      
                      // console.log('TSACT RA subcat_name',subcat_name)
                      

                      let risk_status = risakarea_key.length > 0 ? risakarea_key[0].status : '';

                      let cur_assigned_to = "testing_owner" in tasks[key] ? tasks[key].testing_owner : tasks[key].cur_assigned_to

                      //console.log('TSACT RA risk_status',risk_status)
                      let task_id = tasks[key].task_id === null ? 0 : tasks[key].task_id

                      let physical_task_detail = null
                      let plan_tasks = Store.getStoreData('plan_tasks')
                      if(task_id > 0){
                        physical_task_detail = Object.values(plan_tasks).filter((t, i) => {
                          return (t.task_id === task_id)
                         })
                      }
                      
                      // if(tasks[key].task_id === 53913)console.log("APPROVE TASK::",tasks[key])

                      let show_exclaim = false
                      
                      let tool_tip_id = '';
                      // console.log("KEY::",key)

                      let complete_rem_count = 0;
                      let incomplete_rem_count  = 0;
                      let processed_color_code_remedial = "#04ADA8"
                      let rem_colors = []
                      if(task_id > 0){
                        let followup_key = followup_tasks.filter(function (dt) { return dt.parent_task_id.toString() === task_id.toString() });
                        let comp_length = followup_key.filter(element => element.cur_lane === 'COMP_CMP_FOLLOWUP_COMPLETE');
                        let incomp_length = followup_key.filter(element => element.cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE');

                        //if(incomp_length.length > 0 && incomp_length[0].cur_lane !== 'COMP_CMP_FOLLOWUP_COMPLETE'){
                        if(incomp_length.length > 0 || comp_length.length > 0){
                          show_exclaim = true;
                          if( tasks[key].subcat_id !== "0"){
                            tool_tip_id = `${tasks[key].plan_ref_id}-${tasks[key].risk_id}-${tasks[key].subcat_id}-${tasks[key].testing_act_id}-${this.props.data.m+1}`;
                          }else{
                            tool_tip_id = `${tasks[key].plan_ref_id}-${tasks[key].risk_id}-${tasks[key].testing_act_id}-${this.props.data.m+1}`;
                          }
                        }


                        complete_rem_count = (comp_length.length >= 10) ? comp_length.length : `0${comp_length.length}`;
                        incomplete_rem_count = (incomp_length.length >= 10) ? incomp_length.length : `0${incomp_length.length}`;

                        if(comp_length.length > 0){
                            let rem_color_singl = "#04ADA8"
                            rem_colors.push(rem_color_singl);
                        }
                        for(let j = 0;j <incomp_length.length;j++){
                            let rem_color_singl = this.compareDateGetColorRemedial(incomp_length[j].due_date,incomp_length[j].cur_lane)
                            rem_colors.push(rem_color_singl);
                        }
                      }
 
                      processed_color_code_remedial = this.get_color_code_basedon_priority(rem_colors)
                      if(color === '#04ADA8')show_exclaim=false;

                      let testing_activity_name = physical_task_detail !== null && physical_task_detail[0].cur_lane === 'COMP_CMP_COMPLETED'? physical_task_detail[0].testing_activity : tasks[key].testing_activity
                      cur_assigned_to = physical_task_detail !== null && physical_task_detail[0].cur_lane === 'COMP_CMP_COMPLETED' ? physical_task_detail[0].cur_assigned_to : cur_assigned_to

                      // console.log("physical_task_detail::",physical_task_detail)
                      // console.log("physical_task_detail::tasks[key]",tasks[key])
                      // console.log("physical_task_detail::testing_activity_name",testing_activity_name)
                      
                      
                      let approval_string = (tasks[key].cur_lane === 'COMP_CMP_APPROVAL_REQUEST') ? '(Approval Required)' : '';
                      let elem = {'index': m,
                      'activity': testing_activity_name,
                      'risk_area' : risakarea_key.length > 0 ? risakarea_key[0].risk_area_name : '',
                      'subcat_name' : subcat_name,
                      'assigned_to' : `${all_contacts[cur_assigned_to]}`,
                      'due_date' : moment(key.split('_')[1]).format("DD/MM/YYYY"),
                      'colorwise_text' :  { text: colorwiseTextStatus[color], color: color },
                      'activity_status' : activity_status,
                      'risk_status': risk_status,
                      'testing_act_id' : tasks[key].testing_act_id,
                      'unique_risk_id' : tasks[key].risk_id,
                      'task_id': task_id,
                      'plan_ref_id' : tasks[key].plan_ref_id,
                      'cur_lane': tasks[key].cur_lane,
                      'approval_string' : approval_string,
                      'approver_id': 'approver_id' in tasks[key] ? tasks[key].approver_id : 0,
                      'show_exclaim':show_exclaim,
                      'tool_tip_id':tool_tip_id,
                      'complete_rem_count' : complete_rem_count,
                      'incomplete_rem_count' : incomplete_rem_count,
                      'processed_color_code_remedial': processed_color_code_remedial
                      }
                      ret.data.push(elem);
                  }
                  //
                  //`<div style="border-radius: 4px; padding: 4px; height: 25px; width: 100%; background: ${color}; text-align: center; color: rgb(255, 255, 255);">${colorwiseTextStatus[color]}</div>`
            m++;
        }         
    }

      return ret;  
    }
    get_color_code_basedon_priority = (color_codes_array) => {
        let ret_color_code ;
        if(color_codes_array.includes('red')){ // red
            ret_color_code = "red";
        }else{
            if(color_codes_array.includes('#F39C12')){ // amber
                ret_color_code = "#F39C12";
            }else{
                if(color_codes_array.includes('blue')){ // blue
                    ret_color_code = "blue";
                }else{
                    ret_color_code = "#04ADA8";
                }
            }
        }
        return ret_color_code;
    }
    compareDateGetColorRemedial = (due_date,cur_lane) => {
    // console.log("color::==>"+cur_lane);
      let color_code = '#04ADA8'
      let scheduled_date = moment(due_date, 'YYYY/MM/DD');
      let month = scheduled_date.format('M');
      let year  = scheduled_date.format('YYYY');

      let curr_month = moment().month()+1;
      let curr_year  = moment().year();
      console.log("monthmonth",month,curr_month,year,curr_year)
      // let current_date = moment().unix();
      // let due_date_unix =  moment(due_date).unix();
      let current_date = moment().format('YYYY-MM-DD');
      let due_date_unix =  moment(due_date).format('YYYY-MM-DD');

      if(cur_lane === "COMP_CMP_FOLLOWUP_COMPLETE"){
        color_code = "#04ADA8"; // green
      }else{
        if(due_date_unix >= current_date){
          if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED'){
            color_code = "blue"; // Scheduled
          }else{
            if(curr_month.toString() === month && curr_year.toString() === year){
              color_code = "#F39C12"; // due
            }else{
              color_code = "blue"; // Scheduled
            }
          }
        }else{
          color_code = "red"; // overdue
        }
      }

    //return color_code;
    //if(cur_lane === 'COMP_CMP_FOLLOWUP_SAVED' && color_code === "#F39C12")color_code='blue';
    return color_code;
  }

    compareDateGetColor= (first_schedule_date,cur_lane) => {
      console.log("TSTACT all_hintscur_lane==>",cur_lane,first_schedule_date);
      let color_code = '#04ADA8'
      let scheduled_date = moment(first_schedule_date, 'YYYY/MM/DD');
      let month = scheduled_date.format('M');
      let year  = scheduled_date.format('YYYY');

      let curr_month = moment().month()+1;
      let curr_year  = moment().year();

      let current_date = moment().format('YYYY-MM-DD');
      let due_date_unix =  moment(first_schedule_date).format('YYYY-MM-DD');

      console.log("CELLMODALDATE::",current_date,due_date_unix)

      if(cur_lane === "COMP_CMP_COMPLETED"){
        color_code = "#04ADA8"; // green
      }else{
        if(due_date_unix >= current_date){
          if(curr_month.toString() === month && curr_year.toString() === year){
            color_code = "#F39C12"; // due
          }else{
            color_code = "blue"; // Scheduled
          }
        }else{
          color_code = "red"; // overdue
        }
      }

    return color_code;
  }
    openTestingActivities =(dev_modal,plan_ref_id,due_date, test_act_id,unique_risk_id,task_id,approver_id)=>{
        console.log("APPROVE openTestingActivities::::",dev_modal,plan_ref_id,due_date, test_act_id,unique_risk_id,task_id,approver_id)
        let role = Store.getStoreData('role')
        let user_id = Store.getStoreData('user_info').ID
          
        let plan = Store.getStoreData('selected_plan')

        let all_testing_activities = plan.testing_activities;
          let testing_activities = all_testing_activities.filter((t, i) => {
          return (t.unique_testing_act_id === test_act_id)
         })
        console.log("openTestingActivities:::testing_activities",testing_activities)
        let testing_owner = testing_activities[0].testing_owner

        if(task_id > 0){
            testing_owner = Store.getStoreData('tasks_by_id')[task_id].cur_assigned_to
        }
        console.log("openTestingActivities:::testing_owner",testing_owner)

        console.log("openTestingActivities:::",testing_owner,user_id,role,approver_id)
        console.log("openTestingActivities:::",(testing_owner.toString() !== user_id.toString()),("created_by" in testing_activities[0] && testing_activities[0].created_by.toString() !==  user_id.toString()))
        //console.log("openTestingActivities:::",(role === 'subcat_owner' && (testing_activities[0].testing_owner !== user_id.toString && testing_activities[0].created_by.toString() !==  user_id.toString)))
        if((role === 'risk_owner' && (testing_owner.toString() !== user_id.toString() && ("created_by" in testing_activities[0] && testing_activities[0].created_by.toString() !==  user_id.toString()) && approver_id.toString() !== user_id.toString())) || 
          (role === 'subcat_owner' && (testing_owner.toString() !== user_id.toString() && ("created_by" in testing_activities[0] && testing_activities[0].created_by.toString() !==  user_id.toString()) && approver_id.toString() !== user_id.toString())) ) {
            
                let alert_param = {title: 'ERROR', message: 'You have no permission to access this testing activity.', ok_text: 'Ok', confirm: false, 
                        alertHandler: this.AlertHandler, stack: {}};
                this.setState({alert_param,disabled_button: false});
            
        }else{
          // console.log("openTestingActivities::::",plan)

          let risk_id = unique_risk_id
          let risk_areas = plan.risk_areas;
          //console.log('risk_area::plan', risk_areas)
          let risk = risk_areas.filter((t, i) => {
              return (t.unique_risk_id === risk_id)
          })

          let risk_area = risk

          let dt = due_date.split('/')
          testing_activities[0].due_date = moment(new Date(dt[2]+'-'+dt[1]+'-'+dt[0]))

          this.setState({dev_modal, plan,edit_object:testing_activities,tact_risk_area:risk_area[0],tact_task_id:task_id})
        }


    }
    closeModal = () => {
        this.setState({dev_modal: null, edit_object: null,tact_risk_area:null,tact_task_id:null})
    }
    AlertHandler = (result, stack) => {
        this.setState({alert_param: null})
        //window.location = '/select/ServiceBasedLanding';
    }
    reloadTestingactivity = (plan_ref_id) =>{
        this.setState({dev_modal: null, edit_object: null,tact_risk_area:null,tact_task_id:null})
        EventBus.raiseEvent('data_reload', plan_ref_id)
    }
	render() {
        console.log("CELLMODAL State::",this.state)
		return (<>
      <AlertBox alertParam={this.state.alert_param} />
      {
                (() => {
                    if(this.state.dev_modal !== null) {
                        switch(this.state.dev_modal) {
                            case 'ta_action' : return <CompleteModal 
                                                    testing_activity={this.state.edit_object[0]} 
                                                    subcategory={null}
                                                    risk={this.state.tact_risk_area}
                                                    plan={this.state.plan}
                                                    gc_id = {this.state.plan.gc_id}
                                                    unique_plan_id = {this.state.plan.unique_plan_id}
                                                    closeModal={this.closeModal} 
                                                    reloadTestingactivity={this.reloadTestingactivity}
                                                    tact_task_id={this.state.tact_task_id}/>; 
                                                    break;
                            default: return <div />
                        }
                    }
                })()
            }
      
      <InactiveOverlay style={{ height: "105%" }} />

			<MRModalContainer>
                <MRModalHeaderText>{this.state.header_text}</MRModalHeaderText>
                <MRModalHeaderCloseBtn onClick={this.props.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                
                <div style={{clear: "both", marginTop:"5px"}}></div>
         
                <CSLTable 
                    add={false} 
                    processData={this.processTableData}
                    tableRows={10}
                />
                
            </MRModalContainer>
		</>)
	}
}

export default CellModal;