		let data = {table_headers: [], data: []};
        data.module = {id: 'cmp', display: 'Compliance Monitoring Program'}
		data.table_headers = [
            {
                accessor: "activity", nickname: "Activity", type: "alpha", width: 1.2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "title", nickname: "Title", type: "alpha", width: 1.2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "assigned_to", nickname: "Assigned To", type: "alpha", width: 1.4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':false, 'right':true}
            },
            {
                accessor: "due_date", nickname: "Due Date", type: "date", width: 1.4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            }
        ];    

        data.sortby = {
            fields: [
                {accessor: "activity", nickname: "Activity", type: "alpha", width: 1.2},
                {accessor: "title", nickname: "Title", type: "alpha", width: 1.2},
                {accessor: "assigned_to", nickname: "Assigned To", type: "alpha", width: 1.5},
                {accessor: "due_date", nickname: "Due Date", type: "date", width: 1.4}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {accessor: "activity", nickname: "Activity", type: "alpha", width: 1.2},
                {accessor: "title", nickname: "Title", type: "alpha", width: 1.2},
                {accessor: "assigned_to", nickname: "Assigned To", type: "alpha", width: 1.5},
                {accessor: "due_date", nickname: "Due Date", type: "date", width: 1.4}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data