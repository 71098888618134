import React from 'react';
import styled from 'styled-components';
import {FaPlusCircle, FaMinusCircle, FaTimes} from 'react-icons/fa';
import CSLPreviewConfig from './CSLPreviewConfig';

const FootButton = styled.div`
	padding: 10px 15px;
    float: right;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
`;
const InfoText = styled.div`
	font-size: 11px;
	font-weight: 400;
`;
const ReviewBodyContainer = styled.div`
    background-color: #f7f6f6;
    height: 80vh;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 20px;
    position: fixed;
    z-index: 1001;
    top: 10vh;
    left: 20vw;
    width: 60vw;
    margin-left: 10%;
    margin-top: 20px;
`;
const ReviewBody = styled.div`
    height: calc(80vh - 150px);
    overflow: auto;
    width: 100%;
`;

const ReportModalHeader = styled.div`
	float: left;
	color: #717070;
	font-weight: 600;
`;

const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #717070;
    cursor: pointer;
`;

const ReportSubHeader = styled.div`
	background-color: #EDF0F1;
	border: 1px solid #EDF0F1;
	border-radius: 4px;
	padding: 10px;
	font-weight: 600;
    font-size: smaller;
    margin-top: 15px;
`;

const RButton = styled.div`
	background-color: #0CABA6;
    color: #ffffff;
    border: 1px solid #0CABA6;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 12px;
    padding: 7px;
    font-weight: 600;
`;

const GroupHeader = styled.div`
	background-color: #0CABA6;
    color: #ffffff;
    border: 1px solid #0CABA6;
    font-size: 12px;
    padding: 10px;
    font-weight: 600;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

const GroupBody = styled.div`
	border: 1px solid #e6e6e6;
	background-color: #ffffff;
	border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px 15px;
`;

const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #2f2f2f;
    z-index: 5001;
`;

const Section50 = styled.div`
	width: 50%;
	float: left;
	box-sizing: border-box;
`;

const SectionHeader1 = styled.div`
	color: #717070;
	font-weight: 600;
	font-size: 12px;
`;

const SectionHeader2 = styled.div`
	color: #717070;
	font-size: 11px;
	font-weight: 600;
	padding-bottom: 7px;
`;

const SubHeader = styled.div`
	font-size: 9px;
    color: #969595;
    font-style: italic;
    padding-bottom: 7px;
`;

const Rhr = styled.div`
	background-color: #b3b3b3;
	width: 100%;
	height: 1px;
	margin: 20px 0px;
`;
const SButton = styled.div`
	text-align: center;
	cursor: pointer;
	background-color: #2F5597;
	color: #ffffff;
	border: 1px solid #2F5597;
	border-radius: 4px;
	margin-top: 10px;
	font-size: 12px;
	padding: 7px;
	font-weight: 600;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

class CSLReportModal extends React.Component
{
	state = {show_preview: false, stop_preview: false, data: null, groupby_selections: null, orientation:'portrait'};

	constructor(props) {
    	super(props);
    	this.scrollDiv = React.createRef();
  	}


  	componentDidMount() {
  		this.setState({data: this.props.data})
  	}

	changeSort = (type) => (event) => {
		event.preventDefault();
		let data = JSON.parse(JSON.stringify(this.state.data));
		if (type === "field") {
			data.sortby.selected.accessor = event.target.value;
		}
		if (type === "order") {
			data.sortby.selected.order = event.target.value;
		}
		this.setState({data});
	}

	changeGroup = (groupby) => {
        let data = JSON.parse(JSON.stringify(this.state.data));
        data.groupby = groupby;
        this.setState({data: data, groupby_selections: groupby.selections});
	}

	addGroupBy = (event) => {
		event.preventDefault();
		let groupby = JSON.parse(JSON.stringify(this.state.data.groupby));
		//console.log("groupby",groupby)
		groupby.selections.push({accessor: groupby.fields[0].accessor, sort_order: "desc"});
		//console.log('addGroupBy groupby', groupby)
		this.changeGroup(groupby);
	}

	deleteGroupBy = (position) => (event) => {
		event.preventDefault();
		let newselections = [];
		let groupby = JSON.parse(JSON.stringify(this.props.data.groupby));
		groupby.selections.forEach((gs, index) => {
			if (position !== index) {
				newselections.push(gs);
			}
		})
		groupby.selections = JSON.parse(JSON.stringify(newselections));
		this.changeGroup(groupby);
	}

	changeGroupBy = (position) => (event) => {
		event.preventDefault();
		let groupby = JSON.parse(JSON.stringify(this.state.data.groupby));
		// console.log('i am here')

		groupby.selections.forEach((gs, index) => {
			if (position === index) {
				gs.accessor = event.target.value;
			}
		})
		this.changeGroup(groupby);
	}

	changeSortOrder = (position) => (event) => {
		event.preventDefault();
		let groupby = JSON.parse(JSON.stringify(this.state.data.groupby));
		groupby.selections.forEach((gs, index) => {
			if (position === index) {
				gs.sort_order = event.target.value;
			}
		})
		this.changeGroup(groupby);
	}

	preview = (event) => {
		event.preventDefault();
		//console.log("Preview Data:", this.props.data);
		this.setState({show_preview: true});
	}

	stopPreview = () => {
		this.setState({stop_preview: true});
	}

	hideReport = (event) => {
		event.preventDefault();
		this.props.hideReport();
	}

	changeOrientation = (mode) => (event) => {
		event.preventDefault();
		this.props.toggleOrientation(mode)
		this.setState({orientation: mode});
	}



	render()
	{
		if(this.state.data === null)return <div></div>
		console.log('ReportModal this.props', this.props)
		// console.log('ReportModal this.props.data.sortby', this.props.data.sortby)
		let groupby_selections = {}
		for(let s of this.props.data.groupby.selections) {
			groupby_selections[s.accessor] = 1
		}
		
		return (
			<div>
				<InactiveOverlay />            
				<ReviewBodyContainer ref={this.scrollDiv}>
				<ReportModalHeader>Configure Report</ReportModalHeader>
				<CloseBtn onClick={this.hideReport}><FaTimes /></CloseBtn>
				<div style={{clear: "both"}}></div>
				<div style={{clear: 'both'}} />
				<InfoText>* Fields with a single value throughout the table are excluded.</InfoText>
				<ReportSubHeader>Set your report parameters to generate based on your current filtered view</ReportSubHeader>
				<div style={{clear: 'both'}} />
				<InfoText>* Fields with a single value throughout the table are excluded.</InfoText>
				<ReviewBody>
				{
					this.state.data.groupby.selections.map((sel, index) => {
						console.log('ReportModalSelect sel', sel)
						return (
							<div key={index} style={{marginTop: "10px"}}>
								<GroupHeader>
									<div style={{float: "left", marginTop: "4px"}}>Level {index + 1} Grouping/Ordering</div>
									<div style={{float: "right", marginTop: "3px", fontSize: "17px", cursor: "pointer"}} onClick={this.deleteGroupBy(index)}><FaMinusCircle /></div>
									<div style={{clear: "both"}}></div>
								</GroupHeader>
								<GroupBody>
									<div key={100000 + index} style={{width: '50%', float: "left"}}>
										<SectionHeader2>Group By {index === 0 ? "(Primary)" : ""}</SectionHeader2>
										<SubHeader>Set the {index === 0 ? "primary" : "additional"} grouping for the report</SubHeader>
										<MRModalSelect style={{width: "95%"}} value={sel.accessor} onChange={this.changeGroupBy(index)}>
											{
                                                this.props.data.groupby.fields.map((field) => {                                                    
                                                    if(!(field.accessor in groupby_selections) || field.accessor === sel.accessor)
                                                        return <option key={field.accessor} value={field.accessor}>{field.nickname}</option>;
                                                })                                                
											}
										</MRModalSelect>
									</div>
									<div key={10 * index} style={{width: '50%', float: 'right'}}>
										<SectionHeader2>Order {index === 0 ? "(Primary)" : ""}</SectionHeader2>
										<SubHeader>Define the sorting for the {index === 0 ? "primary" : "additional"} group</SubHeader>
										<MRModalSelect style={{width: "95%"}} value={sel.sort_order} onChange={this.changeSortOrder(index)}>
											<option value="desc">Descending</option>
											<option value="asc">Ascending</option>
										</MRModalSelect>
									</div>
									<div style={{clear: "both"}}></div>
								</GroupBody>
							</div>
						);
					})
				}
				<RButton>
					<div style={{float: "left", marginTop: "4px"}}>Add level {this.state.data.groupby.selections.length + 1} Grouping/Ordering</div>
					<div style={{float: "right", marginTop: "3px", fontSize: "17px", cursor: "pointer"}} onClick={this.addGroupBy}><FaPlusCircle /></div>
					<div style={{clear: "both"}}></div>
				</RButton>
				<Rhr />
				<div style={{marginTop: "10px"}}>
					<GroupHeader>
						<div style={{float: "left", marginTop: "4px"}}>Sort records by:</div>
						<div style={{clear: "both"}}></div>
					</GroupHeader>
					<GroupBody>
						<div style={{marginTop: "5px"}}>
							<Section50>
								<SectionHeader2 style={{paddingBottom: "5px"}}>Field</SectionHeader2>
								<MRModalSelect style={{width: "95%"}} value={this.state.data.sortby.selected.accessor} onChange={this.changeSort("field")}>
									<option value="none">None</option>
									{									
	                                    this.props.data.sortby.fields.map((field) => {
	                                        if(!(field.accessor in groupby_selections))
	                                            return <option key={field.accessor} value={field.accessor}>{field.nickname}</option>;
	                                    })
									}
								</MRModalSelect>
							</Section50>
							<Section50>
								<SectionHeader2 style={{paddingBottom: "5px"}}>Order</SectionHeader2>
								<MRModalSelect value={this.state.data.sortby.selected.order} onChange={this.changeSort("order")}>
									<option value="Descending">Descending</option>
									<option value="Ascending">Ascending</option>
								</MRModalSelect>
							</Section50>
							<div style={{clear: "both"}}></div>
						</div>
					</GroupBody>
				</div>
				<Rhr />

				<div style={{width: "100%", paddingBottom:"30px"}}>
					<div style={{float:"left", width: "60%"}}>
						<RButton style={{width: "25%", textAlign: "center", cursor: "pointer"}} onClick={this.preview}>Preview Configuration</RButton>
					</div>
					<div style={{float:"right", width: "39%"}}>
						{
							(()=>{
								if(this.state.orientation ==='portrait'){
									return(
										<div style={{ width: "100%"}}>
											<SButton style={{width: "20%", textAlign: "center", cursor: "pointer", display:"inline-block", float:"right", marginLeft:"5px", marginRight:"5px", backgroundColor:"#B4C7E7"}} onClick={this.changeOrientation('landscape')}>Landscape</SButton>
											<SButton style={{width: "20%", textAlign: "center", cursor: "no-drop", display:"inline-block", float:"right"}}>Portrait</SButton>
										</div>
										)
								}else{
									return(
										<div style={{ width: "100%"}}>
											<SButton style={{width: "20%", textAlign: "center", cursor: "no-drop", display:"inline-block", float:"right", marginLeft:"5px", marginRight:"5px"}} >Landscape</SButton>
											<SButton style={{width: "20%", textAlign: "center", cursor: "pointer", display:"inline-block", float:"right", backgroundColor:"#B4C7E7"}} onClick={this.changeOrientation('portrait')}>Portrait</SButton>
										</div>
										)
								}
							})()
						}
					</div>
				</div>
				<CSLPreviewConfig
					data={this.state.data}
					show_preview={this.state.show_preview}
					stop_preview={this.state.stop_preview}
					//stopPreview={this.stopPreview}
					hideReport={this.props.hideReport}
					toggleGenerate={this.props.toggleGenerate}
				/>
				</ReviewBody>
			</ReviewBodyContainer>
			</div>            
		);
	}
	scrollToMyRef = () => this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
}

export default CSLReportModal;